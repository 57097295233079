import React, { Component } from 'react';
import FreeArtistPayment from '../../artists/application_process/FreeArtistPayment';

class ArtistPayment extends Component {

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <FreeArtistPayment />
    )
  }
}


export default ArtistPayment
