import React, { Component } from "react";
import Title from "../layouts/Title";
import Nav from "./Nav";

class Dashboard extends Component {
  render() {
    if (this.props.currentUser) {
      return (
        <div className="sm-dashboard">
          <Title location={this.props.location} />
          <Nav
            location={this.props.location}
            currentUser={this.props.currentUser}
          />
          {this.props.children}
        </div>
      );
    } else {
      return (
        <div className="sm-dashboard permission-denied">
          Access Not Allowed. Please Sign In.
        </div>
      );
    }
  }
}

export default Dashboard;
