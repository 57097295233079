import React from 'react';
import { Field, reduxForm } from 'redux-form/immutable';
import { required } from '../../utils/form_validators'; 


class DeactivateAccountButton extends React.Component {

  renderField = ({
    input,
    placeholder,
    type,
    labelName,
    meta: { touched, error }
  }) => {
    return (
      <div>
        <label className="sm-field-label">
          {labelName}
        </label>
        <div>
          <input {...input} placeholder={placeholder} type={type} />
          {touched && error &&
            <div className='sm-error-message'>
              Please confirm deactivation.
            </div>
          }
        </div>
      </div>
    )
  }

  onSubmit = () => {
    window.location.href = "/opt_out"
  }

  render() {
    const { handleSubmit } = this.props;
    return (
      <div className="sm-deactivate-form">
        <form className="sm-form" onSubmit={handleSubmit(this.onSubmit)}>
          <div className='field-wrap checkbox-wrap'>
            <Field 
              labelName="I'm sure I want to deactivate: "
              type="checkbox"
              component={this.renderField}
              name="confirmed"
              validate={required} />
          </div>
          <button type="submit" className="btn btn-tan btn-large">Deactivate Account</button>
        </form>
      </div>
    )
  }
}

const DeactivateAccountButtonForm = reduxForm({
  form: 'DeactivateAccountButton'
})(DeactivateAccountButton)

export default DeactivateAccountButtonForm

