import React from 'react';
import { Link } from 'react-router-dom';


class NotFound extends React.Component {

  render() {
    return (
      <div className="sm-not-found">
        <div className="title-main title-long" id="title-not-found">
          <h1>Page not found</h1>
        </div>
        <div className="sm-not-found-main">
          <h3>The page you are looking for is unavailable.</h3>

          <h4>We recently launched the new <Link to="/">SkinMotion.com</Link></h4>

          <p> Some of the links that used to point to pages on our previous website 
              may no longer work.<br /> We're sorry for the inconvenience.</p>

          <p><Link to="/"><b>Go to the homepage</b></Link></p>
        </div>
      </div>
    )    
  }
}

export default NotFound