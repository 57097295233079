import React from 'react'
import { connect } from 'react-redux'

import {
  fetchMarker,
  claimMarker,
  completeMarker,
  activateMarker,
} from '../../actions/markers'

import DownloadPhoto from './activation/DownloadPhoto'
import UploadPhotos from './activation/UploadPhotos'
import ConfigureOverlay from './activation/ConfigureOverlay'
import RedoOrActivate from './activation/RedoOrActivate'

class ActivateMarker extends React.Component {

  state = {
    redoingProcess: false,
    uploadingPhotos: false,
    imgSrc: null,
    maskSrc: null,
    overlayData: null,
  }

  componentDidMount () {
    this.props.fetchMarker(this.props.match.params.markerId)
    this.props.claimMarker(this.props.match.params.markerId)
  }

  getMarker = (props = null) => {
    const { markers } = props || this.props
    const { markerId } = this.props.match.params

    const marker = markers.get(parseInt(markerId))
    return marker
  }

  activationComponentToRender () {
    let marker = this.getMarker();
    let showRedoOrActivate = (marker.get('artk_data_generating') || marker.get('is_complete') && !this.state.redoingProcess)
    if (showRedoOrActivate) { return RedoOrActivate }
    if (!this.state.uploadingPhotos) { return DownloadPhoto }
    if (!this.state.imgSrc) { return UploadPhotos }
    return ConfigureOverlay
    // else { return CheckoutAndActivate }
  }

  uploadPhotos = () => {
    this.setState({ uploadingPhotos: true })
  }

  uploadedImageAndMask = (imgSrc, maskSrc) => {
    this.setState({
      imgSrc: imgSrc,
      maskSrc: maskSrc,
    })
  }

  completeMarker = (overlayData) => {
    const markerParams = {
      image: this.state.imgSrc,
      mask: this.state.maskSrc,
      overlayData: overlayData
    }
    this.setState({overlayData: overlayData})

    return this.props.completeMarker(this.props.match.params.markerId, markerParams)
    .then(() => {
      this.setState({ redoingProcess: false })
      return Promise.resolve();
    })
  }

  activateMarker = () => {
    this.props.activateMarker(this.props.match.params.markerId)
    .then(() => this.props.push('/admin/manage-tattoos'))
  }

  redoProcess = () => {
    this.setState({ redoingProcess: true })
  }

  render () {
    const marker = this.getMarker()
    if (!marker) { return null }
    const {
      imgSrc,
      maskSrc,
    } = this.state

    const ActivationStepComponent = this.activationComponentToRender()

    return (
      <div className='sm-activate-marker'>
        <ActivationStepComponent
          marker={marker}
          uploadPhotos={this.uploadPhotos}
          imgSrc={imgSrc}
          maskSrc={maskSrc}
          uploadedImageAndMask={this.uploadedImageAndMask}
          completeMarker={this.completeMarker}
          activateMarker={this.activateMarker}
          redoProcess={this.redoProcess}
        />
      </div>
    )
  }
}

const mapStateToProps = (store, props) => ({
  markers: store.get('markers'),
  currentUser: store.get('currentUser'),
  artists: store.get('artists'),
})


const mapDispatchToProps = dispatch => ({
  fetchMarker: markerId => dispatch(fetchMarker(markerId)),
  claimMarker: markerId => dispatch(claimMarker(markerId)),
  completeMarker: (markerId, params) => dispatch(completeMarker(markerId, params)),
  activateMarker: markerId => dispatch(activateMarker(markerId)),
})


export default connect(mapStateToProps, mapDispatchToProps)(ActivateMarker)
