import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class LicenseAgreement extends Component {

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div className='sm-license-agreement'>
        <div className='title-main title-long' id='title-license-agreement'>
          <h1>TATTOO ARTIST LICENSE AGREEMENT</h1>
        </div>
        <div className='sm-license-agreement-main'>
          <p>THIS ARTIST LICENSE AGREEMENT (the "<b>Agreement</b>") dated as of the consent and submission date (the "<b>Effective Date</b>") 
          is made and entered into by and between Skin Motion and you (“You”) with respect to Skin Motion’s proprietary tattoos 
          (the "<b>Tattoos</b>") and augmented reality technology that brings Tattoos to life.</p>

          <p>PLEASE REVIEW THIS AGREEMENT CAREFULLY. BY CLICKING “I AGREE” AND SUBMITTING THIS AGREEMENT TO SKIN MOTION, YOU AGREE TO BE 
          BOUND BY ALL OF THE TERMS AND CONDITIONS CONTAINED HEREIN. DO NOT CLICK “I AGREE” UNLESS YOU UNDERSTAND AND CONSENT TO THESE TERMS. 
          ONCE THIS AGREEMENT IS SUBMITTED TO SKIN MOTION, IT IS A BINDING CONTRACT.</p>

          <p>You desire to register and become a licensed tattoo artist with Skin Motion, which will enable you to provide Skin Motion Tattoos 
          to customers (the “<b>Services</b>”). In order to obtain a license, You agree as follows:</p>

          <p><b>1.	GRANT OF LICENSE:</b> Subject to the terms and conditions of this Agreement, Skin Motion hereby grants to You, during the 
          License Period (defined below), a revocable, non-exclusive, non-transferrable license to use the Skin Motion trademark 
          (the “<b>Mark</b>”), the soundwave tattoo artwork in print and digital forms, (the “<b>Artwork</b>”), aspects of the soundwave 
          tattoo technology necessary to tattoo clients with the Artwork (the “<b>Technology</b>”), and portions of the Skin Motion mobile 
          application as determined by Skin Motion (the “<b>App</b>”) (collectively, the “<b>Proprietary Assets</b>”) solely in connection 
          with the provision of Services (the “<b>License</b>”). All rights not specifically granted herein are reserved by Skin Motion.</p>

          <p><b>2.	USE OF TRADEMARK:</b> You must use the Mark in connection with all tattoos that utilize the Artwork, Technology or the App. 
          You may not combine the Mark with any other trademark or trade name without the prior express written consent of Skin Motion. This 
          means You need to call the Tattoos “SKIN MOTION TATTOOS” or "SOUNDWAVE TATTOOS".</p>

          <p><b>3.	WEBSITE LISTING:</b> As long as you maintain the License and are not in violation of this Agreement, you shall be 
          listed on Skin Motion’s website and App as authorized to provide Skin Motion Tattoos.</p>

          <p><b>4.	LICENSE PERIOD:</b> The License Period shall commence on the Effective Date and shall continue 
          (the “<b>License Period</b>”) unless terminated as provided herein.</p>

          <p><b>5.	PAYMENT:</b> There is no payment required at this time.</p>
          
          <p><b>6.	QUALITY AND APPROVALS:</b> You agree that Your Services and the Tattoos shall:</p>

          <p>(i) be of high standard in style, appearance and quality;</p>

          <p>(ii) not deviate in any manner from the materials provided by Skin Motion;</p>

          <p>(iii) meet Skin Motion’s quality standards and specifications as promulgated by Skin Motion from time to time;</p>

          <p>(iv) be designed, administered, and promoted in accordance with all applicable national, regional, state and local laws, treaties, 
          regulations, rules, and ordinances; and</p>

          <p>(v) shall not be toxic or hazardous to public health or safety and shall comply with all applicable health and safety regulations; and</p>

          <p>(vi) shall be free of any defect which could result in injury to persons receiving a Tattoo or result in the failure of the App 
          to work on the Tattoo.</p>

          <p><b>7.	Advertising, Promotional and Display Materials:</b> Before finalizing, using or distributing any advertising, promotional, 
          display, publicity or other similar materials bearing the Mark or any of the other Proprietary Assets, You shall deliver same to 
          Skin Motion for its prior written approval.</p>

          <p><b>8.	ARTWORK AND OWNERSHIP OF PROPERTY:</b></p>

          <p>(a)	<b>Artwork.</b> Skin Motion shall provide to You the Artwork through this website or the App. The Artwork shall be and 
          shall remain the property of Skin Motion. At a Tattoo customer’s request, You may be asked to create additional original artwork 
          on, around or incorporating the Tattoo Artwork (the “<b>Additional Artwork</b>”). As between You and Skin Motion, You own any 
          Additional Artwork, provided however that you hereby grant Skin Motion a royalty-free, fully paid, non-exclusive, perpetual, 
          non-terminable, sub-licensable worldwide right and license to (a) use the Additional Artwork in connection with the Proprietary 
          Assets and in connection with the use, advertising, production, and promotion of the Proprietary Assets in any and all media 
          now known or hereinafter devised; and (b) grant the right to others to use and display the Additional Artwork in connection 
          with the Proprietary Assets.</p>

          <p>(b)	<b>Ownership.</b> You hereby acknowledge and agree that the Proprietary Assets as well as any ideas, confidential or 
          proprietary information, artwork, trade names, technology, patents, materials, designs and processes using, based on, derived 
          from or relating to the Proprietary Assets and any other intellectual property given to You by Skin Motion shall be and shall 
          remain the sole and exclusive property of Skin Motion.</p>

          <p>(c)	<b>Your Contributions.</b> In the event that you make any suggestions, contributions, changes, modifications, 
          improvements, derivatives, edits, or additions to the Proprietary Assets (“<b>Contributions</b>”), you hereby acknowledge 
          and agree that such Contributions shall be a work made for hire and Skin Motion shall own all Contributions. In the event 
          that such Contributions are not a work made for hire, You hereby irrevocably assign all of your right, title and interest 
          in and to the Contributions to Skin Motion. You acknowledge that You shall not be entitled to any compensation for Your 
          Contributions. You agree to take all steps reasonably necessary to assist Skin Motion in securing, recording, documenting 
          and perfecting all rights in the Contributions. In the event that You fail to sign any document reasonably requested by Skin Motion 
          to transfer and assign any and all rights in the Contributions, You hereby appoint Skin Motion as your Attorney-in-Fact 
          (such appointment to be coupled with an interest) to execute on Your behalf any and all documents deemed necessary by Skin Motion 
          to transfer and assign to Skin Motion all of Your right, title and interest, if any, in and to the Contributions.</p>

          <p>(d)	<b>No Contest.</b> You hereby recognize and acknowledge all of Skin Motion’s rights and interests in and to the 
          Proprietary Assets, and that all use of the Proprietary Assets licensed hereunder inures to the benefit of Skin Motion. 
          You agree not to take any action which may harm or adversely affect Skin Motion’s rights or goodwill in the Proprietary Assets, 
          including without limitation using or registering a name or mark which is identical to or confusingly similar to any name or 
          mark included in the Proprietary Assets, or using or registering any patent that is identical, similar, uses, based on, derived 
          from or related in any way to any patentable rights included in the Proprietary Assets. Further, You agree not to challenge the 
          validity of Skin Motion’s ownership of the Proprietary Assets either during the Licensed Period or thereafter.</p>

          <p>(e)	<b>Limitations.</b> No right, title, or interest, in and to the Proprietary Assets, except the limited License specified 
          in Section 1, is granted by this Agreement. You may not copy (except as expressly permitted by this Agreement), decompile, 
          reverse-engineer, disassemble, attempt to derive the source code of, modify, or create derivative works of the Proprietary Assets.</p>

          <p><b>9.	NO WARRANTY BY SKIN MOTION:</b> THE PROPRIETARY ASSETS ARE PROVIDED AS IS, WHERE IS WITH ALL FAULTS. SKIN MOTION 
          DISCLAIMS ANY EXPRESS OR IMPLIED WARRANTY, INCLUDING WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND 
          NON-INFRINGEMENT. IN NO EVENT SHALL SKIN MOTION BE LIABLE FOR ANY MATTER WHATSOEVER RELATING TO YOUR USE OF THE PROPRIETARY 
          ASSETS AND YOU AGREE TO BE SOLELEY RESPONSIBLE FOR YOUR PROVISION OF THE SERVICES.</p>

          <p><b>10.	LIMITIATION OF LIABILITY:</b> YOU ASSUME ALL RESPONSIBILITY AND RISK FOR USE OF THE PROPRIETARY ASSETS AND THE 
          PROVISION OF YOUR SERVICES. TO THE FULLEST EXTENT ALLOWABLE UNDER APPLICABLE LAW, IN NO EVENT SHALL SKIN MOTION OR ANY OF 
          ITS DIRECTORS, OFFICERS, MEMBERS, EMPLOYEES, SHAREHOLDERS, PARTNERS, AFFILIATES, LICENSEES, ASSIGNS, CONTRACTORS, REPRESENTATIVES, 
          OR AGENTS BE LIABLE FOR ANY INCIDENTAL, INDIRECT, PUNITIVE, EXEMPLARY, OR CONSEQUENTIAL DAMAGES WHATSOEVER (INCLUDING DAMAGES 
          FOR LOSS OF PROFITS, INTERRUPTION, LOSS OF BUSINESS INFORMATION, OR ANY OTHER PECUNIARY LOSS) IN CONNECTION WITH ANY CLAIM, 
          LOSS, DAMAGE, ACTION, SUIT OR OTHER PROCEEDING ARISING UNDER OR OUT OF THIS AGREEMENT, INCLUDING WITHOUT LIMITATION YOUR USE 
          OF, RELIANCE UPON, ACCESS TO, OR EXPLOITATION OF THE PROPRIETARY ASSETS, OR ANY RIGHTS GRANTED TO YOU HEREUNDER, EVEN IF YOU 
          HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES, WHETHER THE ACTION IS BASED ON CONTRACT, TORT (INCLUDING NEGLIGENCE), 
          INFRINGEMENT OF INTELLECTUAL PROPERTY RIGHTS OR OTHERWISE. YOUR SOLE REMEDY IS TO CEASE USE OF THE PROPRIETARY ASSETS. 
          NOTWITHSTANDING SUCH LIMITATION, UNDER NO CIRCUMSTANCES SHALL SKIN MOTION’S MAXIMUM AGGREGATE LIABILITY TO YOU BE MORE THAN 
          TWO HUNDRED AND FIFTY DOLLARS ($250.00).</p>

          <p><b>11.	WARRANTY BY YOU:</b> You expressly warrant that you have (i) all necessary legal capacity, right, power, 
          and authority to enter into and be bound by this Agreement and (ii) all the skills, knowledge and ability to create precise 
          Tattoos in accordance with the specifications set forth by Skin Motion. You further expressly warrant that you have all 
          necessary legal permits to operate as a tattoo artist wherever you provide the Services and agree to provide Skin Motion 
          with documentation of such legal capacity upon Skin Motion’s request.</p>

          <p><b>12.	INDEMNITY:</b> You shall indemnify and defend Skin Motion against, and hold Skin Motion harmless from, all claims, 
          liabilities, suits, losses, damages, and expenses (including reasonable attorneys’ fees) brought by a third party against 
          Skin Motion arising out of or relating to Your provision of the Services or any breach or alleged breach of any of your duties, 
          obligations, representations and warranties set forth herein.</p>

          <p><b>13.	INSURANCE:</b>	During the License Period, You shall obtain and maintain in full force, at your own expense, sufficient 
          insurance coverage as is customary for tattoo artists and for You to meet Your obligations created by this Agreement and by law.</p>

          <p><b>14.	DEFAULT AND TERMINATION:</b> Skin Motion may in its sole discretion terminate the License upon seven (7) calendar days’ 
          written notice for any reason or for no reason upon written notice to You. Skin Motion may also terminate the License immediately 
          upon written notice to You in the event that You violated any duties, obligations, terms, conditions, representations or warranties 
          contained in this Agreement. Email notification will be sufficient to satisfy Skin Motion’s obligation to provide written notice 
          under this Section.</p>

          <p><b>15.	EFFECT OF TERMINATION:</b> Upon termination or expiration of the License, all rights granted to You hereunder shall 
          immediately terminate and You shall have no right, either directly or indirectly, to provide the Services or use or exploit in 
          any manner the Proprietary Assets. In addition, Skin Motion shall remove You from the Website and App and You are not permitted 
          to identify Yourself as having a license, registration or authorization to perform the Services or use the Proprietary Assets. 
          Termination of the License under the provisions of this Agreement shall be without prejudice to any rights which Skin Motion 
          may otherwise have against You.</p>

          <p><b>16.	NOTICES:</b> Any notice required or permitted to be given under this Agreement shall be given, in writing, to 
          you via the email address You provide Skin Motion in your registration profile. You may provide notice to Skin Motion at 
          support@skinmotion.com.</p>

          <p><b>17.	RELATIONSHIP BETWEEN THE PARTIES:</b> Neither party shall represent itself as the agent or legal representative 
          of the other party for any purpose whatsoever or hold itself out contrary to the terms of this section, and neither party 
          shall have the right to create or assume any obligation of any kind, express or implied, for or on behalf of the other party 
          in any way whatsoever. This Agreement shall not create or be deemed to create any agency, partnership, or joint venture 
          between the parties.</p>

          <p><b>18.	NO ASSIGNMENT OR SUBLICENSE:</b> This Agreement is personal to You and may not be sold, assigned, delegated, 
          sublicensed or otherwise transferred or encumbered, in whole or in part, including without limitation, by operation of 
          law, (each a “<b>Transfer</b>”) without the prior written consent of Skin Motion, which consent may be withheld by Skin Motion 
          in its sole discretion. Any attempted Transfer of any right to use any of the Proprietary Assets will immediately void the License.</p>

          <p><b>19.	ENTIRE AGREEMENT:</b> This Agreement is intended by the parties as a final and complete expression of their 
          agreement with respect to the subject matter hereof, and supersedes any and all prior and contemporaneous agreements, 
          representations and understandings relating to it. In the event of a conflict between this Agreement and the website or 
          App terms of service (or any other Skin Motion rules, regulations or instructions), the terms of this Agreement shall prevail.</p>

          <p><b>20.	MODIFICATION AND WAIVER:</b> This Agreement may not be modified and none of its terms may be waived, except in 
          writing signed by both parties. The failure of either party to enforce, or the delay by either party in enforcing, any of its 
          rights shall not be deemed a continuing waiver or a modification of this Agreement.</p>

          <p><b>21.	SEPARABILITY:</b> If any part of this Agreement shall be declared invalid or unenforceable by a court of competent 
          jurisdiction, it shall not affect the validity of the balance of this Agreement.</p>

          <p><b>22.	GOVERNING LAW/JURISDICTION AND VENUE:</b> These Agreement shall be governed by and construed in accordance with the 
          laws of the State of California, U.S.A., without regard to conflicts of laws principles, as if this were a contract entered 
          into and wholly performed within the State of California. Any disputes or differences between the parties arising out of this 
          Agreement, which the parties are unable to resolve themselves shall be submitted to and resolved by arbitration as herein 
          provided. Any controversy or claim arising out of or relating to this Agreement, or the breach thereof, shall be settled 
          by arbitration administered by the American Arbitration Association in accordance with its Commercial Arbitration Rules, 
          and judgment on the award rendered by the arbitrator may be entered in any court having jurisdiction thereof. The place of 
          arbitration shall be Los Angeles, California. Notwithstanding the foregoing, Skin Motion shall be entitled to seek injunctive 
          or other equitable relief in any court of competent jurisdiction located in Los Angeles, California and you consent to personal 
          jurisdiction of any court of competent jurisdiction in Los Angeles, California. Access to or use of this Site is unauthorized 
          in any jurisdiction that does not give effect to all provisions of this Agreement, including, without limitation, this paragraph.</p>

          <p><b>23.  ATTORNEY’S FEES AND COSTS:</b> Should it become necessary for any party to this Agreement to bring a claim in 
          arbitration and/or litigation to enforce any provision herein, or for damages on account of any breach of this Agreement, 
          the prevailing party shall be entitled to recover from the other party or parties all costs and expenses incurred in the 
          arbitration and/or litigation, which includes but is not limited to the recovery of reasonable attorney’s fees and court costs.</p>

          <p><b>24.	CONSTRUCTION OF THE AGREEMENT:</b> The parties agree that in the event of a dispute concerning the meaning or 
          interpretation of the Agreement, a court or arbitrator shall not apply any rule of law that holds or requires that an 
          ambiguous contract provision shall be construed against the party responsible for drafting the provision.</p>

          <p><b>25.  ATTORNEY REVIEW:</b> You have had the opportunity to have Your attorney review the terms and conditions of 
          this Agreement and you have either done so or waived that right.</p>

          <p><b>26.	SURVIVAL:</b> Sections 8 through 13 and 15 through 26 shall survive the termination or expiration of this Agreement.</p>

          <p>BY CLICKING “I AGREE”, I ACKNOWLEDGE THAT I HAVE READ THE ENTIRETY OF THIS AGREEMENT, I UNDERSTAND EACH OF THE TERMS, 
          CONDITIONS, AND OBLIGATIONS CONTAINED HEREIN, AND I AGREE TO BE BOUND BY THIS AGREEMENT.</p>
        </div>
      </div>
    )
  }

}

export default LicenseAgreement
