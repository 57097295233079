import API from '../utils/API'

export const RECEIVE_TOP_REFERRERS = 'TOP_REFERRERS/RECEIVE_TOP_REFERRERS'

export const receiveTopReferrers = topReferrers => ({
  type: RECEIVE_TOP_REFERRERS,
  topReferrers,
})

export const fetchTopReferrers = () =>
  dispatch => {
    API.get('/top-referrers')
    .then((topReferrers) =>
      dispatch(receiveTopReferrers(topReferrers))
    )
  }
