import React from "react";
import Immutable from "immutable";
import { Field, reduxForm, change } from "redux-form/immutable";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
// import API from 'utils/API';
import { required, passwordMatchValidate } from "../../utils/form_validators";
import { trimSpaces, lower } from "../../utils/form_normalizers";
import { geocodeWrapper } from "../../utils/geolocation";
import ReCAPTCHA from "react-google-recaptcha";

const FORM_NAME = "Signup";

const renderField = (props) => {
  const additionalClasses =
    props.meta.touched && props.meta.error ? props.meta.error : "";
  return (
    <input
      {...props.input}
      placeholder={props.placeholder}
      type={props.type}
      className={`${props.className} ${additionalClasses}`}
    />
  );
};

class SignupForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showZip: false,
      error: null,
      submitting: null,
      captchaSolved: false,
    };
  }

  componentDidMount() {
    const { userLocation } = this.props;
    if (userLocation.get("user")) {
      this.dispatchFormChange(userLocation.get("user"));
    } else {
      this.setState({ showZip: true });
    }
  }

  componentWillReceiveProps(nextProps) {
    const { userLocation } = nextProps;
    const user = userLocation.get("user");
    if (user && !Immutable.is(user, this.props.userLocation.get("user"))) {
      this.dispatchFormChange(user);
      this.setState({ showZip: false });
    }
  }

  dispatchFormChange(userLocation) {
    this.props.dispatch(
      change(FORM_NAME, "geolocation.lat", userLocation.get("lat"))
    );
    this.props.dispatch(
      change(FORM_NAME, "geolocation.lng", userLocation.get("lng"))
    );
  }

  // This whole flow is still fucked cause the error message isn't getting passed through
  // all the promises, so for now it's janky af. TODO -- Make better
  signUpUser = (params) => {
    return this.props.onSubmit(params).catch((error) => {
      return Promise.reject("That email is already in use");
    });
  };

  onSubmit = (signupParams) => {
    // If the zip field is present, it means we couldn't
    // geolocate by IP for some reason, so we use GMaps Geocoder API to
    // try and figure it out

    // TODO -- DRY this up...
    let params = {};
    this.setState({ submitting: true });
    if (!this.state.captchaSolved) {
      return this.setState({
        error: "Please solve captcha",
        submitting: false,
      });
    }
    if (signupParams.get("zip")) {
      return geocodeWrapper(signupParams.get("zip"))
        .then((geolocation) => {
          params = signupParams.set("geolocation", geolocation);
          return this.signUpUser(params).catch(() => {
            this.setState({
              error: "That email is already taken!",
              submitting: false,
            });
            return Promise.reject();
          });
        })
        .catch((error) => {
          console.log(error, "errormsg");
          params = signupParams.set(
            "geolocation",
            Immutable.fromJS({ lat: null, lng: null })
          );
          return this.signUpUser(params).catch(() => {
            this.setState({
              error: "That email is already taken! ",
              submitting: false,
            });
            return Promise.reject();
          });
        });
    } else {
      params = signupParams;
      return this.signUpUser(params).catch(() => {
        this.setState({
          error: "That email is already taken!",
          submitting: false,
        });
        return Promise.reject();
      });
    }
  };

  onChange = (value) => {
    if (value) this.setState({ captchaSolved: true });
  };

  render() {
    const { handleSubmit, error, submitFailed, onSubmit } = this.props;
    return (
      <div className="sm-form-container">
        <div className="sm-form">
          <h4>Create your Account</h4>
          {submitFailed && !this.state.submitting && (
            <h3 className="sm-error-message">
              {this.state.error ? this.state.error : error}
            </h3>
          )}

          <form onSubmit={handleSubmit(this.onSubmit)}>
            <div className="sm-signup-contents">
              <div className="field-wrap">
                <label htmlFor="first_name">First Name: </label>
                <Field
                  type="text"
                  name="first_name"
                  component={renderField}
                  validate={required}
                  className="required"
                  placeholder="First Name..."
                />
              </div>
              <div className="field-wrap">
                <label htmlFor="last_name">Last Name: </label>
                <Field
                  type="text"
                  name="last_name"
                  component={renderField}
                  validate={required}
                  className="required"
                  placeholder="Last Name..."
                />
              </div>
              <div className="field-wrap">
                <label htmlFor="email">Email: </label>
                <Field
                  type="email"
                  name="email"
                  component={renderField}
                  validate={required}
                  normalize={(trimSpaces, lower)}
                  className="required"
                  placeholder="Email..."
                />
              </div>
              <div className="field-wrap">
                <label htmlFor="password">Password: </label>
                <Field
                  type="password"
                  name="password"
                  component={renderField}
                  validate={required}
                  className="required"
                  placeholder="Password..."
                />
              </div>
              <div className="field-wrap">
                <label htmlFor="confirm_password">Confirm Password: </label>
                <Field
                  type="password"
                  name="confirm_password"
                  validate={required}
                  className="required"
                  component={renderField}
                  placeholder="Confirm Password..."
                />
              </div>
              {this.state.showZip && (
                <div className="field-wrap">
                  <label htmlFor="zip">Zip: </label>
                  <Field
                    type={this.state.showZip ? "text" : "hidden"}
                    name="zip"
                    component={renderField}
                    validate={required}
                    className="required"
                    placeholder="Zipcode..."
                  />
                </div>
              )}
              <div className="field-wrap">
                <ReCAPTCHA
                  sitekey={this.props.siteKey}
                  onChange={this.onChange}
                  type="image"
                />
              </div>

              <div className="license-text">
                <p>
                  {" "}
                  <Field
                    type="checkbox"
                    component={renderField}
                    name="term"
                    validate={required}
                    className="required"
                  />{" "}
                  I have read and agreed to the
                  <span className="license-text">
                    {" "}
                    <Link to="terms-and-conditions">
                      Terms of Service{" "}
                    </Link>{" "}
                  </span>
                  &nbsp;and&nbsp;
                  <span className="license-text">
                    {" "}
                    <Link to="privacy-policy"> Privacy Policy</Link>{" "}
                  </span>
                </p>
                <p></p>
                <p>
                  <Field
                    type="checkbox"
                    component={renderField}
                    name="license"
                    validate={required}
                    className="required"
                  />{" "}
                  I agree to receive occasional emails from Skin Motion about
                  promotions and special offers which I can unsubscribe at any
                  time.
                </p>
              </div>

              {/* <div className="checkbox-wrap">
                <Field
                  type="checkbox"
                  component={renderField}
                  name="license"
                  validate={required}
                  className="required"
                />
                <p>I Agree</p>
              </div> */}

              <button
                type="submit"
                className="btn btn-tan btn-large"
                disabled={this.state.submitting}
              >
                {this.state.submitting ? "Creating Account..." : "Create Account"}
              </button>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

const ReduxFormSignupForm = reduxForm({
  form: FORM_NAME,
  validate: passwordMatchValidate,
})(SignupForm);

const mapStateToProps = (store) => ({
  userLocation: store.get("userLocation"),
});

export default connect(mapStateToProps, null)(ReduxFormSignupForm);
