import React, { Component } from "react";
// import Immutable from 'immutable';
import { connect } from "react-redux";
import { fetchActiveArtists } from "../../actions/artists";
import {
  dispatchSearchLocation,
  clearSearchLocation,
} from "../../actions/userLocation";
import ArtistsTable from "../artists/ArtistsTable";
import Advertisement from "../layouts/Advertisement";
import GoogleMap from "../artists/GoogleMap";
import { geocodeWrapper } from "../../utils/geolocation";
import { Link } from "react-router-dom";

class ArtistDirectory extends Component {
  constructor(props) {
    super(props);

    this.state = {
      search: "",
      searchRadius: 300,
      sortBy: "closest",
      searchObject: null,
    };
  }

  componentDidMount() {
    this.props.fetchActiveArtists();
    window.scrollTo(0, 0);
    document.addEventListener("keydown", this.handleKeyPress);
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.handleKeyPress);
  }

  handleKeyPress = (e) => {
    if (e.which == 13) {
      this.search();
    }
  };

  onChange = (evt) => {
    this.setState({ [evt.target.name]: evt.target.value });
  };

  search = () => {
    let address = this.state.search;
    if (address) {
      geocodeWrapper(address)
        .then((location) => {
          let searchObject = {
            address: address,
            location: location.toJS(),
          };
          this.setState({ searchObject });
          this.props.dispatchSearchLocation(searchObject);
        })
        .catch(() => console.log("Couldn't find what you searched for"));
    } else {
      this.props.clearSearchLocation();
    }
  };

  render() {
    const { userLocation } = this.props;
    let searchAddress = userLocation.get("search")
      ? userLocation.get("search").get("address")
      : "";
    return (
      <div className="sm-artist-directory">
        <div className="map-input d-flex flex-row justify-content-center">
          <div className="location-form d-flex align-items-center">
            <input
              type="text"
              name="search"
              placeholder="City or Zipcode"
              defaultValue={searchAddress}
              onChange={this.onChange}
            />
            <div className="radius-holder">
              <select name="searchRadius" onChange={this.onChange}>
                <option> Search Radius </option>
                <option value="20">20 miles</option>
                <option value="50">50 miles</option>
                <option value="100">100 miles</option>
                <option value="200">200 miles</option>
                <option value="300">300 miles</option>
              </select>
            </div>
            <button
              onClick={this.search}
              className="btn btn-blue"
              type="submit"
            >
              {" "}
              Search{" "}
            </button>
          </div>
        </div>

        <div className="google-maps">
          <GoogleMap
            artists={this.props.artists}
            searchRadius={this.state.searchRadius}
          />
        </div>

        <div className="results-section sm-content">
          <div className="results-table">
            {/*
            <div className='results-header header-row d-flex flex-row justify-content-start align-items-end'>
              <div className='sort pl-4'>
                <select name='sortBy' onChange={this.onChange}>
                  <option value='closest'>Sort by Closest</option>
                  <option value='other'>Sort by Highest Rated</option>
                  <option value='other'>Sort by Most Rated</option>
                </select>
              </div>
            </div>
            */}
            <ArtistsTable
              searchRadius={this.state.searchRadius}
              search={this.state.search}
              searchObject={this.state.searchObject}
              artists={this.props.artists}
            />
          </div>
        </div>
        <div className="ad-large">
          <Advertisement />
        </div>
        <div className="cta-section">
          <div className="cta-box">
            <h3>
              Are you a talented tattoo artist and want new clients to find you
              here? Join the Skin Motion Artist Directory.{" "}
            </h3>
            <Link to="/apply" className="btn btn-blue">
              Apply Here
            </Link>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (store) => ({
  artists: store.get("artists"),
  userLocation: store.get("userLocation"),
  mobileMode: store.get("mobileMode"),
});

const mapDispatchToProps = (dispatch) => ({
  fetchActiveArtists: (params) => dispatch(fetchActiveArtists(params)),
  dispatchSearchLocation: (location) =>
    dispatch(dispatchSearchLocation(location)),
  clearSearchLocation: () => dispatch(clearSearchLocation()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ArtistDirectory);
