import React, { Component } from 'react';
// import serialize from 'form-serialize';
import { connect } from 'react-redux';
import { updateUser } from '../../actions/currentUser';
import EditAccountForm from './EditAccountForm';

class AccountSettings extends Component {

  state = {
    editAccountFormSubmitted: false,
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  updateUser = (userParams, dispatch, props) => {
    this.setState({ editAccountFormSubmitted: true })
    return this.props.updateUser(this.props.currentUser.get('id'), {
      first_name: userParams.get('first_name'),
      last_name: userParams.get('last_name'),
      email: userParams.get('email')
    })
  }

  pad(n, width, z) {
    if(!isNaN(n)) {
      z = z || '0';
      n = n + '';
      return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;      
    }
  }

  render() {
    const { currentUser } = this.props; 

    return ( 
      <div className="sm-account-settings">
        <EditAccountForm
          onSubmit={this.updateUser}
          initialValues={this.props.currentUser}
          submitted={this.state.editAccountFormSubmitted}
        />

        <a href="/deactivate-account" className="sm-account-settings-deactivate">Deactive Account</a>
      </div>
    )
  }
}

const mapStateToProps = store => ({
  currentUser: store.get('currentUser'),
  mobileMode: store.get('mobileMode')
})

const mapDispatchToProps = dispatch => ({
  updateUser: (userId, userParams) => dispatch(updateUser(userId, userParams))
})

export default connect(mapStateToProps, mapDispatchToProps)(AccountSettings)
