import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { isUsersTattoo } from '../../../models/marker';
import TattooBillingUpdate from '../../account/tattoos/TattooBillingUpdate';
import TattooBillingCancel from '../../account/tattoos/TattooBillingCancel';
import TattooBillingPrepay from '../../account/tattoos/TattooBillingPrepay';
import { fetchMarker } from '../../../actions/markers';

class ManageTattooBilling extends React.Component {

  state = {
    marker: null,
    defaultSource: null
  }

  componentDidMount() {
    let markerId = parseInt(this.props.match.params.markerId);
    this.setState({ defaultSource: this.props.currentUser.get('current_purchase_source') })

    if(!this.props.markers.get(markerId)) {
      this.props.fetchMarker(markerId)
      .then(() => {
        this.setState({ marker: this.props.markers.get(markerId) })
      }); 
    } else {
      this.setState({ marker: this.props.markers.get(markerId) })
    }
  }

  getBillingComponent() {
    if(!this.props.match.params.action) {
      return TattooBillingUpdate
    } else if(this.props.match.params.action == 'cancel') {
      return TattooBillingCancel
    } else if(this.props.match.params.action == 'discount') {
      return TattooBillingPrepay
    }
  }

  render() {
    if(!this.state.marker) { return null }
    const { marker, defaultSource } = this.state
    const { stripeKey, currentUser } = this.props
    const BillingComponent = this.getBillingComponent()

    return (
      <div className="sm-tattoo-billing">
        <BillingComponent
          defaultSource={defaultSource}
          marker={marker}
          stripeKey={stripeKey}
          currentUser={currentUser} />
      </div>
    )
  }
}

const mapStateToProps = (store) => ({
  markers: store.get('markers').filter(marker => isUsersTattoo(marker, store.get('currentUser'))),
  currentUser: store.get('currentUser')
})

const mapDispatchToProps = (dispatch) => ({
  fetchMarker: (markerId) => dispatch(fetchMarker(markerId))
})

export default connect(mapStateToProps, mapDispatchToProps)(ManageTattooBilling)