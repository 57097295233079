import API from "../utils/API";
import { geocodeWrapper } from "../utils/geolocation";
import { fetchCurrentUser } from "./currentUser";

export const RECEIVE_ALL_ARTISTS = "ARTISTS/RECEIVE_ALL_ARTISTS";
export const RECEIVE_ACTIVE_ARTISTS = "ARTISTS/RECEIVE_ACTIVE_ARTISTS";
export const RECEIVE_ARTIST = "ARTISTS/RECEIVE_ARTIST";
export const REMOVE_ARTIST = "ARTISTS/REMOVE_ARTIST";

export const receiveArtist = (artist) => ({
  type: RECEIVE_ARTIST,
  artist,
});

export const receiveAllArtists = (artists) => ({
  type: RECEIVE_ALL_ARTISTS,
  artists,
});

export const receiveActiveArtists = (artists) => ({
  type: RECEIVE_ACTIVE_ARTISTS,
  artists,
});

export const removeArtist = (artistID) => ({
  type: REMOVE_ARTIST,
  artistID,
});

export const createArtistProfile = (userId, profileData) => (dispatch) => {
  return prepareProfileData(profileData, userId)
    .then((params) => {
      // debugger
      return API.post("/artist_profiles", params).then((artist) => {
        dispatch(receiveArtist(artist));
        dispatch(fetchCurrentUser(userId));
        return Promise.resolve();
      });
    })
    .catch((e) => {
      // debugger
      Promise.reject(e);
    });
};

export const updateArtistProfile = (artistId, profileData) => (dispatch) => {
  // debugger
  return prepareProfileData(profileData)
    .then((params) => {
      // debugger
      return API.patch(`/artist_profiles/${artistId}`, params)
        .then((artist) => {
          // debugger
          dispatch(receiveArtist(artist));
          return Promise.resolve();
        })
        .catch((e) => console.error("e", e));
    })
    .catch((e) => Promise.reject(e));
};

export const addArtistReview = (artistId, reviewData) => (dispatch) => {
  return API.patch(`/artist_profiles/${artistId}`, reviewData).then(
    (artist) => {
      dispatch(receiveArtist(artist));
      return Promise.resolve();
    }
  );
};

export const fetchAllArtists = () => (dispatch) => {
  return API.get("/artist_profiles").then((artists) => {
    dispatch(receiveAllArtists(artists));
    return Promise.resolve();
  });
};

export const fetchActiveArtists = () => (dispatch) => {
  return API.get("/artist_profiles").then((artists) => {
    dispatch(receiveActiveArtists(artists));
    return Promise.resolve();
  });
};

export const fetchArtist = (artistId) => (dispatch) => {
  // console.log("fetchhhhh");
  return API.get(`/artist_profiles/${artistId}`).then((artist) => {
    dispatch(receiveArtist(artist));
    return Promise.resolve();
  });
};

const prepareProfileData = (profileData, userId = null) => {
  // debugger
  let image = profileData["profile_image_src"];

  if (profileData["profile_photo"]) {
    // debugger
    let imageMeta = profileData["profile_photo"][0];
    if (imageMeta) {
      // debugger
      let serializable = {
        name: imageMeta.name,
        type: imageMeta.type,
        size: imageMeta.size,
      };
      profileData["profile_photo"] = serializable;
    }
  }

  return getLocation(profileData)
    .then((location) => {
      // debugger
      profileData["location"] = location.toJS();
      let params = {
        profile_data: JSON.stringify(profileData),
      };
      if (image) {
        // debugger
        params["profile_image_src"] = image;
      }
      if (userId) {
        // debugger
        params["user_id"] = userId;
      }
      return Promise.resolve(params);
    })
    .catch((e) => Promise.reject(e));
};

const getLocation = (profileData) => {
  // debugger
  let address = `${profileData["business_address"]}, ${profileData["business_city"]}, ${profileData["business_state"]}`;
  return geocodeWrapper(address)
    .then((location) => {
      // debugger
      return Promise.resolve(location);
    })
    .catch((e) => Promise.reject(e));
};
