import React from 'react'

class ActivatorManager extends React.Component {

  render() {
    return (
      <div className="sm-manage-activators">
        Activator management coming soon!
      </div>
    )
  }
}

export default ActivatorManager