import React from 'react'

import { downloadBlob } from '../../../utils/general'

class DownloadPhoto extends React.Component {

  downloadImage = () => {
    fetch(this.props.marker.get('tattooImageURL'))
    .then(res => res.blob())
    .then(blob => downloadBlob(blob, `tattoo_image_${this.props.marker.get('uuid')}.png`))
  }

  render () {
    const { marker } = this.props

    return (
      <div className='sm-download-photo'>
      <h3>1. Download the high res photo by clicking the button below:</h3>
        <img src={marker.get('tattooImageURL')} className='tattoo-img' />

        <button type='button' onClick={this.downloadImage} className='btn btn-tan'> Download Image </button>


      <h3>2. Compare the photo to the Soundwave below and rotate the photo to match, left to right</h3>
        <img src={marker.get('imageURL')} className='tattoo-img' />
        <button type='button' onClick={this.props.uploadPhotos} className='btn btn-tan'> Continue to Upload Photo & Mask </button>
      </div>
    )
  }
}


export default DownloadPhoto
