import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { reducer as form } from "redux-form/immutable"; // <--- immutable import
import { combineReducers } from "redux-immutable";
import createHistory from "history/createBrowserHistory";
import { routerReducer, routerMiddleware, push } from "react-router-redux";

/* Skin Motion Reducers */
import currentUser from "./currentUser";
import artists from "./artists";
import markers from "./markers";
import instagram from "./instagram";
import topReferrers from "./topReferrers";
import vouchers from "./vouchers";
import artistApplications from "./artistApplications";
import users from "./users";
import blogFeed from "./blogFeed";
import userLocation from "./userLocation";
import mobileMode from "./mobileMode";
import voucherTransfers from "./voucherTransfers";
import campaigns from "./campaigns";
import stripe from "./stripe";

export const history = createHistory();
// Build the middleware for intercepting and dispatching navigation actions
const rtrMiddleware = routerMiddleware(history);

const skinMotionReducer = combineReducers({
  currentUser,
  artists,
  markers,
  instagram,
  topReferrers,
  vouchers,
  stripe,
  form,
  artistApplications,
  blogFeed,
  users,
  userLocation,
  mobileMode,
  voucherTransfers,
  campaigns,
  router: routerReducer,
});

export const skinMotionStore = createStore(
  skinMotionReducer,
  applyMiddleware(thunk),
  applyMiddleware(rtrMiddleware)
);
