import React from "react";
import { Link } from "react-router-dom";

// import TattooPreview from 'components/activation/TattooPreview'

class TattooListComponent extends React.Component {
  state = {
    imgSrc: null,
    maskSrc: null,
    overlayData: null,
  };

  componentDidMount() {
    this.loadImage();
    if (this.props.marker.get("is_active")) {
      this.loadMask();
      this.loadOverlayData();
    }
  }

  componentDidUpdate(prevProps) {
    const currentMarker = this.props.marker;
    const oldMarker = prevProps.marker;

    if (
      currentMarker.get("tattooImageURL") != oldMarker.get("tattooImageURL")
    ) {
      this.loadImage();
    }
    if (!oldMarker.get("is_active") && currentMarker.get("is_active")) {
      this.loadMask();
      this.loadOverlayData();
    }
  }

  loadImage = () => {
    if (!this.props.marker.get("tattooImageURL")) {
      return;
    }
    fetch(this.props.marker.get("tattooImageURL"))
      .then((res) => {
        return res.blob();
      })
      .then((blob) => {
        const reader = new FileReader();

        reader.addEventListener("load", () => {
          this.setState({ imgSrc: reader.result });
        });

        reader.readAsDataURL(blob);
      });
  };

  loadMask = () => {
    fetch(this.props.marker.get("overlayURL"))
      .then((res) => res.blob())
      .then((blob) => {
        const reader = new FileReader();

        reader.addEventListener("load", () => {
          this.setState({ maskSrc: reader.result });
        });

        reader.readAsDataURL(blob);
      });
  };

  loadOverlayData = () => {
    this.setState({ overlayData: this.props.marker.get("overlayData").get(0) });
  };

  uploadAnotherPhoto = () =>
    this.props.push(`/upload-photo/${this.props.marker.get("id")}`);

  continueCreation = () => {
    if (!this.props.marker.get("tattooImageURL")) {
      this.props.push(`/tattoos/${this.props.marker.get("id")}/5`);
    } else {
      this.props.push(`/tattoos/${this.props.marker.get("id")}`);
    }
  };

  descriptionRow = () => {
    const { marker } = this.props;
    if (marker.get("is_active")) {
      return this.activatedDescriptionRow(marker);
    }
    if (marker.get("was_rejected")) {
      return this.rejectedDescriptionRow(marker);
    }
    return this.pendingDescriptionRow(marker);
  };

  activatedDescriptionRow = (marker) => (
    <div className="description-row d-flex flex-row justify-content-between align-items-center">
      <span className="created-on">
        {marker.get("name")} -{" "}
        <small>Activated on {marker.get("activation_date")}</small>
      </span>
      {/*<div className="links">
        <Link className='continue-editing btn btn-tan' to={`/my-tattoos/billing/${this.props.marker.get('id')}/`}>
          Manage Billing 
        </Link>
      </div>*/}
    </div>
  );

  pendingDescriptionRow = (marker) => (
    <div className="description-row d-flex flex-row justify-content-between align-items-center">
      <div className="created-on">
        {marker.get("name")} <span>Purchased - Activation Pending</span>
      </div>
      {marker.get("current_step") < 9 && (
        <div className="continue-creation">
          <button
            type="button"
            className="continue-editing btn btn-tan"
            onClick={this.continueCreation}
          >
            Continue Activation
          </button>
        </div>
      )}
    </div>
  );

  rejectedDescriptionRow = (marker) => (
    <div className="description-row d-flex flex-row justify-content-between align-items-center">
      <div className="created-on">
        {marker.get("name")}{" "}
        <span className="reactivate-alert">
          Your photo was reviewed is too low quality to activate your tattoo,
          please upload a better photo.
        </span>
      </div>

      <div className="links">
        <Link to={`/upload-photo/${marker.get("id")}`}>
          <button
            type="button"
            className="reupload-btn btn btn-tan"
            onClick={this.uploadAnotherPhoto}
          >
            {" "}
            Upload New Photo{" "}
          </button>
        </Link>
      </div>
    </div>
  );

  render() {
    const { imgSrc, maskSrc, overlayData } = this.state;

    const { marker } = this.props;
    let loading = false;
    if (!imgSrc) {
      loading = true;
    }

    return (
      <div className={`tattoo-list-item tattoo-${marker.get("id")}`}>
        {loading ? (
          <img src={marker.get("imageURL")} className="tattoo-img" />
        ) : (
          <img src={imgSrc} className="tattoo-img" />
        )}
        {this.descriptionRow()}
      </div>
    );
  }
}

export default TattooListComponent;
