import React from 'react'

class CampaignHeader extends React.Component {
	render() {
    return (
      <div className="sm-swcampaign-header">
      </div>
    )
  }
}

export default CampaignHeader