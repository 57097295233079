import API from "../utils/API";
import { receiveStripeStatus } from "./stripe";

export const RECEIVE_VOUCHERS = "VOUCHERS/RECEIVE_VOUCHERS";
export const RECEIVE_VOUCHER = "VOUCHERS/RECEIVE_VOUCHER";
export const RECEIVE_STRIPE_STATUS = "VOUCHERS/RECEIVE_STRIPE_STATUS";

export const receiveVouchers = (vouchers) => ({
  type: RECEIVE_VOUCHERS,
  vouchers,
});
export const receiveVoucher = (voucher) => ({
  type: RECEIVE_VOUCHER,
  voucher,
});

export const fetchVouchers = (id) => (dispatch) => {
  return API.get(`/tattoo_vouchers/${id}`).then((vouchers) => {
    dispatch(receiveVouchers(vouchers));
    dispatch(receiveStripeStatus(vouchers.is_stripe_customer));
    return Promise.resolve();
  });
};

export const redeemVoucher = (voucher_id, marker_id) => (dispatch) => {
  return API.patch(`/tattoo_vouchers/${voucher_id}`, {
    is_redeemed: true,
    marker_id: marker_id,
  }).then((voucher) => {
    dispatch(receiveVoucher(voucher));
    return Promise.resolve();
  });
};

export const refundVoucher = (voucher_id, marker_id, reason) => (dispatch) => {
  return API.patch(`/tattoo_vouchers/${voucher_id}`, {
    refund: true,
    marker_id: marker_id,
    reason: reason,
  }).then((voucher) => {
    dispatch(receiveVoucher(voucher));
    return Promise.resolve();
  });
};
