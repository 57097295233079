import React from 'react';
import { Link } from 'react-router-dom';

class AccountDeactivated extends React.Component {

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div className='account-deactiveted-page'>
        <div className="title-main title-long" id="title-forgot-password">
          <h1>ACCOUNT DEACTIVATED</h1>
        </div>
        <div className="sm-content">
          <h2>We're sad to see you go.</h2>
          <h4>Another time perhaps.</h4> 
          <p>Your account has been deactivated!</p>
          
        </div>
      </div>
    )
  }
}

export default AccountDeactivated
