import React, { Component } from "react";
import { Switch, Route } from "react-router-dom";
import Dashboard from "../account/Dashboard";
import Home from "../application/Home";
import PasswordReset from "../application/PasswordReset";
import ArtistDirectory from "../artists/ArtistDirectory";
import MobileDashboard from "../application/MobileDashboard";
import UnsolicitedIdeaPolicy from "../application/UnsolicitedIdeaPolicy";
import SoundwaveTattoos from "../tattoo_tools/SoundwaveTattoos";
import LoginForm from "../application/LoginForm";
import Signup from "../application/Signup";
import News from "../application/News";
import Videos from "../application/Videos";
import AboutUs from "../application/AboutUs";
import PressKit from "../application/PressKit";
import Press from "../application/Press";
import MySoundwaveStory from "../application/MySoundwaveStory";
import MarketingGuide from "../application/MarketingGuide";
import Tutorial from "../application/Tutorial";
import FAQs from "../application/FAQ";
import Demo from "../application/Demo";
import Support from "../application/Support";
import Download from "../application/Download";
import ArtistApplicationSplash from "../artists/application_process/ArtistApplicationSplash";
import ArtistApplicationManager from "../artists/application_process/ArtistApplicationManager";
import Affiliates from "../application/Affiliates";
import PrivacyPolicy from "../application/PrivacyPolicy";
import PatentPending from "../application/PatentPending";
import TermsAndConditions from "../application/TermsAndConditions";
import LicenseAgreement from "../application/LicenseAgreement";
import ReferralTerms from "../application/ReferralTerms";
import ForgotPassword from "../application/ForgotPassword";
import AccountDeactivated from "../account/AccountDeactivated";
import MyTattoos from "../account/tattoos/MyTattoos";
import ManageTattooBilling from "../account/tattoos/ManageTattooBilling";
import MyAccount from "../account/MyAccount";
import ManageVouchers from "../account/ManageVouchers";
import NewSoundwave from "../account/soundwaves/NewSoundwave";
import Soundwave from "../account/soundwaves/Soundwave";
import MySoundwaves from "../account/soundwaves/MySoundwaves";
import InfluencerTools from "../account/InfluencerTools";
import AccountSettings from "../account/AccountSettings";
import DeactivateAccount from "../account/DeactivateAccount";
import Payments from "../account/Payments";
import ChangePassword from "../account/ChangePassword";
import Admin from "../admin/Admin";
import CampaignFunnel from "../campaigns/CampaignFunnel";
import Nav from "../account/Nav";
import Title from "./Title";
import EditProfile from "../artists/EditProfile";
import ArtistDashboard from "../artists/ArtistDashboard";
import ArtistGuide from "../artists/ArtistGuide";
import ComingSoon from "../application/ComingSoon";
import ActivateMarker from "../admin/ActivateMarker";
import UploadReplacementPhoto from "../account/UploadReplacementPhoto";
import { connect } from "react-redux";
import { hasAdminPrivs, isArtist, hasActivatorPrivs } from "../../models/user";
import ArtistDetails from "../artists/ArtistDetails";
import ArtistReview from "../artists/ArtistReview";
import NotFound from "../application/NotFound";
// import ServerError from '../application/ServerError';
import { withRouter } from "react-router";

class Body extends Component {
  render() {
    const { currentUser, location, siteKey } = this.props;
    return (
      <div>
        <Route
          exact
          path="/not-found"
          location={location}
          component={NotFound}
        />
        {/* <Route
                    exact
                    path="/server-error"
                    location={location}
                    component={ServerError}
                /> */}
        <Route
          exact
          path="/artist-directory"
          location={location}
          render={(props) => {
            return <ArtistDirectory {...props} />;
          }}
        />
        {/*
        <Route exact path="/mobile-dashboard" location={location} render={(props) => {
          return (<MobileDashboard {...props} />)
        }} />
        */}
        <Route
          exact
          path="/soundwave-tattoos"
          location={location}
          render={(props) => {
            return <SoundwaveTattoos {...props} />;
          }}
        />
        <Route
          exact
          path="/artist-details/:id/:name?"
          location={location}
          render={(props) => {
            return <ArtistDetails {...props} />;
          }}
        />
        <Route
          exact
          path="/marketing-guide"
          location={location}
          render={(props) => {
            return <MarketingGuide {...props} />;
          }}
        />
        <Route
          exact
          path="/artist-guide"
          location={location}
          render={(props) => {
            return <ArtistGuide {...props} />;
          }}
        />
        <Route
          exact
          path="/unsolicited-idea-policy"
          location={location}
          render={(props) => {
            return <UnsolicitedIdeaPolicy {...props} />;
          }}
        />
        <Route
          exact
          path="/news"
          location={location}
          render={(props) => {
            return <News {...props} />;
          }}
        />
        <Route
          exact
          path="/videos"
          location={location}
          render={(props) => {
            return <Videos {...props} />;
          }}
        />
        <Route
          exact
          path="/about-us"
          location={location}
          render={(props) => {
            return <AboutUs {...props} />;
          }}
        />
        <Route
          exact
          path="/press"
          location={location}
          render={(props) => {
            return <Press {...props} />;
          }}
        />
        <Route
          exact
          path="/press-kit"
          location={location}
          render={(props) => {
            return <PressKit {...props} />;
          }}
        />
        <Route
          exact
          path="/tutorial"
          location={location}
          render={(props) => {
            return <Tutorial {...props} />;
          }}
        />
        <Route
          exact
          path="/faq"
          location={location}
          render={(props) => {
            return <FAQs {...props} />;
          }}
        />
        <Route
          exact
          path="/demo"
          location={location}
          render={(props) => {
            return <Demo {...props} />;
          }}
        />
        <Route
          exact
          path="/support"
          location={location}
          render={(props) => {
            return <Support {...props} />;
          }}
        />
        <Route
          exact
          path="/download"
          location={location}
          render={(props) => {
            return <Download {...props} />;
          }}
        />
        <Route
          exact
          path="/affiliates"
          location={location}
          render={(props) => {
            return <Affiliates {...props} />;
          }}
        />
        <Route
          exact
          path="/privacy-policy"
          location={location}
          render={(props) => {
            return <PrivacyPolicy {...props} />;
          }}
        />
        <Route
          exact
          path="/patent-pending"
          location={location}
          render={(props) => {
            return <PatentPending {...props} />;
          }}
        />
        <Route
          exact
          path="/terms-and-conditions"
          location={location}
          render={(props) => {
            return <TermsAndConditions {...props} />;
          }}
        />
        <Route
          exact
          path="/license-agreement"
          location={location}
          render={(props) => {
            return <LicenseAgreement {...props} />;
          }}
        />
        <Route
          exact
          path="/referrals-terms-and-conditions"
          location={location}
          render={(props) => {
            return <ReferralTerms {...props} />;
          }}
        />
        <Route
          exact
          location={location}
          path="/apply"
          render={(props) => {
            return <ArtistApplicationSplash {...props} />;
          }}
        />
        <Route
          exact
          location={location}
          path="/deactivated"
          render={(props) => {
            return <AccountDeactivated {...props} />;
          }}
        />
        <Route
          exact
          path="/mysoundwavestory"
          location={location}
          render={(props) => {
            return <MySoundwaveStory {...props} />;
          }}
        />
        <Route
          exact
          path="/campaign/:slug"
          location={location}
          render={(props) => {
            return (
              <CampaignFunnel
                showTerms={this.props.showTerms}
                showPrivacy={this.props.showPrivacy}
                {...props}
              />
            );
          }}
        />

        {currentUser ? (
          <div>
            <Route
              exact
              path="/admin/:adminComponent?/:userId?/:subComponent?/:objId?/:adminFunc?"
              location={location}
              render={(props) => (
                <Dashboard {...props} currentUser={this.props.currentUser}>
                  <Admin
                    push={props.history.push}
                    match={props.match}
                    location={location}
                    currentUser={this.props.currentUser}
                  />
                </Dashboard>
              )}
            />
            {hasActivatorPrivs(currentUser) && (
              <div>
                <Route
                  exact
                  path="/activate-marker/:markerId"
                  location={location}
                  render={(props) => (
                    <Dashboard {...props} currentUser={this.props.currentUser}>
                      <ActivateMarker
                        push={props.history.push}
                        match={props.match}
                        location={location}
                      />
                    </Dashboard>
                  )}
                />
              </div>
            )}
            {isArtist(currentUser) && (
              <div>
                <Route
                  exact
                  path="/artist-profile"
                  location={location}
                  render={(props) => (
                    <Dashboard {...props} currentUser={this.props.currentUser}>
                      <EditProfile
                        push={props.history.push}
                        match={props.match}
                        location={location}
                        stripeKey={this.props.stripeKey}
                      />
                    </Dashboard>
                  )}
                />

                <Route
                  exact
                  path="/artist-dashboard"
                  location={location}
                  render={(props) => (
                    <Dashboard {...props} currentUser={this.props.currentUser}>
                      <ArtistDashboard
                        push={props.history.push}
                        match={props.match}
                        location={location}
                      />
                    </Dashboard>
                  )}
                />
              </div>
            )}
            <Route
              exact
              path="/"
              location={location}
              render={(props) => {
                return <Home {...props} currentUser={this.props.currentUser} />;
              }}
            />
            <Route
              exact
              path="/my-tattoos"
              location={location}
              render={(props) => (
                <Dashboard {...props} currentUser={this.props.currentUser}>
                  <MyTattoos
                    push={props.history.push}
                    match={props.match}
                    location={location}
                  />
                </Dashboard>
              )}
            />
            <Route
              exact
              path="/mmarketing-guide"
              location={location}
              render={(props) => (
                <Dashboard {...props} currentUser={this.props.currentUser}>
                  <MarketingGuide
                    push={props.history.push}
                    match={props.match}
                    location={location}
                  />
                </Dashboard>
              )}
            />
            <Route
              exact
              path="/my-tattoos/billing/:markerId/:action?"
              location={location}
              render={(props) => (
                <Dashboard {...props} currentUser={this.props.currentUser}>
                  <ManageTattooBilling
                    push={props.history.push}
                    match={props.match}
                    location={location}
                  />
                </Dashboard>
              )}
            />
            <Route
              exact
              path="/upload-photo/:markerId"
              location={location}
              render={(props) => (
                <Dashboard {...props} currentUser={this.props.currentUser}>
                  <UploadReplacementPhoto
                    push={props.history.push}
                    match={props.match}
                    location={location}
                  />
                </Dashboard>
              )}
            />
            <Route
              exact
              path="/account-settings"
              location={location}
              render={(props) => (
                <Dashboard {...props} currentUser={this.props.currentUser}>
                  <AccountSettings
                    push={props.history.push}
                    match={props.match}
                    location={location}
                  />
                </Dashboard>
              )}
            />
            <Route
              exact
              path="/deactivate-account"
              location={location}
              render={(props) => (
                <Dashboard {...props} currentUser={this.props.currentUser}>
                  <DeactivateAccount
                    push={props.history.push}
                    match={props.match}
                    location={location}
                  />
                </Dashboard>
              )}
            />
            <Route
              exact
              path="/vouchers"
              location={location}
              render={(props) => (
                <Dashboard {...props} currentUser={this.props.currentUser}>
                  <ManageVouchers
                    push={props.history.push}
                    match={props.match}
                    location={location}
                  />
                </Dashboard>
              )}
            />
            <Route
              exact
              path="/payments"
              location={location}
              render={(props) => (
                <Dashboard {...props} currentUser={this.props.currentUser}>
                  <Payments
                    push={props.history.push}
                    match={props.match}
                    location={location}
                  />
                </Dashboard>
              )}
            />
            <Route
              exact
              path="/change-password"
              location={location}
              render={(props) => (
                <Dashboard {...props} currentUser={this.props.currentUser}>
                  <ChangePassword
                    siteKey={siteKey}
                    push={props.history.push}
                    match={props.match}
                    location={location}
                  />
                </Dashboard>
              )}
            />
            <Route
              exact
              path="/referrals"
              location={location}
              render={(props) => (
                <Dashboard {...props} currentUser={this.props.currentUser}>
                  <InfluencerTools
                    push={props.history.push}
                    match={props.match}
                    location={location}
                  />
                </Dashboard>
              )}
            />
            <Route
              exact
              path="/new-soundwave"
              location={location}
              render={(props) => (
                <Dashboard {...props} currentUser={this.props.currentUser}>
                  <NewSoundwave
                    push={props.history.push}
                    match={props.match}
                    location={location}
                  />
                </Dashboard>
              )}
            />
            <Route
              exact
              path="/my-soundwaves"
              location={location}
              render={(props) => (
                <Dashboard {...props} currentUser={this.props.currentUser}>
                  <MySoundwaves
                    push={props.history.push}
                    match={props.match}
                    location={location}
                  />
                </Dashboard>
              )}
            />
            <Route
              exact
              path="/soundwaves/:markerId/:step?"
              location={location}
              render={(props) => (
                <Dashboard {...props} currentUser={this.props.currentUser}>
                  <Soundwave
                    push={props.history.push}
                    match={props.match}
                    location={location}
                    stripeKey={this.props.stripeKey}
                  />
                </Dashboard>
              )}
            />
            <Route
              exact
              path="/tattoos/:markerId/:step?"
              location={location}
              render={(props) => (
                <Dashboard {...props} currentUser={this.props.currentUser}>
                  <Soundwave
                    push={props.history.push}
                    match={props.match}
                    location={location}
                    stripeKey={this.props.stripeKey}
                  />
                </Dashboard>
              )}
            />
            <Route
              exact
              path="/my-account"
              location={location}
              render={(props) => (
                <Dashboard {...props} currentUser={this.props.currentUser}>
                  <MyAccount
                    push={props.history.push}
                    match={props.match}
                    location={location}
                  />
                </Dashboard>
              )}
            />
            <Route
              exact
              path="/password-reset"
              location={location}
              render={(props) => {
                return (
                  <div className="sm-standalone-password-reset">
                    <div
                      className="title-main title-short"
                      id="title-password-reset"
                    >
                      <h1>Reset Password</h1>
                    </div>
                    <PasswordReset
                      {...props}
                      siteKey={siteKey}
                      currentUser={this.props.currentUser}
                    />
                  </div>
                );
              }}
            />
            <Route
              path="/activate/:token"
              location={location}
              render={(props) => {
                return (
                  <div className="sm-standalone-password-reset">
                    <PasswordReset
                      nextLocation="/"
                      {...props}
                      siteKey={siteKey}
                      currentUser={this.props.currentUser}
                    />
                  </div>
                );
              }}
            />
            <Route
              exact
              location={location}
              path="/artist-application"
              render={(props) => {
                return <ArtistApplicationManager {...props} />;
              }}
            />
            <Route
              exact
              path="/artist-review/:id"
              location={location}
              render={(props) => {
                return <ArtistReview {...props} />;
              }}
            />
          </div>
        ) : (
          <div>
            <Route
              exact
              path="/"
              location={location}
              render={(props) => {
                return <Home {...props} currentUser={this.props.currentUser} />;
              }}
            />
            <Route
              path="/ref/:referral"
              location={location}
              render={(props) => {
                return <Signup {...props} siteKey={siteKey} />;
              }}
            />
            <Route
              exact
              path="/login"
              location={location}
              render={(props) => {
                return (
                  <LoginForm
                    key="Login"
                    location={location}
                    siteKey={siteKey}
                    {...props}
                  />
                );
              }}
            />
            <Route
              exact
              path="/signup"
              location={location}
              render={(props) => {
                return <Signup {...props} siteKey={siteKey} />;
              }}
            />
            <Route
              exact
              location={location}
              path="/forgot-password"
              render={(props) => {
                return <ForgotPassword siteKey={siteKey} {...props} />;
              }}
            />
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (store) => ({
  currentUser: store.get("currentUser"),
  location: store.get("router").location,
});

export default connect(mapStateToProps)(Body);
