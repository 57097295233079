import React from "react";
import { Field, reduxForm } from "redux-form/immutable";
import { connect } from "react-redux";
import { submitQuiz } from "../../../actions/artistApplications";
import { fetchCurrentUser } from "../../../actions/currentUser";

const required = (value) =>
  value ? undefined : "All questions must be answered.";

class ArtistQuiz extends React.Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  onSubmit = (answers) => {
    this.props
      .submitQuiz(this.props.currentUser.get("application_id"), answers.toJS())
      .then(() => {
        this.props.fetchCurrentUser(this.props.currentUser.get("id"));
      });
  };

  render() {
    const { handleSubmit, error, submitFailed } = this.props;
    return (
      <div className="sm-artist-app-quiz">
        <div
          className="title-main title-long"
          id="title-artist-application-quiz"
        >
          <h1>Tattoo artist application</h1>
          <h2>Step 3 of 3 - Skin Motion Tattoo Artist Test</h2>
        </div>
        <div className="sm-artist-app-quiz-main">
          <h3>
            {" "}
            Please answer all questions below. You must pass with 100% correct
            to complete your registration
          </h3>
          {submitFailed && (
            <h3 className="sm-error-message">Please answer every question.</h3>
          )}

          <form onSubmit={handleSubmit(this.onSubmit)}>
            <h4>The Skin Motion app uses:</h4>
            <div>
              <Field
                name="answer_0"
                component="input"
                type="radio"
                value="0"
                validate={required}
              />
              <span>Computer Vision</span>
            </div>

            <div>
              <Field
                name="answer_0"
                component="input"
                type="radio"
                value="1"
                validate={required}
              />
              <span>Image Recognition</span>
            </div>
            <div>
              <Field
                name="answer_0"
                component="input"
                type="radio"
                value="2"
                validate={required}
              />
              <span>AR Cloud platform</span>
            </div>
            <div>
              <Field
                name="answer_0"
                component="input"
                type="radio"
                value="3"
                validate={required}
              />
              <span>All of the Above</span>
            </div>

            <h4>You can NOT customize a Soundwave Tattoo by making it:</h4>
            <div>
              <Field
                name="answer_1"
                component="input"
                type="radio"
                value="0"
                validate={required}
              />
              <span>Outlined</span>
            </div>
            <div>
              <Field
                name="answer_1"
                component="input"
                type="radio"
                value="1"
                validate={required}
              />
              <span>Curved</span>
            </div>
            <div>
              <Field
                name="answer_1"
                component="input"
                type="radio"
                value="2"
                validate={required}
              />
              <span>Colored</span>
            </div>
            <div>
              <Field name="answer_1" component="input" type="radio" value="3" />
              <span>Filled In</span>
            </div>

            <h4>
              A Soundwave Tattoo will not be recognized by the app unless it
              has:
            </h4>
            <div>
              <Field
                name="answer_2"
                component="input"
                type="radio"
                value="0"
                validate={required}
              />
              <span>Maximum contrast to the skin tone</span>
            </div>
            <div>
              <Field
                name="answer_2"
                component="input"
                type="radio"
                value="1"
                validate={required}
              />
              <span>Special ink</span>
            </div>
            <div>
              <Field
                name="answer_2"
                component="input"
                type="radio"
                value="2"
                validate={required}
              />
              <span>Random details</span>
            </div>
            <div>
              <Field
                name="answer_2"
                component="input"
                type="radio"
                value="3"
                validate={required}
              />
              <span>Been done very large</span>
            </div>

            <h4>A Soundwave Tattoo should NOT be placed:</h4>
            <div>
              <Field
                name="answer_3"
                component="input"
                type="radio"
                value="0"
                validate={required}
              />
              <span>Straight</span>
            </div>
            <div>
              <Field
                name="answer_3"
                component="input"
                type="radio"
                value="1"
                validate={required}
              />
              <span>Flat</span>
            </div>
            <div>
              <Field
                name="answer_3"
                component="input"
                type="radio"
                value="2"
                validate={required}
              />
              <span>Where the camera can see it</span>
            </div>
            <div>
              <Field
                name="answer_3"
                component="input"
                type="radio"
                value="3"
                validate={required}
              />
              <span>Around curved body parts</span>
            </div>

            <h4>Cross contamination is:</h4>
            <div>
              <Field
                name="answer_4"
                component="input"
                type="radio"
                value="0"
                validate={required}
              />
              <span>
                When different colors of tattoo ink accidentally mix together
              </span>
            </div>
            <div>
              <Field
                name="answer_4"
                component="input"
                type="radio"
                value="1"
                validate={required}
              />
              <span>
                Not a big deal it is a normal part of the tattoo process
              </span>
            </div>
            <div>
              <Field
                name="answer_4"
                component="input"
                type="radio"
                value="2"
                validate={required}
              />
              <span>Potentinally deadly</span>
            </div>
            <div>
              <Field
                name="answer_4"
                component="input"
                type="radio"
                value="3"
                validate={required}
              />
              <span>Properly disposing of anything with blood on it</span>
            </div>
            <div className="nav-buttons">
              <button className="btn btn-tan btn-large" type="submit">
                {" "}
                Submit{" "}
              </button>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

const ReduxFormArtistQuiz = reduxForm({ form: "ArtistQuiz" })(ArtistQuiz);

const mapStateToProps = (store) => ({
  currentUser: store.get("currentUser"),
});

const mapDispatchToProps = (dispatch) => ({
  submitQuiz: (applicationId, params) =>
    dispatch(submitQuiz(applicationId, params)),
  fetchCurrentUser: (userId) => dispatch(fetchCurrentUser(userId)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ReduxFormArtistQuiz);
