import Immutable from 'immutable';
import {
  RECEIVE_CAMPAIGN,
  RECEIVE_CAMPAIGNS
} from '../actions/campaigns';

const campaigns = (state=Immutable.Map(), action) => {
  switch (action.type) {
    case RECEIVE_CAMPAIGNS:
      let tempState = state
      action.campaigns.forEach(campaign => {
        tempState = tempState.set(campaign.id, Immutable.fromJS(campaign))
      })
      return tempState
    case RECEIVE_CAMPAIGN:
      return state.set(action.campaign.id, Immutable.fromJS(action.capaign))
    default:
      return state
  }
}

export default campaigns