import React, {Component, PropTypes} from 'react';
import Dropzone from 'react-dropzone';
import { Field } from 'redux-form';

class ProfileImageUploader extends Component {
  state = {
    imgPreview: null
  }

  render() {
    const {
      input: { onChange },
      dropzoneOptions,
      meta: { error, touched },
      name,
      imgPreview,
      currentImg,
      dropCallback } = this.props;

    return (
      <div className={`sm-profile-upload` + (error && touched ? ' has-error ' : '')}>
        <div className="sm-profile-upload-preview">
          {currentImg && !imgPreview && (
              <div className="sm-profile-upload-preview-image" style={{backgroundImage: 'url(' + currentImg +')'}}>
                &nbsp;
              </div>
          )}
          {imgPreview && (
            <div className="sm-profile-upload-preview-image" style={{backgroundImage: 'url(' + imgPreview +')'}}>
              &nbsp;
            </div>
          )}
        </div>
        <Dropzone
          {...dropzoneOptions}
          onDrop={(f) => {
            dropCallback(f);
            return onChange(f);
          }}
          className="dropzone-input"
          name={name}
        >
          <div className="sm-profile-image-dropzone">
            <img src="https://storage.googleapis.com/skinmotion-assets/images/logo/img-icon.png" alt="image" />
            <span className="mobile btn btn-tan">
            Upload Image
            </span> 
            <span className="desktop  btn btn-tan">
            Upload Image
            </span> 
            <span className='gray-text'>
            or drop your photo
            </span>
            <span>paste an image</span>
          </div>
        </Dropzone>
        {error && touched ? error : ''}
      </div>
    );
  }
}
export default props => <Field {...props} component={ProfileImageUploader} />;
