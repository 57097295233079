import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import ManageTattoos from "../admin/ManageTattoos";
import UserManager from "../admin/UserManager";
import Analytics from "../admin/Analytics";
import ApplicationReview from "../admin/ApplicationReview";
import ActivatorManager from "../admin/ActivatorManager";
import CampaignsAdmin from "../admin/CampaignsAdmin";

import { hasAdminPrivs, hasActivatorPrivs } from "../../models/user";
import { fetchUsers, fetchUser } from "../../actions/users";
import { fetchApplications } from "../../actions/artistApplications";
import { fetchActiveArtists } from "../../actions/artists";
import { updateUser } from "../../actions/currentUser";

class Admin extends Component {
  state = {
    selectedUser: null,
    isPageLoaded: false,
  };

  componentDidMount() {
    if (hasAdminPrivs(this.props.currentUser)) {
      const { userId } = this.props.match.params;
      this.setState({
        isPageLoaded: true,
      });
      // this.props.fetchUsers().then(() => {
      //   if (userId) {
      //     this.props.fetchUser(parseInt(userId));
      //   }
      // });
      // this.setSelectedUser(userId);
      // this.props.fetchApplications();
      // this.props.fetchActiveArtists();
    }
    window.scrollTo(0, 0);
  }

  componentWillReceiveProps(nextProps) {
    const { userId } = nextProps.match.params;
    if (parseInt(userId)) {
      this.setSelectedUser(userId);
    } else {
      this.clearSelectedUser();
    }
  }

  getComponentFromSubNav = (component) => {
    if (component == "manage-tattoos") {
      return ManageTattoos;
    }
    if (component == "analytics") {
      return Analytics;
    }
    if (component == "users") {
      return UserManager;
    }
    if (component == "artists") {
      return ApplicationReview;
    }
    if (component == "activators") {
      return ActivatorManager;
    }
    if (component == "campaigns") {
      return CampaignsAdmin;
    }
    if (!component) {
      return UserManager;
    }
  };

  setSelectedUser = (user_id) => {
    this.setState({ selectedUser: parseInt(user_id) });
  };

  clearSelectedUser = () => {
    this.setState({ selectedUser: null });
  };

  render() {
    const { currentUser } = this.props;
    const { adminComponent } = this.props.match.params;

    const AdminComponent = this.getComponentFromSubNav(adminComponent);
    if (hasAdminPrivs(currentUser)) {
      return (
        <div className="sm-admin">
          <div className="sm-admin-nav">
            <Link to="/admin/analytics"> App Analytics </Link> |
            <Link to="/admin/users"> Users </Link> |
            <Link to="/admin/artists"> Artists </Link> |
            <Link to="/admin/activators"> Activators </Link> |
            <Link to="/admin/campaigns"> Manage Campaigns </Link>
          </div>
          {/* {!this.props.users.size ? ( */}
          {!this.state.isPageLoaded ? (
            <div className="sm-admin-loading">
              <p> Loading User Data... </p>
              <div className="sm-loader"></div>
            </div>
          ) : (
            <AdminComponent
              push={this.props.push}
              match={this.props.match}
              fetchUser={this.props.fetchUser}
              fetchUsers={this.props.fetchUsers}
              updateUser={this.props.updateUser}
              selectedUser={this.props.users.get(this.state.selectedUser)}
            />
          )}
        </div>
      );
    } else if (
      hasActivatorPrivs(currentUser) &&
      adminComponent == "manage-tattoos"
    ) {
      return <ManageTattoos />;
    } else {
      return (
        <div className="sm-dashboard permission-denied">Access Denied.</div>
      );
    }
  }
}

const mapStateToProps = (store) => ({
  users: store.get("users"),
  applications: store.get("artistApplications"),
});

const mapDispatchToProps = (dispatch) => ({
  fetchUsers: () => dispatch(fetchUsers()),
  fetchUser: (userId) => dispatch(fetchUser(userId)),
  updateUser: (userId, userParams) => dispatch(updateUser(userId, userParams)),
  // fetchApplications: () => dispatch(fetchApplications()),
  // fetchActiveArtists: () => dispatch(fetchActiveArtists()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Admin);
