import React, {Component} from 'react';
import { connect } from 'react-redux';
import CampaignStepOne from '../campaigns/CampaignStepOne';
import CampaignStepTwo from '../campaigns/CampaignStepTwo';
import CampaignStepThree from '../campaigns/CampaignStepThree';
import { fetchCampaigns } from '../../actions/campaigns';

class CampaignFunnel extends Component {

  state = {
    step: 0,
    campaignEmail: null,
    campaign: null,
    newUserToken: null
  }

  componentDidMount() {
    const { slug } = this.props.match.params;
    this.props.fetchCampaigns()
    .then(() => {
      this.setState({ campaign: this.props.campaigns.find((campaign) => campaign.get('slug') === slug) })
    })
  }

  setStep = (step, newUserToken = null) => {
    if(newUserToken !== null) {
      this.setState({ newUserToken: newUserToken })
    }
    this.setState({step: step})
  }

  setCampaignEmail = (email) => {
    this.setState({ campaignEmail: email })
  }

  campaignStepToRender = () => {
    if(this.state.step == 0) {
      return CampaignStepOne
    } else if(this.state.step == 1) {
      return CampaignStepTwo
    } else {
      return CampaignStepThree
    }
  }

  render() {
    const CampaignStep = this.campaignStepToRender()
    if(this.state.campaign) {
      return (
        <CampaignStep 
          setStep={this.setStep} 
          setCampaignEmail={this.setCampaignEmail}
          campaignEmail={this.state.campaignEmail}
          campaign={this.state.campaign}
          showTerms={this.props.showTerms}
          showPrivacy={this.props.showPrivacy}
          newUserToken={this.state.newUserToken} />
      )
    } else {
      return (
        <h1> Campaign Not Found </h1>
      )
    }
  }
}

const mapStateToProps = (store) => ({
  campaigns: store.get('campaigns')
})

const mapDispatchToProps = dispatch => ({
  fetchCampaigns: () => dispatch(fetchCampaigns())
})

export default connect(mapStateToProps, mapDispatchToProps)(CampaignFunnel)