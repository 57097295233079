import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import StripeWrapper from "../../checkout/StripeWrapper";
import { fetchVouchers, redeemVoucher } from "../../../actions/vouchers";

class PurchaseActivation extends React.Component {
  state = {
    stripeActivation: null,
    voucherActivation: null,
    isLoading: false,
    isStripCustomer: false,
    voucherId: null,
    paymentURL: null,
  };

  componentDidMount() {
    window.scrollTo(0, 0);
    // const { step } = this.props.match.params;
    const step = window.location.pathname.split("/")[3];
    if (!step || step < this.props.marker.get("current_step")) {
      //console.log(`current_step at mount: ${marker.get('current_step')}`);
      let uri = this.getURIFromStep(
        parseInt(this.props.marker.get("current_step"))
      );
      this.props.push(
        `/${uri}/${this.props.marker.get("id")}/${this.props.marker.get(
          "current_step"
        )}`
      );
    }
    this.props.fetchVouchers(this.props.marker.get("created_by_user"));
  }

  getURIFromStep = (step) => {
    let uri = step >= 5 ? "tattoos" : "soundwaves";
    return uri;
  };

  activateWithVoucher = () => {
    this.setState({ isLoading: true });
    const firstKey = this.props.vouchers.keySeq().first();
    const voucher = this.props.vouchers.get(firstKey);
    const { marker } = this.props;

    this.setState({ voucherId: voucher.get("id") });

    // let paymentURL;

    this.setState({
      paymentURL: `/tattoo_voucher_payment/${marker.get("id")}`,
    });

    if (this.props.stripe) {
      this.props
        .redeemVoucher(voucher.get("id"), marker.get("id"))
        .then(() => this.props.nextStep());
    } else {
      this.setState({ stripeActivation: true });
    }
  };

  activateWithStripe = () => {
    this.setState({
      paymentURL: `/tattoo_payment/${this.props.marker.get("id")}`,
    });
    this.setState({ stripeActivation: true });
  };

  checkoutComplete = (promise) => {
    promise
      .then(() => this.props.nextStep())
      .catch(() => {
        return;
      });
  };

  render() {
    const { marker } = this.props;
    return (
      <div className="sm-waveform-tool common-padding ">
        <h2>PURCHASE SOUNDWAVE TATTOO ACTIVATION</h2>
        <div className="sm-soundwave-thumbnail-container-purchase">
          <div className="sm-soundwave-thumbnail">
            <img src={marker.get("imageURL")}></img>
          </div>
          <div className="sm-sw-actions">
            <h3>
              <span>Name:</span> {marker.get("name")}
            </h3>
            <p className="mb-0">
              <Link
                className="back-to-styling"
                to={`/soundwaves/${marker.get("id")}/2`}
              >
                Edit Soundwave
              </Link>
            </p>
            <p className="mb-0">
              <a
                href={marker.get("pdfURL")}
                target="_blank"
                className="download-pdf-link"
              >
                Download your Tattoo Stencil
              </a>
            </p>
          </div>
        </div>
        {/* SALE BANNER
          <div className="sm-activation-sale">
          <h2>Memorial Day sale</h2>
          <h3>Save $5 off the price of your Soundwave Tattoo activation.</h3>
          <h4>We want to honor the friends and family members who have given so much so that we could be here today.</h4>
          <p>This weekend only – Friday May 25th through Monday May 28th, 2018 – get your Soundwave Tattoo activation for $34.99. 
          Purchase your activation prior to getting your tattoo, and bring your stencil with you or send it 
          to your artist before your appointment!</p>
        </div>
        */}
        <div className="sm-activation-billing">
          <div className="price-table">
            <div className="sm-activation-desc left">
              <h3>Soundwave Tattoo</h3>
              <div className="sm-activation-price ">
                <h2>
                  {/* $34.99 <span>*/}
                  <s>$39.99</s>
                  {/*</span>*/}
                </h2>
              </div>
              <p>Activation for playback by Skin Motion app</p>
              <ul>
                <li>Activation and first year of service</li>
                <li>$14.99 each year after</li>
              </ul>
            </div>
          </div>
          <div className="sm-activation-guarantee">
            <p>
              Receive a 100% satisfaction guarantee on the cost of your
              Soundwave Tattoo activation by entering your Skin Motion licensed
              tattoo artist's secret key while activating your Soundwave Tattoo.
            </p>
          </div>
        </div>
        {this.props.vouchers.size > 0 && !this.state.stripeActivation && (
          <div className="sm-activation-voucher">
            <h3>You have a voucher for a Soundwave Tattoo activation</h3>
            <p>
              Would you like to use a voucher for this Soundwave Tattoo
              activation?*
            </p>
            <button
              className="btn btn-tan btn-large"
              // className="btn btn-tan btn-large btn-sm mt-1 mx-0 mb-4 fs-6"
              disabled={this.state.isLoading}
              onClick={this.activateWithVoucher}
            >
              {this.state.isLoading ? (
                <div className="spinner-border text-dark" role="status"></div>
              ) : (
                "Yes. Complete activation with my voucher"
              )}
            </button>
            <p className="voucher-disclaimer">
              * Playback of your Soundwave Tattoo by the Skin Motion app after
              the first year requires a $14.99 yearly service payment and is
              subject to the Skin Motion{" "}
              <a href="/terms-and-conditions">Terms of Use</a>.{" "}
            </p>
          </div>
        )}
        {this.state.stripeActivation ? (
          <StripeWrapper
            stripeKey={this.props.stripeKey}
            containerClass="sm-checkout-and-activate-form"
            currentUser={this.props.currentUser}
            amount={"3999"}
            paymentURL={this.state.paymentURL}
            handleResponse={this.checkoutComplete}
            voucherId={this.state.voucherId}
          >
            <p className="text-center">
              {" "}
              By clicking "Checkout" you agree to the Soundwave Tattoo&trade;{" "}
              <a href="/terms-and-conditions">Terms of Use</a> and your card
              will be charged for $39.99 and enrolled in yearly service.{" "}
            </p>
          </StripeWrapper>
        ) : (
          <div
            className="btn btn-tan btn-large pay-card"
            onClick={this.activateWithStripe}
          >
            Pay with Debit or Credit Card
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (store) => ({
  vouchers: store.get("vouchers"),
  stripe: store.get("stripe"),
  currentUser: store.get("currentUser"),
});

const mapDispatchToProps = (dispatch) => ({
  fetchVouchers: (id) => dispatch(fetchVouchers(id)),
  fetchMarker: (markerId) => dispatch(fetchMarker(markerId)),
  redeemVoucher: (voucher_id, marker_id) =>
    dispatch(redeemVoucher(voucher_id, marker_id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PurchaseActivation);
