import React from 'react'

class ArtistGuide extends React.Component {

	componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div className="sm-artist-guide">
        <div className='title-main' id='title-artist-guide'>
          <h1>Tattoo Artist Training Guide</h1>
        </div>
        <div className="sm-artist-guide-main">
          <h2>Skin Motion Tattoo Artist Training Guide</h2>
          <p>This is a comprehensive guide that covers all aspects of creating Soundwave 
          Tattoos&trade; specifically for Skin Motion&trade; officially licensed tattoo 
          artists. You will find all the information you need to know in this guide. If 
          you can not find the answer you are looking for, please contact 
          support@skinmotion.com and we will continue to update this guide.</p>
          <h3>Important Notes</h3>
          <ul>
            <li><p>Your client must upload their sound to their own account at SkinMotion.com. 
            They will generate a PDF file with a stencil of a Soundwave to provide you. 
            Soundwave Tattoos&trade; only play back for the user who created them at this 
            time. The user must be logged into their account in the Skin Motion mobile app 
            to play their tattoo. 
            </p></li>
            <li><p>More than one person can get the same audio file as a Soundwave Tattoo 
            as long as each person has their own unique Skin Motion account. Users should 
            never activate a sound more than once in a single account. A user who activates 
            a sound more than once (for example, if two people want the same tattoo) on 
            a single account will cause both to not play back at all.
            </p></li>
            <li><p>For in depth instructions to simplify a complex Soundwave 
            Stencil and details on how to make a Soundwave stencil that can be printed 
            quickly using Photoshop  &nbsp; 
             <a href="https://news.skinmotion.com/how-to-simplify-a-soundwave-tattoo-stencil/">
                click here
             </a>.
            </p></li>
          </ul>
          <h3>How the Skin Motion app works</h3>
          <ul>
            <li><p>The Skin Motion app uses computer vision (your phone’s camera) and 
            image recognition to identify the shape of user-created Soundwaves so that it 
            can play the sound back.
            </p></li>
            <li><p>The Skin Motion app requires a Soundwave Tattoo™ to have a lot of contrast in order 
            to identify the shape of the Soundwave so that it can be played back. Consider how the 
            tattoo will lighten as it heals due to your clients skin tone, so that you do the Soundwave bold 
            and dark enough that it will still be readable as it heals, lightens and fades over time. Do 
            not use light colored inks unless there is a dark outline around the shape of the Soundwave. 
            This is especially important on darker skin tones.
            </p></li>
            <li><p>Soundwaves of music often do not contain enough variation in their shape to be identified by 
            the Skin Motion app. This is because music is compressed, meaning the quiet parts and loud parts 
            have been “normalized” to create a Soundwave like the one below:</p>
            <img src="https://cdn.skinmotion.com/images/artist-guide/artist-guide-music1.jpg" alt="Example of a music Soundwave Tattoo that does not read"/> 
            <p>This Soundwave will not be identified by the Skin Motion app because it does not contain 
            enough variation in its shape. To get around this, you can add additional tattooed elements very 
            close to the stencil that will help the Skin Motion app identify which tattoo it is like in the example below:</p>
            <img src="https://cdn.skinmotion.com/images/artist-guide/artist-guide-music2.jpg" alt="Example of a music Soundwave Tattoo that does read"/> 
            </li>
          </ul>

          <h3>The Soundwave Stencil</h3>
          <ul>
            <li><p>The stencil is your guide. Follow it as closely as you can while doing a tattoo that will look 
            good and heal well.
            </p></li>
            <li><p>For in depth instructions to simplify a complex Soundwave 
            Stencil and details on how to make a Soundwave stencil that can be printed quickly using Photoshop &nbsp; 
             <a href="https://news.skinmotion.com/how-to-fix-an-overly-complicated-soundwave-stencil/">click here</a>.
            </p></li>
            <li><p>What makes a Soundwave Tattoo work – most importantly – is the shape of the outline. The Skin Motion app 
            scans for an image is recognizes in order to play it back. The app is trained on what to look for using a photo 
            of the tattoo that the user uploads.
            </p></li>
            <li><p>You can not remove any part of the stencil. If you want to change the stencil for the tattoo, 
            your client must go back and edit the Soundwave on their account at SkinMotion.com
            </p></li>
            <li><p>The tattoo must follow all the peaks and valleys in the Soundwave as closely as you can while 
            still making a solid tattoo. You may end up simplifying the design a bit to do it and that is okay in 
            most cases. You are absolutely not required to use a 3 liner to do every single little peak, in fact a 
            7 tight liner has worked best in our experience.
            </p></li>
            <li><p>You can thicken lines and soften extreme or thin peaks.
            </p></li>
            <li><p>When editing their Soundwave, your client can use the “Trim” button to remove “silent lines” from the 
            beginning or end of the tattoo.
            </p></li>
            <li><p>Your client can adjust the “Height” and “Width” sliders to create a Soundwave Stencil they find visually appealing. 
            You can resize their stencil proportionally larger or smaller as long as one of it’s dimensions is larger than 2inches or 5cm.
            </p>
            <img src="https://cdn.skinmotion.com/images/artist-guide/soundwave-generator-v1.jpg" 
            alt="Soundwave generator controls"/>
            </li>
            <li><p>If your client wants to remove  silent parts from the middle of the Soundwave, it must be done in an 
            audio editing program before the client uploads their audio to  their account at SkinMotion.com.
            </p></li>
            <li><p>The Skin Motion app assigns the specific audio that is uploaded to the specific image that it generates. 
            So, if any part is removed the playback will not match.
            </p></li>
            <li><p>Imagine the sound bar scrolling across the tattoo and matching the audio. This is the most important 
            part for following the stencil.
            </p></li>
            <li><p>Your client should have the Soundwave stencil PDF file before they come in for the appointment. If they do not, you 
            can help them upload their sound to their own account. They can save and email the Soundwave Stencil PDF to you. 
            This way artists can suggest changes to the stencil (if need be) ahead of time.
            </p>
            <li><p><strong>Preparing a Soundwave Stencil in Photoshop:</strong> Open the Soundwave Tattoo stencil PDF file. Isolate the shape of 
            the Soundwave by removing the background around it. Put the isolated Soundwave shape on its own layer. Using layer 
            styles, add a Color Overlay that is white. Using layer styles, add a 3-5 pixel Stroke that is black on the Inside of 
            the shape. This can be printed and run through a stencil machine.</p></li>
            <h4>Example Soundwave Stencil PDF</h4>
            <img src="https://cdn.skinmotion.com/images/artist-guide/soundwavestencil-sample.png" alt="Soundwave stencil sample"/>
            </li>
          </ul>

          <h3>Adjusting the Size of the Stencil</h3>
          <ul>
            <li><p><b>Please do not make a soundwave tattoo larger than 6 inches / 15 centemeters and do not make it extended to the full length of the body part in either direction.</b></p></li>
            <li><p>You can adjust the size of the stencil while maintaining the proportions.
            </p></li>
            <li><p>The tattoo must be larger than 2 inches or 5cm along its longest side.
            </p></li>
            <li><p>The tattoo should not be so big that it begins to wrap around the body part. 
            Please do not make the Soundwave Tattoo take up the entire inner-forearm. 
            By tattooing it very large it will end up curving around the body part, so don’t do that.
            </p></li>
            <li><p>The client should be able to easily hold their phone so that the entire Soundwave 
            Tattoo easily fits inside the camera screen when they are holding it themselves.
            </p></li>
            <li><p>Medium sized Soundwave Tattoos look and play best.
            </p></li>
          </ul>

          <h3>Customization</h3>
          <ul>
            <li><p>Customization will help to make each Soundwave Tattoo unique for everyone who gets one.
            </p></li>
            <li><p>You can add design elements inside the Soundwave Tattoo outline or around it on the outside, 
            as long as the outline stays intact.
            </p></li>
            <li><p>You can leave the inside of the Soundwave Tattoo empty using negative space, as long as the outline is 
            bold and dark and has maximum contrast to the skin-tone after it heals.
            </p></li>
            <li><p>You can use and add color to a Soundwave Tattoo as long as the outline is present and the tattoo 
            has maximum contrast to the skin-tone after it heals. Please do not make a Soundwave Tattoo using only 
            neutral and bright colors, there needs to be a dark element to it so that it has enough contrast.
            </p></li>
            <li><p>The image that is uploaded by the client for activation must be the image of the completed tattoo and 
            should be taken by their phone from the same posture they will play it back.
            </p></li>
            <li><p>If the client wants to add more tattoos around the Soundwave Tattoo in the near future, the client 
            should be advised to wait to upload the photo of their Soundwave Tattoo until those tattoos are done. <strong>Adding new 
            tattoos around a Soundwave Tattoo that has already been activated may cause it to stop being identified by the Skin Motion app</strong>.
            </p></li>
          </ul>

          {/* <h3>Placement</h3> */}
          <h4>Placement of a Soundwave Tattoo is very important!</h4>
          <ul>
            <li><p>When you do a Soundwave Tattoo, always consider what the camera sees when the client 
            holds their phone pointing at the location of the tattoo.
            </p></li>
            <li><p>The entire tattoo must be visible in the phones camera screen.
            </p></li>
            <li><p>No more than one Soundwaves should be in view of the camera at the same time. The app will not be able to identify which 
            to play back if there is more than one and may not play either back if there is more than one.
            </p></li>
            <li><p>It is important to consider a location on the body that the user will feel 
            comfortable holding their device over to play it back.
            </p></li>
            <li><p>Please make sure that the Soundwave Tattoo is oriented in the correct direction. This is especially 
            important when adding design elements that “read” left-to-right next to the Soundwave Tattoo. The tattoo 
            should be oriented so that it plays the same direction as the additional elements read. 
            </p></li>
            <li><p>Always do the Soundwave tattoo as straight as possible so that when the 
            camera sees that tattoo it is on as flat of a surface as possible. Inner forearms work best.
            </p></li>
            <li><p>The Soundwave Tattoo must be on the flattest area possible.
            </p></li>
            <li><p>The Soundwave Tattoo must be straight.
            </p></li>
            <li><p>The tattoo can not wrap around a body part.
            </p></li>
            <li><p>Do the Soundwave Tattoo where it will distort as little as possible when they move the body part it is on.
            </p></li>
            <li><p>When you put the Soundwave Tattoo stencil on the person have them hold their body part in the same way they 
            will when they are holding their phone to play it back. If they are getting it on their forearm, put the stencil on 
            while they are holding their phone camera looking at the arm. This will ensure that it is straight and flat when they 
              are playing it back. If you put the stencil on when the arm is perfectly straight, it may distort the finished tattoo 
            when they bend their arm to play it back. To get around this, make sure you put the stencil on while they use the same 
            posture they will use to play it back.
            </p></li>
            <li><p id="ag-secret-key">The user has the option of using the forward facing camera if the Soundwave Tattoo is on their Torso.
            </p></li>
          </ul>
          <h3>Artist Secret Key</h3>
          <ul>
            <li><p>The artist key should remain private. Only share it with your clients that get a Soundwave Tattoo. 
            Your secret key is how we know which tattoos you have done. It also allows us to offer a 15 day money-back 
            guarantee to our customers because their Soundwave Tattoo is much more likely to work successfully because 
            you have been properly trained how to do it.
            </p></li>
            <li><p>You can change and update your key under “My Artist Profile” in the menu. You must scroll down and click 
            “Save Profile” to save the change.
            </p></li>
            <li><p>If you change your key, be sure that anyone who has received a Soundwave Tattoo from you has had time to input 
            your old code before you change it or notify them that you changed it.
            </p></li>
            <li><p>The secret key is saved lowercase. Use a word that is easy for 
            you to remember.
            </p></li>
          </ul>

          <h3>Helping your clients</h3>
          <ul>
            <li><p>We realize and appreciate that you want to help your clients to create heir Soundwave Tattoo. 
            However, there are some very important guidelines for doing this.
            </p></li>
            <li><p>Clients MUST create their Soundwave Tattoo stencil under their own SkinMotion.com account.
            </p></li>
            <li><p>Your artist account is for uploading and creating your own personal Soundwaves only.
            </p></li>
            <li><p>Your clients should go through the activation process themselves so that they can read the 
            disclaimers and understand the process.
            </p></li>
            <li><p>We advise users to have their Soundwave Tattoo PDF created and sent to the artist prior to visiting 
            for their tattoo appointment. This helps resolve any last minute issues on the day a tattoo is scheduled.
            </p></li>
            <li><p>Photo Upload: It is best that the user takes the photo themselves while holding their phone in 
            the same comfortable position that they will hold their device during playback.
            </p></li>
            <li><p>The user account that activates the Soundwave Tattoo is responsible for the activation and yearly 
            subscription fee. It will also be the account that must be logged in in order to play the tattoo.
            </p></li>
            <li><p>You can help a client by answering questions and guiding them while they do the activation process if you like.
            </p></li>
            <li><p>You can always refer a client to email Skin Motion customer support at Support@skinmmotion.com with any 
            questions or concerns. Reply times can be up to 24-48 hours, so it is best for them to ask any questions prior to activation.
            </p></li>
            <li><p>Clients with technical questions about their Soundwave Tattoo or Skin Motion account must contact 
            Skin Motion directly so we can access their account to resolve the issue.
            </p></li>
            <li><p>Because all tattoos heal differently we have specifically not provided any tattoo aftercare instructions 
            so that each artist can suggest their own method. 
            </p></li>
            <li><p>A Soundwave Tattoo that is activated immediately after it is done, may stop playing back with the 
            Skin Motion app if it changes if it scabs, ink falls out or the tattoo looks different during the healing 
            process and after it heals. We can reactivate a tattoo with a new photo if the original activation no longer 
            works if it has changed too drastically due to poor healing, however the user should wait until their tattoo is 
            completely healed and done peeling before they attempt to re-activate it.
            </p></li>
            <li><p>Reactivating tattoos is specifically done by contacting Support@skinmotion.com on a case by case 
            basis so that we can give custom instructions for each user’s specific situation.
            </p></li>
          </ul>

          <h3>Cost</h3>
          <ul>
            <li><p>Artists may choose the fee they charge to do each Soundwave Tattoo. This fee is paid directly to the artist.
            </p></li>
            <li><p>Users purchase their Soundwave Tattoo by paying a 39.99 USD activation fee and are enrolled in a 14.99 USD yearly subscription 
            </p></li>
            <li><p>Some users purchased a pre-launch activation voucher and can pay with those instead of a credit card to 
            activate a Soundwave Tattoo. Users who pay with a voucher will be required to sign up for the yearly subscription 
            before their first year is complete and will receive automated instructions at the appropriate time.
            </p></li>
          </ul>

          <h3>Preview / Demo</h3>
          <ul>
            <li><p>Users can open the demo page <a href="/demo">https://skinmotion.com/demo</a> on a computer or second mobile 
            device and use the Skin Motion app on their phone to play demo Soundwave Tattoos.
            </p></li>
          </ul>

          <h3>Refund Policy</h3>
          <ul>
            <li><p>We offer a 15 day money-back guarantee on the cost of a Soundwave Tattoo activation when the customer goes 
            to a Skin Motion licensed Tattoo Artist from the Artist Directory.
            </p></li>
            <li><p>In order to be eligible for a refund the user must input their artist's secret key during the activation process.
            </p></li>
            <li><p>Refunded Soundwave Tattoos will be deactivated and removed from the user’s account.
            </p></li>
          </ul>

          <h3>Continued Training</h3>
          <ul>
            <li><p>Make sure to read artist email updates as they contain the latest information.
            </p></li>
            <li><p>Visit the <a href="/artist-dashboard">Artist Dashboard</a> to view past emails.
            </p></li>
            <li><p>You can upload your own audio to give it a try and see how everything works.
            </p></li>
          </ul>

          <h3>More Questions?</h3>
          <p>If you are not 100% sure about something, it is important to email us at support@skinmotion.com before acting. 
          We usually respond within 24-48 hours. You can add an “Urgent Artist Question” to the subject field to get the speediest response.</p>

          <h3>Thank you</h3>
          <p>Thank you for becoming a licensed Skin Motion Tattoo Artist! We look forward to working with you to 
          bring new and exciting ways to experience tattoos to life.</p>

          <p><strong>Make sure to follow us for the latest news:</strong><br />
          Instagram: <a href="http://instagram.com/soundwavetattoos">@soundwavetattoos</a><br />
          Facebook: <a href="http://facebook.com/soundwavetattoos">@soundwavetattoos</a><br />
          Youtube: <a href="http://youtube.com/skinmotion">@skinmotion</a><br />
          Twitter: <a href="http://twitter.com/soundwavetattoo">@soundwavetattoo</a></p>
         </div>
      </div>
    )
  }
}

export default ArtistGuide