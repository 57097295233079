



//
// params = {
//   canvas,
//   context,
//   mask,
//   audioEl,
//   playbackData,
//   animationState,
//   widthBuffer,
//   heightBuffer
// }
//
export const drawCanvas = (params, step) => {
  const {
    canvas,
    context,
    mask,
    audioEl,
    playbackData,
    animationStart,
    widthBuffer,
    heightBuffer,
    sourceImage,
  } = params
  const { color } = playbackData
  context.clearRect(0, 0, canvas.height, canvas.width)
  // context.drawImage(img, 100, 50, 600, 400);
  context.globalCompositeOperation = 'source-over'
  context.drawImage(mask, widthBuffer/2, heightBuffer/2, sourceImage.width, sourceImage.height)

  if (step) {
    context.save()
    context.fillStyle = `rgba(${color.r}, ${color.g}, ${color.b}, ${color.a})`
    context.globalCompositeOperation = 'source-atop'

    // Calculate the current angle of rotation based on the playback markers
    const wholeDeltaY = playbackData.endPos.y - playbackData.startPos.y
    const wholeDeltaX = playbackData.endPos.x - playbackData.startPos.x
    const defaultRotationAngle = Math.atan(wholeDeltaY/wholeDeltaX)

    // Calculate the delta position from the start of the playback
    // Based on the current Play Time
    const currentTime = (step - animationStart)/1000
    const deltaX = currentTime/audioEl.duration * (playbackData.endPos.x - playbackData.startPos.x)
    const deltaY = currentTime/audioEl.duration * (playbackData.endPos.y - playbackData.startPos.y)

    const xPos = playbackData.startPos.x +  deltaX
    const yPos = playbackData.startPos.y + deltaY
    context.translate(xPos + playbackData.width/2, yPos)
    context.rotate(defaultRotationAngle + Math.PI * playbackData.rotation)
    context.translate(-(xPos + playbackData.width/2), -yPos)

    context.fillRect(xPos, heightBuffer/2, playbackData.width, canvas.height)
    context.restore()
  }
}
