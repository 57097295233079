import React, { Component } from "react";
import { Link } from "react-router-dom";

class AboutUs extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div className="sm-about">
        <div className="title-main title-short" id="title-about-us">
          <h1>About us</h1>
        </div>
        <div className="sm-about-main">
          <h2>
            Skin Motion is a patented proprietary cloud platform for
            creating personalized augmented reality Soundwave Tattoos&trade; and
            world-wide tattoo artist network.
          </h2>

          {/* <p>
            In April 2017, tattoo artist{" "}
            <a href="https://natesiggard.com" target="_blank">
              Nate Siggard
            </a>{" "}
            created the first Soundwave Tattoo™ that could be be played back
            using a mobile app. Nate tattooed one on himself and created a video
            to show how it worked. That video went viral with over{" "}
            <Link to="/press">350 million views</Link> after being covered by
            countless entertainment publishers and{" "}
            <a href="https://www.youtube.com/watch?v=6yqqmD--_mo">
              featured by international news organizations
            </a>
            .
          </p> */}

          <p>
            Skin Motion was founded to make Soundwave Tattoos a reality for
            people all over the world. Skin Motion is based in Southern
            California and has licensed Tattoo Artists from 43 countries to
            create Soundwave Tattoos. You can find an artist close to you in the{" "}
            <Link to="/artist-directory">Tattoo Artist Directory</Link> or ask
            your favorite artist to apply for free.
          </p>

          <p>
            Find answers to your questions in our <Link to="/faq">FAQ</Link> or{" "}
            <Link to="/support">contact support</Link>.
          </p>

          {/***** 
          <h2>The Skin Motion Team</h2>

          <div className="team-member">
            <div className="team-member-picture">
              <img src="https://cdn.skinmotion.com/images/team/nate.png" />
              <h3>Nate Siggard</h3>
              <h4>Tattoo Artist, Founder & CEO</h4>
              <ul>
                  <li><a href="https://natesiggard.com" target="_blank">NateSiggard.com</a></li>
                  <li><a href="https://instagram.com/natesiggard" target="_blank">@NateSiggard on Instagram</a></li>
                  <li><a href="https://facebook.com/natesiggard" target="_blank">@NateSiggard on Facebook</a></li>
                  <li><a href="https://twitter.com/natesiggard" target="_blank">@NateSiggard on Twitter</a></li>
              </ul>
            </div>
            <div className="team-member-details">
              <p>Nate has a diverse background working in various mediums inventing
              and creating experiential art and technology. He began programming 
              websites in his early teens and built multiple web-apps before leaving 
              the startup community in 2007 at 22 years old to become a tattoo 
              artist.</p>

              <p>After apprenticing with tattoo artist Jim Hayek and starting
              his tattoo practice, Nate produced a series of educational 
              tattooing DVD's for award winning hyper-realism portrait tattooer 
              Mike DeVries. His tattoo career has taken him around North America,
              to connect with countless people who would change his life as much 
              as his tattoos changed theirs.</p>

              <p>Nate has creative directed and consulted for promenant clients 
              in music, film, television, and for startups and small businesses alike. 
              Nate is passionate about creating next-generation art and experiences 
              that integrate ritual, mysticism, and the altered states of 
              consciousness to faciliate transmutation of pain, trauma, and grief 
              into love.</p>
            </div>
          </div>
          <div className="team-member">
            <div className="team-member-picture">
              <img src="https://cdn.skinmotion.com/images/team/juliana.png" />
              <h3>Juliana Damiano</h3>
              <h4>Co-Founder & Chief Support Officer</h4>
            </div>
            <div className="team-member-details">
              <p>Juliana first said, "Wouldn't it be cool if you could play 
              that tattoo back?" inspiring Nate to create the first Soundwave 
              Tattoo that could be played back with a mobile app. Juliana 
              graduated from Cal State Northridge with a major in Communications 
              and has fifteen years experience in sales and customer service. Her 
              outgoing personality and welcoming presence contribute to the 
              excellent customer support she provides. She is a dedicated mother, 
              partner and goes out of her way to brighten the day everyone she meets.
              </p>
            </div>
          </div> 
          <div className="team-member">
            <div className="team-member-picture">
              <img src="https://cdn.skinmotion.com/images/team/rob.png" />
              <h3>Rob Kotz</h3>
              <h4>Co-Founder & SR Engineer</h4>
            </div>
            <div className="team-member-details">
              <p>Rob and Nate met in San Francisco in 2015 when Nate started Rob's 
              Shiva tattoo back piece. A few years later when the first Soundwave 
              Tattoo was created and went viral, Rob had just climbed Naya Kanga 
              in Nepal. When Nate reached out, Rob jumped in and starting 
              engineering Skin Motion while living on the sacred River Ganga.  
              During the build out, his travels took him to 
              Peru to studied with indigenous healers, where 
              he launched Skin Motion while living along the Amazon river.</p>

              <p>Rob's love of adventure is supported by his extensive 
              engineering experience and BS from Northwestern University. 
              These days you can find him in Oahu, Hawaii building the next 
              generation of Skin Motion app and practicing Jujutsu.
              </p>
            </div>
          </div>
          <div className="team-member">
            <div className="team-member-picture">
              <img src="https://cdn.skinmotion.com/images/team/jessica.png" />
              <h3>Jessica Richmond</h3>
              <h4>Co-Founder & Marketing</h4>
            </div>
            <div className="team-member-details">
              <p>Jessica is a multi-talented producer, writer, director and consultant
              at the forefront of immersive story telling and narrative driven
              business development. Her passion for facilitating connection and
              increasing human capital through inclusive stakeholder engagement 
              is inavluable to Skin Motion's founding and growth. She has has created 
              original content & strategic release campaigns for HBO, Niantic Labs, 
              The Unmarked Door, Believe Limited, YoutubeRED</p>
              <p>Jessica also wrote and 
              produced <a href="https://www.youtube.com/channel/UCaaLRmsqlYDPa7ZmfbtbIJg">Baker's 
              Dozen series</a> about a smart, strong woman with
              agency over her own sexual experience. You can ctach Jessica and Nate
              in the series 8th episode.</p>
            </div>
          </div>

           
          <div className="team-member-slim">
            <div className="team-member-picture">
              <img src="https://cdn.skinmotion.com/images/team/jessica.png" />
              <h3>Jessica Richmond</h3>
              <h4>Co-Founder & Marketing</h4>
            </div>
          </div>
          

          <h2>We're Hiring!</h2>
          <p>Skin Motion is looking for passionate and dedicated team members who want to 
          create the future of human computer interaction. We run a modern Javascript and
          Python cloud based platform. Do you have a specialized skill or relevant 
          experience that you want to contribute? Send us an intro 
          letter and resume to work [@] skinmotion.com.</p>*/}
        </div>
      </div>
    );
  }
}

export default AboutUs;
