import React from 'react';

class NameOnCard extends React.Component {
  render() {
    const { currentUser } = this.props
    return (
      <div> 
        <label htmlFor="name">Full Name:</label>
        <input type="text" name="name" placeholder="Name as it appears on card *" />
        <input type="hidden" name="email" value={currentUser.get('email')} />
      </div>
    )
  }
}

export default NameOnCard