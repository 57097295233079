const kANY_ROLE = "ANY"
const kADMIN_ROLE = "ADMIN"
const kACTIVATOR_ROLE = "ACTIVATOR"
const kREVIEWER_ROLE = "REVIEWER"
const kARTIST_ROLE = "ARTIST"
const kUSER_ROLE = "USER"
const kAFFILIATE_ROLE = "AFFILIATE"

/**
 * @param user [Immutable.Map]
 * @return true if user has administrator privileges
 */
export const hasAdminPrivs = user =>
  [kADMIN_ROLE, kREVIEWER_ROLE].indexOf(user.get('user_role')) >= 0

export const hasActivatorPrivs = user =>
  [kADMIN_ROLE, kACTIVATOR_ROLE].indexOf(user.get('user_role')) >= 0

export const getRole = user => user.get('user_role', kUSER_ROLE)

export const ownsMarker = (user, marker) => marker.get('created_by_user') == user.get('id')

export const isInfluencer = user => user.get('is_influencer')

export const isArtist = user => user.get('user_role') === kARTIST_ROLE

export const getLastPayment = user => {
  let payments = user.get('purchases')
  let lastPayment = payments.sort((a, b) => {
    let dateA = new Date(a.get('purchase_date'))
    let dateB = new Date(b.get('purchase_date'))
    return dateB - dateA
  })
  return lastPayment.get(0)
}