import React, { Component } from "react";
import { connect } from "react-redux";
import ArtistApplicationReviewRow from "../admin/ArtistApplicationReviewRow";
import UserSearchForm from "../admin/UserSearchForm";
// import { fetchApplications } from "../../actions/artistApplications";
import { fetchUsers } from "../../actions/users";
import "bootstrap/dist/css/bootstrap.min.css";
import API from "../../utils/API";

import ReactPaginate from "react-paginate";

class ApplicationReview extends Component {
  state = {
    data: [], // Fetched data from API
    currentPage: 1, // Current page number
    pageLimit: 10, // Number of records per page
    totalRecords: 0, // Total number of records from API
  };

  fetchData = async (page, pageLimit) => {
    try {
      // Make the API request with the provided page and pageLimit
      const response = await API.get(
        `/artist_applications?page=${page}&page_size=${pageLimit}`
      );
      // Update the component state with the fetched data and total record count
      this.setState({
        data: response[0],
        totalRecords: response[1].total_records,
      });
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  handlePageChange = ({ selected }) => {
    const newPage = selected + 1;
    this.setState({ currentPage: newPage });
    this.fetchData(newPage, this.state.pageLimit);
  };

  componentDidMount() {
    // const response = await API.get("/artist_applications");

    const { currentPage, pageLimit } = this.state;
    this.fetchData(currentPage, pageLimit);
  }
  onChange = (event) => {
    const newPageLimit = parseInt(event.target.value);
    this.setState({ pageLimit: newPageLimit }, () => {
      // After updating the state, refetch the data with the new page limit
      this.fetchData(this.state.currentPage, newPageLimit);
    });
  };

  render() {
    const { pageLimit, totalRecords } = this.state;
    const recordsPerPage = pageLimit;
    const nPage = Math.ceil(totalRecords / recordsPerPage);

    const { selectedUser } = this.props;
    let selectedApplication = null;
    if (selectedUser) {
      selectedApplication = this.state.data.find(
        (application) =>
          selectedUser.get("application_id") == application.get("id")
      );
    }

    return (
      <div className="sm-admin-review-applications">
        <h1>Application review!</h1>

        <div className="limit-wrap d-flex flex-wrap  align-items-end">
          <UserSearchForm
            initialValues={selectedUser}
            fetchUser={this.props.fetchUser}
            fetchUsers={this.props.fetchUsers}
            push={this.props.push}
          />
          <div className="radius-holder">
            <select name="limit-box" onChange={this.onChange}>
              <option value="10"> Limit of the List </option>
              <option value="20">20 </option>
              <option value="50">50 </option>
              <option value="100">100 </option>
            </select>
          </div>
        </div>

        <table className="sm-admin-apps-table">
          <tbody>
            <tr className="sm-admin-apps-header-row">
              <td> Email </td>
              <td> View Application </td>
              <td> Application State </td>
              <td> Test Results </td>
              <td> Quiz Reset </td>
              <td> Verified </td>
            </tr>
            {selectedUser && selectedApplication ? (
              <ArtistApplicationReviewRow
                fetchUser={this.props.fetchUser}
                application={selectedApplication}
                push={this.props.push}
              />
            ) : (
              this.state.data?.map((application, index) => (
                <ArtistApplicationReviewRow
                  fetchUser={this.props.fetchUser}
                  key={index}
                  application={application}
                  push={this.props.push}
                />
              ))
            )}
          </tbody>
        </table>
        <ReactPaginate
          pageCount={nPage}
          pageRangeDisplayed={3}
          marginPagesDisplayed={2}
          onPageChange={this.handlePageChange}
          containerClassName={"pagination"}
          activeClassName={"active"}
        />
      </div>
    );
  }
}

const mapStateToProps = (store) => ({
  users: store.get("users"),
});

const mapDispatchToProps = (dispatch) => ({
  // fetchApplications: () => dispatch(fetchApplications()),
  // fetchUsers: () => dispatch(fetchUsers()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ApplicationReview);
