import React from 'react'
import { connect } from 'react-redux';
import Immutable from 'immutable';
import { Field, reduxForm, change } from 'redux-form/immutable';
import { required } from '../../utils/form_validators';
import { trimSpaces, lower } from '../../utils/form_normalizers'; 
import { addUserToCampaign, fetchCampaigns } from '../../actions/campaigns';

const FORM_NAME = "campaignStepOne"

const renderField = props => {
  const additionalClasses = props.meta.touched && props.meta.error ? props.meta.error : ''
  return (
    <input
      {...props.input}
      placeholder={props.placeholder}
      type={props.type}
      className={`${props.className} ${additionalClasses}`} />
  )
}

class CampaignStepOne extends React.Component {
  
  state = {
    submitting: false
  }

  onSubmit = (signupParams) => {
    this.setState({submitting: true})
    this.props.setCampaignEmail(signupParams.get('email'))
    this.props.addUserToCampaign(signupParams.get('email'), this.props.campaign.get('id'))
    .then((campaign) => {
      this.props.setStep(1, campaign['new_user_token'])
    })
  }

  render() {
    const { handleSubmit, error, submitFailed, onSubmit } = this.props

    return (
      <div className="sm-swcampaign-main">
          <div className="sm-swcampaign-main-smlogo">
            <h1><span>Skin Motion</span></h1>
          </div>
          <div className='sm-swcampaign-main-clientlogo'>
            <h1><span>{this.props.campaign.get('company_name')}</span></h1>
          </div>
        <div className="sm-swcampaign-main-graphic">

        </div>
        <div className="sm-swcampaign-main-activate">
          <h2>Activate your Soundwave</h2>
          <form onSubmit={handleSubmit(this.onSubmit)}>
            <div className="sm-swcampaign-email">
              <Field
                type="email"
                name="email"
                component={renderField}
                validate={required}
                normalize={trimSpaces, lower}
                className="required"
                placeholder="Your email address" />
            </div>
            

            <button 
              type="submit"
              className="sm-swcampaign-main-activate-submit"
              disabled={this.state.submitting} >
              {this.state.submitting ? "Submitting" : "Continue Activation"}
            </button>
          </form>
        </div>
      </div>
    )
  }
}

const ReduxFormCampaignForm = reduxForm({
  form: FORM_NAME
})(CampaignStepOne)

const mapStateToProps = (store) => ({
  currentUser: store.get('currentUser'),
  campaigns: store.get('campaigns')
})

const mapDispatchToProps = dispatch => ({
  addUserToCampaign: (email, campaignId) => dispatch(addUserToCampaign(email, campaignId)),
})

export default connect(mapStateToProps, mapDispatchToProps)(ReduxFormCampaignForm)