import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

import { fetchMarkers } from "../../../actions/markers";

import SoundwaveListComponent from "./partials/SoundwaveListComponent";

import { isUsersSoundwave } from "../../../models/marker";
class MySoundwaves extends React.Component {
  state = {
    markersLoaded: false,
  };
  // componentDidMount() {
  //   this.setState({ markersLoaded: false });
  //   this.props
  //     .fetchMarkers()
  //     .then(() => this.setState({ markersLoaded: true }));
  // }

  // componentDidMount() {
  //   this.props
  //     .fetchMarkers()
  //     .then((markers) => {
  //       console.log(markers, "markers"); // Add this line to log the markers data
  //       this.setState({ markersLoaded: true });
  //     })
  //     .catch((error) => {
  //       console.log("Error fetching markers:", error);
  //     });
  //   this.setState({ markersLoaded: false });
  // }

  componentDidMount() {
    // console.log("Fetching markers...");
    this.props
      .fetchMarkers()
      .then((markers) => {
        // console.log("Markers fetched:", markers);
        this.setState({ markersLoaded: true });
      })
      .catch((error) => {
        console.log("Error fetching markers:", error);
      });
    this.setState({ markersLoaded: false });
  }

  render() {
    const { markers } = this.props;
    // console.log(markers, "CHATGPT");
    const sortedMarkers = markers.sort((a, b) => {
      return b.get("id") - a.get("id");
    });
    if (!this.state.markersLoaded) {
      return (
        <div className="sm-waveform-loading">
          <p>Loading Soundwaves...</p>
          <div className="sm-loader"></div>
        </div>
      );
    }
    return (
      <div className="my-soundwaves">
        {markers.size === 0 && (
          <p className="no-soundwaves-copy">
            <span>You have not created any Soundwaves</span>.
            <br />
            Create a new soundwave <Link to="/new-soundwave">here</Link>.<br />
            <br />
            Purchased Soundwave activations are located in{" "}
            <Link to="/my-tattoos">My Tattoos</Link>.
          </p>
        )}
        {sortedMarkers.valueSeq().map((marker) => (
          <SoundwaveListComponent key={marker.get("id")} marker={marker} />
        ))}
      </div>
    );
  }
}

const mapStateToProps = (store) => ({
  markers: store
    .get("markers")
    .filter((marker) => isUsersSoundwave(marker, store.get("currentUser"))),
});

const mapDispatchToProps = (dispatch) => ({
  fetchMarkers: () => dispatch(fetchMarkers()),
});

export default connect(mapStateToProps, mapDispatchToProps)(MySoundwaves);
