import React, { Component } from 'react';

class Demo extends Component {

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
    <div className="sm-demo">
      <div className="title-main title-short" id="title-demo">
        <h1>Demo</h1>
      </div>
      <div className="sm-demo-main">
        <h2>Use the Skin Motion app on another device to playback the Soundwave Tattoos below</h2>
        <div className="demo-odd">
          <div className="demo-img">
            <img src="https://storage.googleapis.com/skinmotion-assets/images/general/NeilSWTattooCropped_first.jpg" className='imng-fluid' width={'100%'}></img>
          </div>
          <div className="demo-info">
            <div className="info-title">
              {/* <div className="info-title-img">
                <img src='https://cdn.skinmotion.com/images/layout/demo/nate-photo.jpg'></img>
              </div> */}
              <div className="info-title-content">
                <h3>Tommy</h3>
                <h4><strong>Tattoo:</strong> Neil Armstrong walks on the moon</h4>
                {/* <a href='https://www.youtube.com/watch?v=ubVaqWiwGVc' target="_blank">Watch Nate's Soundwave Tattoo Video</a> */}
              </div>
            </div>
            <div className="info-quote">
              <p>"Like a lot of young kids growing up, I always wanted to be an Astronaut.  
                I had this little toy version of the Space Shuttle and dreamt of going places in that thing and exploring the universe. </p>

               <p> Needless to say, I didn’t know at the time what it took to be an Astronaut and wouldn’t ever become one but I still keep Neil Armstrong’s words with me as a reminder.  
                A reminder to explore, a reminder to keep going and sometimes just a reminder to feel like a kid again."</p>

              {/* <p>"I'm so happy that my Soundwave Tattoo could inspire people
                all over the world to get one. It's the most romantic thing
                someone could do!"</p> */}
            </div>
          </div>
        </div>
        <div className="demo-even">
          <div className="demo-info">
            <div className="info-title">
              {/* <div className="info-title-img">
                <img src='https://cdn.skinmotion.com/images/layout/demo/josh-photo.jpg'></img>
              </div> */}
              <div className="info-title-content">
                <h3>Josh</h3>
                <h4><strong>Tattoo:</strong> Dad's inspirational video</h4>
                {/* <a href='https://www.youtube.com/watch?v=pFOvV8hXXU0' target="_blank">Watch Josh's Soundwave Tattoo Video</a> */}
              </div>
            </div>
            <div className="info-quote">
              <p>"When I'm feeling down I listen to this every day- every single morning I listen to this now. I scan it.</p>

              <p>"It's like I used to do when I used to call my dad and you know always ask for advice, and I can apply it to the situation at the time".</p>
            </div>
          </div>
          <div className="demo-img">
            <img src='https://storage.googleapis.com/skinmotion-assets/images/layout/demo/josh.jpg' className='imng-fluid' width={'100%'}></img>
          </div>
        </div>
        <div className="demo-odd">
          <div className="demo-img">
            <img src="https://storage.googleapis.com/skinmotion-assets/images/general/DaniMilkshakeCropped_second.jpg" className='imng-fluid' width={'100%'}></img>
          </div>
          <div className="demo-info">
            <div className="info-title">
              {/* <div className="info-title-img">
                <img src='https://cdn.skinmotion.com/images/layout/demo/juliana-photo.jpg'></img>
              </div> */}
              <div className="info-title-content">
                <h3>Alyssa</h3>
                <h4><strong>Tattoo: </strong>Milkshake</h4>
                {/* <a href='https://youtu.be/wVsXARjQc_w'>Watch Juliana's Soundwave Tattoo Video</a> */}
              </div>
            </div>
            <div className="info-quote">
              <p>"We used to go on family trips to the Florida Gulf Coast where my dad would always make strawberry milkshakes.</p>

               <p> As a little girl, I would beg him night and day of more milkshakes which quickly led to the “milkshake” nickname. 
                I miss those days, I miss those milkshakes and I really miss my dad."</p>
            </div>
          </div>
        </div>
        <div className="demo-even">
          <div className="demo-info">
            <div className="info-title">
              {/* <div className="info-title-img">
                <img src='https://cdn.skinmotion.com/images/layout/demo/bryan-photo.jpg'></img>
              </div> */}
              <div className="info-title-content">
                <h3>Bryan</h3>
                <h4><strong>Tattoo:</strong> Voicemail from Grandmother</h4>
                {/* <a href='https://www.youtube.com/watch?v=DUzXJskzSsU'>Watch Bryan's Soundwave Tattoo Video</a> */}
              </div>
            </div>
            <div className="info-quote">
              <p>"My tattoo is a voicemail my grandmother left me before she
                passed away that's going to say 'See you later babe'.</p>

              <p>I found these voicemails that I could listen to every once
                and a while and I found the Soundwave Tattoo that I was like,
                this is the only way I can memorialize her."</p>
            </div>
          </div>
          <div className="demo-img">
            <img src='https://storage.googleapis.com/skinmotion-assets/images/layout/demo/brayan.jpg' className='imng-fluid' width={'100%'}></img>
          </div>
        </div>
        <div className="call-to-action">
          <h2>Want to share your Soundwave Tattoo?</h2>
          <p >Post your tattoo photos and videos by tagging us on Instagram, Facebook and TikTok.</p>
        </div>
      </div>
    </div>)
  }
}

export default Demo
