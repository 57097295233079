import React from "react";
import { Link } from "react-router-dom";

class AdminTattooListComponent extends React.Component {
  componentDidMount() {
    const { marker, users } = this.props;

    if (marker.get("activator_id") && !users.get(marker.get("activator_id"))) {
      this.props.fetchUser(marker.get("activator_id"));
    }
  }

  rejectMarker = () => {
    this.props.rejectMarker(this.props.marker.get("id"));
  };

  unclaimMarker = () => {
    this.props.unclaimMarker(this.props.marker.get("id"));
  };

  activateMarker = () => {
    this.props.activateMarker(this.props.marker.get("id"));
  };

  render() {
    const { marker, users } = this.props;
    const activator =
      marker.get("activator_id") && users
        ? users.get(marker.get("activator_id"))
        : null;

    const activatorName = activator
      ? `${activator.get("first_name")} ${activator.get("last_name")}`
      : null;

    let artkGenerating = false;
    let readyToTest = false;
    let processingError = false;
    if (!marker.get("processing_error")) {
      readyToTest =
        marker.get("is_complete") && !marker.get("artk_data_generating");
      artkGenerating = marker.get("artk_data_generating");
    } else {
      processingError = true;
    }

    return (
      <div className={`tattoo-list-item tattoo-${marker.get("id")}`}>
        {artkGenerating && (
          <div className="notification-banner artk-gen">
            AR ToolKit marker data is currently being generated for this marker.
          </div>
        )}
        {readyToTest && (
          <div className="notification-banner success">
            The marker is ready to test on your mobile device!
          </div>
        )}
        {processingError && (
          <div className="notification-banner error">
            There was a problem processing this marker. Please contact support!
          </div>
        )}
        <img
          src={this.props.marker.get("tattooImageURL")}
          className="tattoo-img"
        />
        <div className="description-row d-flex flex-row justify-content-between align-items-center">
          <span className="created-on">
            <div className="detail-box d-flex align-item-center justify-content-between">
            <span> {marker.get("name")} </span>
             <span> Client ID: {marker.get("created_by_user")}  </span>
            </div>            
            {marker.get("activator_id") && !artkGenerating && (
              <span>
                <span>
                  <b>Activator:</b> {activatorName}{" "}
                </span>
                <span>
                  <Link to={`/activate-marker/${marker.get("id")}`}>
                    {" "}
                    Continue/Restart Activation{" "}
                  </Link>
                </span>
              </span>
            )}
          </span>

          <div className="links">
            {!artkGenerating && (
              <button
                type="button"
                className="reject-btn btn btn-tan"
                onClick={this.rejectMarker}
              >
                {" "}
                Reject Image{" "}
              </button>
            )}

            {marker.get("is_complete") && (
              <div className="btn btn-tan" onClick={this.activateMarker}>
                Activate
              </div>
            )}
            {!artkGenerating && !marker.get("activator_id") && (
              <Link
                to={`/activate-marker/${marker.get("id")}`}
                className="btn btn-tan"
              >
                Claim & Activate
              </Link>
            )}
            {!artkGenerating && marker.get("activator_id") && (
              <div className="btn btn-tan" onClick={this.unclaimMarker}>
                Unclaim
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default AdminTattooListComponent;
