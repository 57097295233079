import React from "react";
import Immutable from "immutable";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { fetchPosts } from "../../actions/instagram";
import { fetchArtist } from "../../actions/artists";
import { getDistance } from "../../utils/geolocation";
import GoogleMap from "../artists/GoogleMap";
// import Reviews from './Reviews';

class ArtistDetails extends React.Component {
  constructor(props) {
    super(props);
  }

  state = {
    distance: 0,
  };

  componentDidMount() {
    window.scrollTo(0, 0);
    const artistId = this.props.match.params.id;
    this.props.fetchArtist(artistId).then(() => {
      const artist = this.props.artists.get(parseInt(artistId));
      this.props.fetchPosts(artist.get("instagram"));
    });
    this.initializeData();
  }

  componentWillReceiveProps() {
    this.initializeData();
  }

  getDistance(lat1, lon1, lat2, lon2) {
    const R = 3959; // Earth's mean radius in miles
    const dLat = this.deg2rad(lat2 - lat1);
    const dLon = this.deg2rad(lon2 - lon1);
    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(this.deg2rad(lat1)) *
        Math.cos(this.deg2rad(lat2)) *
        Math.sin(dLon / 2) *
        Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const d = R * c; // distance in miles
    return d.toFixed(1); // Round the distance to one decimal place to match the output of the second code
  }

  getUserLocation() {
    const defaultLocation = {
      lat: 34.0195,
      lng: -118.4912,
      zoom: 2,
    };
    return new Promise((resolve, reject) => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const { latitude, longitude } = position.coords;
            resolve({ lat: latitude, lng: longitude });
          },
          () => {
            resolve(defaultLocation);
            // reject("Unable to retrieve user location");
          }
        );
      } else {
        reject("Geolocation not supported");
      }
    });
  }

  async initializeData() {
    const artist = this.props.artists.get(parseInt(this.props.match.params.id));
    if (artist) {
      const newArtist = artist?.toJS();
      const currentUserLocation = await this.getUserLocation();
      const distance = this.getDistance(
        currentUserLocation.lat,
        currentUserLocation.lng,
        newArtist?.location.lat,
        newArtist?.location.lng
      );

      this.setState({ distance: distance || 0 });
    }
  }
  deg2rad(deg) {
    return deg * (Math.PI / 180);
  }

  render() {
    const artist = this.props.artists.get(parseInt(this.props.match.params.id));
    const { userLocation } = this.props;
    const { distance } = this.state;
    if (!artist || !artist.get("is_active")) {
      return null;
    }
    const instagramPosts = this.props.instagram.get(artist.get("instagram"));
    const artistMap = Immutable.Map().set(artist.get("id"), artist);

    let distanceFrom = userLocation?.get("search")
      ? userLocation.get("search").get("address")
      : " you";
    return (
      <div className="sm-artist-details">
        <div className="title-main title-short" id="title-artist-details">
          <h1>ARTIST DETAILS</h1>
        </div>
        <div className="sm-artist-details-main">
          <div className="sm-artist-details-about">
            <div
              className="profile-pic"
              style={{
                backgroundImage: "url(" + artist.get("profile_photo") + ")",
              }}
            />
            <div className="artist-info">
              <h3 className="name"> {artist.get("name")} </h3>
              <h4 className="shop"> {artist.get("shop")} </h4>
              {!this.props.mobileMode && (
                <div className="artist-contact">
                  <a
                    href={"mailto:" + artist.get("email")}
                    className="btn btn-tan"
                    target="_blank"
                  >
                    Email
                  </a>
                </div>
              )}
              {!this.props.mobileMode && (
                <div className="social-media-links">
                  {artist.get("facebook") && (
                    <a
                      href={
                        "https://www.facebook.com/" + artist.get("facebook")
                      }
                      className="social-media"
                      target="_blank"
                    >
                      <img src="https://cdn.skinmotion.com/images/layout/social-media/FB-round-logo.png"></img>
                    </a>
                  )}
                  {artist.get("instagram") && (
                    <a
                      href={
                        "https://www.instagram.com/" + artist.get("instagram")
                      }
                      className="social-media"
                      target="_blank"
                    >
                      <img src="https://cdn.skinmotion.com/images/layout/social-media/IG-round-logo2.png"></img>
                    </a>
                  )}
                  {artist.get("twitter") && (
                    <a
                      href={"https://www.twitter.com/" + artist.get("twitter")}
                      className="social-media"
                      target="_blank"
                    >
                      <img src="https://cdn.skinmotion.com/images/layout/social-media/TW-round-logo.png"></img>
                    </a>
                  )}
                </div>
              )}

              {/*<div className='artist-stars star-rating rating-45'/>*/}

              <h5 className="sm-artist-address">{artist.get("address")}</h5>
              <p className="distance-from-user">
                {distance}mi away from {distanceFrom}
              </p>
            </div>

            <div className="sm-artist-details-bio">
              <p>{artist.get("bio")}</p>
              <p className="sm-artist-details-main-about-website">
                <strong>Website:</strong> &nbsp;
                {!this.props.mobileMode ? (
                  <a
                    href={artist.get("website")}
                    title={artist.get("Name" + " website")}
                    target="_blank"
                  >
                    {artist.get("website")}
                  </a>
                ) : (
                  <span>{artist.get("website")}</span>
                )}
              </p>

              <div className="sm-artist-details-back">
                <Link to="/artist-directory" className="btn btn-tan">
                  Back to Artist Directory
                </Link>
              </div>
            </div>
          </div>

          <GoogleMap artists={artistMap} centerOnArtist={true} />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (store, props) => ({
  artists: store.get("artists"),
  mobileMode: store.get("mobileMode"),
  userLocation: store.get("userLocation"),
  instagram: store.get("instagram"),
});

const mapDispatchToProps = (dispatch) => ({
  fetchArtist: (artistId) => dispatch(fetchArtist(artistId)),
  fetchPosts: (username) => dispatch(fetchPosts(username)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ArtistDetails);
