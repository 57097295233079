import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class Advertisement extends Component {
  render() {
    return <div className="sm-advertise">
      <Link to="/apply" className="sm-advertise-main">
                  
    </Link>
    </div>
  }
}

export default Advertisement
