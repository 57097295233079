import Immutable from 'immutable'
import {
  RECEIVE_MARKERS,
  RECEIVE_MARKER,
  REMOVE_MARKER,
} from '../actions/markers'

const markers = (state = Immutable.Map(), action) => {
  switch (action.type) {
    case RECEIVE_MARKER:
      return state.set(action.marker.id, Immutable.fromJS(action.marker))
    case RECEIVE_MARKERS:
      let tempState = state
      action.markers.forEach(marker =>
        tempState = tempState.set(marker.id, Immutable.fromJS(marker))
      )
      return tempState
    case REMOVE_MARKER:
      return state.delete(action.markerId)
    default:
      return state
  }
}
export default markers
