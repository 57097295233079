import React from "react";
import Select from "react-select";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { updateUser, assignVoucher } from "../../actions/users";
import UserPurchaseHistory from "../admin/UserPurchaseHistory";

class UserDetails extends React.Component {
  banUser = () => {
    this.props.updateUser(this.props.selectedUser.get("id"), {
      is_active: false,
    });
  };

  activateUser = () => {
    this.props.updateUser(this.props.selectedUser.get("id"), {
      is_active: true,
    });
  };

  assignVoucher = () => {
    this.props.assignVoucher(this.props.selectedUser.get("id"));
  };

  transmogrify = () => {
    window.location.href = `/admin/switch-user/${this.props.selectedUser.get(
      "id"
    )}`;
  };

  handleChange = (value) => {
    this.props.updateUser(this.props.selectedUser.get("id"), {
      user_role: value.value,
    });
  };

  render() {
    const { selectedUser, user } = this.props;

    const userTypes = [
      { value: "USER", label: "User" },
      { value: "ACTIVATOR", label: "Activator" },
      { value: "ADMIN", label: "Admin" },
      { value: "ARTIST", label: "Artist" },
    ];

    return (
      <div className="sm-admin-user-details">
        <div className="sm-admin-title">
          <h2> User Details </h2>
          <Link to="/admin/users"> Back to user search </Link>
        </div>
        <div className="sm-user-account-details">
          <ul>
            <li>
              {" "}
              <b> User ID: </b> {`${selectedUser.get("id")}`}{" "}
            </li>
            <li>
              {" "}
              <b> Name: </b>{" "}
              {`${selectedUser.get("first_name")} ${selectedUser.get(
                "last_name"
              )}`}{" "}
            </li>
            <li>
              {" "}
              <b> Email: </b> {selectedUser.get("email")}{" "}
            </li>
            <li>
              {" "}
              <b> Date Registered: </b> {selectedUser.get("created_at")}{" "}
            </li>
            <li>
              {" "}
              <b> Account Status: </b>{" "}
              {selectedUser.get("is_active") ? "Active" : "Disabled"}{" "}
            </li>
            <li>
              {" "}
              <b> Email Verified: </b>{" "}
              {selectedUser.get("is_verified") ? "Yes" : "No"}{" "}
            </li>
            <li>
              {" "}
              <b> User Type: </b>
              <Select
                value={selectedUser.get("user_role")}
                options={userTypes}
                onChange={this.handleChange}
              />
            </li>
            <li>
              {" "}
              <b> Total Vouchers: </b> {selectedUser.get("total_voucher_count")}{" "}
            </li>
            <li>
              {" "}
              <b> Unused Vouchers: </b>{" "}
              {selectedUser.get("unused_voucher_count")}{" "}
            </li>
            <li>
              {" "}
              <b> Referrals: </b> {selectedUser.get("referral_count")}{" "}
            </li>
            <li>
              {" "}
              <b> App Version: </b> Placeholder{" "}
            </li>
            <li>
              {" "}
              <b> Device: </b> Placeholder{" "}
            </li>
            <li>
              {" "}
              <b> OS Version: </b> Placeholder{" "}
            </li>
            <li>
              {" "}
              <b> Last Geo: </b> Placeholder{" "}
            </li>
          </ul>
        </div>
        <div className="sm-user-admin-actions">
          {selectedUser.get("is_active") && (
            <div onClick={this.banUser} className="btn btn-tan">
              Ban User
            </div>
          )}
          {!selectedUser.get("is_active") && (
            <div onClick={this.activateUser} className="btn btn-tan">
              Activate (unban) User
            </div>
          )}
          <div onClick={this.assignVoucher} className="btn btn-tan">
            Assign Voucher
          </div>
          <div onClick={this.transmogrify} className="btn btn-tan">
            Possess Account
          </div>
        </div>
        <div className="sm-user-purchases">
          <h2> Purchases </h2>
          {selectedUser.get("purchases", []).size > 0 ? (
            <UserPurchaseHistory
              push={this.props.push}
              fetchMarker={this.props.fetchMarker}
              selectedUser={selectedUser}
              setSelectedPurchase={this.props.setSelectedPurchase}
            />
          ) : (
            <p> No Purchases </p>
          )}
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  updateUser: (userId, params) => dispatch(updateUser(userId, params)),
  assignVoucher: (userId) => dispatch(assignVoucher(userId)),
});

export default connect(null, mapDispatchToProps)(UserDetails);
