import Immutable from 'immutable'
import { DESTROY_CURRENT_USER, SET_CURRENT_USER } from '../actions/constants'

const currentUser = (state = null, action) => {
  
  switch (action.type) {
    case SET_CURRENT_USER:
      return Immutable.fromJS(action.user)
    case DESTROY_CURRENT_USER:
      return null
    default:
      return state
  }
}

export default currentUser
