import React, { Component } from 'react';

class Affiliates extends Component {

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (<div className="sm-affiliates">
      <div className="title-main">
        <h1>AFFILIATES</h1>
      </div>
      <div className="sm-affiliates-main">
        <h2>COMING SOON</h2>
      </div>
    </div>)
  }
}

export default Affiliates
