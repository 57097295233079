import React from 'react';
import SMColorPicker from '../../utils/SMColorPicker'


class PlayBarColor extends React.Component {

  state = {
    playbackColor: this.initialPlaybackColor()
  }

  componentDidMount() {
    window.scrollTo(0, 0); 
  }

  initialPlaybackColor() {
    const overlayData = this.props.marker.get('overlayData', []).get(0)
    if(overlayData) {
      const markerColor = overlayData.get('color')
      if(markerColor) { 
        return markerColor.toJS()
      }
    }
    return { r: 56, g: 197, b: 243, a: .7 }
  }

  updateColor = (e) => {
    this.setState({ playbackColor: e.value })
  }

  nextStep = (e) => {
    this.props.updateMarker(this.props.marker.get('id'), {marker: { playback_color: this.state.playbackColor } })
    this.props.nextStep()
  }

  render() {
    return (
      <div className="sm-playbar-color common-padding">
        <div className='sm-select-playbar-color'>
          <div>
            <h2> Select the color of the play-bar in your Soundwave Tattoo&trade;</h2>
            <p> This is the color of the play-bar that will play back
            inside of your tattoo.</p>
            <p>Bright colors work best, do not choose black unless your tattoo is an outline only.</p>
            <div>
              <SMColorPicker value={this.state.playbackColor} handleChange={this.updateColor} name='color' />
            </div>
            <div className="btn btn-tan btn-large" onClick={this.nextStep}>
              Continue
            </div>
            <div className="bottom-spacer"></div>
          </div>
        </div>
      </div>
    )
  }
}

export default PlayBarColor