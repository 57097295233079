import React, { Component } from 'react';
const {PropTypes} = React;

class ModalWrapper extends Component{
  
  handleBackgroundClick = (e) => {
    if (e.target === e.currentTarget) this.props.hideModal();
  }

  onOk = () => {
    this.props.onOk();
    this.props.hideModal();
  }

  okButton = this.props.showOk
    ? (
      <button
        onClick={this.onOk}
        disabled={this.props.okDisabled}
      >
        {this.props.okText}
      </button>
    ) : null;

  handleKeyPress = (e) => {
    if(e.which == 27) {
      this.props.hideModal();
    }
  }

  componentDidMount() {
    document.addEventListener('keydown', this.handleKeyPress);
  }
  
  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleKeyPress);
  }

  render() {
    return (
      <div id={this.props.modalID} className="sm-modal" onClick={this.handleBackgroundClick}>
        <div className="modal-window">
          <header>
            <h1>{this.props.title}</h1>

            <button className="close btn-tan" onClick={this.props.hideModal}>Close</button>
          </header>

          <section className="content">
            {this.props.children}
          </section>

          {this.okButton}
        </div>
      </div>
    )
  }
};

ModalWrapper.propTypes = {
  // props
  title: PropTypes.string,
  showOk: PropTypes.bool,
  okText: PropTypes.string,
  okDisabled: PropTypes.bool,
  width: PropTypes.number,
  style: PropTypes.object,
  children: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.element,
    PropTypes.string,
  ]).isRequired,

  // methods
  hideModal: PropTypes.func,
  onOk: PropTypes.func,
};

ModalWrapper.defaultProps = {
  title: '',
  showOk: true,
  okText: 'OK',
  okDisabled: false,
  width: 400,
  onOk: () => {}
};

export default ModalWrapper;