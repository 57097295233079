import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { formatDate, formatAmount } from "../../models/payments";

class UserPurchaseRow extends React.Component {
  marker = null;

  componentDidMount() {
    const markerId = this.props.purchase.get("marker_id");
    if (markerId) {
      this.props.fetchMarker(markerId).then(() => {
        this.marker = this.props.markers.get(parseInt(markerId));
      });
    }
  }

  viewPurchase = (e) => {
    const { selectedUser, purchase } = this.props;
    this.props.setSelectedPurchase(purchase.get("id"));
    this.props.push(
      `/admin/users/${selectedUser.get("id")}/payment/${purchase.get("id")}`
    );
  };

  getStatus() {
    const { purchase } = this.props;

    if (purchase.get("is_refunded")) {
      return ` Refunded on ${formatDate(purchase.get("refund_date"))}`;
    } else if (this.marker && this.marker.get("is_active")) {
      return ` Activated on ${this.marker.get("activation_date")}`;
    } else if (this.marker && this.marker.get("is_deleted")) {
      return ` Deleted`;
    } else if (purchase && purchase.get("is_renwed")) {
      return ` Renewed`;
    } else if (this.marker) {
      return ` Purchase Completed - Step ${this.marker.get("current_step")}`;
    } else {
      return ` Deleted`;
    }
  }

  render() {
    const { selectedUser, purchase } = this.props;
    return (
      <tr>
        <td> {formatDate(purchase.get("purchase_date"))} </td>
        <td> {formatAmount(purchase.get("amount"))} </td>
        <td> {this.getStatus()} </td>
        <td>
          {" "}
          <div className="div-link" onClick={this.viewPurchase}>
            {" "}
            View Details{" "}
          </div>{" "}
        </td>
      </tr>
    );
  }
}

const mapStateToProps = (store) => ({
  markers: store.get("markers"),
});

export default connect(mapStateToProps, null)(UserPurchaseRow);
