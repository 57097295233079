import React from "react";
import { Field, reduxForm } from "redux-form/immutable";
import ReCAPTCHA from "react-google-recaptcha";
import { passwordMatchValidate } from "../../utils/form_validators";
import { updateUser } from "../../actions/currentUser";
import { updateUser as adminUpdateUser } from "../../actions/users";
import { connect } from "react-redux";

const required = (value) => (value ? undefined : "Required");

class PasswordReset extends React.Component {
  state = {
    submitted: false,
    next: null,
    captchaSolved: false,
    error: null,
  };

  componentDidMount() {
    window.scrollTo(0, 0);
    if (this.props.nextLocation) {
      this.setState({ next: this.props.nextLocation });
    }
  }

  onChange = (value) => {
    if (value) this.setState({ captchaSolved: true });
  };

  onSubmit = (userParams) => {
    if (!this.state.captchaSolved)
      return this.setState({
        error: "Please solve captcha",
        submitted: false,
      });

    this.setState({
      error: null,
    });

    this.setState({ submitted: true });
    if (this.props.adminUser) {
      return this.props.adminUpdateUser(
        this.props.adminUser.get("id"),
        userParams
      );
    } else {
      return this.props
        .updateUser(this.props.currentUser.get("id"), userParams)
        .then(() => {
          if (this.state.next) {
            this.props.history.push(this.state.next);
          }
        });
    }
  };

  renderField = ({
    input,
    placeholder,
    type,
    labelName,
    meta: { touched, error },
  }) => {
    return (
      <div>
        <label className="sm-field-label">{labelName}</label>
        <div>
          <input {...input} placeholder={placeholder} type={type} />
          {touched && error && <div className="sm-error-message">{error}</div>}
        </div>
      </div>
    );
  };

  render() {
    const { handleSubmit, onSubmit, error, submitFailed, submitSucceeded } =
      this.props;

    const { submitted } = this.state;

    return (
      <div className="sm-password-reset">
        <form className="sm-form" onSubmit={handleSubmit(this.onSubmit)}>
          <h4>Change your password </h4>

          {submitSucceeded && submitted && (
            <div className="sm-success-message">Saved Password!</div>
          )}
          {submitFailed && submitted && (
            <div className="sm-error-message">Error saving password</div>
          )}
          {this.state.error && (
            <div className="sm-error-message">{this.state.error}</div>
          )}
          {submitFailed && error && (
            <div className="sm-error-message">{error}</div>
          )}
          <div className="field-wrap">
            <Field
              labelName="Password:"
              type="password"
              component={this.renderField}
              name="password"
              placeholder="Password"
            />
          </div>
          <div className="field-wrap">
            <Field
              labelName="Confirm New Password:"
              type="password"
              component={this.renderField}
              name="confirm_password"
              placeholder="Confirm Password"
              validate={required}
            />
          </div>
          <div className="field-wrap d-flex flex-wrap justify-content-center">
            <ReCAPTCHA
              sitekey={this.props.siteKey}
              onChange={this.onChange}
              type="image"
            />
          </div>

          <button className="btn btn-tan btn-large" type="submit">
            Update Password
          </button>
        </form>
      </div>
    );
  }
}

const PasswordResetReduxForm = reduxForm({
  form: "PasswordReset",
  validate: passwordMatchValidate,
})(PasswordReset);

const mapStateToProps = (store) => ({
  currentUser: store.get("currentUser"),
});

const mapDispatchToProps = (dispatch) => ({
  updateUser: (userId, userParams) => dispatch(updateUser(userId, userParams)),
  adminUpdateUser: (userId, userParams) =>
    dispatch(adminUpdateUser(userId, userParams)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PasswordResetReduxForm);
