import "whatwg-fetch";
const API = {};
// const baseUrl = "https://tough-papayas-pull.loca.lt";
// const baseUrl = "http://127.0.0.1:6030";
// const baseUrl = "http://192.168.11.167:6030";
const baseUrl = "";

API.get = (url) => {
  return fetch(`${baseUrl}${url}`, {
    headers: {
      "X-Requested-With": "XMLHttpRequest",
      "Content-Type": "application/json",
    },
    // credentials: "include",
    credentials: "same-origin",
  }).then((response) => {
    if (response.status >= 200 && response.status < 300) {
      if (response.headers.get("content-type") === "application/json") {
        return response.json();
      } else {
        return response.blob();
      }
    }

    const error = new Error(response.statusText);
    error.response = response;
    throw error;
  });
};

API.getXML = (url) => {
  return fetch(`${baseUrl}${url}`, {
    headers: {
      "X-Requested-With": "XMLHttpRequest",
    },
    // credentials: "include",
    credentials: "same-origin",
  }).then((response) => {
    if (response.status >= 200 && response.status < 300) {
      return response.text();
    }

    const error = new Error(response.statusText);
    error.response = response;
    throw error;
  });
};

API.post = (url, body = {}, jsonifyBody = true) => {
  // let headers = {
  //   "X-Requested-With": "XMLHttpRequest",
  // };
  let headers = {
    Accept: "*/*",
    "Accept-Encoding": "gzip, deflate, br",
    "Accept-Language": "en-US,en;q=0.9",
    Connection: "keep-alive",
    "Content-Type": "application/json",
    Cookie:
      "_ga=GA1.1.672031107.1698744882; _ga_V3VFLK8NV8=GS1.1.1698744882.1.1.1698746370.59.0.0; session=.eJydjsFqxDAMRH_F6ByKbMuula_ovSyLLCubQNotcfa07L_X0D_oaRhmhjdPuC679NU6zJ9PcOcQ-LLe5WYwwcdu0s3t95vbvt15d6I6QneuW3c_o_MGl9f0z91lGvDD-grzeTxsuK3BDBmZOC0BW6lNQ7L3kKoyCSKpZ-bqY64tpKi5JkMUFm_REmUkjkKhYhH2MerwSWwpyJirNKNYyBYNVbNXadEXq4NBS4gNQ0bRwjzuXx_djr83VOD1C0cSWPQ.ZUIasA.sFOUur6m2a9BLLhKaR2fCy9kAuA",
    Host: "127.0.0.1:6030",
    Origin: "http://127.0.0.1:3001",
    Referer: "http://127.0.0.1:3001/",
    "Sec-Fetch-Dest": "empty",
    "Sec-Fetch-Mode": "cors",
    "Sec-Fetch-Site": "same-site",
    "Sec-GPC": "1",
    "User-Agent":
      "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/118.0.0.0 Safari/537.36",
    "X-Requested-With": "XMLHttpRequest",
    dnt: "1",
    "sec-ch-ua": '"Chromium";v="118", "Brave";v="118", "Not=A?Brand";v="99"',
    "sec-ch-ua-mobile": "?0",
    "sec-ch-ua-platform": "macOS",
  };
  body = JSON.stringify(body);
  headers["Content-Type"] = "application/json";

  return fetch(`${baseUrl}${url}`, {
    method: "POST",
    headers: headers,
    body: body,
    // credentials: "include",
    credentials: "same-origin",
  })
    .then((response) => {
      for (var pair of response.headers.entries()) {
      }
      if (response.status >= 200 && response.status < 300) {
        if (response.headers.get("content-type") === "application/json") {
          return response.json();
        } else {
          return response.blob();
        }
      }
      const error = new Error(response.statusText);
      error.response = response;
      throw error;
    })
    .catch((err) => {
      console.log("err", err);
      return err;
    });
};

API.patch = (url, body, jsonifyBody = true) => {
  let headers = {
    "X-Requested-With": "XMLHttpRequest",
  };

  body = JSON.stringify(body);
  headers["Content-Type"] = "application/json";

  return fetch(`${baseUrl}${url}`, {
    method: "PATCH",
    headers: headers,
    body: body,
    // credentials: "include",
    credentials: "same-origin",
  }).then((response) => {
    if (response.status >= 200 && response.status < 300) {
      return response.json();
    }

    const error = new Error(response.statusText);
    error.response = response;
    throw error;
  });
};

API.delete = (url) => {
  let headers = {
    "X-Requested-With": "XMLHttpRequest",
  };

  return fetch(`${baseUrl}${url}`, {
    method: "DELETE",
    headers: headers,
    // credentials: "include",
    credentials: "same-origin",
  }).then((response) => {
    if (response.status >= 200 && response.status < 300) {
      return response.json();
    }

    const error = new Error(response.statusText);
    error.response = response;
    throw error;
  });
};

export default API;
