import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class PrivacyPolicy extends Component {

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (<div className="sm-privacy">
      <div className="title-main" id='title-privacy-policy'>
        <h1>Privacy Policy</h1>
      </div>
      <div className="sm-privacy-main">

        <p>Last Modified: July 31, 2017</p>

        <p>Skin Motion, LLC (“Skin Motion”) is committed to protecting the privacy
          rights of users of its website (“Site”) and mobile application (“App”)
          (collectively the “Services”). The Services allow registered users to
          create and purchase personalized augmented reality tattoo experiences
          and search for tattoo artists to implement such personalized augmented
          reality tattoo experiences through Skin Motion’s proprietary technology
          that brings tattoos to life. This Privacy Policy describes the information
          that we collect from you through the Services, how we collect this
          information, and what we do with it after we collect it.</p>

        <p>By using the Services, or by providing your information to us or to
          our affiliates (and through the registration process for potential
          licensed tattoo artists), you consent to the collection, use, and
          disclosure of your information in accordance with this Privacy Policy
          and our Terms and Conditions of Use (the “Terms”). IF YOU DO NOT AGREE
          TO THIS PRIVACY POLICY, PLEASE DO NOT USE THE SERVICES.</p>

        <p>We reserve the right to modify this Privacy Policy at any time,
          so please review it frequently. If we make material or significant
          changes to this Privacy Policy, we will notify you here, by email,
          or by means of a notice on our Site or App. Your continued use of
          any of our Services will signify your acceptance of any changes
          to our Privacy Policy.</p>

        <h4>INFORMATION WE MAY COLLECT</h4>
        <p>Registration is mandatory in order to use the Services. If you register
          and create an account and/or application for registration, we will ask
          for some personal information, such as a valid email address and a
          password, which is used to help protect your account from unauthorized
          access. We may also collect additional personal information such as
          your name, physical address, phone number, and credit card information,
          when you create your account, submit a registration application, or
          make a purchase. All such information is collectively referred to herein
          as “Personal Information.” In addition, the App may collect certain
          information automatically, including, but not limited to, the location
          of your mobile device; the type of mobile device you use; mobile carrier
          and your mobile device’s unique device ID; the IP address of your mobile
          device; the mobile device operating system you use to access the App;
          the type of mobile Internet browsers you use; information about the way
          you use the App; information associated with your Internet or mobile
          service provider or your account with your Internet or mobile service
          provider; information associated with your Apple iTunes or Google Play
          accounts or any other account use to download or access the App; the
          content you view and/or download, search terms you enter, features you use,
          and the date and time of your visits to the App; and information collected
          from third parties providing services to us and from third parties with
          whom Skin Motion may advertise.</p>

        <h4>HOW WE USE YOUR INFORMATION</h4>
        <p>We may use the information and/or Personal Information we collect from
          you to: fulfill your requests for products and services, process your
          registration application, provide you with a voucher and/or purchase
          confirmation, facilitate your use of the Services, offer products and
          services that may be of interest to you, manage your account and your
          preferences, communicate with you about your account or changes to the
          Services, the Terms or this Privacy Policy, respond to your questions,
          customize your experience, analyze use of and improve our Services,
          identify and protect against fraudulent transactions and other misuses
          of our Services, and enforce our Terms.</p>

        <h4>DISCLOSURE OF INFORMATION</h4>
        <p>We may share aggregated, non-personally identifiable information about
          you with third parties, including selling such aggregated non-personally
          identifiable data and the results of analytics regarding same to third
          parties. This information is anonymous, statistical information that
          reflects usage of the Services and does not include your Personal
          Information. We may use your email address to send messages relating
          to our company or products, from which you will have the ability to
          opt-out.</p>

        <p>We will not share your Personal Information with others, except as
          indicated below.</p>

        <p>We may share personal information with service providers, such as
          credit-card processors, fulfillment partners, data management providers,
          promotional managers, remarketers, or other similar partners
          performing services on our behalf. Third party service providers
          with whom we share Personal Information will be required to use
          that Personal Information only as needed to provide the services
          they are providing to us and in a manner consistent with this Privacy
          Policy; <br/>
          We may need to disclose your Personal Information without your consent
          and without notice to you in response to legal process; to prevent fraud;
          to comply with legal or regulatory requirements; to enforce our Privacy
          Policy and/or Terms; to investigate claims of intellectual property
          infringement, to protect the rights, property or safety of our users,
          our employees, or others; or to investigate security breaches or
          otherwise cooperate with authorities pursuant to a legal matter. <br/>
          We may disclose your Personal Information in connection with a merger,
          acquisition, or liquidation of our company or sale of certain assets,
          to any third party involved in such transactions, including those who
          may be located outside the United States, and in connection with business
          negotiations with respect to any such transactions.</p>

        <h4>NOTIFICATIONS AND LOCATION SERVICES</h4>
        <p>When you access our App, you may be asked whether you will allow us
          to access your location while you are not using the App and/or whether
          you would like to receive notifications on your mobile device. If you
          click “allow” but change your mind at any time in the future and no
          longer wish to allow us to access your location and/or to receive
          notifications, you will be able to opt-out by updating the privacy
          settings on your mobile device.</p>

        <h4>COOKIES, CLEAR GIFs, AND USAGE DATA</h4>
        <p>Like most Services, we may track use of the Services so we can analyze
          usage patterns and improve our service. The technologies we use to
          monitor Services activity may include cookies and clear GIFs, among
          others. A cookie is a small text file that may have a unique identifier,
          and resides and may be stored, among other places, on a user’s computer,
          mobile device, and emails we send you. Through the use of a cookie,
          we may automatically collect information about your activity on the
          Services, such as the pages you visit, the links you click, the searches
          you conduct, and the date and time of your use of the Services. When
          you sign in to your account we may record your user ID and the name on
          your account in the cookie file on your computer or mobile device, as
          applicable. We also may record your password in this cookie file, if you
          indicated that you would like your password saved for automatic sign-in.
          For security purposes, we will encrypt any usernames, passwords, and
          other user member account-related data that we store in such cookies.
          We may allow third-party providers to serve cookies from the Services
          to allow them to assist us in various activities, such as analysis and
          research on the effectiveness of the Services, content, and potential
          advertising. Any information that these third parties collect via cookies
          and web beacons is not linked to any Personal Information collected by
          us. Cookies may be persistent or stored only during an individual
          session.</p>

        <p>You may delete or decline browser cookies on your computer by changing
          your browser settings. (Click “Help” in the toolbar of most browsers for
          instructions.) If you do so, some of the features of the Site may not
          function properly.</p>

        <p>Clear GIFs (a.k.a. web bugs, beacons, or tags) are small graphic images
          placed on a web page, web-based document, or in an email message. Clear
          GIFs are invisible to the user because they are typically very small
          (only 1-by-1 pixel) and the same color as the background of the web page,
          document or email message. We do not use clear GIFs to collect Personal
          Information about you. However, we may use clear GIFs to capture statistical
          usage information for our Services or products and services offered
          through the Services. We may correlate this information to a user to
          personalize the user experience and for statistical analysis of user
          experiences on our Services.</p>

        <p>We also maintain log files that contain IP addresses. In general, we
          may use log files to monitor traffic on our Services, to troubleshoot
          technical problems, and to report aggregate information to our advertisers.
          In the event of user abuse of our Services, however, we may block certain
          IP addresses or console or mobile device IDs, as applicable. If available,
          IP addresses and console or mobile device IDs may be used to personally
          identify you in order to enforce our Terms.</p>

        <h4>SECURITY</h4>
        <p>We take the safeguarding of your Personal Information seriously. We
          employ reasonable security measures to prevent or reduce the risk of
          disclosure of such information. Any sensitive, personal information,
          such as credit card numbers, will be encrypted with Secure Socket Layer
          (SSL). If accessing the Site through your computer, while on a secure
          page, the lock icon in the address bar of your internet browser becomes
          locked, as opposed to un-locked, or open, when you are just ‘surfing’.
          While we strive to ensure the safety and security of your information,
          no security measures are perfect or impenetrable. To protect the
          confidentiality of your Personal Information, you must keep your
          password confidential and not disclose it to any other person.</p>

        <h4>THIRD PARTY SITES</h4>
        <p>The Services may contain advertising or links to web sites operated
          by third parties. The fact that our Services contain such links is not
          an endorsement or representation of our affiliation with that third
          party. By clicking on one of these links, you will leave the Services
          and will be subject to the privacy policy of the linked website. Because
          we cannot control the activities of third parties, we cannot accept
          responsibility for any use of your Personal Information by such third
          parties, and we cannot guarantee that they will adhere to the same privacy
          and security practices as we do. If you visit a third party website
          that is linked to our Services, you should consult that website’s privacy
          policy before providing any Personal Information.</p>

        <h4>WEB LOGS AND OTHER FORUMS</h4>
        <p>The Services may offer web logs, web postings, bulletin boards, chat
          rooms, newsgroups or other forums which permit users to interact online.
          Any Personal Information or other information you transmit or post online
          in such forums will be made public and Skin Motion cannot control nor
          is it responsible for the use any third party may make of such Personal
          Information or other information.</p>

        <h4>REMARKETING</h4>
        <p>We may use third party technologies to remarket our Services or products
          based on your behavior while using the Services. Note that only non-personally
          identifiable information is used in this process. This means that
          depending which pages you visit on the Services, your browser can
          become part of an audience, thus be exposed to our ads though third-party
          ad networks (including Google) across the Internet. You can opt out of
          being included in remarketing by visiting the Network Advertising Initiative
          opt-out page. If you agree to receive promotional offers on our products,
          we will provide your name and email address to our partners so that they
          can send you such promotions.</p>

        <h4>MINORS</h4>
        <p>We do not knowingly or intentionally collect any Personal Information
          from anyone under the age of 18. If a parent or guardian becomes aware
          that his or her child has provided us with Personal Information without
          their consent, he or she should contact us at support@skinmotion.com.
          If we become aware that a child under 18 has provided us with Personal
          Information, we will delete such Personal Information.</p>

        <h4>BUSINESS TRANSITIONS</h4>
        <p>In the event Skin Motion goes through a business transition, such as
          a merger, acquisition by another company, or sale of a portion of its
          assets, your information, including your Personal Information will, in
          most instances, be part of the assets transferred.</p>

        <h4>INTERNATIONAL DATA TRANSFERS</h4>
        <p>The Services are operated and maintained within the United States.
          If you are visiting the Services from outside of the United States,
          you should be aware that your data will be transferred to and processed
          under the laws of the United States, which may not have the same data
          protection laws as the country in which you are located. By visiting
          the Services, you consent to the processing and transfer of your data
          as set out in this Privacy Policy in the United States and subject to
          the laws of the United States. If you do not consent to having your
          information used in accordance with this Privacy Policy, you may not
          access the Services.</p>

        <h4>LIMITATION OF LIABILITY</h4>
        <h4 id="liability">YOU UNDERSTAND AND AGREE THAT ANY DISPUTE OVER PRIVACY IS SUBJECT TO
          THE TERMS AND CONDITIONS OF THIS PRIVACY POLICY AND THE SERVICES’ TERMS
          (INCLUDING ANY LIMITATIONS ON DAMAGES CONTAINED THEREIN).</h4>

        <h4>MODIFYING YOUR INFORMATION AND OPTING OUT</h4>
        <p>You may log in to your account at any time to modify the information
          you provided when you created it. For security purposes, however, the
          first and last name on an account cannot be changed once it has been
          created. You may unsubscribe from any emails using the link provided
          in the email. If you request that your account be deleted, we may still
          maintain information about any transactions or campaigns in which you
          participated.</p>

        <p>You may request details of Personal Information which we hold about
          you for a small fee. In addition, California Civil Code Section 1798.83
          entitles California customers to request information concerning whether
          a business has disclosed Personal Information to any third parties
          for the third parties’ direct marketing purposes. As stated in this
          Privacy Policy, Skin Motion will not sell or share your Personal
          Information with third-party companies for their direct marketing
          purposes without your consent. California customers who wish to
          request further information about our compliance with this law may
          contact us by email at <Link to="/support">support@skinmotion.com</Link> with
          Attention: Legal Department CA Civil Code Section 1798.83 Inquiry
          in the subject line.</p>

        <p>You can stop all collection of information (including Personal Information)
          by the App easily by uninstalling the App. You may use the standard
          uninstall processes as may be available as part of your mobile device
          or via the mobile App marketplace or network. You can also request to
          opt-out via email, at <Link to="/support">support@skinmotion.com</Link>, but you will be unable
          to continue using the App.</p>

        <h4>RESPONSE TO DO NOT TRACK SIGNALS</h4>
        <p>We do not track our customers over time and across third party websites
          to provide targeted advertising and therefore do not respond to Do Not
          Track (“DNT”) signals. However, some third party sites and mobile applications
          do keep track of your browsing activities when they serve you content,
          which enables them to tailor what they present to you. If you are visiting
          such sites or mobile applications, your web browser may allow you to
          set the DNT signal on your browser so that third parties (particularly advertisers)
          know you do not want to be tracked.</p>

        <h4>CONTACT</h4>
        <p>If you would like to communicate with us regarding privacy issues or
          have questions or comments about this Privacy Policy, please contact
          us at <Link to="/support">support@skinmotion.com</Link>.</p>

      </div>
    </div>)
  }
}

export default PrivacyPolicy
