import React, { Component } from 'react';

class Tutorial extends Component {

  componentDidMount() {
    window.scrollTo(0, 0);
  }
  
  render() {
    return <div className="sm-tutorial" id="title-large">
      <div className="title-main">
        <h1>TUTORIAL</h1>
      </div>
      <div className="sm-tutorial-main">
        <h2>COMING SOON</h2>
      </div>
    </div>
  }
}

export default Tutorial
