import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import YouTube from 'react-youtube';
import { setMobileMode } from '../../actions/mobileMode'


class MobileDashboard extends Component {

  state = { 
    player: null
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  componentWillReceiveProps(nextProps) {
    if(!nextProps.mobileMode) {
      this.props.setMobileMode()
      this.state.player.pauseVideo();
    }
  }

  onReady = (event) => {
    this.setState({ player: event.target })
  }

  render() {
    const opts = {
      height: '100%',
      width: '100%',
      playerVars: {
        list: 'PLbGddS5sCL06jre5r7IWWQXCdW3uUJMbO',
        showinfo: 0
      }
    }
    return (
      <div className="sm-mobile-dashboard">
        <div className="sm-mobile-dashboard-video">
          <YouTube
            opts={opts}
            onReady={this.onReady} />
        </div>     
        <div className="sm-mobile-dashboard-main">
          <h1>"Tattoos you can hear!"</h1>
          <h2>Have you ever imagined you would be able to hear sound played back from a tattoo?</h2>
          <p>We're pleased
          to announce that you can now get your own Soundwave Tattoo! We have been working
          hard since April 2017 to create the first personalized augmented reality cloud platform
          to revolutionize the tattoo industry and bring tattoos to life.</p>
          <p>To see how Soundwave Tattoos play back using this app, visit our website <Link to="/demo">SkinMotion.com</Link> on
           another computer or device and point your camera at the Soundwave Tattoos on the demo page.</p>
          <p>The Skin Motion Team is working hard to optimize our app and website and appreciate your feedback.
          If you have any issues or problems with the app please <Link to="/support">contact
           customer support</Link>.</p>
        </div>
      </div>
    )
  }

}

{/* To break out of mobile browser window: uniwebview://goto?url=skinmotion.com */}

const mapStateToProps = (store) => ({
  mobileMode: store.get('mobileMode')
})

const mapDispatchToProps = dispatch => ({
  setMobileMode: () => dispatch(setMobileMode())
})

export default connect(mapStateToProps, mapDispatchToProps)(MobileDashboard)
