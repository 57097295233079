import React from 'react';
import { Link } from 'react-router-dom';

class ArtistRow extends React.Component {
    render() {
        const { artist, distance } = this.props;
        // console.log('distance', distance)
        if (!artist.is_active) {
            return null;
        }

        let name = null;
        if (artist.name) {
            name = artist.name.replace(/\s+/g, '-');
        }

        return (
            <div className="sm-artist-results-item">
                <div className="sm-artist-results-profile-pic">
                    <Link to={`/artist-details/${artist.id}/${name}`}>
                        <div
                            className="profile-pic"
                            style={{
                                backgroundImage:
                                    'url(' + artist.profile_photo + ')',
                            }}
                        />
                    </Link>
                </div>
                <div className="sm-artist-results-info">
                    <h3 className="sm-artist-results-name">
                        <Link to={`/artist-details/${artist.id}/${name}`}>
                            {artist.name}
                        </Link>
                    </h3>
                    <h4 className="sm-artist-results-shop">
                        <Link to={`/artist-details/${artist.id}/${name}`}>
                            {artist.shop}
                        </Link>
                    </h4>
                    <p className="sm-artist-results-distance">
                        <Link to={`/artist-details/${artist.id}/${name}`}>
                            {distance}mi away from you
                        </Link>
                    </p>
                    {/*
          <div className="sm-artist-results-rating">
            <Link to={`/artist-details/${artist.artist.get('id')}/${name}`}>
              <div className={`star-count star-rating rating-${artist.artist.get('rating')}`}></div>
            </Link>
          </div>
          */}
                </div>
                <div className="sm-artist-results-button">
                    <Link
                        className="btn btn-tan"
                        to={`/artist-details/${artist.id}/${name}`}
                    >
                        View artist
                    </Link>
                </div>
            </div>
        );
    }
}

export default ArtistRow;
