import React from 'react'
import { connect } from 'react-redux'

import { uploadPhoto, fetchMarker } from '../../actions/markers'
import TattooImageUpload from './soundwaves/TattooImageUpload'

class UploadReplacementPhoto extends React.Component {

  state = {
    marker: null
  }

  componentDidMount() {
    this.props.fetchMarker(this.props.match.params.markerId)
    .then(() => {
      this.setState({marker: this.getMarker()})
    })
  }

  getMarker = () => {
    const { markerId } = this.props.match.params
    if (!this.props.markers) { return null }
    return this.props.markers.get(parseInt(markerId))
  }

  render () {
    return (
      <TattooImageUpload 
        saveImageInState={() => {} }
        uploadPhoto={this.props.uploadPhoto}
        marker={this.state.marker} 
        nextStep={() => {this.props.push('/my-tattoos')}} />
    )
  }
}

const mapStateToProps = store => ({
  markers: store.get('markers')
})

const mapDispatchToProps = (dispatch) => ({
  uploadPhoto: (markerId, params) => dispatch(uploadPhoto(markerId, params, true)),
  fetchMarker: (markerId) => dispatch(fetchMarker(markerId))
})

export default connect(mapStateToProps, mapDispatchToProps)(UploadReplacementPhoto)
