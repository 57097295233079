import React, { Component } from "react";
import { Link } from "react-router-dom";
import Immutable from "immutable";
import { connect } from "react-redux";
// import loadGoogleMapsAPI from 'load-google-maps-api';
import { getDistance } from "../../utils/geolocation";

// Just a null comment to test sentry commit tracking.
class GoogleMap extends Component {
  static defaultProps = {
    addressList: [],
  };

  map = null;
  markerInfoWindows = [];

  componentDidMount() {
    // loadGoogleMapsAPI().then((gmaps) => {
    // //  console.log(gmaps)
    //   // TODO --  Auth with google here and make sure the window.google.maps
    //   // object is globally available and/or refactor places using it directly
    //   // then remove from _base.html the html <script> tag including the
    //   // maps api.
    // })
    // .catch((err) => console.log(err))
    this.initializeMap();
  }

  componentWillReceiveProps(nextProps) {
    const { artists } = nextProps;
    let userLocation = nextProps.userLocation.get("user");
    let searchLocation = nextProps.userLocation.get("search");
    const oldUserLocation = this.props.userLocation.get("user");
    const oldSearchLocation = this.props.userLocation.get("search");

    this.initializeMap();

    if (artists && !Immutable.is(artists, this.props.artists)) {
      this.addArtistMarkers(artists, nextProps.userLocation);
    }
    if (!this.props.centerOnArtist) {
      if (
        userLocation &&
        !searchLocation &&
        !Immutable.is(userLocation, oldUserLocation)
      ) {
        this.panMap(userLocation);
      }
      if (searchLocation && !Immutable.is(searchLocation, oldSearchLocation)) {
        this.panMap(searchLocation.get("location"));
      }
    }
  }

  async initializeMap() {
    this.getUserLocation().then((res) => {
      const { artists, centerOnArtist } = this.props;
      const searchLocation = this.props.userLocation.get("search");
      const userLocation = this.props.userLocation.get("user");

      const defaultLocation = Immutable.fromJS({
        lat: 34.0195,
        lng: -118.4912,
        zoom: 2,
      });

      let center = null;

      if (artists && centerOnArtist) {
        center = artists.first().get("location");
      } else {
        center = searchLocation
          ? searchLocation.get("location")
          : userLocation
          ? userLocation
          : res
          ? Immutable.fromJS(res)
          : defaultLocation;
      }
      this.map = new window.google.maps.Map(this.refs.map, {
        zoom: center.get("zoom") || 8,
        center: new window.google.maps.LatLng(
          center?.get("lat") || center.lat,
          center?.get("lng") || center.lng
        ),
      });

      if (artists) {
        this.addArtistMarkers(artists);
      }
    });
  }

  getUserLocation() {
    const defaultLocation = {
      lat: 34.0195,
      lng: -118.4912,
      zoom: 2,
    };
    return new Promise((resolve, reject) => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const { latitude, longitude } = position.coords;
            resolve({ lat: latitude, lng: longitude });
          },
          () => {
            resolve(defaultLocation);
            // reject("Unable to retrieve user location");
          }
        );
      } else {
        reject("Geolocation not supported");
      }
    });
  }

  addArtistMarkers(artists) {
    const { userLocation } = this.props;
    let infoWindow = new window.google.maps.InfoWindow({
      content: "",
    });
    artists.forEach((artist, artistId) => {
      let location = artist.get("location", Immutable.Map());
      if (location) {
        let marker = new window.google.maps.Marker({
          position: new window.google.maps.LatLng(
            location.get("lat"),
            location.get("lng")
          ),
          map: this.map,
        });
        marker.addListener("click", () => {
          infoWindow.setContent(
            document
              .getElementById(`artist-${artist.get("id")}`)
              .cloneNode(true)
          );
          infoWindow.open(this.map, marker);
        });

        // This is a little janky but the idea is if we're
        // "centering on artist", there's only one artist in the address
        // list in the first place so we're good to just pan to the location.
        if (this.props.centerOnArtist) {
          this.panMap(location);
        }
      }
    });
  }

  createInfoContent(artist) {
    const { userLocation } = this.props;
    let distance = getDistance(userLocation, artist);
    let name = null;
    if (artist.get("name")) {
      name = artist.get("name").replace(/\s+/g, "-");
    }

    return (
      <div
        key={artist.get("id")}
        id={`artist-${artist.get("id")}`}
        className="sm-map-marker-info"
      >
        <Link
          onClick={this.profileClick}
          to={`/artist-details/${artist.get("id")}/${name}${
            this.props.mobileMode ? "?inapp=true" : ""
          }`}
        >
          <div
            className="sm-map-marker-info-photo"
            style={{
              backgroundImage: "url(" + artist.get("profile_photo") + ")",
            }}
          />
        </Link>
        <div className="sm-map-marker-info-name">{artist.get("name")}</div>
        <div className="sm-map-marker-info-city">{artist.get("city")}</div>
        {/** this calculates to the starting location, but not updated search locations
          ** removed until search is redone
        <div className="sm-map-marker-info-distance">
          {distance}mi
        </div>
        */}
        <div className="sm-map-marker-info-link">
          <Link
            onClick={this.profileClick}
            className="btn btn-blue"
            to={`/artist-details/${artist.get("id")}/${name}${
              this.props.mobileMode ? "?inapp=true" : ""
            }`}
          >
            View Profile
          </Link>
        </div>
      </div>
    );
  }

  profileClick = (e) => {
    e.preventDefault();
  };

  panMap(location) {
    // TODO -- Update zoom level to include 5 matches
    this.map.panTo(
      new window.google.maps.LatLng(location.get("lat"), location.get("lng"))
    );
  }

  render() {
    let infoWindows = this.props.artists
      .map((artist, artistId) => {
        return this.createInfoContent(artist);
      })
      .valueSeq();
    return (
      <div className="map-container">
        <div className="google-map" ref="map" />
        <div className="sm-info-container">{infoWindows}</div>
      </div>
    );
  }
}

const mapStateToProps = (store) => ({
  userLocation: store.get("userLocation"),
  mobileMode: store.get("mobileMode"),
});

export default connect(mapStateToProps, null)(GoogleMap);
