"use strict";
import "./index.scss";
import React from "react";
import Raven from "raven-js";
import ReactDOM from "react-dom";
import { StripeProvider } from "react-stripe-elements";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { ConnectedRouter } from "react-router-redux";
import Main from "./components/Main";
// import PasswordReset from "components/application/PasswordReset";
import { history, skinMotionStore } from "./reducers";

Raven.config("https://b75e6d808d66467d996e038cf236ddf2@sentry.io/177580", {
  environment: process.env.NODE_ENV,
  release: process.env.RELEASE_TAG,
}).install();

let userID = null;
let siteKey = null;
const appRoot = document.querySelector("#main");
if (appRoot) {
  let stripeAPIKey = appRoot.getAttribute("data-stripe-key")
    ? appRoot.getAttribute("data-stripe-key")
    : null;
  userID = appRoot.getAttribute("data-user-id");
  siteKey = appRoot.getAttribute("data-site-key");

  if (userID == "None") {
    userID = null;
  } else {
    userID = parseInt(userID);
  }

  ReactDOM.render(
    <Provider store={skinMotionStore}>
      <ConnectedRouter history={history}>
        <StripeProvider apiKey={stripeAPIKey}>
          <Main userID={userID} siteKey={siteKey} />
        </StripeProvider>
      </ConnectedRouter>
    </Provider>,
    appRoot
  );
}
