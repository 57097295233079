import Immutable from 'immutable'
import { RECEIVE_TRANSFERS, RECEIVE_TRANSFER } from '../actions/voucherTransfers'

const voucherTransfers = (state = Immutable.Map(), action) => {
  switch(action.type) {
    case RECEIVE_TRANSFER:
      return state.set(action.transfer.id, Immutable.fromJS(action.transfer))
    case RECEIVE_TRANSFERS:
      let tmpState = state
      action.transfers.forEach(transfer => {
        tmpState = tmpState.set(transfer.id, Immutable.fromJS(transfer))
      })
      return tmpState
    default:
      return state
  }
}

export default voucherTransfers