import React from 'react';

class TransferRow extends React.Component {

  state = {
    alradyClaimed: null
  }

  cancelTransfer = () => {
    this.props.cancelVoucherTransfer(this.props.transfer.get('id'))
    .catch(() => this.props.handleClaimError())
  }

  render() {
    let formattedDate = new Date(this.props.transfer.get('created_at'))
    return (
      <tr>
        <td>{formattedDate.toLocaleDateString("en-US")}</td>
        <td>{this.props.transfer.get('to_user_email')}</td>
        <td>
          {this.props.transfer.get('is_claimed') ? (
            <p>Accepted</p>
          ) : this.props.transfer.get('is_canceled') ? (
            <p> Canceled </p>
          ) : (
            <p>Pending <div className="div-link" onClick={this.cancelTransfer}>Cancel</div></p>
          )}
        </td>
      </tr>
    )
  }
}

export default TransferRow