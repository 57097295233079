import React, { Component } from "react";
import { Link } from "react-router-dom";
import Body from "./layouts/Body";
import Header from "./layouts/Header";
import Footer from "./layouts/Footer";
import VerificationReminder from "./account/VerificationReminder";
import MobileHeader from "./layouts/MobileHeader";
import MobileFooter from "./layouts/MobileFooter";
import CampaignHeader from "./campaigns/CampaignHeader";
import CampaignFooter from "./campaigns/CampaignFooter";
import ModalWrapper from "./UI/ModalWrapper";
import TermsAndConditions from "./application/TermsAndConditions";
import PrivacyPolicy from "./application/PrivacyPolicy";
import { connect } from "react-redux";
import { fetchCurrentUser } from "../actions/currentUser";
import { getGeolocation } from "../actions/userLocation";
import { setMobileMode } from "../actions/mobileMode";
import { firebaseObj } from "../utils/firebase";

class Main extends Component {
  state = {
    vouchers: [],
    feed: null,
    fetchFeed: false,
    topReferrers: [],
    showTermsModal: false,
    showPrivacyModal: false,
  };

  showTerms = () => {
    this.setState({ showTermsModal: true });
  };

  showPrivacy = () => {
    this.setState({ showPrivacyModal: true });
  };

  hideModal = () => {
    this.setState({ showTermsModal: false, showPrivacyModal: false });
  };

  componentWillMount() {
    let query = this.props.location.search.split("=");
    if (query[0] == "?inapp" && query[1] == "true") {
      this.props.setMobileMode();
    }
  }

  componentDidMount() {
    // Removing all console log
    // console.log = function () {};

    if (this.props.userID || localStorage.getItem("userId")) {
      this.props
        .fetchCurrentUser(this.props.userID || localStorage.getItem("userId"))
        .then((user) => {
          this.props.getGeolocation(user).catch();
        })
        .catch((err) => console.log(err));
    } else {
      this.props
        .getGeolocation(null)
        .then()
        .catch((err) => console.error(err));
    }
  }

  componentDidUpdate() {
    if (this.props.currentUser) {
      // Passing the user's firebase token so firebase signin can happen.
      if (!firebaseObj.active) {
        firebaseObj.authenticate(this.props.currentUser);
      }
    }
  }

  handleScriptLoad = () => {
    window.Stripe.setPublishableKey(this.props.stripeKey);
  };

  render() {
    const { currentUser, mobileMode, location } = this.props;
    const isCampaignPage = location.pathname.includes("/campaign/")
      ? true
      : false;
    const SiteHeader = isCampaignPage
      ? CampaignHeader
      : mobileMode
      ? MobileHeader
      : Header;
    const SiteFooter = isCampaignPage
      ? CampaignFooter
      : mobileMode
      ? MobileFooter
      : Footer;
    let campaignSlug = null;
    if (isCampaignPage) {
      campaignSlug = location.pathname.split("/").slice(-1)[0];
    }

    return (
      <div className={`sm-main ${campaignSlug}`}>
        <SiteHeader location={this.props.location} />

        {currentUser &&
          !currentUser?.get("is_verified") &&
          currentUser?.get("is_active") && (
            <VerificationReminder currentUser={currentUser} />
          )}
        {this.state.showTermsModal && (
          <ModalWrapper
            modalID="terms-and-conditions-modal"
            title="abc"
            width={400}
            showOk={true}
            hideModal={this.hideModal}
          >
            <TermsAndConditions />
          </ModalWrapper>
        )}
        {this.state.showPrivacyModal && (
          <ModalWrapper
            modalID="privacy-policy-modal"
            title="abc"
            width={400}
            showOk={true}
            hideModal={this.hideModal}
          >
            <PrivacyPolicy />
          </ModalWrapper>
        )}
        <Body
          stripeKey={this.props.stripeKey}
          siteKey={this.props.siteKey}
          showTerms={this.showTerms}
          showPrivacy={this.showPrivacy}
          hideModal={this.hideModal}
        />
        <SiteFooter
          showTerms={this.showTerms}
          showPrivacy={this.showPrivacy}
          hideModal={this.hideModal}
        />
      </div>
    );
  }
}

const mapStateToProps = (store, ownProps) => ({
  currentUser: store.get("currentUser"),
  mobileMode: store.get("mobileMode"),
  location: store.get("router").location,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  fetchCurrentUser: (id) => dispatch(fetchCurrentUser(id)),
  getGeolocation: (user) => dispatch(getGeolocation(user)),
  setMobileMode: () => dispatch(setMobileMode()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Main);
