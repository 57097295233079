import React, { Component } from "react";
// import serialize from 'form-serialize';
import { connect } from "react-redux";
import { updateUser } from "../../actions/currentUser";
import PasswordReset from "../application/PasswordReset";

class ChangePassword extends Component {
  state = {
    editAccountFormSubmitted: false,
    passwordResetFormSubmitted: false,
  };

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  updateUser = (userParams, dispatch, props) => {
    if (props.form === "PasswordReset") {
      this.setState({ passwordResetFormSubmitted: true });
    } else {
      this.setState({ editAccountFormSubmitted: true });
    }
    return this.props.updateUser(this.props.currentUser.get("id"), {
      first_name: userParams.get("first_name"),
      last_name: userParams.get("last_name"),
      email: userParams.get("email"),
    });
  };

  pad(n, width, z) {
    if (!isNaN(n)) {
      z = z || "0";
      n = n + "";
      return n.length >= width
        ? n
        : new Array(width - n.length + 1).join(z) + n;
    }
  }

  render() {
    const { currentUser, siteKey } = this.props;

    return (
      <div className="sm-change-password common-padding">
        <PasswordReset siteKey={siteKey} />
      </div>
    );
  }
}

const mapStateToProps = (store) => ({
  currentUser: store.get("currentUser"),
  mobileMode: store.get("mobileMode"),
});

const mapDispatchToProps = (dispatch) => ({
  updateUser: (userId, userParams) => dispatch(updateUser(userId, userParams)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ChangePassword);
