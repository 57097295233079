import React from 'react'
import { connect } from 'react-redux'

import AdminTattooListComponent from './AdminTattooListComponent'

import { 
  fetchMarkersForReview, 
  rejectMarker, 
  unclaimMarker,
  activateMarker } from '../../actions/markers'
import { fetchUser } from '../../actions/users'
import { needsConfiguration } from '../../models/marker'
const PAGE_SIZE = 20

class ManageTattoos extends React.Component {

  state = {
    page: 1,
    loading: null
  }

  componentDidMount () {
    this.setState({ loading: true }); 
    this.props.fetchMarkersForReview()
    .then(() => this.setState({ loading: false }))
  }

  // TODO -- Make this list live-update with various changes. 

  previousPage = () => { this.setState({page: this.state.page - 1}) }

  nextPage = () => { this.setState({page: this.state.page + 1}) }

  paginationLinks () {
    const { markers } = this.props
    let showPreviousPage = false
    let showNextPage = false
    if (markers.size < PAGE_SIZE) { return null }
    if (this.state.page > 1) {  showPreviousPage = true }
    if (Math.ceil(markers.size/PAGE_SIZE) > this.state.page ) { showNextPage = true }

    return (
      <div className='page-markers'>
        {showPreviousPage ? <a href='#/' onClick={this.previousPage}> Previous </a> : null }
        {showNextPage ? <a href='#/' onClick={this.nextPage}> Next </a> : null }
      </div>
    )
  }

  render () {
    const { page } = this.state
    const { currentUser } = this.props;

    if(this.state.loading) {
      return (
        <div className="sm-waveform-loading">
          <p>Loading Tattoos...</p>
          <div className="sm-loader"></div>
        </div>
      )
    }

    return (
      <div className='sm-manage-tattoos'>
        { this.paginationLinks() }
        {
          this.props.markers.valueSeq()
          .slice((page-1)*PAGE_SIZE, (page * PAGE_SIZE))
          .filter(marker => (!marker.get('activator_id') || marker.get('activator_id') == currentUser.get('id')))
          .filter(marker => (marker.get('current_step') >= 8))
          .map(marker => (
            <AdminTattooListComponent
              key={marker.get('id')}
              marker={marker}
              users={this.props.users}
              fetchUser={this.props.fetchUser}
              rejectMarker={this.props.rejectMarker}
              unclaimMarker={this.props.unclaimMarker}
              activateMarker={this.props.activateMarker}
            />
          ))
        }
      </div>
    )
  }
}

const mapStateToProps = (store) => ({
  users: store.get('users'),
  markers: store.get('markers').filter(marker => needsConfiguration(marker)),
  currentUser: store.get('currentUser')
})

const mapDispatchToProps = (dispatch) => ({
  fetchUser: (userId) => dispatch(fetchUser(userId)),
  fetchMarkersForReview: () => dispatch(fetchMarkersForReview()),
  rejectMarker: markerId => dispatch(rejectMarker(markerId)),
  unclaimMarker: markerId => dispatch(unclaimMarker(markerId)),
  activateMarker: markerId => dispatch(activateMarker(markerId))
})

export default connect(mapStateToProps, mapDispatchToProps)(ManageTattoos)
