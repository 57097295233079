import React, { Component } from 'react';
import Immutable from 'immutable';
import { Link } from 'react-router-dom';
import { Field, reduxForm } from 'redux-form/immutable';
import { connect } from 'react-redux';
import { saveApplication } from '../../../actions/artistApplications';
import { fetchCurrentUser } from '../../../actions/currentUser';
import {
  required,
  fbNameValidate,
  socialHandleValidate } from '../../../utils/form_validators';
import { trimSpaces } from '../../../utils/form_normalizers';

class ArtistApplication extends Component {

  componentDidMount() {
    window.scrollTo(0,0);
  }

  onSubmit = (params) => {
    return this.props.saveApplication(this.props.currentUser.get('id'), params.toJS())
    .then(() => {
      this.props.fetchCurrentUser(this.props.currentUser.get('id'));
    })
  }

  renderField = ({
    input,
    placeholder,
    type,
    meta: { touched, error }
  }) => {
    return (
      <div>
        <div>
          <input {...input} placeholder={placeholder} type={type} />
          {touched && error &&
            <div className='sm-error-message'>
              {error}
            </div>
          }
        </div>
      </div>
    )
  }

  render () {
    const { handleSubmit } = this.props

    return (
      <div className="sm-artist-application">
        <div className="title-main title-long" id='title-artist-application'>
          <h1>Tattoo artist application</h1>
          {/* <h2>Join the Skin Motion Tattoo Artist Network</h2> */}
        </div>
        <div className="sm-artist-application-intro">
          <p>Fill out the application completely.</p>
          <p>After filling out this application you will need to watch a
          short training video and pass a test in order to complete your registration.</p>
          <p className='mb-0'>Your business information will be used on your Artist Profile,
          and can be changed after you have completed registration.</p>
        </div>
        <div className="sm-artist-application-content">
          <h3>Your Account Information</h3>
          <div className="sm-artist-info">
            <p><strong>Name:</strong> {this.props.currentUser.get('first_name')} {this.props.currentUser.get('last_name')}</p>
            <p><strong>E-mail:</strong> {this.props.currentUser.get('email')}</p>
            <p>Is this information correct? If not, please <Link to="account-settings">update your account information</Link> </p>
          </div>
          <h3>Personal Information</h3>
          <form className='sm-form' onSubmit={handleSubmit(this.onSubmit)}>
            <div className="field-wrap">
              <label htmlFor='address'>Address: </label>
              <Field
                name="address"
                type="text"
                placeholder="Address"
                component={this.renderField}
                validate={required}
                />
            </div>
            <div className="field-wrap">
              <label htmlFor='country'>Country: </label>
              <Field
                name="country"
                type="text"
                placeholder="Country"
                component={this.renderField}
                validate={required}
              />
            </div>
            <div className="field-wrap">
              <label htmlFor='city'>City: </label>
              <Field
                name="city"
                type="text"
                placeholder="City"
                component={this.renderField}
                validate={required}
               />
            </div>
            <div className="field-wrap">
              <label htmlFor='phone'>Phone: </label>
              <Field
                name="phone"
                type="text"
                placeholder="Phone"
                component={this.renderField}
                validate={required}
              />
            </div>
            <div className="field-wrap">
              <label htmlFor='state'>State: </label>
              <Field
                name="state"
                type="text"
                placeholder="State"
                component={this.renderField}
                validate={required}
              />
            </div>
            <div className="field-wrap">
              <label htmlFor='website'>Website: </label>
              <Field
                name="website"
                type="text"
                placeholder="Include http://"
                component={this.renderField}
                normalize={trimSpaces}
              />
            </div>
            <div className="field-wrap">
              <label htmlFor='zip'>Zip: </label>
              <Field
                name="zip"
                type="text"
                placeholder="Zip"
                component={this.renderField}
                validate={required}
              />
            </div>
            <div className="field-wrap">
              <label htmlFor='years_tattooing'>Years Tattooing: </label>
              <Field
                name="years_tattooing"
                type="text"
                placeholder="Years Tattooing"
                component={this.renderField}
                validate={required}
              />
            </div>
            <h3 className='w-100'>Tattoo Studio Information</h3>
              <div className="field-wrap">
                <label htmlFor='business_name'>Tattoo Studio Name: </label>
                <Field
                  name="business_name"
                  type="text"
                  placeholder="Business Name"
                  component={this.renderField}
                  validate={required}
                />
              </div>
              <div className="field-wrap">
                <label htmlFor='business_address'>Tattoo Studio Address: </label>
                <Field
                  name="business_address"
                  type="text"
                  placeholder="Address"
                  component={this.renderField}
                  validate={required}
                />
              </div>
              <div className="field-wrap">
                <label htmlFor='business_city'>Tattoo Studio City: </label>
                <Field
                  name="business_city"
                  type="text"
                  placeholder="City"
                  component={this.renderField}
                  validate={required}
                />
              </div>
              <div className="field-wrap">
                <label htmlFor='business_state'>Tattoo Studio State: </label>
                <Field
                  name="business_state"
                  type="text"
                  placeholder="State"
                  component={this.renderField}
                  validate={required}
                />
              </div>
              <div className="field-wrap">
                <label htmlFor='business_zip'>Tattoo Studio Zip: </label>
                <Field
                  name="business_zip"
                  type="text"
                  placeholder="Zip"
                  component={this.renderField}
                  validate={required}
                />
              </div>
              <div className="field-wrap">
                <label htmlFor='business_phone'>Tattoo Studio Phone: </label>
                <Field
                  name="business_phone"
                  type="text"
                  placeholder="Phone"
                  component={this.renderField}
                  validate={required}
                />
              </div>
              <div className="field-wrap w-100 mb-0">
                <label htmlFor='business_website'>Tattoo Studio Website: </label>
                <Field
                  name="business_website"
                  type="text"
                  placeholder="Include http://"
                  component={this.renderField}
                  normalize={trimSpaces}
                />
              </div>
              <h3 className='w-100'>Your Social Media Accounts</h3>
              <div className="social-media-acc-wrap w-100">
              <div className="field-wrap">
                <label htmlFor='twitter'>Twitter Profile Username: </label>
                <Field
                  name="twitter"
                  type="text"
                  placeholder="Twitter Profile Name"
                  component={this.renderField}
                  validate={socialHandleValidate}
                  normalize={trimSpaces}
                />
              </div>
              <div className="field-wrap">
                <label htmlFor='instagram'>Instagram Profile Username: </label>
                <Field
                  name="instagram"
                  type="text"
                  placeholder="Instagram Profile Name"
                  component={this.renderField}
                  validate={socialHandleValidate}
                  normalize={trimSpaces}
                />
              </div>
              <div className="field-wrap">
                <label htmlFor='facebook'>Facebook Page Username: </label>
                <Field
                  name="facebook"
                  type="text"
                  placeholder="Facebook Page Name"
                  component={this.renderField}
                  validate={fbNameValidate}
                  normalize={trimSpaces}
                />
              </div>
              </div>
              
              <h3 className='w-100 mt-2'>Certifications</h3>
              <div className="certification-wrap">
                <div className="d-flex">
                  <label id='certification-label'>
                    Do you have a current Blood Borne Pathogens certification?
                  </label>
                  <div className="certification-radios">
                  <span>Yes </span>
                  <Field
                    name="blood_borne"
                    component="input"
                    type="radio"
                    value="Yes"/>
                  <span className="app-radio-span-second">No </span>
                  <Field
                    name="blood_borne"
                    component="input"
                    type="radio"
                    value="No"/>
                  </div>
                </div>
                <div className="d-flex">
                  <label id='certification-label'>
                    Do you have a current CPR certification?
                  </label>
                  <div className="certification-radios">
                  <span>Yes </span>
                  <Field
                    name="cpr"
                    component="input"
                    type="radio"
                    value="Yes"/>
                  <span className="app-radio-span-second">No </span>
                  <Field
                    name="cpr"
                    component="input"
                    type="radio"
                    value="No"/>
                  </div>
                </div>
                <div className="d-flex">
                  <label id='certification-label'>
                    Do you have a business license in accordance with local business
                    regulations?
                  </label>
                  <div className="certification-radios">
                  <span>Yes </span>
                  <Field
                    name="business_license"
                    component="input"
                    type="radio"
                    value="Yes"/>
                  <span className="app-radio-span-second">No </span>
                  <Field
                    name="business_license"
                    component="input"
                    type="radio"
                    value="No"/>
                  </div>
                </div>
                  
                <div className="d-flex">
                  <label id='certification-label'>
                    Do you have a Tattoo Artist insurance policy?
                  </label>
                  <div className="certification-radios">
                  <span>Yes </span>
                  <Field
                    name="insurance_policy"
                    component="input"
                    type="radio"
                    value="Yes"/>
                  <span className="app-radio-span-second">No </span>
                  <Field
                    name="insurance_policy"
                    component="input"
                    type="radio"
                    value="No"/>
                  </div>
                </div>
                 
              </div>
              <div className="license-text">
               <p className="last-warning"> You acknowledge that Tattoo Studios, Shops and Collectives can NOT register as a group and that each Tattoo Artist must register individually. Any Artist accounts found to violate this agreement are subject to immidiate removal without notice.</p>

               <p>In order to apply to register, you must agree to the<br /> <a href="/license-agreement" target="_blank">Skin Motion Tattoo Artist license agreement</a>.</p>
              </div>
              <div className="checkbox-wrap">
                <Field
                  type="checkbox"
                  component="input"
                  name="license"
                  className='required' />
                <p>I Agree</p>
              </div>
              <button className="btn btn-tan btn-large" type="submit">Next</button>
          </form>
        </div>
      </div>
    )
  }
}

const ReduxFormArtistApplication = reduxForm({ form: 'ArtistApplication' })(ArtistApplication)

const mapStateToProps = (store) => ({
  currentUser: store.get('currentUser')
})

const mapDispatchToProps = dispatch => ({
  saveApplication: (userId, params) => dispatch(saveApplication(userId, params)),
  fetchCurrentUser: (userId) => dispatch(fetchCurrentUser(userId))
})

export default connect(mapStateToProps, mapDispatchToProps)(ReduxFormArtistApplication)
