import React from 'react';
import Waveform from './Waveform';

class StyleSoundwave extends React.Component {

  componentDidMount() {
    window.scrollTo(0, 0); 
  }

  render() {
    const { marker } = this.props
    return (
      <Waveform
        nextStep={this.props.nextStep}
        push={this.props.push}
        marker={marker}
        name={marker.get('name')}
        audioSrc={this.props.audioSrc}
        height={marker.get('height')}
        width={marker.get('width')}
        updateMarker={this.props.updateMarker}
        waveformInitiallySaved={marker.get('imageURL')} 
      />
    )
  }
}

export default StyleSoundwave