import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

class ArtistApplicationSplash extends React.Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div className="sm-artist-application-splash">
        <div
          className="title-main title-long"
          id="title-artist-application-apply"
        >
          <h1>Tattoo artist application</h1>
        </div>
        <div className="sm-artist-application-splash-main common-padding">
          <div className="top-side">
            <h4>
              Tattoo artists can register for free to join the Skin Motion
              tattoo artist network and create their profile in our tattoo
              artist directory.
            </h4>
          </div>
          <div className="left-side">
            <h3>Artist Network Membership</h3>
            <h5>Do you want new tattoo clients from your area?</h5>
            <p>
              Skin Motion&#8482; users are all over the world and want to know
              where they can get a Soundwave Tattoo&#8482;. We created a Tattoo
              Artist Network to help our users find the best tattoo artist to do
              their tattoo and to help tattoo artists get more people into their
              studios.
            </p>
            <h5>Professional Tattoo Artists Only</h5>
            <p>
              Skin Motion Tattoo Artist Network is only available for
              professional tattoo artists working at licensed businesses.
              Artists must have Blood Borne Pathogens and CPR certifications and
              be insured.
            </p>
            <p>
              Skin Motion monitors the Tattoo Artist Network for abuses and
              violations of the Skin Motion{" "}
              <Link to="terms-and-conditions">Terms of Use </Link> &nbsp; and
              will deactivate accounts as necessary to protect our users.
            </p>
          </div>
          <div className="right-side">
            <h3>How do I sign up?</h3>
            <h5>How does the registration process work?</h5>
            <ol>
              <li>Fill out the application</li>
              <li>Watch the tutorial video</li>
              <li>
                Take and pass a test about what you learned from the video
              </li>
              <li>Create your Profile</li>
            </ol>
            <h5>What if I fail the test?</h5>
            <p>
              Most professional tattoo artists should be able to pass the test
              the first time. If you do not pass the test on the first try, you
              can submit a support request and customer support will verify your
              application information before allowing you to try again.
            </p>
          </div>
        </div>
        <div className="nav-buttons">
          <a className="btn btn-tan" href="/artist-application">
            {" "}
            Apply Here{" "}
          </a>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (store) => ({
  currentUser: store.get("currentUser"),
});

export default connect(mapStateToProps, null)(ArtistApplicationSplash);
