import React from 'react'

import TattooPreview from '../../activation/TattooPreview'

class RedoOrActivate extends React.Component {
  state = {
    imgSrc: null,
    maskSrc: null,
    overlayData: null,
    readyToTest: null
  }

  componentDidMount () {
    this.loadImage()
    this.loadMask()
    this.loadOverlayData()
  }

  loadImage = () => {
    fetch(this.props.marker.get('tattooImageURL'))
    .then(res => {
      return res.blob()
    })
    .then(blob => {
      const reader = new FileReader()

      reader.addEventListener('load', () => {
        this.setState({ imgSrc: reader.result })
      })

      reader.readAsDataURL(blob)
    })
  }

  loadMask = () => {
    if(this.props.marker.get('overlayURL')) {
      fetch(this.props.marker.get('overlayURL'))
      .then(res => res.blob())
      .then(blob => {
        const reader = new FileReader()

        reader.addEventListener('load', () => {
          this.setState({ maskSrc: reader.result })
        })

        reader.readAsDataURL(blob)
      })
    }
  }

  loadOverlayData = () => {
    if(this.props.marker.get('overlayData')) {
      this.setState({overlayData: this.props.marker.get('overlayData').get(0)})
    }
  }

  render () {
    const {
      imgSrc,
      maskSrc,
      overlayData
    } = this.state

    const {
      marker
    } = this.props

    let loading = false;
    if (
      !imgSrc || 
      (marker.get('is_complete') &&  !(maskSrc && overlayData))
    ) { loading = true; }

    let readyToTest = false;
    let artkGenerating = false;
    let processingError = false;
    if(!marker.get('processing_error')) {
      readyToTest = marker.get('is_complete') && !marker.get('artk_data_generating')
      artkGenerating = marker.get('artk_data_generating')
    } else {
      processingError = true;
    }

    return (
      <div className='sm-redo-or-activate'>
      <h2>Test the Skin Motion app with the photo below to test the playback</h2>
          <div className="tattoo-list-item">
          {artkGenerating && (
            <div className="notification-banner artk-gen">
              AR ToolKit marker data is currently being generated for this marker.
            </div>
          )}
          {readyToTest && (
            <div className="notification-banner success"> 
              The marker is ready to test on your mobile device! 
            </div>)}
          {processingError && (
            <div className="notification-banner error">
              There was a problem processing this marker. Please contact support!
            </div>
          )}
          {loading ? (
            <div className="sm-waveform-loading">
              <p>Loading Tattoo...</p>
              <div className="sm-loader"></div>
            </div>
          ) : (
            <TattooPreview
              imgSrc={imgSrc}
              maskSrc={maskSrc}
              marker={marker}
              overlayData={overlayData}
            />
          )}

          <div className='description-row d-flex flex-row justify-content-between align-items-center'>
            <span className='created-on'>
              {marker.get('name')}
            </span>

            <div className='links'>
              <button 
                type='button' 
                className='btn btn-tan' 
                onClick={this.props.redoProcess}
                disabled={marker.get('artk_data_generating')}> 
                {marker.get('artk_data_generating') ? "Generating ARTK Data!" : "Redo Mask and Overlay"}
              </button>
              <button 
                type='button' 
                className='btn btn-tan' 
                onClick={this.props.activateMarker}
                disabled={marker.get('artk_data_generating')}> 
                Activate 
              </button>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default RedoOrActivate
