import React, { Component } from "react";
import { Link } from "react-router-dom";
// import Advertisement from 'components/layouts/Advertisement';

class Home extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div className="sm-home">
        <div className="sm-home-banner">
          <div className="video-container">
            <video
              muted
              autoPlay
              loop
              poster="https://cdn.skinmotion.com/images/layout/home-video-poster.jpg"
            >
              <source
                src="https://cdn.skinmotion.com/videos/hero-banner-videos/home-page.mp4"
                type="video/mp4"
              ></source>
              <source
                src="https://cdn.skinmotion.com/videos/hero-banner-videos/home-page.webm"
                type="video/webm"
              ></source>
            </video>
          </div>
          <div className="title-home">
            <div className="sm-home-banner-caption">
              <h3>
                {/* Share the sounds &amp; stories you love with */}
                Listen to your favorite sounds or the audio messages you love
                with
              </h3>
              <h1>
                <Link
                  className="soundwave-logo"
                  to="/soundwave-tattoos"
                  title="Soundwave Tattoos by Skin Motion"
                >
                  <span>Soundwave Tattoos</span>
                </Link>
              </h1>
              <h4>
                Get up to 30 seconds of audio tattooed in a unique soundwave
                design to be played back using your smart phone and the Skin
                Motion app.
              </h4>
              <Link
                to="/soundwave-tattoos"
                title="Soundwave Tattoos - tattoos you can hear"
                className="btn btn-blue start-button"
              >
                Start here
              </Link>
            </div>
          </div>
        </div>
        <div className="sm-home-mobile-intro">
          <div className="sm-home-banner-caption">
            <h3>
              Listen to your favorite sounds or the audio messages you love with
            </h3>
            <h1>
              <Link
                className="soundwave-logo"
                to="/soundwave-tattoos"
                title="Soundwave Tattoos by Skin Motion"
              >
                <span>Soundwave Tattoos</span>
              </Link>
            </h1>
            <h4>
              Get up to 30 seconds of audio tattooed in a unique soundwave
              design to be played back using your smart phone and the Skin
              Motion app.
            </h4>
            <Link
              to="/soundwave-tattoos"
              title="Soundwave Tattoos - tattoos you can hear"
              className="btn btn-blue start-button"
            >
              Start here
            </Link>
          </div>
        </div>
        <div className="sm-home-mobile-download">
          <div className="download-apple">
            <a href="https://itunes.apple.com/us/app/skin-motion/id1255007214?mt=8">
              <span>Download the Skin Motion on the App Store</span>
            </a>
          </div>
          <div className="download-google">
            <a href="https://play.google.com/store/apps/details?id=com.skinmotion.skinmotion">
              <span>Download the Skin Motion app on Google Play</span>
            </a>
          </div>
        </div>
        <div className="sm-press-logos">
          <h4>As seen on</h4>
          <a href="/press" className="btn btn-tan">
            In the press
          </a>
        </div>
        <div className="sm-home-section-wrappper">
          <div className="container">
            <div className="sm-home-section-one">
              <div className="sm-home-part-1">
                <div className="sm-home-feature1">
                  <Link
                    to="/soundwave-tattoos"
                    title="Soundwave Tattoos by Skin Motion"
                  >
                    &nbsp;
                  </Link>
                </div>
                <h4>
                  <Link
                    to="/soundwave-tattoos"
                    title="Soundwave Tattoos by Skin Motion"
                  >
                    Tattoos You Can Hear
                  </Link>
                </h4>
                <p>
                  <Link
                    to="/soundwave-tattoos"
                    title="Soundwave Tattoos by Skin Motion"
                  >
                    Upload your audio, customize your soundwave, get tattooed
                    and play it back.  Already have a special tattoo and want to
                    add sound?  We can do that too. Start creating yours here
                    &nbsp;
                    <span>Click Here</span>.
                  </Link>
                </p>
              </div>
              <div className="sm-home-part-2">
                <div className="sm-home-feature2">
                  <Link
                    to="/artist-directory"
                    title="Skin Motion Tattoo Artist Directory"
                  >
                    &nbsp;
                  </Link>
                </div>
                <h4>
                  <Link
                    to="/artist-directory"
                    title="Skin Motion Tattoo Artist Directory"
                  >
                    Tattoo Artist Directory
                  </Link>
                </h4>
                <p>
                  <Link
                    to="/artist-directory"
                    title="Skin Motion Tattoo Artist Directory"
                  >
                    We’ve created a network of authorized tattoo artists all
                    around the globe who collaborate with us to guarantee your
                    tattoo will play.  Find your authorized artist here &nbsp;
                    <span>Click Here</span>.
                  </Link>
                </p>
                <p>
                  <Link
                    to="/artist-directory"
                    title="Skin Motion Tattoo Artist Directory"
                  >
                    Are you a talented tattoo artist interested in joining our
                    Tattoo Artist Directory? &nbsp;
                    <span>Click Here</span>.
                  </Link>
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="sm-home-section-two">
          <div className="sm-home-video">
            {/* <iframe
                            src="https://www.youtube.com/embed/c4MhVd2KYzs?rel=0&amp;showinfo=0"
                            frameBorder="0"
                            allowFullScreen
                        ></iframe> */}
            <img
              className=""
              src="https://storage.googleapis.com/skinmotion-assets/images/general/skin-motion-inspiration-img.png"
              alt="home section image"
            ></img>
          </div>
          <div className="sm-home-info">
            <h3>Find Your Inspiration.</h3>
            <p>
              Once you have uploaded your audio and received your soundwave,
              it’s time to let your creativity thrive. Customize your soundwave
              design, work with your tattoo artist and check out our Instagram
              page for inspiration <span>@skinmotionapp. </span>
            </p>
            <Link to="/soundwave-tattoos" className="btn btn-tan">
              {/* <Link to="/soundwave-tattoos" className="btn btn-blue"> */}
              {/* <img
                                className="btn-star"
                                src="https://cdn.skinmotion.com/images/layout/star.png"
                            ></img> */}
              Learn More
            </Link>
          </div>
        </div>
      </div>
    );
  }
}

export default Home;
