import React from "react";
import { connect } from "react-redux";
import { getLastPayment } from "../../../models/user";
import { formatDate, formatAmount } from "../../../models/payments";
import { fetchCurrentUser } from "../../../actions/currentUser";

class PurchaseConfirmation extends React.Component {
  componentDidMount() {
    window.scrollTo(0, 0);
    this.props.fetchCurrentUser(this.props.currentUser.get("id"));
  }

  styleSoundwaveStep = () => {
    const { marker } = this.props;
    this.props.push(`/soundwaves/${marker.get("id")}/2`);
  };

  render() {
    const { marker, currentUser } = this.props;
    let lastPayment = getLastPayment(currentUser);
    if (!lastPayment) {
      return null;
    }
    return (
      <div className="sm-waveform-tool common-padding purchase-wrapper">
        <h2>Purchase Confirmation</h2>
        <p className="sm-intro-text">
          Thank you for purchasing your Soundwave Tattoo activation. This is
          your receipt, please save this information for your records.
        </p>
        <div className="sm-payment-info">
          <h3>Payment Receipt</h3>
          <p>
            {" "}
            <strong>Description:</strong>{" "}
            <span>{lastPayment.get("description")}</span>{" "}
          </p>
          <p>
            {" "}
            <strong>Amount:</strong>{" "}
            <span>{formatAmount(lastPayment.get("amount"))}</span>{" "}
          </p>
          <p>
            {" "}
            <strong>Payment Method:</strong>{" "}
            <span>{lastPayment.get("payment_method")}</span>{" "}
          </p>
          <p>
            {" "}
            <strong>Payment Date:</strong>{" "}
            <span>{formatDate(lastPayment.get("purchase_date"))}</span>{" "}
          </p>
        </div>
        <div className="sm-payment-conditions">
          <h3>Subscription Information</h3>
          <p>
            Your Soundwave Tattoo activation includes a one year subscription
            for playback of your Soundwave Tattoo.
          </p>
          {lastPayment.get("payment_method").includes("Voucher") ? (
            <p className="mb-0">
              You will be required to renew your yearly subscription after one
              year to continue playback with the Skin Motion app.
            </p>
          ) : (
            // <p className='mb-0'>Your subscription will renew after the first year for $14.99 each year after.</p>
            <p className="mb-0">
              You will be required to renew your yearly subscription after one
              year to continue playback with the Skin Motion app.
            </p>
          )}
        </div>
        <div className="sm-activation-guarantee">
          <p>
            Receive a 100% satisfaction guarantee on the cost of your Soundwave
            Tattoo activation by entering your Skin Motion licensed tattoo
            artist's secret key while activating your Soundwave Tattoo.
          </p>
          <p>
            Find a Skin Motion licensed tattoo artist near you in our{" "}
            <a href="/artist-directory" className="bold-fonts">
              Tattoo Artist Directory
            </a>
            .
          </p>
        </div>
        <div className="sm-next-steps">
          <h2>Next Steps</h2>
          <p className="sm-intro-text">
            <a
              href={marker.get("pdfURL")}
              target="_blank"
              className="download-pdf-link"
            >
              Download your Tattoo Stencil
            </a>
          </p>
          <p className="sm-intro-text">
            If you have <strong>not</strong> been tattooed yet, and would like
            to trim or change your Soundwave you can&nbsp;
            <a className="back-to-styling" onClick={this.styleSoundwaveStep}>
              edit your Soundwave Stencil
            </a>
            .
          </p>
        </div>
        <div className="sm-continue">
          <p className="mb-0">
            Continue your Soundwave Tattoo activation by uploading a photo of
            your tattoo.
          </p>
          <div className="btn btn-tan btn-large" onClick={this.props.nextStep}>
            Continue
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (store) => ({
  currentUser: store.get("currentUser"),
});

const mapDispatchToProps = (dispatch) => ({
  fetchCurrentUser: (userId) => dispatch(fetchCurrentUser(userId)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PurchaseConfirmation);
