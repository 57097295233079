import React, { Component } from 'react';
import { Link } from 'react-router-dom';
const kREFERRAL_TARGET = 25;

class InfluencerStats extends Component {

  constructor (props) {
    super(props)

    let progress = null

    if (this.props.currentUser.get('referral_count') >= kREFERRAL_TARGET) {
      progress = this.props.currentUser.get('referral_count') % kREFERRAL_TARGET;
    } else {
      progress = this.props.currentUser.get('referral_count')
    }

    this.state = {
      progress,
    }
  }

  render() {
    return (
      <div className="sm-influencer-stats">
        <div className="sm-influencer-referral-chart">
          <h3>Your Referrals</h3>
          <h6>{this.state.progress} Referrals of {kREFERRAL_TARGET}</h6>
          <div className='progress-bar'>
            <div className={`progress-bar-results progress-bar-result-${this.state.progress}`}></div>
          </div>
        </div>
        <div className="sm-influencer-top-influencers">
          <h4>Top Referrers:</h4>
          {this.props.topReferrers.map((count, index) => (
            <div key={index} className="sm-influencer-rank"><b>#{index+1}:</b> {count} referrals</div>
          ))}
        </div>
      </div>
    )
  }
}

export default InfluencerStats
