import React from "react";
import serialize from "form-serialize";
import { injectStripe } from "react-stripe-elements";
import AddressSection from "../checkout/AddressSection";
import CardSection from "../checkout/CardSection";
import NameOnCard from "../checkout/NameOnCard";
import API from "../../utils/API";

class CheckoutForm extends React.Component {
  state = {
    submitDisabled: false,
    processingPayment: false,
    paymentError: null,
  };

  submitButtonText() {
    if (this.state.processingPayment) {
      return "Processing...";
    } else {
      return this.props.submitButtonText
        ? this.props.submitButtonText
        : "Checkout";
    }
  }

  successMessage() {
    return this.props.successMessage
      ? this.props.successMessage
      : "Payment Complete!";
  }

  submitPayment = (e) => {
    e.preventDefault();

    this.setState({
      paymentError: null,
      submitDisabled: true,
      processingPayment: true,
    });

    let formData = serialize(e.target, { hash: true });

    this.props.stripe.createToken(formData).then(({ token }) => {
      if (!token) {
        this.setState({
          paymentError: "Card Error: Incorrect Number",
          processingPayment: false,
          submitDisabled: false,
        });
        return;
      }
      let payload = {
        id: token.token,
        amount: parseInt(this.props.amount),
        qty: 1,
        voucher_id: parseInt(this.props.voucherId),
      };
      Object.assign(payload, token);
      // console.log({ paymentURL: this.props.paymentURL, payload });

      const promise = API.post(this.props.paymentURL, payload)
        .then((data) => {
          this.setState({
            processingPayment: false,
            submitDisabled: false,
          });
          if (data.error) {
            this.setState({ paymentError: data.error });
          } else {
            this.setState({ paymentComplete: true });
            this.props.fetchCurrentUser(this.props.currentUser.get("id"));
          }
          return Promise.resolve();
        })
        .catch((err) => {
          let message =
            err.message != "Internal Server Error"
              ? err.message
              : "Server error, please contact support@skinmotion.com";
          this.setState({
            paymentError: message,
            processingPayment: false,
            submitDisabled: false,
          });
          return Promise.reject();
        });

      this.props.handleResponse(promise);
    });
  };

  render() {
    return (
      <form className="sm-form" onSubmit={this.submitPayment}>
        {this.props.nameOnly ? (
          <NameOnCard currentUser={this.props.currentUser} />
        ) : (
          <AddressSection currentUser={this.props.currentUser} />
        )}
        {/* <CardSection /> */}
        {this.props.children}
        {this.state.paymentError && (
          <div className="sm-error-message">{this.state.paymentError}</div>
        )}
        {this.state.paymentComplete && (
          <div className="sm-success-message">{this.successMessage()}</div>
        )}
        <button
          className="btn btn-tan btn-large"
          type="submit"
          disabled={this.state.submitDisabled}
        >
          {this.submitButtonText()}
        </button>
      </form>
    );
  }
}

export default injectStripe(CheckoutForm);
