import API from '../utils/API';
import Parser from 'xml2js-parser';

export const RECEIVE_BLOG_FEED = 'BLOG_FEED/RECEIVE_BLOG_FEED'

export const receiveBlogFeed = blogFeed => ({
  type: RECEIVE_BLOG_FEED,
  blogFeed,
})

export const fetchBlogFeed = () =>
  dispatch => {
    return API.getXML('/rss-feed')
    .then(data => {
      const parser = new Parser({trim: true});
      parser.parseString(data, (err, result) => {
        dispatch(receiveBlogFeed(result.rss.channel[0].item))      
        return Promise.resolve();  
      })
    })
  }

