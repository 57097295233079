import React from 'react'

class UploadPhotos extends React.Component {
  state = {
    imgSrc: null,
    maskSrc: null,
  }

  imageUploaded = (e) => {
    let file = e.target.files[0]
    // Check that file exists
    if (!file) { return }

    let reader = new FileReader()

    reader.addEventListener('load', () => {
      this.setState({ imgSrc: reader.result })
    })

    reader.readAsDataURL(file)
  }

  maskUploaded = (e) => {
    let file = e.target.files[0];

    // Check that file exists
    if (!file) { return }

    let reader = new FileReader()

    reader.addEventListener('load', () => {
      this.setState({ maskSrc: reader.result })
    })

    reader.readAsDataURL(file)
  }

  completeStep = () => {
    this.props.uploadedImageAndMask(this.state.imgSrc, this.state.maskSrc)
  }

  render () {
    const { imgSrc, maskSrc } = this.state
    return (
      <div className='sm-upload-soundwave'>
      <h2>Update photo and overlay</h2>
        <form>
            <div>
            <label htmlFor="image_file">Cropped Tattoo Image .jpg only</label>
            <input type="file" name="image_file" onChange={this.imageUploaded} />
            { this.state.imgSrc && <img src={this.state.imgSrc} className='tattoo-img' /> }
          </div>
          <div>
            <label htmlFor="mask_file">Mask Image transparent .png only</label>
            <input type="file" name="mask_file" onChange={this.maskUploaded} />
            { this.state.maskSrc && <img src={this.state.maskSrc} className='mask-img' /> }
          </div>
          {this.state.error && <div className='alert'>{this.state.error}</div>}
          <button type='button' className='btn btn-tan' onClick={this.completeStep}> Configure Overlay </button>
        </form>
      </div>
    )
  }
}

export default UploadPhotos
