import Immutable from "immutable";
import {
  RECEIVE_ALL_ARTISTS,
  RECEIVE_ACTIVE_ARTISTS,
  RECEIVE_ARTIST,
  REMOVE_ARTIST,
  RECEIVE_APPLICATION,
} from "../actions/artists";

const artists = (state = Immutable.Map(), action) => {
  switch (action.type) {
    case RECEIVE_ARTIST:
      const artist = state.set(
        action.artist.id,
        Immutable.fromJS(action.artist)
      );
      return artist;
    case RECEIVE_ACTIVE_ARTISTS:
      let tempState = state;
      action.artists.forEach((artist) => {
        if (artist.is_active) {
          tempState = tempState.set(artist.id, Immutable.fromJS(artist));
        }
      });
      return tempState;
    case RECEIVE_ALL_ARTISTS:
      let allArtists = state;
      action.artists.forEach(
        (artist) =>
          (allArtists = allArtists.set(artist.id, Immutable.fromJS(artist)))
      );
      return state.set("all", allArtists);
    case REMOVE_ARTIST:
      return state.delete(action.artistID);
    default:
      return state;
  }
};
export default artists;
