import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class ReferralTerms extends Component {

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div className='sm-referral-terms'>
        <div className='title-main' id='title-referral-terms'>
          <h1>Referral Terms and Conditions</h1>
        </div>
        <div className='sm-referral-terms-main'>
          <h4>Who is a Referrer?</h4>
          <p>These Referrer Terms and Conditions (“Terms”) apply to anyone
            with a valid Skin Motion user account who applies through such
            account page by agreeing to these Terms to become someone who
            sends an email or other communication to encourage others to
            download the Skin Motion App and create an account (“Referrer”).
          </p>
          <h4>What Does an Referrer Do and What Can an Referrer Earn?</h4>
          <p>An Referrer can earn a free Skin Motion personalized augmented
            reality tattoo experience for every twenty-five (25) people who the
            Referrer refers who download the Skin Motion App and create an
            account. The Referrer earns a Skin Motion personalized augmented
            reality tattoo activation only. This does not include the cost of
            the tattoo from a tattoo artist in the Skin Motion tattoo artist
            network which is arranged by the Referrer and is at a separate fee.
            Referrals are tracked by Skin Motion and must be verified by Skin
            Motion as determined by Skin Motion in its sole discretion.
          </p>
          <h4>Obligations of an Referrer</h4>
          <p>In addition to any obligations otherwise communicated to Referrers,
            all Referrers must have downloaded the Skin Motion App and created
            an account.  By doing so, all Referrers agree to Skin Motion’s <Link to='terms-and-conditions'>Terms of Use</Link> and <Link to='privacy-policy'>Privacy Policy</Link>. Referrers
            are obligated to ensure that all communications they send are in
            compliance with all applicable laws, including, but not limited to,
            any Federal Trade Commission disclosure requirements. As an Referrer,
            you must all ensure you maintain the confidentiality of all
            non-public information gained through your access to and use of the
            Referrer tools.
          </p>
          <h4>Restrictions on What an Referrer Can Do</h4>
          <p>In addition to any restrictions otherwise communicated to
            Referrers, Referrers may not: (i) indicate that they work for Skin
            Motion or any affiliated person or entity; (ii) indicate that they
            are a partner of Skin Motion or any affiliated person or entity or
            have any other type of legal or other relationship; (iii) indicate
            that they are endorsed by Skin Motion or any affiliated person or
            entity; and/or (iv) indicate that they are sponsored by Skin Motion
            or any affiliated person or entity. Any Referrer that Skin Motion
            in its sole discretion determines is in any way cheating, generating
            fake accounts, or otherwise acting in contravention of these Terms,
            Skin Motion’s Marketing Tips and Best Practices, and/or the Skin
            Motion Referrer tools or in a way other than Skin Motion intended
            he Referrer tools to be used will have their Referrer tools
            automatically and permanently disabled and any prior awards will be
            void. Skin Motion will regularly review Referrer data to look for
            fake and/or fraudulent accounts. Any Referrer who is determined by
            Skin Motion in its sole discretion to be sending harassing,
            inappropriate, or illegal communications; or to otherwise be
            engaging in any activity that misrepresents its affiliation with
            Skin Motion; misrepresents Skin Motion, any affiliated person or
            entity, and/or Skin Motion’s products and services; does not adhere
            to these Terms, Skin Motion’s Marketing Tips and Best Practices, and
            all other requirements of Skin Motion however communicated; or that
            is engaging in activities that are undesirable in any aspect in Skin
            Motion’s sole discretion, shall have their account automatically and
            permanently disabled and any prior awards will be void.
          </p>
          <h4>General Restrictions</h4>
          <p>Reselling and promotional uses prohibited. Limited time offer.
            These Terms are subject to change at any time without notification.
            Referrer program ends in Skin Motion’s sole discretion.
          </p>
        </div>
      </div>
    )
  }

}

export default ReferralTerms
