import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class InfluencerSignup extends Component {

  state = {
    error: null,
    agreed: null
  }

  agreed = null;

  saveSelection = (e) => {
    this.setState({
      agreed: e.target.checked,
    })
  }

  signup = (e) => {
    if(this.state.agreed && this.props.currentUser.get('is_verified')) {
      this.props.signupInfluencer();
    } else if(!this.state.agreed) {
      //console.log("Set state to highlight checkbox");
    } else if(!this.props.currentUser.get('is_verified')) {
      this.setState({error: 'Please verify your email address'});
    }
  }

  render() {
    return (
      <div className="sm-influencer-signup">
        <h1>Referral Tools</h1>

        <p>The referral program is no longer active. Thank you for participating.</p>
        
        {/* The referral tools are depricated as of March 6, 2019
        <p>Earn free Soundwave Tattoos activation vouchers by referring 25 people who install the Skin Motion app and create an account. </p>
        <p>To sign up you must read and agree to the <Link to="referrals-terms-and-conditions">Referrals Terms of Service.</Link> </p>
        {this.state.error && (
          <div className="sm-influencer-signup-error">{this.state.error}</div>
        )}
        <input type="checkbox" onChange={this.saveSelection} id="agree" name="agree" />
        <label htmlFor="agree"> I agree. </label>
        <button className='btn btn-blue' type="submit" onClick={this.signup}>Sign Up</button>
       */}
      </div>
    )
  }
}

export default InfluencerSignup
