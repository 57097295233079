import Immutable from 'immutable'
import {
  RECEIVE_BLOG_FEED,
} from '../actions/blogFeed'

const blogFeed = (state = Immutable.List(), action) => {
  switch (action.type) {
    case RECEIVE_BLOG_FEED:
      let tempState = state;
      let pages = chunk(action.blogFeed, 6);
      pages.forEach((itemList, pageNum) => {
        let immutablePage = Immutable.List();
        itemList.forEach((item, index) => {
          item.description = parseText(item.description);
          [item.pubMonth, item.pubDay] = parseDate(item.pubDate[0]);
          item.link = item.link[0];
          item.image = item.image[0];
          immutablePage = immutablePage.set(index, Immutable.fromJS(item))
        })
        tempState = tempState.set(pageNum, immutablePage)
      })
      return tempState;
    default:
      return state
  }
}

const parseText = (text) => {
  let parser = new DOMParser;
  let dom = parser.parseFromString(
    `<!doctype html><body>${text}`,
    'text/html'
  );
  return dom.body.textContent;
}

const parseDate = (dateString) => {
  let dateObj = new Date(dateString);
  return [dateObj.toLocaleString('en-us', {month: "short"}), dateObj.getDay()]
}

const chunk = (arr, len) => {
  let chunks = [],
      i = 0,
      n = arr.length;
  while (i < n) {
    chunks.push(arr.slice(i, i += len));
  }
  return chunks;
}

export default blogFeed
