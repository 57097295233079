import React from 'react';
import { Link } from 'react-router-dom';
import { isActive } from '../../../models/marker';
import { formatDate } from '../../../models/payments';
import StripeWrapper from '../../checkout/StripeWrapper';

class TattooBillingUpdate extends React.Component {

  render() {
    const { marker, defaultSource } = this.props
    return (
      <div className="sm-tattoo-billing-update">
        <h3>Manage Subscription</h3>

        <hr />
        
        <table className="sm-tattoo-billing-table">
          <tr className="sm-tattoo-billing-table-header">
            <td> <b>Tattoo:</b> </td>
            <td> {marker.get('name')} </td>
          </tr>
          <tr className="sm-tattoo-billing-table-body">
            <td> <b>Current Payment Method:</b> </td>
            <td> {`${defaultSource.get('brand')}: ${defaultSource.get('last4')}, Exp: ${defaultSource.get('exp_month')}/${defaultSource.get('exp_year')}`} </td>
          </tr>
          <tr className="sm-tattoo-billing-table-body">
            <td> <b>Subscription Status:</b> </td>
            <td> {isActive(marker) ? (
              <p>Active - Expires { formatDate(marker.get('subscription_expires')) }</p>
            ) : (
              <p>Expired </p>
            )} </td>
          </tr>
        </table>
        
        <hr />

        {isActive(marker) ? (
          <div>
            <h3> Update Payment Method </h3>
            <p> Update your credit card to keep your subscription active. </p>
          </div>
        ) : (
          <div>
            <h2> Reactivate Tattoo </h2>
            <p> Reactivate your tattoo subscription for $14.99.</p>
            <p> Your subscription will renew annually from the date of subscription. </p>
          </div>
        )}
        <StripeWrapper
          stripeKey={this.props.stripeKey}
          nameOnly={true}
          submitButtonText={'Update'}
          successMessage={'Card Updated!'}
          containerClass='sm-tattoo-billing-update-form'
          currentUser={this.props.currentUser}
          paymentURL={`/tattoo_payment/update/${marker.get('id')}`}
          handleResponse={this.checkoutComplete} >
        </StripeWrapper>
        <p className="sm-tattoo-billing-cancel-link"><Link to={`/my-tattoos/billing/${marker.get('id')}/cancel`}>Cancel Subscription</Link></p>
      </div>
    )
  }
}

export default TattooBillingUpdate