import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class Download extends Component {
    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        return (
            <div className="sm-download">
                <div className="sm-download-main" id="title-download">
                    <h1>Download the Skin Motion app</h1>
                    <p>
                        Create your Soundwave Tattoo&trade; on this website and
                        play back your own Soundwave Tattoo using the Skin
                        Motion app.
                    </p>

                    <div>
                        <a
                            href="https://itunes.apple.com/us/app/skin-motion/id1255007214?mt=8"
                            className="btn btn-blue btn-hero btn-large"
                            target="_blank"
                        >
                            Download for iOS
                        </a>
                        <a
                            href="https://play.google.com/store/apps/details?id=com.skinmotion.skinmotion"
                            className="btn btn-blue btn-hero btn-large"
                            target="_blank"
                        >
                            Download for Android
                        </a>
                    </div>
                    <div className="faq-link">
                        <Link to="/faq">Frequently Asked Questions</Link>
                    </div>
                </div>
            </div>
        );
    }
}

export default Download;
