import React, { Component } from 'react';

const dateFormat = require('dateformat');
const APIkey = 'AIzaSyCf3TYj3XEHeBz0SsVBYkIjmygEUuSQpJs';
const channelID = ' ';
const result = 50;

var url = `https://www.googleapis.com/youtube/v3/search?key=${APIkey}&channelId=${channelID}&part=snippet,id&order=date&maxResults=${result}`;

class Videos extends Component {

  constructor() {
    super();

    this.state={
      youtubeData:[],
      selectedVideo: 'https://www.youtube.com/embed/ubVaqWiwGVc',
    }
  }

  componentDidMount() {
    fetch(url)
    .then((response) => response.json())
    .then((res) => {
      const youtubeData = res.items
      this.setState({youtubeData});
    })
    .catch((error) => {
      console.error(error);
    });
    window.scrollTo(0, 0);
  }

  getDate = (string) => {
    let date = new Date(string)
    var dateAdded = dateFormat(date, "mmm dS, yyyy");
    return dateAdded;
  }

  render() {
    return (
      <div className="sm-videos">
        <div className="title-main title-short" id="title-videos"><h1>VIDEOS</h1></div>
        <div className="sm-videos-main" id="video">
          <iframe src={this.state.selectedVideo}></iframe>
        </div>
        <div className="sm-videos-secondary d-flex justify-content-evenly flex-wrap">
          {
            this.state.youtubeData.map((item, index) => {
              if(item.id.kind == "youtube#video") {
                var video = <div key={index} className="video">
                  <img src={item.snippet.thumbnails.medium.url} onClick={() => {this.setState({selectedVideo: 'https://www.youtube.com/embed/'+item.id.videoId }); window.scrollTo(0,"video")}}>
                  </img>
                  <div className="sm-videos-details">
                    <h3>{item.snippet.title}</h3>
                    <h4>{this.getDate(item.snippet.publishedAt)}</h4>
                  </div>
                </div>
                return video
              }
            })
          }
        </div>
      </div>
    )
  }
}

export default Videos
