import API from "../utils/API";

export const RECEIVE_MARKER = "MARKERS/RECEIVE_MARKER";
export const RECEIVE_MARKERS = "MARKERS/RECEIVE_MARKERS";
export const REMOVE_MARKER = "MARKERS/REMOVE_MARKER";

export const receiveMarker = (marker) => ({
  type: RECEIVE_MARKER,
  marker,
});

export const receiveMarkers = (markers) => ({
  type: RECEIVE_MARKERS,
  markers,
});

export const removeMarker = (markerId) => ({
  type: REMOVE_MARKER,
  markerId,
});

export const createMarker = () => (dispatch) => {
  return API.post("/markers")
    .then((marker) => {
      dispatch(receiveMarker(marker));
      return Promise.resolve(marker.id);
    })
    .catch((err) => {
      return err;
    });
};

export const setArtistOnMarker =
  (markerId, artistId, secretKey) => (dispatch) => {
    return API.patch(`/markers/${markerId}`, {
      marker: { artist_id: artistId, secret_key: secretKey },
    })
      .then((marker) => {
        dispatch(receiveMarker(marker));
        return Promise.resolve();
      })
      .catch(() => {
        return Promise.reject();
      });
  };

export const updateMarker = (markerId, params) => (dispatch) => {
  return API.patch(`/markers/${markerId}`, params).then((marker) => {
    dispatch(receiveMarker(marker));
    return Promise.resolve(marker);
  });
};

export const fetchMarker = (markerId) => (dispatch) => {
  return API.get(`/markers/${markerId}`).then((marker) => {
    dispatch(receiveMarker(marker));
    return Promise.resolve();
  });
};

// export const fetchMarkers = (markerId) => (dispatch) => {
//   return API.get("/markers")
//     .then((marker) => {
//       dispatch(receiveMarker(marker));
//       return Promise.resolve();
//     })
//     .catch((error) => {
//       return Promise.reject(error);
//     });
// };
export const fetchMarkers = (markerId) => (dispatch) => {
  return API.get("/markers")
    .then((marker) => {
      // console.log("Markers received:", marker);
      dispatch(receiveMarkers(marker));
      return Promise.resolve();
    })
    .catch((error) => {
      console.log("Error fetching markers:", error);
      return Promise.reject(error);
    });
};

export const fetchMarkersForReview = () => (dispatch) =>
  API.get("/markers?review=true").then((markers) =>
    dispatch(receiveMarkers(markers))
  );

export const deleteMarker = (markerId) => (dispatch) =>
  API.delete(`/markers/${markerId}`).then((deletedId) =>
    dispatch(removeMarker(deletedId))
  );

export const uploadPhoto =
  (markerId, imgSrc, reset = false) =>
  (dispatch) => {
    let params = {
      marker: {
        tattoo_image: {
          src: imgSrc,
          reset: reset,
        },
      },
    };
    return API.patch(`/markers/${markerId}`, params).then((marker) => {
      dispatch(receiveMarker(marker));
      return Promise.resolve();
    });
  };

export const rejectMarker = (markerId) => (dispatch) =>
  API.patch(`/markers/${markerId}`, {
    marker: { reject_marker_image: true },
  }).then((marker) => dispatch(receiveMarker(marker)));

export const claimMarker = (markerId) => (dispatch) =>
  API.patch(`/markers/${markerId}`, {
    marker: { claim_for_activation: true },
  }).then((marker) => dispatch(receiveMarker(marker)));

export const unclaimMarker = (markerId) => (dispatch) =>
  API.patch(`/markers/${markerId}`, {
    marker: { claim_for_activation: false },
  }).then((marker) => dispatch(receiveMarker(marker)));

export const completeMarker = (markerId, params) => (dispatch) => {
  let payload = {
    marker: {
      complete_marker: params,
    },
  };
  return API.patch(`/markers/${markerId}`, payload).then((marker) => {
    dispatch(receiveMarker(marker));
    return Promise.resolve();
  });
};

export const activateMarker = (markerId) => (dispatch) => {
  let params = {
    marker: {
      activate_marker: true,
    },
  };
  return API.patch(`/markers/${markerId}`, params).then((marker) => {
    dispatch(receiveMarker(marker));
    return Promise.resolve();
  });
};

export const reactivateMarker = (markerId) => (dispatch) => {
  return API.patch(`/markers/${markerId}`, {
    marker: { reactivate_marker: true },
  }).then((marker) => {
    dispatch(receiveMarker(marker));
    return Promise.resolve();
  });
};
