
/**
 * returns 'empty' error if there is no value
 */

export const required = value => {
  if (!value) { return 'empty' }
}

export const fbNameValidate = value => {
  if(value) {
    if(value.includes('http') || value.includes('www') || value.includes('.com')) {
      return 'Please enter only your Facebook Profile Name'
    }
  }
}

export const socialHandleValidate = value => {
  if(value) {
    if(value.includes('@')) {
      return 'Please enter without the @ symbol.'
    } else if(value.includes('http') || value.includes('www') || value.includes('.com')) {
      return 'Please enter your handle only (no URL)'
    }
  }
}

/**
 * Validates that the password confirm matches the password field
 */
export const passwordMatchValidate = values => {
  const errors = {}
  if (values.get('password') !== values.get('confirm_password')) {
    errors._error = "Password's don't match!"
  }

  return errors
}
