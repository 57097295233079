import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class TermsAndConditions extends Component {

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (<div className="sm-terms">
      <div className="title-main title-long" id='title-terms-and-conditions'>
        <h1>Terms and Conditions of Use</h1>
      </div>
      <div className="sm-terms-main common-padding">

        <p>Last Modified: November 30, 2017</p>

        <p>PLEASE READ THIS SKIN MOTION SERVICES (AS DEFINED HEREIN) TERMS AND 
        CONDITIONS OF USE AGREEMENT CAREFULLY BEFORE CREATING AND REGISTERING 
        YOUR ACCOUNT. By downloading, accessing or using the Skin Motion website 
        at https://www.skinmotion.com (or any successor URL) (“Site”) or mobile 
        app (the “App”) owned by Skin Motion (“Skin Motion”) (and collectively 
          the “Services”) you signify that you have read, understand and agree 
        to be bound by all of the terms and conditions as set forth in these 
        Skin Motion Mobile Services Terms and Conditions of Use Agreement (“the Terms”).  
        Access to and use of the Services is conditioned upon acceptance of and 
        compliance with these Terms. If you do not agree with any part of these 
        Terms, then you do not have permission to access the Services. By using 
        the Services, you represent and warrant that you are of legal age to form 
        a binding contract.</p>

        <h4>THE SERVICES AND THEIR FUNCTIONS</h4>
        <p>The Services consist of a Site and an App that allow consumers to create, 
        upload, and purchase for an initial fee and an annual subscription fee (the 
          amounts of which may change in Skin Motion’s sole discretion), personalized 
        augmented reality tattoo experiences and search for tattoo artists to implement 
        such personalized augmented reality tattoo experience through Skin Motion’s 
        proprietary technology that brings tattoos to life.  The Services also allow 
        tattoo artists to register and be vetted as potential Skin Motion licensees 
        to deliver high quality tattoo experiences to consumers using Skin Motion’s 
        proprietary technology.  If you are purchasing an augmented reality experience, 
        you will have access through the Services to purchase that augmented reality 
        tattoo experience, but will still need to separately search for a Skin Motion 
        licensed tattoo artist through the Services to obtain an actual tattoo based 
        on such experience purchased.  <strong>The implementation of the personalized augmented 
        reality tattoo shall be at an additional cost (in addition to the initial and 
          annual subscription fees).</strong></p>

        <h4>END USER LICENSE</h4>
        <p>Skin Motion grants you a personal, non-exclusive, non-transferable, 
        non-sublicensable, limited and revocable license to download and use the 
        App and use the Site for personal use only in accordance with these Terms 
        (“User License”). Skin Motion does not grant you any other rights whatsoever 
        in relation to the Services or the material contained therein. All other rights 
        are expressly reserved by Skin Motion. Any use of the Services in any other 
        manner, including, without limitation, resale, reverse-engineering, disassembling, 
        redistribution, transfer, modification, or distribution of the Services or 
        Skin Motion Content (as defined in the Content Section herein) is strictly 
        prohibited. You may not rent, lease, lend, sell, transfer, redistribute or 
        sublicense the Services. You may not copy (except as expressly permitted by 
        this Agreement), decompile, reverse-engineer, disassemble, attempt to derive 
        the source code of, modify, or create derivative works of the Services, any 
        updates, or any part thereof (except as and only to the extent that any foregoing 
        restriction is prohibited by applicable law). If you breach any of these 
        restrictions, you may be subject to prosecution and damages. </p>

        <h4>ACCOUNTS AND REGISTRATION</h4>
        <p>When you create an account on our Services and/or submit a registration 
        application, you represent and warrant that you are above the age of 18 and 
        that the information you provide us is accurate, complete, and current at all 
        times.  We reserve the right to refuse service or terminate accounts and/or 
        registrations in our sole discretion.</p>

        <p>By creating an Account on our Services, you agree to subscribe to newsletters, 
        marketing or promotional materials and other information we may elect to send. 
        However, you may opt out of receiving any, or all, of these communications 
        from us by following the unsubscribe link or instructions provided in any email we send.</p>

        <p>You are responsible for maintaining the confidentiality of your account and 
        password, including, but not limited to, restricting access to your computer 
        or mobile device, as applicable, or account. You may not share your account, 
        username, or password with any other party or use the account, username, or 
        password of any other party. You will be solely responsible for all activities 
        undertaken and all obligations made by you or through your account and/or 
        registration application. You must notify us immediately upon becoming aware 
        of any breach of security or unauthorized use of your account.</p>

        <p>You may not use as a username the name of another person or entity or 
        that is not lawfully available for use, a name or trademark that is subject 
        to any rights of another person or entity other than you, without appropriate 
        authorization. You may not use as a username any name that is offensive, vulgar, or obscene.</p>

        <p>To the extent that the Services allow you to link your account to any social 
        networking or other online accounts, you may only link your own accounts. By 
        linking your other accounts, you expressly authorize us to access your account 
        information from those third party accounts, on your behalf as your agent, and 
        permit us to access, use and, in some cases, store your account information.</p>

        <p>You may only have one single account in the Services. Multiple accounts 
        are prohibited and violate the User License granted herein.</p>

        <h4>CONSUMER ACCESS AND REGISTRATION FOR LICENSE AND LICENSE</h4>
        <p>By accessing the Services, creating and uploading content for the creation 
        of a personalized augmented reality tattoo experience, searching for a Skin Motion 
        licensed tattoo artist, or submitting an application for registration with us to 
        potentially become a licensed tattoo artist for our products and services made 
        available through the Services, you represent and warrant as applicable that:</p>

        <ol>
          <li>you have all necessary legal capacity, right, power, and authority to enter 
          into and be bound by these Terms;</li>

          <li>you are 18 years of age or older;</li>
          <li>you have the legal right to use the payment method provided;</li>

          <li>all content you upload onto the Services is wholly original to you 
          and does not infringe on the rights of any third party;</li>

          <li>you will provide all required information and complete all required 
          aspects of the registration process (and as a potential Skin Motion 
            licensed tattoo artist, accept the terms of the Artist License Agreement);</li>

          <li>all information you provide to us in connection with such account 
          and/or registration is true, accurate, and complete;</li>

          <li>you will abide by all requirements of Skin Motion;</li>

          <li>you will comply with any applicable third party terms of agreement when 
          using the Services (e.g. you must ensure your use of the App is not in violation 
            of your mobile device agreement or any wireless service agreement); and</li>

          <li>you will abide by all applicable laws when submitting your account and/or 
          registration information and while engaging in all other activities that flow 
          from your access to the Services, use of the Skin Motion products and services, 
          and/or, if applicable, activities after acceptance (if accepted) as a licensed 
          Skin Motion tattoo artist.</li>
        </ol>

        <p>Further, you may not in any way use the Services or submit to us or to the 
        Services anything which in any respect:</p>

        <ol>
          <li>is in breach of any law, statute, regulation, or bylaw of any applicable jurisdiction;</li>
          <li>is fraudulent, criminal, or unlawful;</li>


          <li>is inaccurate or out-of-date;</li>

          <li>may be obscene, indecent, pornographic, vulgar, profane, racist, sexist, 
          discriminatory, offensive, derogatory, harmful, harassing, threatening, embarrassing, 
          malicious, abusive, hateful, menacing, defamatory, untrue or political;</li>

          <li>impersonates any other person or body or misrepresents a relationship 
          with any person or body;</li>

          <li>may infringe any rights of us or any third party;</li>

          <li>may be contrary to our interests;</li>

          <li>is contrary to any specific rule or requirement that we stipulate on the Services; or</li>

          <li>involves your use, delivery or transmission of any viruses, unsolicited 
          emails, trojan horses, trap doors, back doors, easter eggs, worms, time bombs, 
          cancelbots or computer programming routines that are intended to damage, 
          detrimentally interfere with, surreptitiously intercept or expropriate any system, 
          data or personal information.</li>
        </ol>

        <p>Skin Motion’s review of your use of the Services at any time (e.g. and without 
        limitation, during the activation process for your augmented reality tattoo experience) 
        does not in any way alter the foregoing or other obligations you have in these Terms or 
        any other language elsewhere in these Terms.</p>  

        <p>You may be asked to supply certain information relevant to the account and/or 
        registration you place through the Services, including, without limitation, as 
        applicable, your name; your contact information; your experience; your website 
        and other social media channel information; your business’ name, physical address 
        and phone number; your hourly or other rate information; your credit card number; 
        the expiration date of your credit card; and your billing address. We may use third 
        party services to process your payment or process your registration. By submitting 
        your information, you grant us the right to provide the information to these third 
        parties subject to our Privacy Policy <a href="https://skinmotion.com/privacy-policy">
        https://skinmotion.com/privacy-policy</a>.</p>

        <p>We reserve the right to refuse any account registration or registration submission 
        you place with us. We may, in our sole discretion, limit or cancel the number of licensed 
        tattoo artists associated with Skin Motion. We reserve the right to refuse or cancel your 
        account and/or registration if fraud or an unauthorized or illegal transaction is suspected. 
        We may also ask you to provide a copy of your license or other official documentation 
        during the registration approval process.</p>

        <p>As part of the registration process to become a licensed tattoo artist with Skin 
        Motion, you will be required to execute a separate Artist License Agreement, watch a 
        video, and obtain a one hundred percent score on a quiz of the content of such video.  
        If there are any conflicts between the terms in these Terms and the terms of such Artist 
        License Agreement, the terms of the Artist License Agreement shall prevail.</p>
        
        <h4>CONTENT AND ACCURACY OF CONTENT OF THE SERVICES</h4>
        <p>All features, specifications, products, prices, discounts, promotions, and offers 
        described on our Services are subject to change at any time, without notice. We have 
        made every effort to accurately display our products, but images on the Services may 
        not exactly match the actual product. All prices displayed on the Services or in connection 
        with your registration and license fees are in U.S. dollars unless otherwise noted. We will 
        add applicable sales taxes or other similar fees as necessary. You acknowledge that you 
        may make a pre-sale purchase and/or purchase of a personalized augmented reality tattoo 
        experience through your use of the Services. We may provide you with a voucher to redeem 
        with a licensed tattoo artist associated with Skin Motion or another form of purchase 
        confirmation.  The voucher is redeemable, but not returnable.  All purchases whether 
        reflected by the receipt of a voucher or another form of purchase confirmation are final 
        and not returnable or refundable for any reason.  You agree to abide by any additional 
        terms and conditions indicated at the time of pre-sale and/or purchase. You further 
        acknowledge your understanding that the implementation of the personalized augmented 
        reality tattoo experience shall be at an additional cost and you are solely responsible 
        for all aspects of the implementation and maintenance of the tattoo, including, but not 
          limited to, searching for a licensed tattoo artist associated with Skin Motion, 
        scheduling an appointment, paying the separate fee for the implementation of such tattoo, 
        and any and all maintenance and other costs.</p>

        <p>Occasionally there may be information on our Services that contains typographical errors, 
        inaccuracies, or omissions that may relate to product descriptions, pricing, promotions, 
        offers, and availability. We reserve the right to correct any errors, inaccuracies or 
        omissions and to change or update information or cancel orders if any information on the 
        Services is inaccurate at any time without prior notice.</p>

        <h4>NOTICE REGARDING POTENTIAL ISSUES WITH SERVICES</h4>
        <p>Skin Motion’s personalized augmented reality tattoo experiences work using new 
        experiential technology that relies on the human body and although Skin Motion 
        designed the platform and App to work with the greatest number of variations of 
        natural human body characteristics, the personalized augmented reality tattoo 
        experience and tattoo cannot be standardized or quantified in a way that guarantees 
        that it will work for everyone. You understand that purchasing a Skin Motion personalized 
        augmented reality tattoo experience and getting such tattoo applied to your body from a 
        licensed tattoo artist associated with Skin Motion does not guarantee that it will work, 
        or guarantee that it will work to any specification other than is experienced while using 
        the App. You further agree that we shall not be liable to you or to any third party if 
        your personalized augmented reality tattoo does not work for you. 
        <strong> PLEASE ONLY GET TATTOOED IF YOU ACCEPT THAT THERE IS A POSSIBILITY THAT YOUR 
        PERSONALIZED AUGMENTED REALITY TATTOO MAY NOT WORK WITH THE APP.</strong></p>

        <h4>MONEY BACK GUARANTEE</h4>
        <p>If you have any questions or your personalized augmented reality tattoo isn't 
        working properly, please contact the Skin Motion support team for assistance at 
        support@skinmotion.com.  You must contact us within fifteen (15) days after 
        implementation of your personalized augmented reality tattoo and if we are not 
        able to fix the issue with your tattoo we offer a 100% money-back satisfaction 
        guarantee. If you accept such refund your tattoo will be disabled and unable 
        to work with our Services. If you activate a pre-existing soundwave tattoo that 
        is not a Skin Motion personalized augmented reality tattoo experience tattooed 
        by a licensed tattoo artist associated with Skin Motion, this 100% money-back 
        satisfaction guarantee does NOT apply.</p>


        <h4>RIGHT TO CHANGE THE SERVICES</h4>
        <p>We reserve the right to modify, suspend, or terminate the Services, or any 
        service, content, feature or product offered through the Services, pricing, or 
        terminate your right to access or use any portion of the Services, with or 
        without notice, at any time in our sole discretion. You agree that we shall 
        not be liable to you or to any third party for any modification, suspension, 
        restriction, or termination of the Services or any portion thereof.  You 
        acknowledge that there is no guarantee of any perpetual continuation of 
        any portion of the Services, the Site and/or App themselves, or technology 
        available through the Services or otherwise to perform any part of the services 
        offered through the Services at any given time, including, but not limited to, 
        technology and functionality necessary to support the audio for the personalized 
        augmented reality tattoo.</p>

        <h4>ACCESS TO AND USE OF THE SERVICES</h4>
        <p>You agree to access or use the Services solely for 
        legal purposes as allowed by these Terms. Amongst other things, you agree not to:</p>

        <ul>
          <li>hack into the Services, or modify another website or mobile application so 
          as to falsely imply that it is associated or affiliated with the Services;</li>

          <li>damage, disable, overburden, transmit any worms or viruses or any code of a 
          destructive nature, or interfere with any other party's use and enjoyment of the Services;</li>
          
          <li>violate any applicable laws, rules or regulations in your jurisdiction 
          (including but not limited to trademark and copyright laws);</li>

          <li>use or copy any material from the Services, including, but not 
          limited to, onto other websites or in other mobile applications;</li>

          <li>frame any of the Services onto your own or another person’s website or 
          mobile application; or</li>

          <li>access or attempt to access any password-protected, secure or non-public 
          areas of the Services without our express permission.</li>
        </ul>

        <h4>NO COMPENSATION AND YOUR COSTS</h4>
        <p>Your use of the Services is completely voluntary and for your own benefit. 
        You shall not be entitled to compensation of any kind from Skin Motion for such 
        use. You hereby waive any claims you may have against Skin Motion, its agents, 
        employees, and representatives for any compensation of any kind for your use of 
        the Services, except as expressly authorized in advance in writing by such party. 
        Your use of the Services requires appropriate telecommunication links. We shall 
        not have any responsibility or liability with respect to any mobile or other costs 
        you may incur due to your use of the Services. You are solely responsible for all 
        of costs involved in your use of the Services, including, but not limited to, 
        computer and/or mobile costs, initial and annual subscription fees, cost of the 
        personalized augmented reality tattoo experience, implementation and maintenance 
        of such tattoo, and any other costs that flow directly or indirectly from your use 
        of the Services and from such personalized augmented reality tattoo experience.</p>

        <h4>CONTESTS, SWEEPSTAKES, AND PROMOTIONS</h4>
        <p>Any contests, sweepstakes, or other promotions (collectively, “Promotions”) 
        made available through the Services may be governed by rules that are separate 
        from these Terms. If you participate in any Promotions, please review the 
        applicable rules as well as our Privacy Policy. If the rules for a Promotion 
        conflict with these Terms, the Promotion rules will apply.</p>

        <h4>CONTENT</h4>
        <p>Unless otherwise noted, the Services, and all materials on the Services, 
        including text, images, illustrations, designs, icons, photographs, and video 
        clips, and the copyrights, trademarks, trade dress, or other intellectual 
        property in such materials (“Skin Motion Content”), are owned, controlled or 
        icensed by Skin Motion. The Skin Motion brand name and logo are trademarks of 
        Skin Motion. The Services and the Skin Motion Content are intended solely for 
        personal, non-commercial use. You may download or copy the Skin Motion Content 
        and other downloadable materials displayed on the Services for your personal 
        use only. No right, title or interest in any downloaded Skin Motion Content is 
        transferred to you as a result of any such downloading or copying. You may not 
        reproduce (except as noted above), publish, transmit, distribute, display, modify, 
        create derivative works from, sell or exploit in any way any of the Skin Motion 
        Content or the Services.  The only exception to the foregoing restrictions in 
        this provision are the rights of licensed Skin Motion tattoo artists with a 
        valid Artist License Agreement to use the Services for commercial purposes in 
        accordance with the terms hereof and the terms of such Artist License Agreement.</p>

        <p>Any third-party content, products, or services posted on, transmitted through, 
        or linked from the Services are the sole responsibility of the third-party originator. 
        If you choose to access any third-party content, you do so at your own risk. 
        WE ARE NOT RESPONSIBLE FOR ANY LOSS OR DAMAGE OF ANY SORT INCURRED AS A RESULT OF 
        ACCESSING A THIRD-PARTY WEBSITE OR APP OR ANY THIRD-PARTY CONTENT, PRODUCTS, OR SERVICES.</p>


        <h4>CONTENT YOU PROVIDE</h4>
        <p>Our Services may allow you to post, link, store, share, and otherwise make 
        available certain information, text, graphics, videos, or other material (“User Content”). 
        You are responsible for the User Content that you post on or through the Services, 
        including its legality, reliability, and appropriateness. We take no responsibility 
        and assume no liability for User Content you or any third party posts on or 
        through the Services.</p>

        <p>The Services are publicly viewable and you should have no expectation of privacy 
        or confidentiality in any User Content you post. You represent and agree that any 
        User Content you post will not violate any right of any third party, including copyright, 
        trademark, privacy or other personal or proprietary right and will not contain libelous 
        or otherwise unlawful, abusive or obscene material, or contain any computer virus or 
        other malware that could in any way affect the operation of the Services. You may not 
        use a false e-mail address, pretend to be someone other than yourself, or otherwise 
        mislead us or any third parties regarding the source of any User Content. We take no 
        responsibility and assume no liability for any User Content posted by you or any third party.</p>

        <p>By submitting User Content to the Services, you grant, and you represent and 
        warrant that you have the right to grant and hereby do grant, Skin Motion (and its 
        affiliates, agents, licensees, successors and assigns) an irrevocable, perpetual, 
        non-exclusive, fully paid, worldwide license to use, copy, perform, display, reproduce, 
        publish, distribute, sublicense, and otherwise disclose, prepare derivative works 
        of, or incorporate into other works, the User Content, for any purpose without 
        restriction and without providing any attribution or compensation to you. If you 
        do not want us to use your User Content, please do not post it to the Services. 
        We maintain the right, but not the obligation, to monitor and edit all User 
        Content posted on the Services.</p>

        <h4>UNSOLICITED SUBMISSIONS AND FEEDBACK ON SERVICES</h4>
        <p>Without in any way limiting the foregoing Section (Content You Provide) or 
        language elsewhere in these Terms, Skin Motion (or any of its employees or 
        representatives) does not accept or consider unsolicited ideas, including, but 
        in no way limited to, ideas for new advertising campaigns, new promotions, new 
        or improved products or technologies, product enhancements, processes, materials, 
        marketing plans or new product names. Please do not submit any unsolicited ideas, 
        original creative artwork, suggestions or any other works (collectively “Submissions”) 
        in any form to Skin Motion (or any of its employees or representatives). The sole 
        purpose of Skin Motion’s Unsolicited Idea Submission and Product Feedback Policy 
        (“Unsolicited Idea Submission Policy”)&nbsp;  
        <a href="https://skinmotion.com/unsolicited-idea-policy">https://skinmotion.com/unsolicited-idea-policy</a> 
        &nbsp;is to avoid potential misunderstandings or disputes when Skin Motion’s products or 
        marketing strategies might seem similar to ideas submitted to Skin Motion. If, 
        despite our request that you not send us your Submission, you still submit them, 
        then regardless of what your letter or other communication says when submitting 
        such Submissions, the following terms shall apply to your Submissions. You agree 
        that: (1) Your Submissions and their contents will automatically become the property 
        of Skin Motion, without any compensation or attribution to you; (2) Without in any 
        way intending to review the previous language, Skin Motion may use or redistribute 
        the Submissions and their contents for any purpose and in any way; (3) There is no 
        obligation for Skin Motion to review the Submission; and (4) There is no obligation 
        to keep any Submission confidential. Skin Motion does, however, welcome your feedback 
        regarding many areas of Skin Motion’s existing business. If you want to send us your 
        feedback, and we hope you do, we simply request that you send it to us at 
        support@skinmotion.com. Please provide only specific feedback on Skin Motion’s 
        existing products or marketing strategies; do not include any ideas that our 
        Unsolicited Idea Submission Policy&nbsp;
         <a href="https://skinmotion.com/unsolicited-idea-policy">https://skinmotion.com/unsolicited-idea-policy</a> 
           &nbsp;will not permit us to accept or consider. Product feedback is just one more way 
          that Skin Motion can learn how to best satisfy your needs. Any feedback you 
          provide to Skin Motion shall be deemed to be non-confidential. Skin Motion 
          shall be free to use such information on an unrestricted basis without notice, 
          approval, compensation, or attribution to you.</p>

        <h5>PRIVACY</h5>
        <p>We respect the privacy of your Personal Information (as defined in our Privacy Policy). 
        Please view our Privacy Policy&nbsp; 
        <a href="https://skinmotion.com/privacy-policy">https://skinmotion.com/privacy-policy</a>&nbsp; 
        for more information.</p>

        <h4>DISCLAIMER OF WARRANTIES AND LIMITATION ON DAMAGES</h4>
        <p>THE SERVICES AND PRODUCTS AND SERVICES THEREIN ARE OFFERED ON AN “AS IS” 
        AND “AS AVAILABLE” BASIS, DISCLAIMING ALL WARRANTIES, INCLUDING SPECIFICALLY 
        THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, 
        AND NON-INFRINGEMENT. WE ASSUME NO LIABILITY FOR PERSONAL INJURY OR PROPERTY 
        DAMAGE, OF ANY NATURE WHATSOEVER, RESULTING FROM YOUR ACCESS TO AND USE OF THE 
        SERVICES, PRODUCTS AND SERVICES THEREIN OR ANY THIRD PARTY WEBSITE(S), APPS, OR 
        PRODUCTS, OR FOR ANY BUGS, VIRUSES, TROJAN HORSES, OR THE LIKE, WHICH MAY BE 
        TRANSMITTED TO OR THROUGH THE SERVICES.</p>

        <p>YOU ASSUME ALL RESPONSIBILITY AND RISK FOR USE OF THE SERVICES AND THE 
        MATERIALS INCLUDING WITHOUT LIMITATION ANY OF THE INFORMATION CONTAINED 
        THEREIN. TO THE FULLEST EXTENT ALLOWABLE UNDER APPLICABLE LAW, IN NO EVENT 
        SHALL SKIN MOTION  OR ANY OF ITS DIRECTORS, OFFICERS, EMPLOYEES, SHAREHOLDERS, 
        PARTNERS, AFFILIATES, CONTRACTORS, REPRESENTATIVES, OR AGENTS BE LIABLE FOR 
        ANY INCIDENTAL, INDIRECT, PUNITIVE, EXEMPLARY, OR CONSEQUENTIAL DAMAGES 
        WHATSOEVER (INCLUDING DAMAGES FOR LOSS OF PROFITS, INTERRUPTION, LOSS OF 
        BUSINESS INFORMATION, OR ANY OTHER PECUNIARY LOSS) IN CONNECTION WITH ANY 
        CLAIM, LOSS, DAMAGE, ACTION, SUIT OR OTHER PROCEEDING ARISING UNDER OR OUT 
        OF THESE TERMS OF USE, INCLUDING WITHOUT LIMITATION YOUR USE OF, RELIANCE 
        UPON, ACCESS TO, OR EXPLOITATION OF THE SERVICES, THE MATERIALS OR ANY PART 
        THEREOF, OR ANY RIGHTS GRANTED TO YOU HEREUNDER, EVEN IF YOU HAVE BEEN 
        ADVISED OF THE POSSIBILITY OF SUCH DAMAGES, WHETHER THE ACTION IS BASED 
        ON CONTRACT, TORT (INCLUDING NEGLIGENCE), INFRINGEMENT OF INTELLECTUAL 
        PROPERTY RIGHTS OR OTHERWISE. YOUR SOLE REMEDY IS TO CEASE USE OF THE 
        SERVICES.  NOTWITHSTANDING SUCH LIMITATION, UNDER NO CIRCUMSTANCES SHALL 
        SKIN MOTION’S MAXIMUM AGGREGATE LIABILITY TO YOU BE MORE THAN FIVE HUNDRED DOLLARS ($500.00).</p>

        <p>Skin Motion and any subsidiaries and/or affiliates do not warrant that: 
        a) the Services will function uninterrupted, secure, or available at any 
        particular time or location; b) any errors or defects will be corrected; 
        c) the Services are free of viruses or other harmful components; or d) the 
        results of using the Services, the products, services, and experiences offered 
        will meet your requirements.</p>

        <p>Some states or jurisdictions do not allow the limitation or exclusion of 
        certain warranties, or the exclusion or limitation of certain damages. If 
        you reside in one of these states or jurisdictions, the above limitations 
        or exclusions may not apply to you.</p>

        <p>In no event shall Skin Motion, nor its directors, employees, partners, 
        agents, suppliers, or any subsidiaries and/or affiliates, be liable for any 
        indirect, incidental, special, consequential or punitive damages, including 
        without limitation, loss of profits, data, use, goodwill, or other intangible 
        losses, resulting from (i) your access to or use of or inability to access or 
        use the Services; (ii) your use of the products, services, and experiences 
        offered on the Services; (iii) any conduct or content of any third party on 
        the Services; (iv) any content obtained from the Services; and (v) unauthorized 
        access, use, or alteration of your transmissions or content, whether based on 
        warranty, contract, tort (including negligence) or any other legal theory, 
        whether or not we have been informed of the possibility of such damage, 
        and even if a remedy set forth herein is found to have failed of its essential purpose.</p>


        <h4>INDEMNITY</h4>
        <p>You shall defend, indemnify, and hold harmless, Skin Motion, any subsidiaries 
        and/or affiliates, and each of its and its and their employees, contractors, 
        directors, suppliers, agents, and representatives from all liabilities, claims, 
        and expenses, including reasonable attorneys' fees and other legal costs, that 
        arise from or relate to (i) your use or misuse of the Services; (ii) your use 
        of the products, services, and experiences offered on the Services; (iii) 
        violation of these Terms; (iv) violation by you, or any third party using 
        your account, of any law and/or intellectual property or other right of any 
        person or entity; or (v) any User Content you submitted. We reserve the right 
        to assume the exclusive defense and control of any matter otherwise subject to 
        indemnification by you, in which event you will assist and cooperate with us in 
        asserting any available defenses.</p>

        <h4>REMEDIES</h4>
        <p>In addition to any other available remedies, if you breach any of the terms 
        or conditions listed within these Terms, Skin Motion may immediately terminate 
        these Terms and pursue legal action and/or equitable injunctions in order to 
        compensate Skin Motion for all injuries caused by such breach.  You also 
        further agree to forfeit any compensation earned by you in connection with 
        your breach(es) of these Terms.</p>
        
        <h4>EQUITABLE REMEDIES</h4>
        <p>You hereby agree that if the terms of these Terms are not specifically 
        enforced, Skin Motion will be irreparably damaged, and therefore you agree 
        that Skin Motion shall be entitled, without bond, other security, proof of 
        damages, to appropriate equitable remedies with respect any of this Agreement, 
        in addition to any other available remedies.  You hereby expressly waive all 
        rights to injunctive relief.</p>

        <h4>COPYRIGHT POLICY</h4>
        <p>We respect intellectual property rights and will remove or disable access 
        to any material on the Services that infringes any copyright after being 
        properly notified by the copyright owner or the copyright owner’s legal 
        agent. If you believe that any content on the Services violates your 
        copyright, please provide our copyright agent with the written information 
        specified below:</p>

        <ul>
          <li>an electronic or physical signature of the person authorized to act 
          on behalf of the owner of the copyright interest;</li>

          <li>an description of the copyrighted work that you claim has been infringed;</li>

          <li>a description of where the material that you claim is infringing is 
          located on the Services;</li>
          
          <li>your address, telephone number, and e-mail address;</li>

          <li>a statement by you that you have a good faith belief that the 
          disputed use is not authorized by the copyright owner, its agent, or the law;</li>

          <li>a statement by you, made under penalty of perjury, that the above 
          information in your notice is accurate and that you are the copyright 
          owner or authorized to act on the copyright owner’s behalf.</li>
        </ul>

        <p>Our Copyright Agent for notice of claims of copyright infringement on the 
        Services can be reached as follows: support@skinmotion.com.</p>

        <h4>CHANGES</h4>
        <p>We reserve the right, at our sole discretion, to modify or replace these 
        Terms at any time. If a revision is material we will provide written notice 
        prior to any new terms taking effect. What constitutes a material change 
        will be determined at our sole discretion. By continuing to access or use 
        the Services after any revisions become effective, you agree to be bound 
        by the revised terms. If you do not agree to the new terms, you are no 
        longer authorized to use the Services.</p>

        <h4>TERMINATION</h4>
        <p>These Terms and the User License are effective until terminated by you 
        or Skin Motion, with or without written notice. Your rights under the User 
        License will terminate automatically without notice from Skin Motion if you 
        fail to comply with any terms or conditions of the User License. Upon 
        termination of the User License, you shall cease all use of the Services 
        and delete and uninstall all copies.</p>

        <h4>GOVERNING LAW/JURISDICTION AND VENUE</h4>
        <p>These Terms shall be governed by and construed in accordance with the 
        laws of the State of California, U.S.A., without regard to conflicts of laws 
        principles, as if this were a contract entered into and wholly performed 
        within the State of California.  Any disputes or differences between the 
        parties arising out of these Terms which the parties are unable to resolve 
        themselves shall be submitted to and resolved by arbitration as provided 
        herein below.  Notwithstanding the arbitration obligations, Skin Motion 
        shall be entitled to seek injunctive or other equitable relief in any court 
        of competent jurisdiction located in Los Angeles, California and you consent 
        to personal jurisdiction of any court of competent jurisdiction in Los 
        Angeles, California.  Access to or use of the Services is unauthorized 
        in any jurisdiction that does not give effect to all provisions of these 
        Terms, including, without limitation, this paragraph.</p>

        <h4>ARBITRATION</h4>
        <p>This Agreement requires the use of arbitration on an individual basis to 
        resolve disputes, rather than jury trials or class actions, and also limits 
        the remedies available to you in the event of a dispute. Please read this 
        carefully. It affects your rights.</p>

        <p>Except for a claim by Skin Motion of infringement or misappropriation of 
        its patent, copyright, trademark, or trade secret and/or Skin Motion’s 
        ability to seek injunctive or other equitable relief, any and all disputes 
        between you and Skin Motion arising under or related in any way to these 
        Terms must be resolved through binding arbitration as described in this 
        section. This agreement to arbitrate is intended to be interpreted broadly. 
        It includes, but is not limited to, all claims and disputes relating to your 
        use of the Services. You agree that by entering into these Terms, you and 
        Skin Motion are each waiving the right to trial by jury or to participate 
        in a class action. You and Skin Motion agree that each may bring claims 
        against the other only in your or its individual capacity, and not as a 
        plaintiff or class member in any purported class or representative 
        proceeding. Any arbitration will take place on an individual basis; 
        class arbitrations and class actions are not permitted.</p>

        <p>The arbitration will be governed by the Commercial Arbitration Rules 
        and the Supplementary Procedures for Consumer Related Disputes of the 
        American Arbitration Association (“AAA”), as modified by this section. 
        For any claim where the total amount of the award sought is $10,000 or 
        less, the AAA, you and Skin Motion must abide by the following rules: 
        (a) the arbitration shall be conducted solely based on written submissions; 
        and (b) the arbitration shall not involve any personal appearance by the 
        parties or witnesses unless otherwise mutually agreed by the parties. 
        If the claim exceeds $10,000, the right to a hearing will be determined 
        by the AAA rules, and the hearing (if any) must take place in Los 
        Angeles, California. The arbitrator’s ruling is binding and may be 
        entered as a judgment in any court of competent jurisdiction. In the 
        event this agreement to arbitrate is held unenforceable by a court, 
        then the disputes that would otherwise have been arbitrated shall be 
        exclusively brought in the state or federal courts located in Los 
        Angeles, California. Any arbitration proceedings held hereunder shall be confidential.</p>

        <h4>ATTORNEY’S FEES AND COSTS</h4>
        <p>Should it become necessary for any party to these Terms to bring a 
        claim in arbitration and/or litigation to enforce any provision herein, 
        or for damages on account of any breach of these Terms, the prevailing 
        party shall be entitled to recover from the other party or parties all 
        costs and expenses incurred in the arbitration and/or litigation, which 
        includes but is not limited to the recovery of reasonable attorney’s 
        fees and court costs.</p>

        <h4>GENERAL LEGAL PROVISIONS</h4>
        <p>These Terms (and our Privacy Policy any other document referred 
          to in these Terms and any other terms and conditions specifically 
          agreed between you and us in writing) contain all the terms agreed 
        between us and you regarding their subject matter and supersedes and 
        excludes any prior terms and conditions, understanding or arrangement 
        between us and you, whether oral or in writing. No representation, 
        undertaking or promise shall be taken to have been given or be implied 
        from anything said or written in negotiations between us and you prior 
        to these Terms except as expressly stated in these Terms.</p>

        <p>If any provision in these Terms is invalid or unenforceable under 
        applicable law, the remaining provisions will continue in full 
        force and effect, and the invalid unenforceable provision will be 
        deemed superseded by a valid, enforceable provision that most closely 
        matches the intent of the original provision.</p>

        <p>No waiver of any breach of any provision of these Terms shall 
        constitute a waiver of any prior, concurrent, or subsequent breach 
        of the same or any other provisions hereof, and no waiver shall be 
        effective unless made in writing and signed by an authorized 
        representative of the waiving party. You may not assign your 
        rights under this Agreement to any party; we may assign our 
        rights under this Agreement without condition. This Agreement 
        will be binding upon and will inure to the benefit of the parties, 
        their successors, and permitted assigns. All rights not expressly 
        granted herein are hereby reserved.</p>

        <h5>CONTACT</h5>
        <p>If you have concerns relating to the Services or these Terms, 
        please contact Skin Motion at <Link to="/support">support@skinmotion.com</Link>.</p>

      </div>
    </div>)

  }

}

export default TermsAndConditions
