import React, { Component } from "react";
import serialize from "form-serialize";
import ReCAPTCHA from "react-google-recaptcha";
// import ForgotPassword from 'components/application/ForgotPassword';
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { signinUser } from "../../actions/currentUser";

class LoginForm extends Component {
  state = {
    error: null,
    forgot: null,
    next: "/my-account",
    passiveLogin: false,
    loggingIn: false,
    captchaSolved: false,
  };
  onChange = (value) => {
    if (value) this.setState({ captchaSolved: true });
  };

  componentDidMount() {
    window.scrollTo(0, 0);
    let query = this.props.location.search.split("=");
    if (query[0] == "?next") {
      // Good god sometimes Flask-Login puts a full URL as the next query
      // and sometimes just a URI... no idea why so this will do for now.
      let nextPath = decodeURIComponent(query[1]);
      if (nextPath.includes("http")) {
        let el = document.createElement("a");
        el.href = nextPath;
        nextPath = el.pathname;
      }
      this.setState({ next: nextPath, passiveLogin: true });
    }
  }

  doSignIn = (e) => {
    e.preventDefault();
    let credentials = serialize(e.target, { hash: true });

    this.setState({ loggingIn: true });
    if (!this.state.captchaSolved)
      return this.setState({
        error: "Please solve captcha",
        loggingIn: false,
      });
    this.props
      .signinUser(credentials)
      .then(() => this.props.history.push(this.state.next))
      .catch(() => {
        this.setState({
          error: "Incorrect email or password.",
          loggingIn: false,
        });
      });
  };

  forgotPassword = (e) => {
    this.setState({ forgot: true });
  };

  render() {
    let signupLink = this.state.passiveLogin
      ? `/signup?next=${encodeURIComponent(this.state.next)}`
      : "/signup";
    return (
      <div className="sm-login-form common-padding">
        <div className="sm-form-container">
          <div className="sm-form">
            <h4>Log in to your account</h4>
            <form onSubmit={this.doSignIn}>
              {this.state.error && (
                <div className="sm-error-message">{this.state.error}</div>
              )}
              {this.state.passiveLogin && (
                <div className="sm-form-passive">
                  <p>
                    <strong>Please login to continue.</strong>
                    <br /> Please create an account or login to proceed.
                  </p>
                </div>
              )}
              <div className="field-wrap">
                <label htmlFor="email">Email: </label>
                <input type="text" name="email" />
              </div>
              <div className="field-wrap">
                <label htmlFor="password">Password: </label>
                <input type="password" name="password" className="mb-0" />
              </div>
              <div className="text-right forgot-pass">
                {" "}
                <Link to="/forgot-password">Forgot password?</Link>
              </div>
              <div className="field-wrap"></div>
              <div className="bottom-row d-flex flex-wrap justify-content-center">
                <ReCAPTCHA
                  sitekey={this.props.siteKey}
                  onChange={this.onChange}
                  type="image"
                />
                <button
                  className="btn btn-tan btn-large"
                  disabled={this.state.loggingIn}
                  type="submit"
                >
                  {this.state.loggingIn ? "Logging in..." : "Sign In"}
                </button>
                <div className=" mb-0 ">
                  {" "}
                  <p className="mb-0">
                    {" "}
                    Don't have an account? <Link to={signupLink}>Sign Up</Link>.
                  </p>{" "}
                </div>
                {/* <div></div> */}
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  signinUser: (credentials) => dispatch(signinUser(credentials)),
});

export default connect(null, mapDispatchToProps)(LoginForm);
