import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class pressKit extends Component {

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return <div className="sm-presskit">
      <div className="title-main" id='title-press-kit'>
        <h1>Media Resources</h1>
      </div>
      <div className="sm-presskit-main">
        <h3>
          These files are provided for use by official media outlets. <br/>
          By using them you agree to include the text “COURTESY OF SKIN MOTION”
          on screen for at least 5 seconds. <br/>
          Internet posts must include a link to our website or relevant social
          media account.
        </h3>
        <div className="sm-presskit-left">
          <h2>Videos for sharing</h2>
          <ul>
            <li>You can find up to date videos for sharing directly from our <a href="http://youtube.com/skinmotion">Youtube Channel</a></li>
          </ul>

          <h2>Videos without Music</h2>
          <p>These videos are provided <strong>ONLY</strong> to be re-edited into original pieces. If you would like to use one of them in it's entirety without editing you <strong>MUST</strong> use the video links above.</p>
          <p>Right click and "Save-as"</p>
          <h4>First Announcement - April 2017</h4>
          <ul>
            <li><a href="https://www.dropbox.com/s/ycxmwziw4738jh8/20170424-SoundwaveTattoos-NoMusic-NoWatermark.mov?dl=0" target="_blank">Soundwave Tattoo - "I love you"</a></li>
            <li><a href="https://www.dropbox.com/s/spmpxdi5ydz2xna/20170504-Juliana-Video-No%20Music.mp4?dl=0" target="_blank">Soundwave Tattoo - "Baci the singing dog"</a></li>
          </ul>

          <h4>Soundwave Tattoo Stories - October 2017</h4>
          <ul>
            <li><a href="https://www.dropbox.com/s/7gldqu56dmw11yp/20171002-Reactions.mp4?dl=0" target="_blank">Soundwave Tattoo Reactions</a></li>
            <li><a href="https://www.dropbox.com/s/vzhgmxvhqas39g0/20171002-MigueleStephanie.mp4?dl=0" target="_blank">Soundwave Tattoo for Miguel and Stephanie</a></li>
            <li><a href="https://www.dropbox.com/s/3uaa9996q8nzrnk/20171002-Bryan.mp4?dl=0" target="_blank">Soundwave Tattoo for Bryan</a></li>
          </ul>
        </div>

        <div className="sm-presskit-right">
          <h2>Social Media Accounts for Soundwave Tattoos</h2>
          <p><strong>These are our main social media accounts</strong></p>
          <ul>
            <li>Youtube: <a href="http://youtube.com/skinmotion">Youtube Channel</a></li>
            <li>Facebook: <a href="http://facebook.com/soundwavetattoos">http://facebook.com/soundwavetattoos</a></li>
            <li>Instagram: <a href="http://instagram.com/soundwavetattoos">@soundwavetattoos</a></li>
            <li>Twitter: <a href="http://twitter.com/soundwavetattoo">@soundwavetattoo</a></li>
          </ul>

          <h2>Social Media Accounts for Skin Motion</h2>
          <strong>These accounts are secondary</strong>
          <ul>
            <li>Facebook: <a href="http://facebook.com/skinmotionapp">http://facebook.com/skinmotionapp</a></li>
            <li>Instagram: <a href="http://instagram.com/skinmotionapp">@skinmotionapp</a></li>
            <li>Twitter: <a href="http://twitter.com/skinmotionapp">@skinmotionapp</a></li>
          </ul>

          <h2>Press Releases</h2>
          <ul>
             <li>May 11, 2017 - <a href="https://cdn.skinmotion.com/press/20170511-SkinMotion-Announcement.pdf" target="_blank">Announcing Skin Motion</a></li>
          </ul>

          <h2>Visual Assets</h2>
          <ul>
           <li><a href="https://cdn.skinmotion.com/images/media-resources/skinmotionlogo.zip" target="_blank">Skin Motion Logo</a> (.zip package)</li>
           <li><a href="https://cdn.skinmotion.com/images/media-resources/soundwavetattoologo.zip" target="_blank">Soundwave Tattoo Logo</a> (.zip package)</li>
           <li><a href="https://cdn.skinmotion.com/images/media-resources/promotional-photos.zip" target="_blank">Promotional Photos</a> (.zip package)</li>
          </ul>
        </div>
      </div>
    </div>
  }
}

export default pressKit
