import React, { Component } from "react";
import { connect } from "react-redux";
import { downloadBlob } from "../../utils/general";
import API from "../../utils/API";

class UserSearchForm extends Component {
  state = {
    searchValue: "",
    notFound: null,
    error: null,
  };

  search = async () => {
    this.setState({ notFound: false, error: null });
    let selectedUser;
    //regex to validate correct email address
    let re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!re.test(this.state.searchValue)) {
      if (isNaN(this.state.searchValue)) {
        return this.setState({ error: "Please enter valid input" });
      }
    }
    selectedUser = await API.post("/user-search", {
      search_value: this.state.searchValue,
    });
    if (selectedUser?.response?.status === 404) {
      return this.setState({ notFound: true });
    }
    this.setState({ notFound: false });
    this.props.fetchUser(selectedUser.id).then(() => {
      this.props.push(`/admin/users/${selectedUser.id}`);
    });
    // const selectedUser = this.props.users.find((user) => {
    //   if (isNaN(this.state.searchValue)) {
    //     return user.get("email") == this.state.searchValue.toLowerCase();
    //   } else {
    //     return user.get("id") == parseInt(this.state.searchValue);
    //   }
    // });
    // if (selectedUser) {
    //   this.setState({ notFound: false });
    //   this.props.fetchUser(selectedUser.get("id")).then(() => {
    //     this.props.push(`/admin/users/${selectedUser.get("id")}`);
    //   });
    // } else {
    //   this.setState({ notFound: true });
    // }
  };

  handleChange = (e) => {
    this.setState({ searchValue: e.target.value });
  };

  exportUsersCSV = async () => {
    await this.props.fetchUsers();
    let exportData = [];
    this.props.users.map((user) => {
      exportData.push([
        user.get("id"),
        user.get("email"),
        user.get("first_name"),
        user.get("last_name"),
      ]);
    });

    let csvContent = "";
    exportData.forEach((rowObj) => {
      csvContent += `${rowObj.join(",")}\r\n`;
    });
    let csvBlob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    downloadBlob(csvBlob, `user_export_${Math.floor(Date.now() / 1000)}.csv`);
  };

  render() {
    return (
      <div className="sm-admin-user-search">
        <div className="sm-admin-title">
          <h2> Users </h2>
        </div>
        <div className="div-link no-margin" onClick={this.exportUsersCSV}>
          {" "}
          Export Users CSV{" "}
        </div>
        {this.state.notFound && (
          <div className="sm-error-message">
            No users found with that email address or user id
          </div>
        )}
        {this.state.error && (
          <div className="sm-error-message">Please enter valid input</div>
        )}
        <div className="sm-admin-user-search mt-4">
          <label htmlFor="email">Search for user by Email or User Id: </label>
          <input
            type="text"
            id="email"
            name="email"
            value={this.state.searchValue}
            onChange={this.handleChange}
          />
          <button className="btn btn-tan" onClick={this.search}>
            Search
          </button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (store) => ({
  users: store.get("users"),
});

const mapDispatchToProps = (dispatch) => ({
  //   // fetchApplications: () => dispatch(fetchApplications()),
  fetchUsers: () => dispatch(fetchUsers()),
});

export default connect(mapStateToProps, null)(UserSearchForm);
