import React, { Component } from 'react';
import YouTube from 'react-youtube';

class MyAccount extends Component {
    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        return (
            <div className="sm-my-account sm-content">
                <h3 className="sm-my-account-first">
                    Create your Soundwave Tattoo&trade;
                </h3>
                <p>
                    Start a new Soundwave Tattoo by uploading your sound file
                    and generate your Soundwave Tattoo stencil.
                </p>
                <p className='mb-0'>
                    <a className="btn btn-tan" href="/new-soundwave">
                        Upload your sound
                    </a>
                </p>

                <h3>Find your Skin Motion tattoo artist</h3>
                <p>
                    Once you have a Soundwave Tattoo stencil, find a Skin Motion
                    licensed tattoo artist near you in our Artist Directory.
                    Don't see an artist near you? You can ask your local artist
                    to apply. It only takes ten minutes and it's free for
                    artists to register.
                </p>
                <p className='mb-0'>
                    <a className="btn btn-tan" href="/artist-directory">
                        Artist Directory
                    </a>
                </p>

                <h3>Edit and Save your soundwave designs</h3>
                <p>
                    Prior to your tattoo payment and being activated, you can
                    review and edit your pending designs in the My Soundwaves
                    area of your account. Once you’re ready, select the design
                    you wish to proceed with and continue through to the payment
                    and activation prompts.
                </p>
                <p className='mb-0'>
                    <a className="btn btn-tan" href="/my-soundwaves">
                        My Soundwaves
                    </a>
                </p>

                <h3>Activated Ink</h3>
                <p>
                    Once you have completed payment for one of your designs, it
                    will then be moved into the My Tattoos area of your account.
                    Once the activation process is complete and you have
                    received a confirmation email from us, it’s time to test the
                    playback and share with friends. Tag us on Instagram,
                    @skinmotionapp or #skinmotion so we get to see it too.
                </p>
                <p className='mb-0'>
                    <a className="btn btn-tan" href="/my-tattoos">
                        My Tattoos
                    </a>
                </p>
            </div>
        );
    }
}

export default MyAccount;
