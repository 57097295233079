import React from 'react';

class ComingSoon extends React.Component {

  render() {
    return (
      <div className="sm-coming-soon">
        <h3>Coming Soon</h3>

        <p>This feature is coming soon. Thank you for your patience.</p>

        <p className="sm-coming-soon-team">- The Skin Motion Team</p>
      </div>
    )
  }
}

export default ComingSoon