import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

import { deleteMarker } from "../../../../actions/markers";
class SoundwaveListComponent extends React.Component {
  deleteMarker = () => {
    this.props
      .deleteMarker(this.props.marker.get("id"))
      .then(() => this.props.push("/my-soundwaves"));
  };

  render() {
    const { marker } = this.props;

    return (
      <div className={`soundwave-list-item tattoo-${marker.get("id")}`}>
        {marker.get("is_updating") ||
        marker.get("is_updating_waveform") ||
        !marker.get("imageURL") ? (
          <img src="https://cdn.skinmotion.com/images/general/blurry-soundwave.jpg" />
        ) : (
          <img src={marker.get("imageURL")} />
        )}
        <div className="description-row d-flex flex-row justify-content-between align-items-center">
          <span className="created-on">
            {marker.get("name")}{" "}
            <small>Created on {marker.get("created_at")}</small>
          </span>
          <div className="btn-wrap">
            <Link
              to={`/soundwaves/${marker.get("id")}`}
              className="btn btn-tan mr-2"
            >
              Continue
            </Link>

            <button onClick={this.deleteMarker} className="btn btn-opaque ">
              Delete
            </button>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  deleteMarker: (markerId) => dispatch(deleteMarker(markerId)),
});

export default connect(null, mapDispatchToProps)(SoundwaveListComponent);
