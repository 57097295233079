import Immutable from "immutable";
import React, { Component } from "react";
import { connect } from "react-redux";
import ModalWrapper from "../UI/ModalWrapper";

class ViewApplication extends Component {
  state = {
    showModal: false,
  };

  showModal = (e) => {
    this.setState({ showModal: true });
  };

  hideModal = (e) => {
    this.setState({ showModal: false });
  };

  renderAppData() {
    let applicationData = JSON.parse(this.props.application.application_data);

    applicationData = Immutable.fromJS(applicationData);
    return (
      <table className="sm-artist-application">
        <tbody>
          {applicationData.map((value, key) => (
            <tr key={key}>
              <td>
                {" "}
                <b>{key}</b>{" "}
              </td>
              <td> {value} </td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  }

  showState() {
    if (this.state.showModal) {
      return (
        <ModalWrapper
          modalID="application-modal"
          title="abc"
          width={400}
          showOk={false}
          hideModal={this.hideModal}
        >
          {this.renderAppData()}
        </ModalWrapper>
      );
    } else {
      return (
        <div className="div-link" onClick={this.showModal}>
          View
        </div>
      );
    }
  }

  render() {
    return <div className="sm-admin-view-application">{this.showState()}</div>;
  }
}

export default ViewApplication;
