import Immutable from 'immutable'
import {
  RECEIVE_APPLICATION,
  RECEIVE_APPLICATIONS
} from '../actions/artistApplications'

const artistApplications = (state = Immutable.Map(), action) => {
  switch(action.type) {
    case RECEIVE_APPLICATION:
      return state.set(action.application.id, Immutable.fromJS(action.application))
    case RECEIVE_APPLICATIONS:
      let tempState = state;
      action.applications.forEach(application => 
        tempState = tempState.set(application.id, Immutable.fromJS(application))
      )
      return tempState
    default:
      return state;
  }
}

export default artistApplications