import React from 'react'

class Analytics extends React.Component {

  render() {
    return (
      <div className="sm-admin-analytics">
        Analytics Coming Soon!
      </div>
    )
  }
}

export default Analytics