import React from 'react';
import { Field, reduxForm } from 'redux-form/immutable'
// import { passwordMatchValidate } from 'utils/form_validators'

const required = value => (value ? undefined : 'Required')

class EditAccountForm extends React.Component {

  renderField = ({
    input,
    placeholder,
    type,
    labelName,
    meta: { touched, error }
  }) => {
    return (
      <div>
        <label className="sm-field-label">
          {labelName}
        </label>
        <div>
          <input {...input} placeholder={placeholder} type={type} />
          {touched && error &&
            <div className='sm-error-message'>
              {error}
            </div>
          }
        </div>
      </div>
    )
  }

  render () {
    const {
      handleSubmit,
      onSubmit,
      submitted,
      submitFailed,
      submitSucceeded,
    } = this.props

    const formSubmitted = submitFailed || submitSucceeded

    return (
      <div className='sm-account-form'>
        {(submitSucceeded && submitted) && (<div className="sm-success-message">Saved!</div>)}
        {(submitFailed && submitted) && (<div className="sm-error-message">Server error, couldn't save</div>)}
        <form className='sm-form' onSubmit={handleSubmit(onSubmit)}>
          <div className="account-edit">
            <div className="field-wrap">
              <Field
                labelName='First Name:'
                type='text'
                component={this.renderField}
                name='first_name'
                validate={required}
              />
            </div>
            <div className="field-wrap">
              <Field
                labelName='Last Name:'
                type='text'
                component={this.renderField}
                name='last_name'
                validate={required}
              />
            </div>
            <div className="field-wrap">
              <Field
                labelName='Email:'
                type='text'
                component={this.renderField}
                name='email'
                validate={required}
              />
            </div>
            <button className="btn btn-tan btn-large" type="submit">Edit Account</button>
          </div>
        </form>
      </div>
    )
  }
}

export default reduxForm({
  form: 'EditAccountForm'
})(EditAccountForm)
