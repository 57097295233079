import React from 'react'
import { connect } from 'react-redux'
import { signupUser } from '../../actions/currentUser'
import SignupForm from './SignupForm'
import { SubmissionError } from 'redux-form';


class Signup extends React.Component {

  state = {
    error: false,
    next: '/my-account'
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    let query = this.props.location.search.split('=');
    if(query[0] == "?next") {
      this.setState({next: decodeURIComponent(query[1])});
    }
  }

  onSubmit = (signupParams) => {
    signupParams = signupParams.toJS()

    if (this.props.match) {
      signupParams.referral = this.props.match.params.referral
    }

    return this.props.signupUser(signupParams)
    .then(() => this.props.history.push(this.state.next))
    .catch((error) => {
      throw new SubmissionError('That email is already in use!');
    });
  }

  render () {
    return (
      <div className='sm-signup'>
        <div className="title-main title-short" id='title-signup'>
          <h1>Sign up</h1>
        </div>
        <div className="sm-signup-main">
          <div className="sm-signup-helper">
            <p className="sm-signup-helper-intro">Create a Skin Motion account to upload and create your Soundwave
            Tattoo&trade; stencil and activate your tattoo to play back 
            with <a href="/download">the Skin Motion app</a>.</p>
            <h4>Your account is Free!</h4>
            <p>It costs you nothing to create your account. Pay when you activate
            your Soundwave Tattoo for playback with the Skin Motion app, when your activation
            renews each year after, and for any special products, services or promotions.</p>
            <h4>Your data is private.</h4>
            <p>We don't sell your account data to third parties. We collect the minimum 
            possible information needed to provide the services we do.</p>
          </div>
          <SignupForm onSubmit={this.onSubmit} error={this.state.error} siteKey = {this.props.siteKey} />
        </div>
      </div>
    )
  }
}

const mapStateToProps = store => ({
  currentUser: store.get('currentUser')
})

const mapDispatchToProps = (dispatch) => ({
  signupUser: userParams => dispatch(signupUser(userParams))
})

export default connect(mapStateToProps, mapDispatchToProps)(Signup)
