import React from 'react';

class TattooImageUpload extends React.Component {
  state ={
    error: null,
    uploading: null
  }

  componentDidMount() {
    window.scrollTo(0, 0); 
  }

  imageUploaded = (e) => {
    let file = e.target.files[0]
    // Check that file exists
    if (!file) { return }

    this.setState({ uploading: true })
    this.processImageUpload(file)
  }

  processImageUpload = (file) => {
    let reader = new FileReader()

    reader.addEventListener('load', () => {
      this.props.saveImageInState(reader.result)
      this.props.uploadPhoto(
        this.props.marker.get('id'),
        reader.result)
      .then(() => this.props.nextStep())
    })

    reader.readAsDataURL(file)
  }

  render () {
    return (
      <div className='sm-upload-tattoo common-padding'>
        <div className='upload-container d-flex flex-column justify-content-around text-center align-items-center '>
          <h2 className=''> Upload a photo of your tattoo to activate it. </h2>
          <p className="activate-disclaimer">Make sure you take the photo with the correct lighting, is in focus, and is not blurry.</p>
          <p>Read the tips below for help taking the best possible picture. Pictures that were not taken properly will be reviewed 
          and rejected by Skin Motion "Activators",
          and will require a better photo for activation.</p>

          <ul className='tips pt-4 text-left'>
            <li>The entire tattoo must be in the picture and can not be cut off. Do not crop the photo before uploading it.</li>
            <li>The tattoo must be clean of aftercare products and blood and should not be shiny or reflective. It needs to be freshly washed and patted dry.</li>
            <li>Your photo needs to be taken in a brightly lit room, using the flash on the camera. </li>
            <li>Take the photo from the same position you will be in when you play it back with the Skin Motion app.</li>
            <li>Turn off any filters on the camera. Portrait mode, facetune, and other filters blur the photo and will cause problems activating your tattoo.</li>
            <li><strong>Make sure the photo is NOT a "live" photo on iPhones. "Live" photos will not work and will be rejected.</strong></li>
          </ul>
          <h3>Examples</h3>
          <ul className="example-photos">
            <li className="activate-help-correct">
              <img src="https://cdn.skinmotion.com/images/layout/activate-help-1.jpg" />
              <p className="correct"><b>Correct!</b> Brightly lit room and the photo is taken with the cameras flash.</p>
            </li>
            <li className="activate-help-wrong">
              <img src="https://cdn.skinmotion.com/images/layout/activate-help-2-b.jpg" />
              <p className="alert"><b>Wrong.</b> Do not have light source behind tattoo.</p>
            </li>
            <li className="activate-help-wrong">
              <img src="https://cdn.skinmotion.com/images/layout/activate-help-3-b.jpg" />
              <p className="alert"><b>Wrong.</b> The background in the photo can not be brighter than tattoo.</p>
            </li>
          </ul>
          <form>
            <label className='file-upload btn btn-tan'>
              {this.state.uploading ? 'Uploading Photo...' : 'Upload Tattoo Photo'}
              <input type="file" name="image_file" onChange={this.imageUploaded} />
            </label>
            {this.state.error && <div className='alert'>{this.state.error}</div>}
          </form>
          <div className="bottom-spacer"></div>
        </div>
      </div>
    )
  }
}

export default TattooImageUpload