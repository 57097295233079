import React from 'react';

class SetSoundwaveName extends React.Component {

  state = {
    name: this.props.marker.get('name') ? this.props.marker.get('name') : ''
  }

  componentDidMount() {
    window.scrollTo(0, 0); 
  }

  handleNameChange = (e) => {
    this.setState({name: e.target.value })
  }

  nextStep = () => {
    this.props.updateMarker(this.props.marker.get('id'), {marker: { name: this.state.name } })
    .then(() => this.props.nextStep())
  }

  render() {
    const { name } = this.state;

    return (
      <div className="sm-waveform-tool common-padding">
        <div className='sm-form text-center pb-2'>
          <h4>Name your soundwave</h4>
          <div className='center-container'>
            <label> Soundwave Name: </label>
            <input 
              type='text' 
              value={this.state.name}
              onChange={this.handleNameChange} />
            <button 
              className="btn btn-tan btn-large" 
              onClick={this.nextStep}
              disabled={this.state.name == ''}>
                Upload
            </button>
          </div>
        </div>
      </div>
    )
  }
}

export default SetSoundwaveName