import React, { Component } from "react";
import Immutable from "immutable";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form/immutable";
import ProfileImageUploader from "../artists/ProfileImageUploader";
import ArtistPayment from "../artists/application_process/ArtistPayment";
import {
  createArtistProfile,
  updateArtistProfile,
} from "../../actions/artists";
import { fetchApplication } from "../../actions/artistApplications";
import { fetchArtist } from "../../actions/artists";
import {
  required,
  fbNameValidate,
  socialHandleValidate,
} from "../../utils/form_validators";
import { trimSpaces, lower } from "../../utils/form_normalizers";

/**
 * this.props.initialValues
 * required fields
 */

const MOCK_DATA = {
  license: true,
  zip: "380061",
  business_zip: "380060",
  business_license: "Yes",
  cpr: "Yes",
  facebook: "yudizsolutions",
  business_city: "ahmedabad",
  business_name: "Yudiz",
  city: "Ahmedabad",
  years_tattooing: "5",
  phone: "9106160072",
  business_phone: "+919106160072",
  state: "Gujarat",
  business_state: "Gujarat",
  instagram: "yudizsolutions",
  twitter: "yudizsolutions",
  address: "4922, Testing ",
  business_address: "Manohar'studio",
  country: "india",
  insurance_policy: "Yes",
  blood_borne: "Yes",
  website: "https://www.yudiz.com/",
  business_website: "https://www.yudiz.com/",
};

class EditProfile extends Component {
  constructor(props) {
    super(props);

    this.state = {
      imgSrc: null,
      saved: null,
      error: null,
      submitting: null,
      profileAccess: null,
      params: new Immutable.Map(),
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.props.fetchApplication(this.props.currentUser.get("application_id"));
    if (this.props.currentUser.get("artist_id")) {
      this.props.fetchArtist(this.props.currentUser.get("artist_id"));
    }
  }

  flashSaved = () => {
    this.setState({ saved: true, submitting: false });
    setTimeout(() => {
      this.setState({ saved: null });
    }, 5000);
  };

  flashError = () => {
    this.setState({ error: true, submitting: false });
    setTimeout(() => {
      this.setState({ error: false });
    }, 5000);
  };

  setProfileAccess = () => {
    const { currentUser, updateArtistProfile } = this.props;
    const artist = this.props.artists.get(
      currentUser.get("artist_id"),
      Immutable.Map()
    );
    if (currentUser.get("artist_id") && artist.get("is_active")) {
      this.setState({ profileAccess: "false" });
      const params = this.state.params.set(
        "is_active",
        this.state.profileAccess
      );
      updateArtistProfile(currentUser.get("artist_id"), params.toJS());
    } else if (currentUser.get("artist_id") && !artist.get("is_active")) {
      this.setState({ profileAccess: "true" });
      const params = this.state.params.set(
        "is_active",
        this.state.profileAccess
      );
      updateArtistProfile(currentUser.get("artist_id"), params.toJS());
    } else {
      console.log("profile access set failed");
    }
  };

  // onSubmit = (values) => {

  //   const { currentUser, updateArtistProfile, createArtistProfile } = this.props;

  //   let params = new Immutable.Map()
  //   if(this.state.imgSrc) {
  //     debugger
  //     params = this.state.params.set('profile_image_src', this.state.imgSrc);
  //     this.setState({ params });
  //   }
  //   this.setState({ submitting: true })
  //   if(currentUser.get('artist_id')) {
  //     debugger
  //     const that = this;

  //     const paramsObj = Object.fromEntries(params)
  //     updateArtistProfile(currentUser.get('artist_id'), {...MOCK_DATA, ...paramsObj})
  //     .then(() => {
  //       debugger
  //       that.flashSaved()})
  //     .catch((error) => {
  //       debugger
  //       console.error(error);
  //       that.flashError()}
  //       );
  //   } else {
  //     debugger
  //     const params = this.state.params.set('name', `${currentUser.get('first_name')} ${currentUser.get('last_name')}`);
  //     const that = this;
  //     createArtistProfile(currentUser.get('id'), params.toJS())
  //     .then(() =>{
  //       that.flashSaved()
  //       })
  //     .catch((error) => {
  //       that.flashError();
  //     })
  //   }
  // }
  onSubmit = (values) => {
    const { currentUser, updateArtistProfile, createArtistProfile } =
      this.props;
    const params = values.toJS();

    if (this.state.imgSrc) {
      params.profile_image_src = this.state.imgSrc.replace(" ", "");
    }

    this.setState({ submitting: true });

    if (currentUser.get("artist_id")) {
      const that = this;
      updateArtistProfile(currentUser.get("artist_id"), params)
        .then(() => {
          that.flashSaved();
        })
        .catch((error) => {
          console.error(error);
          that.flashError();
        });
    } else {
      params.name = `${currentUser.get("first_name")} ${currentUser.get(
        "last_name"
      )}`;
      const that = this;
      createArtistProfile(currentUser.get("id"), params)
        .then(() => {
          that.flashSaved();
        })
        .catch((error) => {
          console.error(error);
          that.flashError();
        });
    }
  };

  dropCallback = (f) => {
    let reader = new FileReader();

    reader.addEventListener("load", () => {
      this.setState({ imgSrc: reader.result });
    });
    reader.readAsDataURL(f[0]);
  };

  renderField = ({
    input,
    placeholder,
    type,
    labelName,
    meta: { touched, error },
  }) => {
    return (
      <div>
        <label className="sm-field-label">{labelName}</label>
        <div>
          <input {...input} placeholder={placeholder} type={type} />
          <div className="sm-error-message">
            {touched && error ? error : ""}
          </div>
        </div>
      </div>
    );
  };

  renderTextAreaField(field) {
    return (
      <div>
        <label className="sm-field-label">{field.labelName}</label>
        <textarea type="text" rows="3" cols="50" {...field.input} />
        <div className="sm-error-message">
          {field.meta.touched && field.meta.error ? "Please add a bio!" : ""}
        </div>
      </div>
    );
  }

  render() {
    const { currentUser, handleSubmit } = this.props;
    const artist = this.props.artists.get(
      currentUser.get("artist_id"),
      Immutable.Map()
    );
    {
      /* remove the following conditional? */
    }
    if (!currentUser.get("paid_artist_account")) {
      return <ArtistPayment stripeKey={this.props.stripeKey} />;
    } else {
      return (
        <div className="sm-edit-profile sm-content">
          <form
            className="sm-form"
            onSubmit={handleSubmit(this.onSubmit.bind(this))}
          >
            <div className="profile-item-wrap d-flex">
              <div className="sm-edit-profile-item">
                <ProfileImageUploader
                  name="profile_photo"
                  imgPreview={this.state.imgSrc}
                  currentImg={artist.get("profile_photo")}
                  dropCallback={this.dropCallback}
                  dropzoneOptions={{
                    multiple: false,
                    accept: "image/*",
                  }}
                />
              </div>

              <div className="sm-edit-profile-item">
                <div className="field-wrap">
                  <Field
                    labelName="Secret Key:"
                    name="secret_key"
                    type="text"
                    component={this.renderField}
                    validate={required}
                    normalize={(trimSpaces, lower)}
                  />
                  <a
                    href="/artist-guide#ag-secret-key"
                    className="sm-form-help"
                  >
                    What is a secret key?
                  </a>
                </div>

                {/* </div> */}

                {/* <div className="sm-edit-profile-item"> */}
                <div className="field-wrap">
                  <Field
                    labelName="Artist Name:"
                    name="name"
                    type="text"
                    component={this.renderField}
                    validate={required}
                  />
                </div>
                <div className="field-wrap">
                  <Field
                    labelName="Booking Email:"
                    name="contact_email"
                    type="text"
                    component={this.renderField}
                    validate={required}
                  />
                </div>
                <div className="field-wrap">
                  <Field
                    labelName="Bio:"
                    name="bio"
                    component={this.renderTextAreaField}
                    validate={required}
                  />
                </div>
              </div>
            </div>

            <div className="sm-edit-profile-item">
              <h4>Tattoo Shop Information</h4>
              <div className="shop-field-wrap">
                <div className="field-wrap">
                  <Field
                    labelName="Shop Name:"
                    name="business_name"
                    type="text"
                    placeholder="Shop Name"
                    component={this.renderField}
                    validate={required}
                  />
                </div>
                <div className="field-wrap">
                  <Field
                    labelName="Shop Address:"
                    name="business_address"
                    type="text"
                    placeholder="Address"
                    component={this.renderField}
                    validate={required}
                  />
                </div>
                <div className="field-wrap">
                  <Field
                    labelName="Shop City:"
                    name="business_city"
                    type="text"
                    placeholder="City"
                    component={this.renderField}
                    validate={required}
                  />
                </div>
                <div className="field-wrap">
                  <Field
                    labelName="Shop State:"
                    name="business_state"
                    type="text"
                    placeholder="State"
                    component={this.renderField}
                    validate={required}
                  />
                </div>
                <div className="field-wrap">
                  <Field
                    labelName="Shop Zip:"
                    name="business_zip"
                    type="text"
                    placeholder="Zip"
                    component={this.renderField}
                    validate={required}
                  />
                </div>
                <div className="field-wrap">
                  <Field
                    labelName="Shop Phone:"
                    name="business_phone"
                    type="text"
                    placeholder="Phone"
                    component={this.renderField}
                    validate={required}
                  />
                </div>
                <div className="field-wrap">
                  <Field
                    labelName="Shop Website:"
                    name="business_website"
                    type="text"
                    placeholder="Website (Include http://)"
                    component={this.renderField}
                    validate={required}
                    normalize={(trimSpaces, lower)}
                  />
                </div>
              </div>
            </div>

            <div className="sm-edit-profile-item">
              <h4 className="mb-4">Social Media</h4>
              <p className="custom-spacing">
                Please enter social media usernames WITHOUT the @ symbol.
                <br />
                Make sure there are no spaces after your username.
              </p>
              <div className="website-social-wrap">
                <div className="field-wrap">
                  <Field
                    labelName="Twitter Profile Name:"
                    name="twitter"
                    type="text"
                    component={this.renderField}
                    validate={socialHandleValidate}
                    normalize={(trimSpaces, lower)}
                  />
                </div>
                <div className="field-wrap">
                  <Field
                    labelName="Instagram Profile Name:"
                    name="instagram"
                    type="text"
                    component={this.renderField}
                    validate={socialHandleValidate}
                    normalize={(trimSpaces, lower)}
                  />
                </div>
                <div className="field-wrap">
                  <Field
                    labelName="Facebook Profile Name (not URL):"
                    name="facebook"
                    type="text"
                    component={this.renderField}
                    validate={fbNameValidate}
                    normalize={(trimSpaces, lower)}
                  />
                </div>
              </div>
            </div>

            {this.state.saved && (
              <div className="sm-success-message">
                {" "}
                Your Profile has been saved!{" "}
              </div>
            )}
            {this.state.error && (
              <div className="sm-error-message">
                {" "}
                There was an error saving your profile. Please check your address details or contact support.{" "}
              </div>
            )}
            <button className="btn btn-tan" type="submit">
              {this.state.submitting ? "Saving..." : "Save Profile"}
            </button>
          </form>
        </div>
      );
    }
  }
}

const ReduxFormEditProfile = reduxForm({
  form: "EditProfile",
  enableReinitialize: true,
})(EditProfile);

const initialValuesFromStore = (store) => {
  const currentUser = store.get("currentUser");
  if (!currentUser) {
    return Immutable.fromJS({});
  }
  const artist = store.get("artists").get(currentUser.get("artist_id"));

  if (artist) {
    const profile_data = JSON.parse(artist?.get("profile_data"))
    if (profile_data.secret_key) {
      let profileData = store.get("artists").get(currentUser.get("artist_id"));
      if (profileData) {
        return Immutable.fromJS(JSON.parse(profileData.get("profile_data")));
      }
    }
    else {
      let application = store 
        .get("artistApplications")
        .get(currentUser.get("application_id"));
      if (application) {
        return Immutable.fromJS(JSON.parse(application.get("application_data")));
      }
    }
  } else {
    let application = store
      .get("artistApplications")
      .get(currentUser.get("application_id"));
    if (application) {
      return Immutable.fromJS(JSON.parse(application.get("application_data")));
    }
  }
};

// const mapStateToProps = (store) => ({
//   artists: store.get('artists'),
//   currentUser: store.get('currentUser'),
//   initialValues: initialValuesFromStore(store)
//   console.log(initialValues,"initialvaluesssssssssssssssssssssssssssssss")
// })
const mapStateToProps = (store) => {
  const initialValues = initialValuesFromStore(store);
  return {
    artists: store.get("artists"),
    currentUser: store.get("currentUser"),
    initialValues: initialValues,
  };
};

const mapDispatchToProps = (dispatch) => ({
  createArtistProfile: (userId, profileData) =>
    dispatch(createArtistProfile(userId, profileData)),
  updateArtistProfile: (userId, profileData) =>
    dispatch(updateArtistProfile(userId, profileData)),
  fetchApplication: (applicationId) =>
    dispatch(fetchApplication(applicationId)),
  fetchArtist: (artistId) => dispatch(fetchArtist(artistId)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ReduxFormEditProfile);
