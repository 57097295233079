import Immutable from 'immutable'

import {
  RECEIVE_USER,
  RECEIVE_USERS
} from '../actions/users';

const users = (state = Immutable.Map(), action) => {
  switch (action.type) {
    case RECEIVE_USER:
      if(action.user.href && state.get(action.user.id) && !state.get(action.user.id).get('href')) {
        return state.get(action.user.id)
      } else {
        return state.set(action.user.id, Immutable.fromJS(action.user))        
      }
    case RECEIVE_USERS:
      let tempState = state
      action.users.forEach(user => {
        tempState = tempState.set(user.id, Immutable.fromJS(user))
      })
      return tempState
    default:
      return state
  }
}

export default users
