import React from 'react';
import Select from 'react-select';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { formatDate, formatAmount } from '../../models/payments';
import { refundVoucher } from '../../actions/vouchers';
import API from '../../utils/API';

class PaymentDetails extends React.Component {

  state = {
    marker: null,
    reasonId: null,
    refundError: null,
    refundProcessing: null
  }

  refundReasons = [
    {value: 0, label: 'User didn\'t like it'},
    {value: 1, label: 'Playback didn\'t work well'},
    {value: 2, label: 'Artist did tattoo wrong'},
    {value: 3, label: 'Other'},
    {value: 4, label: 'No reason'}
  ]


  componentDidMount() {
    window.scrollTo(0,0)
    const { selectedPurchase } = this.props
    if(selectedPurchase) {
      this.props.fetchMarker(selectedPurchase.get('marker_id'))
      .then(() => this.getMarker(selectedPurchase.get('marker_id')))
    }
  }

  componentWillReceiveProps(nextProps) {
    const { selectedPurchase } = nextProps
    const currentSelectedPurchase = this.props.selectedPurchase
    const currentPurchaseId = currentSelectedPurchase ? currentSelectedPurchase.get('id') : null
    if(selectedPurchase && selectedPurchase.get('marker_id') && selectedPurchase.get('id') != currentPurchaseId) {
      this.props.fetchMarker(selectedPurchase.get('marker_id'))
      .then(() => this.getMarker(selectedPurchase.get('marker_id')))    
    }
  }

  getMarker(markerId) {
    const marker = this.props.markers.get(parseInt(markerId))
    this.setState({ marker: marker })
  }

  viewTattoo = () => {
    const { selectedUser } = this.props
    this.props.push(`/admin/users/${selectedUser.get('id')}/tattoo/${this.state.marker.get('id')}`)
  }

  getDaysBetween(date1, date2) {
    let timeDiff = Math.abs(date2.getTime() - date1.getTime());
    let diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24)); 
    return diffDays
  }

  handleChange = (value) => {
    this.setState({ reasonId: value.value })
  }

  refundPurchase = (e) => {
    const { selectedUser, selectedPurchase } = this.props 
    if (!selectedPurchase) { return null }
    if(selectedPurchase.get('payment_method') == 'Voucher') {
      this.refundVoucher(selectedPurchase, selectedUser, this.refundReasons[this.state.reasonId])
    } else {
      this.refundStripe(selectedPurchase, selectedUser, this.refundReasons[this.state.reasonId])
    }
  }

  refundVoucher = (purchase, user, reason) => {
    const { marker } = this.state 
    this.props.refundVoucher(purchase.get('id'), marker.get('id'), reason)
    .then(() => {
      this.props.fetchMarker(purchase.get('marker_id'))
      .then(() => this.getMarker(purchase.get('marker_id')))
      this.props.fetchUser(user.get('id'))
    })
  }

  refundStripe = (purchase, user, reason) => {
    const { marker } = this.state
    if(!purchase.get('charge_id')) { 
      this.setState({ 
        refundError: 'Don\'t have Stripe payment token for this purchase. Must use stripe dashboard.' 
      }) 
      return
    }
    this.setState({ refundProcessing: true })
    API.post(`/tattoo_refund/${marker.get('id')}`, { charge_id: purchase.get('charge_id'), reason: reason })
    .then((data) => {
      this.props.fetchMarker(purchase.get('marker_id'))
      .then(() => this.getMarker(purchase.get('marker_id')))
      this.props.fetchUser(user.get('id'))
      this.setState({ refundProcessing: false })
    })
    .catch((err) => {

    })
  }

  render() {
    const { selectedUser, selectedPurchase, artists } = this.props
    const { marker } = this.state
    let artist = null
    if(marker) { artist = artists.get(marker.get('artist_id')) }
    if(!selectedPurchase) { return null }

    const purchaseDate = new Date(selectedPurchase.get('purchase_date'))
    const currentDate = new Date()
    const dayDiff = this.getDaysBetween(purchaseDate, currentDate)
    let refundEligible = false
    if(dayDiff <= 15) {
      refundEligible = true
    }

    return (
      <div className="sm-admin-payment-details">
        <div className="sm-admin-title">
          <h2> Payment Details! </h2>
          <Link to={`/admin/users/${selectedUser.get('id')}`}> Back to user details </Link>
        </div>
        <div className="sm-payment-info">
          <ul>
            <li> <b> User Email: </b> {selectedUser.get('email')} </li>
            <li> <b> Purchase Date: </b> {formatDate(selectedPurchase.get('purchase_date'))} </li>
            <li> <b> Payment Description: </b> {selectedPurchase.get('description')} </li>
            <li> <b> Purchase Method: </b> {selectedPurchase.get('payment_method')} </li>
            <li> <b> Purchase Amount: </b> {formatAmount(selectedPurchase.get('amount'))} </li> 
            {selectedPurchase.get('is_refunded') && (
              <div>
                <li> <b> Purchase Status: </b> Refunded </li>
                <li> <b> Refund Date: </b> {formatDate(selectedPurchase.get('refund_date'))} </li>
                <li> <b> Refund Reason: </b> {selectedPurchase.get('refund_reason')} </li>
              </div>
            )}
            {marker && ( <li> <b> Tattoo Name: </b> "{marker.get('name')}" </li> )}
            {marker && ( <li> <b> Tattoo Status: </b> {this.props.getTattooStatus(marker)} </li> )}
          </ul>
          {selectedPurchase.get('transfers') && (
            <div className="sm-payment-transfer-history">
              <h3>Transfer History</h3>
              <table>
                <tbody>
                  <tr>
                    <td> Transferred by: </td>
                    <td> Transfer Date: </td>
                    <td> Old Marker ID: </td>
                    <td> New Marker ID: </td>
                    <td> Admin Notes: </td>
                  </tr>
                  {selectedPurchase.get('transfers').map((transfer_info, index) => (
                    <tr key={index}>
                      <td>{transfer_info.get('transferred_by')}</td>
                      <td>{formatDate(transfer_info.get('date'))}</td>
                      <td>{transfer_info.get('from')}</td>
                      <td>{transfer_info.get('to')}</td>
                      <td>{transfer_info.get('note')}</td>
                    </tr>
                  ))} 
                </tbody>
              </table>
            </div>
          )}
          {marker && (
            <button className="btn btn-tan btn-large" onClick={this.viewTattoo}>
              View Tattoo Details
            </button>
          )}
        </div>
        <hr />
        {marker && !selectedPurchase.get('is_refunded') && (
          <div className="sm-payment-refund">
            <h3> Refund Purchase </h3> 
            <p> This purchase was {dayDiff} day(s) ago 
            {refundEligible ? ' and is eligible for a refund' : 'and is not eligible for refund.'}
            </p>
            { artist && (
              <div className="artist-info">
                <p> <b> Tattoo Artist: </b> {artist.get('name')} </p>
                <p> <b> Artist Email: </b> {artist.get('email')} </p>
                <p> <b> Artist ID: </b> {artist.get('id')} </p>
                <p> <b> Artist's UserID: </b> {artist.get('user_id')} </p>
              </div>
            )}
            <div className="refund-selector">
              <Select value={this.state.reasonId} options={this.refundReasons} onChange={this.handleChange} />
              {this.state.refundError && (
                <div className='sm-error-message'>
                  {this.state.refundError}
                </div>
              )}
              <button className="btn btn-tan btn-large" onClick={this.refundPurchase}>
                {this.state.refundProcessing ? 'Processing...' : 'Refund'}
              </button>
            </div>
          </div>
        )}
      </div>
    )
  }
}

const mapStateToProps = (store) => ({
  markers: store.get('markers'),
  artists: store.get('artists')
})

const mapDispatchToProps = dispatch => ({
  refundVoucher: (voucherId, markerId, reason) => dispatch(refundVoucher(voucherId, markerId, reason))
})

export default connect(mapStateToProps, mapDispatchToProps)(PaymentDetails)