import React from 'react';
import StripeWrapper from '../../checkout/StripeWrapper';

class TattooBillingPrepay extends React.Component {
  state = {
    imgSrc: null
  }

  componentDidMount() {
    this.loadImage();
  }

  loadImage() {
    fetch(this.props.marker.get('tattooImageURL'))
    .then(res => {
      return res.blob()
    })
    .then(blob => {
      const reader = new FileReader()

      reader.addEventListener('load', () => {
        this.setState({ imgSrc: reader.result })
      })

      reader.readAsDataURL(blob)
    })
  }

  render() {
    const { imgSrc } = this.state
    const { marker } = this.props
    let loading = false;
    if(!imgSrc) { loading = true }

    return (
      <div className="sm-tattoo-billing-prepay">
        <h3>Get 5 years free!</h3>
        <h3>When you pre-purchase 5 years of your</h3>
        <h2>Soundwave Tattoo subscription</h2>
        <p>Pre-pay to save and relax for 10 more years.</p>
        { loading ? (
          <img src={marker.get('imageURL')} className='tattoo-img' />
        ) : (
          <img src={imgSrc} className='tattoo-img' />
        )} 
        <h3>{marker.get('name')}</h3>
        <p> Renews </p>
        <p> We want to offer you the opportunity to get 50% off the next 10 years
        of your Soundwave Tattoo subscription by pre-paying. For a limited time,
        save $50 now for ten years of your Soundwave Tattoo subscription.</p>
        <p><span className="strike-through">$99</span> $49.99</p>
        <StripeWrapper
          stripeKey={this.props.stripeKey}
          nameOnly={true}
          amount={4999}
          submitButtonText={'Complete Purchase'}
          containerClass='sm-tattoo-billing-prepay-form'
          currentUser={this.props.currentUser}
          paymentURL={`/tattoo_payment/${marker.get('id')}`}
          handleResponse={this.checkoutComplete} >
        </StripeWrapper>

      </div>
    )
  }
}

export default TattooBillingPrepay