import $ from 'jquery';

class Base {

  jsonBlob = {};  
  url = ''; 

  constructor(dict) {
    this.jsonBlob = dict;
  }

  get(key, defaultValue) {
    if(this.jsonBlob[key]) {
      return this.jsonBlob[key];      
    } else {
      return defaultValue;
    }
  }

  set(key, value) {
    this.jsonBlob[key] = value; 
  }

  save() {
    return new Promise((resolve, reject) => {
      $.ajax({
        type: 'POST',
        dataType: 'json',
        contentType: 'application/json',
        url: this.url,
        data: JSON.stringify(this.jsonBlob),
        success: () => {},
        error: () => {
          resolve("Server error, please contact support.")
        }
      }).done((data) => {
        if(data.error) {
          resolve(data.error);
        }
        this.jsonBlob = data; 
        resolve(); 
      });
    })
  }

  update(dict) {
    return new Promise((resolve, reject) => {
      $.ajax({
        type: 'PATCH',
        dataType: 'json',
        contentType: 'application/json',
        url: `${this.url}/${this.get('id')}`,
        data: JSON.stringify(dict),
        success: (user) => {
          this.jsonBlob = user; 
          resolve(user);
        }, 
        error: (error) => {
          resolve({error: 'Server Error!'}); 
        }
      });
    });
  }

  fetch() {
    return new Promise((resolve, reject) => {
      $.getJSON(`${this.url}/${this.get('id')}`, (data) => {
        this.jsonBlob = data; 
        resolve(data); 
      });
    })
  }

  fetchFor(id) {
    return new Promise((resolve, reject) => {
      $.getJSON(`${this.url}/${id}`, (data) => {
        this.jsonBlob = data;
        resolve(data); 
      });
    })
  }

}

export default Base