import API from '../utils/API'

export const RECEIVE_CAMPAIGNS = 'CAMPAIGNS/RECEIVE_CAMPAIGNS'
export const RECEIVE_CAMPAIGN  = 'CAMPAIGNS/RECEIVE_CAMPAIGN'

export const receiveCampaigns = campaigns => ({
  type: RECEIVE_CAMPAIGNS,
  campaigns
})

export const receiveCampaign = campaign => ({
  type: RECEIVE_CAMPAIGN,
  campaign
})

export const createCampaign = (campaignPayload) => 
  dispatch => {
    return API.post('/campaigns', campaignPayload)
    .then(campaign => {
      dispatch(receiveCampaign(campaign))
      return Promise.resolve()
    })
    .catch(() => {
      return Promise.reject()
    })
  }

export const fetchCampaigns = () =>
  dispatch => {
    return API.get('/campaigns')
    .then(campaigns => {
      dispatch(receiveCampaigns(campaigns))
      return Promise.resolve()
    })
    .catch(() => {
      return Promise.reject()
    })
  }

export const fetchCampaign = (campaignId) =>
  dispatch => {
    return API.get(`/campaigns/${campaignId}`)
    .then(campaign => {
      dispatch(receiveCampaign(campaign))
      return Promise.resolve()
    })
    .catch(() => {
      return Promise.reject()
    })
  }

export const addUserToCampaign = (email, campaignId) => 
  dispatch => {
    return API.patch(`/campaigns/${campaignId}`, { email: email })
    .then(campaign =>  {
      dispatch(receiveCampaign(campaign))
      return Promise.resolve(campaign)
    })
    .catch(() => {
      return Promise.reject()
    })
  }

export const completeCampaignSignup = (campaignId, signupData) => 
  dispatch => {
    return API.patch(`/campaigns/${campaignId}`, signupData)
    .then(campaign => {
      dispatch(receiveCampaign(campaign))
      return Promise.resolve()
    })
    .catch(() => {
      return Promise.reject()
    })
  }