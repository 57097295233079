import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import {
  reactivateMarker,
  updateMarker,
  deleteMarker,
} from "../../actions/markers";
import { formatDate } from "../../models/payments";
import ConfigureOverlay from "./activation/ConfigureOverlay";

class TattooDetails extends React.Component {
  state = {
    imgSrc: null,
    maskSrc: null,
    editAnimation: false,
  };

  componentDidMount() {
    const { objId, adminFunc } = this.props;
    this.props.fetchMarker(parseInt(objId)).then(() => {
      if (adminFunc == "animate") {
        this.editAnimation();
      }
    });
  }

  reactivate = () => {
    const { objId } = this.props;
    this.props.reactivateMarker(parseInt(objId));
  };

  editAnimation = () => {
    const { markers, objId } = this.props;
    const marker = markers.get(parseInt(objId));

    this.loadImage(marker);
    this.loadMask(marker);
    this.setState({ editAnimation: true });
    window.scrollTo(0, 0);
  };

  exitAnimationMode = () => {
    this.setState({ editAnimation: false });
  };

  loadImage = (marker) => {
    fetch(marker.get("tattooImageURL"))
      .then((res) => {
        return res.blob();
      })
      .then((blob) => {
        let reader = new FileReader();

        reader.addEventListener("load", () => {
          this.setState({ imgSrc: reader.result });
        });
        reader.readAsDataURL(blob);
      });
  };

  loadMask = (marker) => {
    fetch(marker.get("overlayURL"))
      .then((res) => {
        return res.blob();
      })
      .then((blob) => {
        let reader = new FileReader();

        reader.addEventListener("load", () => {
          this.setState({ maskSrc: reader.result });
        });
        // console.log('reading mask image')
        reader.readAsDataURL(blob);
      });
  };

  toggleMarkerAssignment = () => {
    const { currentUser, objId } = this.props;
    let myMarkers = currentUser.get("marker_ids");
    let markersArray = myMarkers.toJS();
    if (myMarkers.includes(parseInt(objId))) {
      const newMarkers = myMarkers.filter((item) => item != parseInt(objId));
      this.props.updateUser(currentUser.get("id"), {
        marker_ids: newMarkers.toJS(),
      });
    } else {
      markersArray.push(parseInt(objId));
      this.props.updateUser(currentUser.get("id"), {
        marker_ids: markersArray,
      });
    }
  };

  assignButtonText = () => {
    const { currentUser, objId } = this.props;
    let myMarkers = currentUser.get("marker_ids");
    if (myMarkers.includes(parseInt(objId))) {
      return "Unassign From My Account";
    } else {
      return "Assign To My Account";
    }
  };

  deleteMarkerButton = () => {
    const { markers, objId } = this.props;
    const marker = markers.get(parseInt(objId));
    this.props.deleteMarker(marker.get("id")).then(() => {
      console.log("Creator ", marker.get("created_by_user"));
      this.props.push(`/admin/users/${marker.get("created_by_user")}`);
    });
  };

  render() {
    const { selectedUser, selectedPurchase, objId, markers } = this.props;
    if (!markers) {
      return null;
    }
    const marker = markers.get(parseInt(objId));
    if (!marker) {
      return null;
    }
    const artist = this.props.artists.get(marker.get("artist_id"));

    return this.state.editAnimation ? (
      <div className="sm-admin-tattoo-details">
        {this.state.imgSrc && this.state.maskSrc ? (
          <ConfigureOverlay
            updateMarker={this.props.updateMarker}
            marker={marker}
            imgSrc={this.state.imgSrc}
            maskSrc={this.state.maskSrc}
            reAnimate={true}
            exitAnimationMode={this.exitAnimationMode}
          />
        ) : (
          <div className="sm-admin-loading">
            <p> Loading Tattoo Assets... </p>
            <div className="sm-loader"></div>
          </div>
        )}
      </div>
    ) : (
      <div className="sm-admin-tattoo-details">
        <div className="sm-admin-title">
          <h2> Tattoo Details </h2>
          {selectedPurchase ? (
            <Link
              to={`/admin/users/${selectedUser.get(
                "id"
              )}/payment/${selectedPurchase.get("id")}`}
            >
              Back to payment details
            </Link>
          ) : (
            <Link to={`/admin/users/${selectedUser.get("id")}`}>
              Back to user details
            </Link>
          )}
        </div>
        <div className="tattoo-details">
          <ul>
            <li>
              {" "}
              <b> User Email: </b> {selectedUser.get("email")}{" "}
            </li>
            {selectedPurchase && (
              <li>
                {" "}
                <b> Purchase Date: </b>{" "}
                {formatDate(selectedPurchase.get("purchase_date"))}{" "}
              </li>
            )}
            <li>
              {" "}
              <b> Tattoo Name: </b> {marker.get("name")}{" "}
            </li>
            {artist && (
              <div>
                <li>
                  {" "}
                  <b> Tattoo Artist: </b> {artist.get("name")}{" "}
                </li>
                <li>
                  {" "}
                  <b> Artist Email: </b> {artist.get("email")}{" "}
                </li>
                <li>
                  {" "}
                  <b> Artist ID: </b> {artist.get("id")}{" "}
                </li>
                <li>
                  {" "}
                  <b> Artist's UserID: </b> {artist.get("user_id")}{" "}
                </li>
              </div>
            )}
            <li>
              {" "}
              <b> Tattoo Status: </b> {this.props.getTattooStatus(marker)}{" "}
            </li>
            <li>
              {" "}
              <b> Review: </b> Coming soon!{" "}
            </li>
          </ul>
        </div>
        <div className="tattoo-img">
          <img
            src={marker.get("imageURL")}
            height={marker.get("height")}
            width={marker.get("width")}
          />
        </div>
        {marker.get("is_complete") && (
          <div className="admin-tattoo-actions">
            <div className="reactivate-tattoo">
              <p>
                {" "}
                If the mask is done poorly (notify user of up to 24-48 hours for
                re-activation){" "}
              </p>
              <button
                className="btn btn-tan btn-large"
                onClick={this.reactivate}
              >
                Re-Activate Tattoo
              </button>
            </div>
            <div className="edit-animation">
              <p>
                {" "}
                If the animation playback is not timed correctly. Fix it
                yourself now.{" "}
              </p>
              <button
                className="btn btn-tan btn-large"
                onClick={this.editAnimation}
              >
                Edit Tattoo Animation
              </button>
            </div>
            <div className="self-assign">
              <p>
                {" "}
                To test the tattoo using your phone (must be logged in to this
                account){" "}
              </p>
              <button
                className="btn btn-tan btn-large"
                onClick={this.toggleMarkerAssignment}
              >
                {this.assignButtonText()}
              </button>
            </div>
          </div>
        )}
        <div className="delete-marker">
          <p>
            To delete this tattoo permanently (This process is not reversible)
          </p>
          <button
            className="btn btn-tan btn-large"
            onClick={this.deleteMarkerButton}
          >
            Delete Tattoo
          </button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (store) => ({
  markers: store.get("markers"),
  artists: store.get("artists"),
  currentUser: store.get("currentUser"),
});

const mapDispatchToProps = (dispatch) => ({
  reactivateMarker: (markerId) => dispatch(reactivateMarker(markerId)),
  updateMarker: (markerId, markerParams) =>
    dispatch(updateMarker(markerId, markerParams)),
  deleteMarker: (markerId) => dispatch(deleteMarker(markerId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TattooDetails);
