import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { logoutUser } from "../../actions/currentUser";
import { hasAdminPrivs, isArtist, hasActivatorPrivs } from "../../models/user";

class Nav extends Component {
  render() {
    if (!this.props.currentUser) {
      return null;
    }
    const {
      currentUser,
      location: { pathname },
    } = this.props;
    // debugger
    return (
      <div className="sm-left-nav">
        <nav className="sm-nav">
          {hasAdminPrivs(currentUser) && (
            <div className="navSection">
              <Link to="/admin">
                <div
                  data-path="/admin"
                  className={`sm-nav__item${
                    pathname == "/admin" ? " selected" : ""
                  }`}
                >
                  Admin Panel
                </div>
              </Link>
              <Link to="/admin/campaigns">
                <div
                  data-path="/admin/campaigns"
                  className={`sm-nav__item${
                    pathname == "/admin/campaigns" ? " selected" : ""
                  }`}
                >
                  Manage Campaigns
                </div>
              </Link>
            </div>
          )}
          {hasActivatorPrivs(currentUser) && (
            <div className="navSection">
              <Link to="/admin/manage-tattoos">
                <div
                  data-path="/admin/manage-tattoos"
                  className={`sm-nav__item${
                    pathname == "/admin/manage-tattoos" ? " selected" : ""
                  }`}
                >
                  Activation Queue
                </div>
              </Link>
            </div>
          )}
          <div className="navSection">
            <Link to="/new-soundwave">
              <div
                data-path="/new-soundwave"
                className={`sm-nav__item${
                  pathname == "/new-soundwave" ? " selected" : ""
                }`}
              >
                New Soundwave
              </div>
            </Link>
            <Link to="/my-soundwaves">
              <div
                data-path="/my-soundwaves"
                className={`sm-nav__item${
                  pathname.includes("soundwaves") ? " selected" : ""
                }`}
              >
                My Soundwaves
              </div>
            </Link>
            <Link to="/my-tattoos">
              <div
                data-path="/my-tattoos"
                className={`sm-nav__item${
                  pathname.includes("my-tattoos") ? " selected" : ""
                }`}
              >
                My Tattoos
              </div>
            </Link>
          </div>
          {isArtist(currentUser) && (
            <div className="navSection">
              <Link to="/artist-dashboard">
                <div
                  data-path="/artist-dashboard"
                  className={`sm-nav__item${
                    pathname == "/artist-dashboard" ? " selected" : ""
                  }`}
                >
                  Artist Dashboard
                </div>
              </Link>
              <Link to="/artist-guide">
                <div
                  data-path="/artist-guide"
                  className={`sm-nav__item${
                    pathname == "/artist-guide" ? " selected" : ""
                  }`}
                >
                  Artist Guide
                </div>
              </Link>
              <Link to="/artist-profile">
                <div
                  data-path="/artist-profile"
                  className={`sm-nav__item${
                    pathname == "/artist-profile" ? " selected" : ""
                  }`}
                >
                  My Artist Profile
                </div>
              </Link>
            </div>
          )}
          <div className="navSection">
            <Link to="/account-settings">
              <div
                data-path="/account-settings"
                className={`sm-nav__item${
                  pathname == "/account-settings" ? " selected" : ""
                }`}
              >
                Account Settings
              </div>
            </Link>
            <Link to="/change-password">
              <div
                data-path="/change-password"
                className={`sm-nav__item${
                  pathname == "/change-password" ? " selected" : ""
                }`}
              >
                Change Password
              </div>
            </Link>
            <Link to="/payments">
              <div
                data-path="/payments"
                className={`sm-nav__item${
                  pathname == "/payments" ? " selected" : ""
                }`}
              >
                Payments
              </div>
            </Link>
            {currentUser.get("unused_voucher_count") > 0 && (
              <Link to="/vouchers">
                <div
                  data-path="/vouchers"
                  className={`sm-nav__item${
                    pathname == "/vouchers" ? " selected" : ""
                  }`}
                >
                  Vouchers
                </div>
              </Link>
            )}
          </div>
          <div className="navSectionEnd">
            <Link to="/" onClick={this.props.logoutUser}>
              <div className="sm-nav__item">Logout</div>
            </Link>
          </div>
        </nav>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  logoutUser: () => dispatch(logoutUser()),
});

export default connect(null, mapDispatchToProps)(Nav);
