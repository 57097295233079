import React from 'react'
// import API from 'utils/API'
import { drawCanvas } from '../../utils/playback_helpers'

const WIDTH_BUFFER = 0
const HEIGHT_BUFFER = 0



// NOTE -- Nate hid the overlay canvas (maybe more?) in the SCSS
// so if/when this component ever gets re-incorported anywhere, 
// that's why the playback stuff doesn't show up. Just a heads up. 

class TattooPreview extends React.Component {
  state = {
    drawingRefs: {
      maskCanvas: null,
      maskContext: null,
    },
    mask: null,
    audioSrc: null,
    audioEl: null,
    animationStart: null,
  }

  componentDidMount () {
    this.sourceImage.onload = () => {
      const height = this.sourceImage.height + HEIGHT_BUFFER

      const maskCanvas = this.maskCanvas
      maskCanvas.height = height
      const maskContext = maskCanvas.getContext('2d')
      this.setState({
        drawingRefs: {
          maskCanvas: maskCanvas,
          maskContext: maskContext,
        }
      })
    }

    const mask = new Image

    mask.onload = () => {
      this.setState({
        mask
      })
    }

    mask.src = this.props.maskSrc


  }

  componentDidUpdate () {
    const hasDrawingRefs = this.state.drawingRefs.maskCanvas &&
                            this.state.drawingRefs.maskContext

    const hasDrawingData = this.state.mask

    if (hasDrawingRefs && hasDrawingData) { this.drawCanvas() }
    if (this.props.marker && !this.state.audioSrc) { this.loadAudio() }
    if (this.state.audioSrc && !this.state.audioEl) {
      const audioEl = new Audio(this.state.audioSrc)

      audioEl.addEventListener('playing', () => {
        this.setState({ animationStart: performance.now() })
        window.requestAnimationFrame(this.playAnimation)
      })

      audioEl.addEventListener('ended', () => {
        this.setState({ animationStart: null })
      })

      if (this.props.autoPlay) {
        this.setState({ audioEl }, this.previewPlayback)
      } else {
        this.setState({ audioEl })
      }
    }
  }

  previewPlayback = () => {
    if (!this.state.audioEl) { return }
    this.state.audioEl.play()
  }

  playAnimation = (step) => {
    if (!this.state.animationStart) {
      this.drawCanvas()
      return
    }

    this.drawCanvas(step)
    window.requestAnimationFrame(this.playAnimation)
  }

  loadAudio = () => {
    fetch(this.props.marker.get('audioURL'))
    .then((res) => { return res.blob() })
    .then(blob => {
      let reader = new FileReader();

      reader.addEventListener('load', () => {
        this.setState({ audioSrc: reader.result })
      });

      reader.readAsDataURL(blob)
    })
  }

  drawCanvas = (step = null) => {
    const {
      drawingRefs: {
        maskCanvas: canvas,
        maskContext: context,
      },
      mask,
      audioEl,
      animationStart,
    } = this.state

    const params = {
      canvas,
      context,
      mask,
      audioEl,
      playbackData: this.props.overlayData.toJS(),
      animationStart,
      widthBuffer: WIDTH_BUFFER,
      heightBuffer: HEIGHT_BUFFER,
      sourceImage: this.sourceImage,
    }

    drawCanvas(params, step)
  }

  beginPreview = () => {
    if (!this.state.animationStart) {
      this.previewPlayback()
    }
  }

  render () {
    const {
      imgSrc
    } = this.props
    return (
      <div className='sm-tattoo-preview'>
        <div className='tattoo-preview-wrapper'>
          <img
            className='tattoo-img'
            src={imgSrc}
            ref={(sourceImage) => { this.sourceImage = sourceImage }}
          />
          <canvas
            className='mask-playback-canvas'
            width='800'
            ref={(canvas) => { this.maskCanvas = canvas }}
          />
          {
            !this.state.animationStart &&
            <img
              className='play-button'
              src='https://cdn.skinmotion.com/images/general/play-button-overlay.png'
              onClick={this.beginPreview}
            />
          }

        </div>
      </div>
    )
  }
}

export default TattooPreview
