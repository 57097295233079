export const RECEIVE_MOBILE_MODE = "MOBILE_MODE/RECEIVE_MOBILE_MODE";
export const UNSET_MOBILE_MODE = "MOBILE_MODE/UNSET_MOBILE_MODE";

export const receiveMobileMode = () => ({
  type: RECEIVE_MOBILE_MODE
})

export const unsetMobileMode = () => ({
  type: UNSET_MOBILE_MODE
})

export const setMobileMode = () => 
  dispatch => {
    return dispatch(receiveMobileMode())
  }

export const closeMobileMode = () =>
  dispatch => {
    return dispatch(unsetMobileMode())
  }