import API from '../utils/API';
import { firebaseObj } from '../utils/firebase';
import Immutable from 'immutable';
import { DESTROY_CURRENT_USER, SET_CURRENT_USER } from './constants';

export const setCurrentUser = (user) => ({
    type: SET_CURRENT_USER,
    user,
});

export const destroyUser = () => ({
    type: DESTROY_CURRENT_USER,
});

export const fetchCurrentUser = (userId) => (dispatch) => {
    return API.get(`/users/${userId}`).then((data) => {
        dispatch(setCurrentUser(data));
        return Promise.resolve(Immutable.fromJS(data));
    });
};

export const signinUser = (credentials) => (dispatch) => {
    return API.post(`/login`, credentials)
        .then((data) => {
            dispatch(setCurrentUser(data));
            return Promise.resolve();
        })
        .catch((error) => {
            return Promise.reject();
        });
};

export const signupUser = (userParams) => (dispatch) => {
    return API.post('/users', userParams)
        .then((data) => {
            if (data.error) {
                return Promise.reject(new Error(data.error));
            } else {
                dispatch(setCurrentUser(data));
                return Promise.resolve();
            }
        })
        .catch(() => Promise.reject());
};

export const logoutUser = () => (dispatch) => {
    return API.get('/logout').then(() => {
        firebaseObj.signOut();
        dispatch(destroyUser());
    });
};

export const updateUser = (userId, userParams) => (dispatch) => {
    return API.patch(`/users/${userId}`, userParams)
        .then((user) => dispatch(setCurrentUser(user)))
        .catch(() => Promise.reject());
};
