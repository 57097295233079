import Immutable from 'immutable'
import {
  RECEIVE_TOP_REFERRERS,
} from '../actions/topReferrers'

const topReferrers = (state = Immutable.List(), action) => {
  switch (action.type) {
    case RECEIVE_TOP_REFERRERS:
      return Immutable.fromJS(action.topReferrers)
    default:
      return state
  }
}

export default topReferrers
