import React, { Component } from "react";
// import serialize from 'form-serialize';
import { connect } from "react-redux";
import { updateUser } from "../../actions/currentUser";
import { formatDate, formatAmount } from "../../models/payments";
import API from "../../utils/API";

class Payments extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  state = {
    isLoading: false,
  };
  pad(n, width, z) {
    if (!isNaN(n)) {
      z = z || "0";
      n = n + "";
      return n.length >= width
        ? n
        : new Array(width - n.length + 1).join(z) + n;
    }
  }

  render() {
    const { currentUser } = this.props;
    // const notyf = new Notyf({position:{x:'right',y:'top'}});
    // console.log({currentUser : currentUser.get('email')});
    return (
      <div className="sm-payments common-padding">
        <h3 className="mb-1">Manage Payment Profile</h3>
        <button
          type="submit"
          className="btn btn-tan btn-sm mt-1 mx-0 mb-4 fs-6"
          style={{ minWidth: "225px", minHeight: "52px" }}
          disabled={this.state.isLoading}
          onClick={async () => {
            this.setState({ isLoading: true });
            try {
              let res = await API.post("/create-customer-portal-session", {
                email: currentUser.get("email"),
              });
              this.setState({ isLoading: false });

              if (res.url) window.open(res.url, "_blank");
              else notyf.error("No data found!");
              // setTimeout(()=>{

              // }, 1000)
            } catch (error) {
              notyf.error("Some thing went wrong!");
              this.setState({ isLoading: false });
              console.log({ error });
            }
          }}
        >
          {this.state.isLoading ? (
            <div className="spinner-border text-dark" role="status"></div>
          ) : (
            <b>View Customer Portal </b>
          )}
        </button>

        <div className="payment-history">
          <hr
            style={{
              color: "grey",
              backgroundColor: "grey",
              height: 2,
            }}
          />
          <h3>Payment History</h3>
          {currentUser.get("purchases").size ? (
            <table>
              <tbody>
                <tr>
                  <th>Order ID</th>
                  <th>Datetime</th>
                  <th>Description of Purchase</th>
                  <th>Amount</th>
                  <th>Payment Method</th>
                </tr>
                {currentUser
                  .get("purchases")
                  .map((purchase, index) => {
                    return (
                      <tr key={index}>
                        <td>{this.pad(index, 5)}</td>
                        <td>{formatDate(purchase.get("purchase_date"))}</td>
                        <td>{purchase.get("description")}</td>
                        <td>{formatAmount(purchase.get("amount"))}</td>
                        <td>{purchase.get("payment_method")}</td>
                      </tr>
                    );
                  })
                  .valueSeq()}
              </tbody>
            </table>
          ) : (
            <div> You do not have any purchases. </div>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (store) => ({
  currentUser: store.get("currentUser"),
  mobileMode: store.get("mobileMode"),
});

const mapDispatchToProps = (dispatch) => ({
  updateUser: (userId, userParams) => dispatch(updateUser(userId, userParams)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Payments);
