import Immutable from "immutable";
import API from "./API";

export const ipGeoWrapper = () => {
  return new Promise((resolve, reject) => {
    API.get("/geobyip")
      .then((data) => {
        if (data.lat && data.long) {
          const location = {
            lat: parseFloat(data.lat),
            lng: parseFloat(data.long),
          };
          resolve(location);
        } else {
          reject();
        }
      })
      .catch(() => {
        reject();
      });
  });
};

// Currently unused, but in case we need a way to get geo data
// from the client's browser...
export const browserGeoWrapper = () => {
  return new Promise((resolve, reject) => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const location = {
            lat: parseFloat(position.coords.latitude),
            lng: parseFloat(position.coords.longitude),
          };
          resolve(Immutable.fromJS(location));
        },
        (err) => {
          reject();
        }
      );
    } else {
      reject();
    }
  });
};

// export const geocodeWrapper = (addressOrZip) => {
//   debugger
//   return new Promise((resolve, reject) => {

//     let geocoder = new window.google.maps.Geocoder();

//     geocoder.geocode({ address: addressOrZip }, (results, status) => {
//       console.log(results)
//       debugger
//       if (status == window.google.maps.GeocoderStatus.OK) {
//         debugger
//         const location = {

//           lat: parseFloat(results[0].geometry.location.lat()),
//           lng: parseFloat(results[0].geometry.location.lng()),
//         };
//         resolve(Immutable.fromJS(location));
//       } else {
//        reject('geocodeWrapper ' + status);
//       }
//     });
//     return resolve(Immutable.fromJS({

//       lat: parseFloat(23.030357),
//       lng: parseFloat(72.517845),
//     }))
//   });
// };
export const geocodeWrapper = (addressOrZip) => {
  // debugger
  return new Promise((resolve, reject) => {
    let geocoder = new window.google.maps.Geocoder();
    geocoder.geocode({ address: addressOrZip }, (results, status) => {
      // debugger
      if (status == window.google.maps.GeocoderStatus.OK) {
        const location = {
          lat: parseFloat(results[0].geometry.location.lat()),
          lng: parseFloat(results[0].geometry.location.lng()),
        };
        resolve(Immutable.fromJS(location));
      } else {
        reject("geocodeWrapper " + status);
      }
    });
  });
};

export const reverseGeocode = (location) => {
  return new Promise((resolve, reject) => {
    let geocoder = new window.google.maps.Geocoder();
    geocoder.geocode({ location: location.toJS() }, (results, status) => {
      if (status == window.google.maps.GeocoderStatus.OK) {
        resolve(results[0]);
      }
    });
  });
};

export const getDistance = (userLocation, artist) => {
  let distanceFrom = null;
  if (userLocation.get("search")) {
    distanceFrom = userLocation.get("search").get("location");
  } else {
    distanceFrom = userLocation.get("user");
  }
  let artistLocation = artist.get("location");
  // console.log('artistLocation', artistLocation)
  let distance = 0;
  if (distanceFrom && artistLocation) {
    distance = calcDistance(distanceFrom, artistLocation);
  }
  return distance;
};

const rad = (x) => {
  return (x * Math.PI) / 180;
};

// Warning! This is from some random StackOverflow. Grain of salt!
const calcDistance = (p1, p2) => {
  p1 = p1.toJS();
  p2 = p2.toJS();
  let R = 3959; // Earth's mean radius in miles (according to window.google)
  let dLat = rad(p2.lat - p1.lat);
  let dLong = rad(p2.lng - p1.lng);
  let a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(rad(p1.lat)) *
      Math.cos(rad(p2.lat)) *
      Math.sin(dLong / 2) *
      Math.sin(dLong / 2);
  let c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  let d = R * c;
  return d.toFixed(1); // The distance in miles.
};
