import React, { Component } from 'react'

import Slider from 'rc-slider'
import 'rc-slider/assets/index.css'
// import API from 'utils/API'

import { drawCanvas } from '../../../utils/playback_helpers'

const PLAYBACK_MARKER_SIZE = 25
const HALF_PLAYBACK_MARKER_SIZE = PLAYBACK_MARKER_SIZE/2
// const CANVAS_WIDTH = 600
// const CANVAS_HEIGHT = 400
// const FULL_CANVAS_HEIGHT = 500
// const FULL_CANVAS_WIDTH = 800
const WIDTH_BUFFER = 200
const HEIGHT_BUFFER = 100
const IMAGE_WIDTH = 800
const CANVAS_WIDTH = 1000


class ConfigureOverlay extends React.Component {

  state = {
    playbackData: {
      startPos: { x: 75, y: 250 },
      endPos: { x: 925, y: 250},
      color: { r: 56, g: 197, b: 243, a: .7},
      rotation: 0,
      width: 30
    },
    drawingRefs: {
      markersCanvas: null,
      markersContext: null,
      maskCanvas: null,
      maskContext: null,
    },
    mask: null,
    dragging: null,
    audioSrc: null,
    audioEl: null,
    animationStart: null,
    uploadingAssets: null
  }

  componentDidMount () {
    window.scrollTo(0,0)
    this.sourceImage.onload = () => {
      const markersCanvas = this.canvasEl
      const height = this.sourceImage.height + HEIGHT_BUFFER
      markersCanvas.height = height
      const markersContext = markersCanvas.getContext('2d')

      const maskCanvas = this.maskCanvas
      maskCanvas.height = height
      const maskContext = maskCanvas.getContext('2d')

      const currentPlaybackData = this.state.playbackData
      currentPlaybackData.startPos.y = markersCanvas.height/2
      currentPlaybackData.endPos.y = markersCanvas.height/2

      let overlayData = this.props.marker.get('overlayData').get(0)
      currentPlaybackData.color = overlayData.get('color').toJS()
      
      // debugger
      this.setState({
        drawingRefs: {
          markersCanvas: markersCanvas,
          markersContext: markersContext,
          maskCanvas: maskCanvas,
          maskContext: maskContext,
        },
        playbackData: currentPlaybackData,
      })
    }
    // const height = Math.floor(IMAGE_WIDTH/this.sourceImage.naturalWidth * this.sourceImage.naturalHeight)
    const mask = new Image
    mask.onload = () => {
      this.setState({ mask })
    }
    mask.src = this.props.maskSrc
  }

  componentDidUpdate (prevProps, prevState) {
    const hasDrawingRefs = this.state.drawingRefs.markersCanvas &&
                            this.state.drawingRefs.maskCanvas

    const hasDrawingData = this.state.mask

    if (hasDrawingRefs && hasDrawingData) { this.drawCanvas() }
    if (this.props.marker && !this.state.audioSrc) { this.loadAudio() }
    if (this.state.audioSrc && !this.state.audioEl) {
      const audioEl = new Audio(this.state.audioSrc)

      audioEl.addEventListener('playing', () => {
        this.setState({ animationStart: performance.now() })
        window.requestAnimationFrame(this.playAnimation)
      })

      audioEl.addEventListener('ended', () => {
        this.setState({ animationStart: null })
      })

      this.setState({ audioEl })
    }
  }

  handleOnMouseDown = (e) => {
    if (!this.state.drawingRefs.markersCanvas) { return } 
    const rect = this.state.drawingRefs.markersCanvas.getBoundingClientRect()
    const clickX = e.clientX - rect.left
    const clickY = e.clientY - rect.top
    const { startPos, endPos } = this.state.playbackData

    // Check if click is in playback start pos
    if (
      clickX > startPos.x - HALF_PLAYBACK_MARKER_SIZE &&
      clickX < startPos.x + HALF_PLAYBACK_MARKER_SIZE &&
      clickY > startPos.y - HALF_PLAYBACK_MARKER_SIZE &&
      clickY < startPos.y + HALF_PLAYBACK_MARKER_SIZE
    ) {
      this.setState({ dragging: 'startPos'})
      return
    }

    if (
      clickX > endPos.x - HALF_PLAYBACK_MARKER_SIZE &&
      clickX < endPos.x + HALF_PLAYBACK_MARKER_SIZE &&
      clickY > endPos.y - HALF_PLAYBACK_MARKER_SIZE &&
      clickY < endPos.y + HALF_PLAYBACK_MARKER_SIZE
    ) {
      this.setState({ dragging: 'endPos'})
    }
  }

  previewPlayback = () => {
    this.state.audioEl.play()
  }

  playAnimation = (step) => {
    if (!this.state.animationStart) {
      this.drawCanvas()
      return
    }

    this.drawCanvas(step)
    window.requestAnimationFrame(this.playAnimation)
  }

  handleOnMouseMove = (e) => {
    if (!this.state.dragging) { return }
    const rect = this.state.drawingRefs.markersCanvas.getBoundingClientRect();

    const currentX = e.clientX - rect.left
    const currentY = e.clientY - rect.top

    this.setState(prevState => {
      const newPlaybackData = prevState.playbackData
      newPlaybackData[prevState.dragging] = { x: currentX, y: currentY }
      return {
        playbackData: newPlaybackData
      }
    })
  }

  handleOnMouseUp = () => {
    this.setState({ dragging: null })
  }

  loadAudio = () => {
    fetch(this.props.marker.get('audioURL'))
    .then((res) => { return res.blob() })
    .then(blob => {
      // const b = new Blob([blob], { type: 'audio/ogg' })
      let reader = new FileReader();

      reader.addEventListener('load', () => {
        this.setState({ audioSrc: reader.result })
      });

      reader.readAsDataURL(blob)
    })
  }

  drawCanvas = (step = null) => {
    const {
      drawingRefs: {
        maskCanvas: canvas,
        maskContext: context,
      },
      mask,
      audioEl,
      playbackData,
      animationStart,
    } = this.state

    const params = {
      canvas,
      context,
      mask,
      audioEl,
      playbackData,
      animationStart,
      widthBuffer: WIDTH_BUFFER,
      heightBuffer: HEIGHT_BUFFER,
      sourceImage: this.sourceImage,
    }

    drawCanvas(params, step)
    this.drawPlaybackMarkers()
  }

  drawPlaybackMarkers = () => {
    // const context = this.state.drawingRefs.markersContext
    const {
      drawingRefs: {
        markersContext: context,
        markersCanvas,
      },
      playbackData: {
        startPos,
        endPos,
      }
    } = this.state

    context.fillStyle = 'red'
    context.clearRect(0, 0, markersCanvas.width, markersCanvas.height)

    context.fillRect(
      startPos.x - HALF_PLAYBACK_MARKER_SIZE,
      startPos.y - HALF_PLAYBACK_MARKER_SIZE,
      PLAYBACK_MARKER_SIZE,
      PLAYBACK_MARKER_SIZE
    )
    context.fillRect(
      endPos.x - HALF_PLAYBACK_MARKER_SIZE,
      endPos.y - HALF_PLAYBACK_MARKER_SIZE,
      PLAYBACK_MARKER_SIZE,
      PLAYBACK_MARKER_SIZE
    )

    context.beginPath()
    context.moveTo(startPos.x, startPos.y)
    context.lineTo(endPos.x, endPos.y)
    context.closePath()
    context.stroke()
  }

  updateRotation = (v) => {
    const newPlaybackData = this.state.playbackData
    newPlaybackData.rotation = v
    this.setState({
      playbackData: newPlaybackData
    })
  }

  updateWidth = (v) => {
    const newPlaybackData = this.state.playbackData
    newPlaybackData.width = v
    this.setState({
      playbackData: newPlaybackData
    })
  }

  saveOverlay = () => {
    this.setState({ uploadingAssets: true })
    let convertedPlaybackData = this.convertPlaybackData()
    this.props.completeMarker(convertedPlaybackData)
  }

  saveAnimationSettings = () => {
    let convertedPlaybackData = this.convertPlaybackData()
    this.props.updateMarker(this.props.marker.get('id'), { marker: { overlayData: convertedPlaybackData }})
    .then(() => {
      this.props.exitAnimationMode()
    })
  }

  convertPlaybackData = () => {
    const currentPlaybackData = this.state.playbackData
    // The start/end positions are currently recorded as pixel positions
    // within the configure-overlay-canvas, but this is wacky so we're going to 
    // fix it here by converting them to a percentage scale based on the 
    // dimensions of the actual tattoo image. 
    let startPos = currentPlaybackData.startPos
    startPos.x = (startPos.x - WIDTH_BUFFER/2) / IMAGE_WIDTH
    startPos.y = (startPos.y - HEIGHT_BUFFER/2) / this.sourceImage.height
    let endPos = currentPlaybackData.endPos
    endPos.x = (endPos.x - WIDTH_BUFFER/2) / IMAGE_WIDTH
    endPos.y = (endPos.y - HEIGHT_BUFFER/2) / this.sourceImage.height
    currentPlaybackData.startPos = startPos
    currentPlaybackData.endPos = endPos
    return currentPlaybackData
  }

  render () {
    const {
      maskSrc,
      imgSrc,
    } = this.props

    return (
      <div className='sm-configure-overlay'>
        <div className='overlay-canvas-wrapper'>
          <img
            src={imgSrc}
            ref={(img) => { this.sourceImage = img}}
          />
          <canvas
            className='mask-playback-canvas'
            width={CANVAS_WIDTH}
            ref={(canvas) => { this.maskCanvas = canvas }}
          />
          <canvas
            className='configure-overlay-canvas'
            width={CANVAS_WIDTH}
            ref={(canvas) => { this.canvasEl = canvas }}
            onMouseDown={this.handleOnMouseDown}
            onMouseMove={this.handleOnMouseMove}
            onMouseUp={this.handleOnMouseUp}
          />
        </div>

        <div className='description-row'>
          <div className='options-row'>
            <div>
              <span>Playbar Rotation</span>
              <Slider value={this.state.playbackData.rotation} onChange={this.updateRotation}  min={0} max={1} step={0.01} />
            </div>
            <div>
              <span>Playback Width</span>
              <Slider value={this.state.playbackData.width} onChange={this.updateWidth}  min={10} max={100} step={1} />
            </div>
          </div>

          <div className='options-row'>

            <button 
              type='button' 
              onClick={this.previewPlayback}
              disabled={!this.state.audioEl}> 
              Preview Playback 
            </button>
            {this.props.reAnimate ? (
              <button
                type='button'
                onClick={this.saveAnimationSettings} >
                Save Animation Settings
              </button>
            ) : (
              <button 
                type='button' 
                onClick={this.saveOverlay}
                disabled={this.state.uploadingAssets}> 
                {this.state.uploadingAssets ? "Uploading Assets..." : "Complete Marker"}
              </button>
            )}

          </div>

        </div>

      </div>
    )

  }

}

export default ConfigureOverlay
