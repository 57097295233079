import React from "react";
import Select from "react-select";
// import API from 'utils/API'

// Be sure to include styles at some point, probably during your bootstrapping
import "react-select/dist/react-select.css";

class ArtistSecretKeyEntry extends React.Component {
  state = {
    artistId: null,
    secretKey: "",
    artistReminder: null,
    keyMatch: null,
  };

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  selectArtist = (value) => {
    let artistId = null;
    if (value) artistId = value.value;

    this.setState({
      artistId: artistId,
      artistReminder: false,
      keyMatch: null,
    });
  };

  handleKeyEntry = (e) => {
    this.setState({ secretKey: e.target.value });
    if (!this.state.artistId && e.target.value) {
      this.setState({ artistReminder: true, keyMatch: null });
    } else {
      this.setState({
        secretKey: e.target.value,
        artistReminder: false,
        keyMatch: null,
      });
    }
  };

  nextStep = () => {
    if (this.state.artistId) {
      this.props
        .setArtistOnMarker(
          this.props.marker.get("id"),
          this.state.artistId,
          this.state.secretKey
        )
        .then(() => this.props.nextStep())
        .catch(() => this.setState({ keyMatch: false }));
    } else {
      this.props.nextStep();
    }
  };

  render() {
    const { marker } = this.props;
    const sortedArtists = this.props.artists.sort((a, b) => {
      if (a.get("name") < b.get("name")) {
        return -1;
      }
      if (a.get("name") > b.get("name")) {
        return 1;
      }
      return 0;
    });

    const artistOptions = sortedArtists
      .valueSeq()
      .map((artist) => ({
        value: artist.get("id"),
        label: artist.get("name"),
      }))
      .toJS();

    return (
      <div className="sm-artist-key-entry common-padding">
        <div className="sm-tattoo-image-review">
          <h3>Your Tattoo</h3>
          {marker.get("processing_error") && (
            <div className="notification-banner error">
              <p>
                There was a problem saving your tattoo image. Please go back and
                try again.
              </p>
            </div>
          )}
          <img src={this.props.imgSrc} className="tattoo-image" />
          <div className="div-link" onClick={this.props.previousStep}>
            Go back to upload a different photo
          </div>
        </div>
        <h2>Select your tattoo artist</h2>
        <p>
          Select your tattoo artist from the Skin Motion Tattoo Artist Network
          below, and enter their secret-key to continue.
        </p>

        <p>
          If you did not get your Soundwave Tattoo&trade; from a Tattoo Artist
          from the Skin Motion Tattoo Artist Directory, you can continue without
          selecting an artist but you will not receive a guarantee on your
          activation.
        </p>
        {this.state.artistReminder && (
          <div className="sm-message">
            Please select an artist from the list.
          </div>
        )}
        {this.state.keyMatch == false && (
          <div className="sm-message">
            Your secret key did not match the artist you selected. Please try
            again.
          </div>
        )}
        <div className="sm-select-artist">
          {artistOptions && (
            <div>
              <Select
                value={this.state.artistId}
                options={artistOptions}
                onChange={this.selectArtist}
                // isOpen={true}
                // toggle={false}
              />
              {/* <p> AND </p> */}
            </div>
          )}
        </div>
        <div className="secretkey">
          <div className="field-wrap">
              <label> Enter Your Artist's Secret Key: </label>
              <input
                type="text"
                value={this.state.secretKey}
                onChange={this.handleKeyEntry}
              />  
          </div>
         
          <button
            className="btn btn-tan btn-large"
            onClick={this.nextStep}
            disabled={marker.get("processing_error")}
          >
            Continue
          </button>
          <div className="bottom-spacer"></div>
        </div>
      </div>
    );
  }
}

export default ArtistSecretKeyEntry;
