
/**
 * Scales the waveform data for drawing on a canvas context.
 *
 * @param {Number} amplitude The waveform data point amplitude.
 * @param {Number} height The height of the waveform, in pixels.
 * @returns {Number} The scaled waveform data point.
 */

function scaleY(amplitude, height) {
  var range = 256;
  var offset = 128;

  return height - ((amplitude + offset) * height) / range;
}

/**
 * Draws a waveform on a canvas context.
 * Modified from waveforms.mixins.DrawWaveform from peaks.js to be symmetrical
 * over the center line (top to bottom)
 *
 * @param  {Konva.Context} context  The canvas context to draw on.
 * @param  {WaveformData} waveformData  The waveform data to draw.
 * @param  {Int} frameOffset
 * @param  {Int} startPixels
 * @param  {Int} endPixels
 * @param  {Int} width The width of the waveform area, in pixels
 * @param  {Int} height The height of the waveform area, in pixels
 */

const drawWaveform = (context, waveformData, frameOffset, startPixels, endPixels, width, height, interval=1) => {
  if (startPixels < frameOffset) {
    startPixels = frameOffset;
  }

  var limit = frameOffset + width;

  if (endPixels > limit) {
    endPixels = limit;
  }

  var adapter = waveformData.adapter;
  var x, val;

  context.beginPath();

  for (x = startPixels; x < endPixels; x+=interval) {
    val = adapter.at(2 * x);

    context.lineTo(x - frameOffset + 0.5, scaleY(val, height) + 0.5);

  }

  for (x = endPixels - 1; x >= startPixels; x-=interval) {
    val = adapter.at(2 * x);

    context.lineTo(x - frameOffset + 0.5, scaleY(-val, height) + 0.5);
  }

  context.closePath()
}

/*
 * Generates the Scene Function for the Zoom View Waveform
 */
export const generateSceneFunc = (wzv, interval) => {
  return function(context) {
    drawWaveform(
      context,
      wzv.data,
      wzv.frameOffset,
      wzv.frameOffset,
      wzv.frameOffset + wzv.width,
      wzv.width,
      wzv.height,
      interval
    )

    context.fillShape(this)
  }
}

/*
 * Generates the Konva Scene Function for the Segment Waveform
 */
export const generateSegmentSceneFunc = (wzv, segment, interval) => {
  return function(context) {
    drawWaveform(
      context,
      wzv.data,
      wzv.frameOffset,
      wzv.timeToPixels(segment.startTime),
      wzv.timeToPixels(segment.endTime),
      wzv.width,
      wzv.height,
      interval
    )

    context.fillShape(this)
  }
}
