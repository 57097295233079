import React from "react";
import { Link } from "react-router-dom";

const Footer = (props) => (
  <footer className="common-padding">
    <div className="links">
      <div className="column3">
        <div className="download-apple">
          <a href="https://play.google.com/store/apps/details?id=com.skinmotion.skinmotion">
            <span>Download the Skin Motion on the App Store</span>
          </a>
        </div>
        <div className="download-google">
          <a href="https://itunes.apple.com/us/app/skin-motion/id1255007214?mt=8">
            <span>Download the Skin Motion app on Google Play</span>
          </a>
        </div>
      </div>
      <div className="column1">
        <ul>
          <li>
            <Link to="/faq">FAQ</Link>
          </li>
          <li>
            <Link to="/about-us">About Us</Link>
          </li>
          <li>
            <Link to="/press">Press</Link>
          </li>
        </ul>
      </div>
      <div className="column2">
        <ul>
          {/* <li><Link to='/demo'>Demo</Link></li> */}
          <li>
            <Link to="/apply">Artist Application</Link>
          </li>
          <li>
            <Link to="/support">Support</Link>
          </li>
        </ul>
      </div>
    </div>
    <div className="copyright">
      <span>&#169; 2024 Skin Motion, LLC</span>
      <span>Patented AR Platform</span>
      <span>
        <Link to="privacy-policy " className="text-underline">
          Privacy Policy
        </Link>
      </span>
      <span>
        <Link to="/terms-and-conditions " className="text-underline">
          Terms of use
        </Link>
      </span>
    </div>
    <div className="footer-img">
      <Link to="/demo">
        <span>#mysoundwavestory winners chosen every week!</span>
      </Link>
    </div>

    <div id="google_translate_element"></div>
  </footer>
);

export default Footer;
