import firebase from "firebase";
// import firebase from 'firebase/compat/app'
import { skinMotionStore } from "../reducers";
import { fetchMarker } from "../actions/markers";
import { updateUser } from "../actions/currentUser";
// import 'firebase/compat/auth';

class Firebase {
  // TODO -- factor this out appropriately when time comes to redo the
  // config setup of the app.

  config = {
    apiKey: process.env.API_KEY,
    authDomain: process.env.AUTH_DOMIN,
    databaseURL: process.env.DATABASE_URL,
    projectId: process.env.PROJECT_ID,
    storageBucket: process.env.STORAGE_BUCKET,
  };

  // config = {
  //   apiKey: "AIzaSyCgMCTGzegz_Ux_BaT9xhCQqiYaVbZwQOw",
  //   authDomain: "skinmotion-app.firebaseapp.com",
  //   databaseURL: "https://skinmotion-app.firebaseio.com",
  //   projectId: "skinmotion-app",
  //   storageBucket: "skinmotion-app.appspot.com",
  // };

  firebaseDB = null;
  active = null;
  firebaseAuth = null;
  firebaseAuthUnsub = null;
  firebaseDB = null;
  currentUser = null;

  constructor() {
    console.log("Initializing firebase...");
    firebase.initializeApp(this.config);
    console.log("Initialied");
  }

  authenticate(currentUser) {
    console.log("currentUser", currentUser);
    this.currentUser = currentUser;
    if (!this.active) {
      console.log("in--------------------->");
      this.active = true;
      this.firebaseAuth = firebase.auth();
      console.log("----auth----");
      this.firebaseAuthUnsub = this.firebaseAuth.onAuthStateChanged((user) => {
        // console.log("user", user);
        if (user) {
          console.log("user authed...");
          this._listenToDatabase();
        } else {
          console.log("doing firebase signin");
          this._firebaseSignin().catch(() => this._triggerAuthRetry());
        }
      });
    }
  }

  _firebaseSignin() {
    if (!this.currentUser.get("firebase_token")) {
      return Promise.reject();
    }
    return this.firebaseAuth
      .signInWithCustomToken(this.currentUser.get("firebase_token"))
      .then(() => {
        this._listenToDatabase();
        return Promise.resolve();
      })
      .catch((err) => {
        this._triggerAuthRetry();
        return Promise.reject();
      });
  }

  _triggerAuthRetry() {
    // Patching firebase_token to /users/<id> generates a new FB auth token
    // which is passed back in the user blob so we can authenticate again.
    this.active = false;
    console.log("triggering auth retry...");
    skinMotionStore.dispatch(
      updateUser(this.currentUser.get("id"), {
        firebase_token: true,
      })
    );
  }

  _listenToDatabase() {
    if (this.firebaseDB) {
      return;
    }

    console.log(`Listening to FirebaseDB: ${this._userEventsRef()}`);
    console.log(`Listening to FirebaseDB: ${this._userEventsRef()}`);
    this.firebaseDB = firebase.database().ref(this._userEventsRef());
    this.firebaseDB.on("value", (snapshot) => {
      console.log("Got value event!");
      // console.log(snapshot)
      if (snapshot.val()) {
        this._handleFirebaseEvent(snapshot);
      }
    });
  }

  _handleFirebaseEvent(snapshot) {
    if (snapshot.val()) {
      // console.log(JSON.stringify(snapshot.val()));
      snapshot.val().forEach((event) => {
        // console.log("handling event: ");
        // console.log(event);
        if (event.event_name == "refresh_marker" && !event.for_mobile) {
          // console.log(
          //   `fetching marker id ${event.data} after refresh_marker event received...`
          // );
          skinMotionStore.dispatch(fetchMarker(parseInt(event.data)));
        }
      });
      this.firebaseDB
        .orderByChild("event_name")
        .equalTo("refresh_marker")
        .once("value", (snapshot) => {
          let updates = [];
          snapshot.forEach((event) => {
            if (!event.val().for_mobile) {
              // console.log(`Removing: ${this._userEventsRef()}/${event.key}`);
              // console.log(`Removing: ${this._userEventsRef()}/${event.key}`);
              let childNode = firebase
                .database()
                .ref(`${this._userEventsRef()}/${event.key}`);
              childNode.remove();
            }
          });
        });
    }
  }

  _userEventsRef() {
    return `production/users/${this.currentUser.get("id")}/events`;
    // return `${process.env.NODE_ENV}/users/${this.currentUser.get("id")}/events`;
  }

  signOut() {
    if (this.firebaseAuthUnsub) {
      this.firebaseAuthUnsub();
    }
    if (this.firebaseDB) {
      this.firebaseDB.off();
      this.firebaseDB = null;
    }
    if (this.firebaseAuth) {
      this.firebaseAuth
        .signOut()
        .then(() => {
          this.active = false;
          console.log(
            "Succesfully signed out of firebase, this.active = " + this.active
          );
        })
        .catch(() =>
          console.log("Something went wrong signing out of firebase")
        );
    }
  }
}

export let firebaseObj = new Firebase();
