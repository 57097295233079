import { ownsMarker } from '../models/user'

export const needsConfiguration = marker =>
  marker.get('is_paid') && !marker.get('is_active') && !marker.get('was_rejected')

export const isUsersTattoo = (marker, user) =>
  marker.get('is_paid') && ownsMarker(user, marker)

export const isUsersSoundwave = (marker, user) =>
  
    !marker.get('is_paid') && ownsMarker(user, marker)
  

export const isActive = (marker) => {
  let activationDate = new Date(marker.get('activation_date'));
  let currentDate = new Date();
  let difference = (currentDate.getTime() - activationDate.getTime()) / 86400000;
  return (marker.get('is_active') && difference < 365)
}
