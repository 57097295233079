import API from '../utils/API'

export const RECEIVE_INSTAGRAM_POSTS = 'INSTAGRAM/RECEIVE_POSTS'

const receivePosts = (username, posts) => ({
  type: RECEIVE_INSTAGRAM_POSTS,
  username,
  posts,
})

export const fetchPosts =  username =>
  dispatch => {
    API.get(`/insta-feed/${username}`)
    .then(posts => {
      dispatch(receivePosts(username, posts.items))
    })
  }
