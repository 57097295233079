import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class MarketingGuide extends Component {

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div className="sm-marketing-guide">
        <div className='title-main' id='title-marketing-guide'>
          <h1>Marketing Guidelines</h1>
        </div>
    		<div className="sm-marketing-guide-main">
          <h3>Overview</h3>
          <p>These guidelines are provided for Skin Motion&trade; licensed Tattoo 
          Artists that must be used when promoting their affiliation with Skin Motion 
          in all marketing communications. The guidelines include information about 
          the Skin Motion logo, product images, and marketing best practices. Failure
          to follow these guidelines may result in the termination of your Tattoo 
          Artist license.</p>

          <h4>Trademarks</h4>

          <h5>Skin Motion&trade;</h5>

          <p><strong>Skin Motion</strong> is a registered Trademark in the United States and should always include the 
          trademark symbol – &trade; – the first time it is mentioned on a page. The trademark symbol 
          should never be removed from the Skin Motion Logo. The trademark symbol on the Skin Motion Logo does not qualify
          as the first use on a page.</p>

          <h5>Soundwave Tattoos&trade;</h5>

          <p><strong>Soundwave Tattoos</strong> is a registered Trademark in the United States and should always include the 
          trademark symbol – &trade; – the first time it is mentioned on a page. The trademark symbol 
          should never be removed from the Skin Motion Logo. The trademark symbol on the Soundwave Tattoos Logo does not qualify
          as the first use on a page.</p>

          <h5>Incorrect usage</h5>

          <p>Skin Motion is the app, and company name. Whenever you refer to the app or company you should refer to Skin Motion.</p>

          <p>Soundwave Tattoos are the product and service that Skin Motion facilitates. You should never refer to the app at Soundwave app, Soundwave Tattoo app, or anything besides Skin Motion App.</p>

          <p>The following are not correct:</p>

          <ul>
            <li>"Tattoos Soundwave" - This is not correct. It should say "Soundwave Tattoos"</li>
            <li>"Soundwave App" - this is not correct. It should say Skin Motion app</li>
          </ul>

          <h4>Skin Motion Approval Required</h4>

          <p>Marketing of any kind in the following methods must be approved by Skin Motion before 
          publication or broadcast</p>

          <ul>
            <li>TV appearances, advertisements, or campaigns</li>
          </ul>

          <p>Submit TV campaign plans and creative for Skin Motion review to marketing@skinmotion.com</p>
          <p>Provide a brief description of the campaign timeline and include the following information 
          with your submission:</p>

          <ul>
            <li>Contact details and phone number</li>
            <li>Your Skin Motion account email address</li>
            <li>Campaign spend or media value</li>
            <li>Impressions expected</li>
            <li>List of countries where the campaign will run</li>
            <li>Link to view your content</li>
          </ul>
          <p className="sm-marketing-guide-section-end">&nbsp;</p>

          <h3>Skin Motion Tattoo Artist Membership</h3>

          <p>The Skin Motion logo, product images, and marketing resources are for use only by Tattoo 
          Artists who have agreed to the <a href='/license-agreement' target='_blank'>Tattoo Artist 
          License Agreement</a>. Use of artwork provided by Skin Motion without modification. Skin 
          Motion reserves the right to withdraw permission to use the Skin Motion marketing resources 
          anytime their use is inconsistent with these guidelines or is otherwise deemed inappropriate by Skin Motion.</p>

          <h4>Skin Motion Logo</h4>

          <p>The Skin Motion logo is provided in .jpg, .svg, for onscreen communications and .eps format for 
          use in printed materials. The .jpg should never be made larger than it is. The .svg is for high resolution
          preferrable for onscreen communications including websites and social media. Use only the logo here. 
          Never create your own Skin Motion logo, or alter the artwork in anyway.</p>

          <div className="sm-marketing-guide-logo">
            <img src='https://cdn.skinmotion.com/images/media-resources/Logo-wTag.png'/><br/><br/>
            <a href='https://cdn.skinmotion.com/images/media-resources/SkinMotionLogo.zip'>Download Skin Motion Logo Resources (.zip file)</a>
          </div>

          <h4>Soundwave Tattoos Logo</h4>

          <p>The Soundwave Tattoos logo is provided in .jpg, .svg, for onscreen communications and .eps format for 
          use in printed materials. The .jpg should never be made larger than it is. The .svg is for high resolution
          preferrable for onscreen communications including websites and social media. Use only the logo here. 
          Never create your own Soundwave Tattoos logo, or alter the artwork in anyway.</p>

          <div className="sm-marketing-guide-logo">
            <img src='https://cdn.skinmotion.com/images/media-resources/Soundwave-Tattoos-Logo.png'/><br/><br/>
            <a href='https://cdn.skinmotion.com/images/media-resources/soundwavetattoologo.zip'>Download Soundwave Tattoos Logo Resources (.zip file)</a>
          </div>

          <h4>Logo Use</h4>
          <p>Use only one Skin Motion and Soundwave Tattoos logo on a layout or video sequence.</p>

          <h5>Placement</h5>
          <p>Minimum clar space is equal to one quater the height of the logo. Do not use photos, typography, or 
          other graphic elements inside the minimum clear space.</p>

          <h5>Backgrounds and color</h5>
          <p>The Skin Motion and Soundwave Tattoos logos have been provided for use on light backgrounds in a 
          black variation, and for use on dark backgrounds in white variation. Do not change the color of the logo artwork.
          You may add a light drop-shadow or glow on the logo to seperate it from the background.</p>

          <h5>Avoid Mistakes</h5>
          <p>Use only artwork provided by Skin Motion in this guide. Do not modify, angle, animate, rotate or 
          tilt the Skin Motion and Soundwave Tattoo logos.</p>

          <h4>Contests and Sweepstakes</h4>
          <p>You may use Skin Motion activation vouchers for contests and promotions. Contests should never 
          place contestants in harms way or create any circumstance placing undue harm or risk to the contestant</p>

          <p>Do not indicate any kind of sponsorship or endorsement by Skin Motion.</p>
          <p className="sm-marketing-guide-section-end">&nbsp;</p>

          <h3>Marketing Resources</h3>

          <h4>Social Media</h4>
          <p>Please feel free to share and repost any of Skin Motion's social media content. Skin Motion monitors 
          social media for mentions and may occiasionally share or repost your social media that Skin Motion is tagged in.</p>

          <h5>Facebook</h5>
            <ul>
              <li><a href='https://facebook.com/skinmotionapp' target='_blank'>Skin Motion - Facebook page</a></li>
              <li><a href='https://facebook.com/soundwavetattoos' target='_blank'>Soundwave Tattoos - Facebook page</a></li>
            </ul>

          <h5>Instagram</h5>
            <ul>
              <li><a href='https://instagram.com/skinmotionapp' target='_blank'>@skinmotionapp</a></li>
              <li><a href='https://instagram.com/soundwavetattoos' target='_blank'>@soundwavetattoos</a></li>
            </ul>

          <h5>Twitter</h5>
            <ul>
              <li><a href='https://twitter.com/skinmotionapp' target='_blank'>@skinmotionapp</a></li>
              <li><a href='https://twitter.com/soundwavetattoo' target='_blank'>@soundwavetattoo</a></li>
            </ul>       
  		  </div>
  	 </div>
    )
  }
}

export default MarketingGuide