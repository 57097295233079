import React, { Component } from 'react'
import { Link } from 'react-router-dom'

import InfluencerStats from './InfluencerStats'

const kREFERRAL_TARGET = 25;

class InfluencerPanel extends Component {

  constructor (props) {
    super(props)

    let progress = null

    if (this.props.currentUser.get('referral_count') >= kREFERRAL_TARGET) {
      progress = this.props.currentUser.get('referral_count') % kREFERRAL_TARGET;
    } else {
      progress = this.props.currentUser.get('referral_count')
    }

    this.state = {
      progress,
    }

  }

  render() {
    const {
      currentUser,
      topReferrers,
    } = this.props

    return (
      <div className="sm-influencer-panel">
        <div className='sm-influencer-header'>
          <h1>Referral Tools</h1>
        </div>
        <div className="sm-influencer-left">
          <div className="sm-influencer-referral-link">
            <h2>Your Referral Link</h2>
            <a> {currentUser.get('referral_url')} </a>
          </div>
          <div className="sm-influencer-vouchers">
            <h2> Soundwave Tattoo Activation Vouchers</h2>
            {currentUser.get('referral_count', 0) < kREFERRAL_TARGET ? (
              <p> You do not have any vouchers yet, you need
              {` ${kREFERRAL_TARGET - this.state.progress} `}
              more referrals to earn your first voucher. </p>
            ) : (
              <p> You're doing great! You've earned
                {` ${this.props.currentUser.get('referral_count') / kREFERRAL_TARGET } `}
                tattoo vouchers! Only {` ${kREFERRAL_TARGET - this.state.progress} `} more for another!</p>
            )}

          </div>
          <div className="sm-influencer-tips">
            <h2>Referral Marketing Tips</h2>
            <p> We created these suggested "best practices" to help you with being an impactful referrer. </p>
            <ul>
              <li>Only send this link to people who have requested it.</li>
              <li>Only post this link once per day</li>
              <li>Skin Motion&trade; and Soundwave Tattoos&trade; are both trademarked and should always include the trademark symbol &trade;</li>
            </ul>
          </div>
        </div>
        <div className="sm-influencer-right">
          <InfluencerStats currentUser={currentUser} topReferrers={topReferrers} />
        </div>
      </div>
    )
  }
}

export default InfluencerPanel
