import React, {Component} from 'react';
// import { Link } from 'react-router-dom';
// import { connect } from 'react-redux';
// import { createCampaign, fetchCampaigns } from 'actions/campaigns'

class CampaignsAdmin extends Component {
  render() {
    return (
      <div className="sm-admin-campaign-manager">
        Testing!
      </div>
    )
  }
}

export default CampaignsAdmin