import React from "react";
import { Link } from "react-router-dom";
// import { downloadBlob } from 'utils/general'

class DownloadSoundwave extends React.Component {
  componentDidMount() {
    window.scrollTo(0, 0);
    const { marker } = this.props;
    if (
      !marker.get("is_updating_waveform") &&
      !marker.get("generating_pdf") &&
      marker.get("imageURL")
    ) {
      this.generatePDF();
    }
  }

  componentWillReceiveProps(nextProps) {
    const currentMarker = this.props.marker;
    const nextMarker = nextProps.marker;

    if (
      currentMarker.get("is_updating_waveform") &&
      !nextMarker.get("is_updating_waveform")
    ) {
      this.generatePDF();
    }
    if (currentMarker.get("pdfURL") != nextMarker.get("pdfURL")) {
      this.fetchAndDownloadPDF(nextMarker);
    }
  }

  fetchAndDownloadPDF = (nextMarker) => {
    //console.log("New pdfURL on marker, fetching now...");
    fetch(nextMarker.get("pdfURL"))
      .then((res) => {
        return res.blob();
      })
      .then((blob) => {
        //console.log("Got PDF... starting download");
        //downloadBlob(blob, 'soundwave.pdf')
      });
  };

  generatePDF = () => {
    let markerParams = {
      generate_pdf: true,
    };
    this.props.updateMarker(this.props.marker.get("id"), {
      marker: markerParams,
    });
  };

  renderThumbnail = () => {
    if (this.props.marker.get("is_updating_waveform")) {
      return (
        <div className="sm-waveform-updating">
          Saving Soundwave...
          <div className="sm-loader"></div>
        </div>
      );
    } else {
      return (
        <img
          src={this.props.marker.get("imageURL")}
          height={this.props.marker.get("height")}
          width={this.props.marker.get("width")}
        ></img>
      );
    }
  };

  renderDownloadText = () => {
    if (this.props.marker.get("is_updating_waveform")) {
      return (
        <div className="sm-download-text">
          <p>
            Your Soundwave is processing and your stencil PDF file download will
            be generated as soon as it's complete!
          </p>
        </div>
      );
    } else if (this.props.marker.get("generating_pdf")) {
      return (
        <div className="sm-download-text">
          <p>Your Soundwave tattoo stencil PDF file is beign created now!</p>
        </div>
      );
    } else if (this.props.marker.get("pdfURL")) {
      return (
        <div>
          <a
            href={this.props.marker.get("pdfURL")}
            target="_blank"
            className="btn btn-blue btn-large"
          >
            Download PDF
          </a>
        </div>
      );
    }
  };

  render() {
    const { marker } = this.props;
    return (
      <div className="sm-waveform-tool common-padding">
        <div className="sm-download-waveform-main edit-soundwave-wrap">
          {marker.get("processing_error") && (
            <div className="notification-banner error">
              <p>
                There was an error saving or creating your PDF file. Please go
                back and try again, or contact support.
              </p>
            </div>
          )}
          <h2>Your Soundwave Tattoo stencil</h2>
          <div className="sm-soundwave-thumbnail-container">
            <div className="sm-soundwave-thumbnail">
              {this.renderThumbnail()}
            </div>

            <div className="download-info">
              <a
                className="back-to-styling btn"
                onClick={this.props.previousStep}
              >
                Edit Soundwave
              </a>
              <p>
                Download, save and print your Soundwave Tattoo stencil to bring
                or send to an artist from the Skin Motion{" "}
                <Link to="/artist-directory">Tattoo Artist Directory</Link>.
              </p>
              {this.renderDownloadText()}

              <div></div>
            </div>
          </div>

          <div className="sm-download-helper-images">
            <ul className="instructions-list text-left">
              <li>
                <p>
                  Share this PDF with your preferred tattoo artist from the Skin
                  Motion{" "}
                  <Link to="/artist-directory">Tattoo Artist Directory</Link>.
                  Your tattoo artist can make the Soundwave bigger or smaller
                  for you using this stencil.
                </p>
              </li>
              {/* <li>
                <p><b>Step 1: </b></p>
                <p>After you upload your audio to SkinMotion.com, name the sound, trim the silent parts 
                from the beginning and end, and customized your Soundwave to look the way you want.</p>
                <p><b>You must get the exact shape of the Soundwave above tattooed, or go back and change your 
                Soundwave so that it sounds and looks exactly how you want it before you purchase your 
                activation or get it tattooed. Once you have purchased the activation you can not go back 
                and change your sound or the shape of the Soundwave stencil.</b></p>
              </li> */}
              <li>
                {/* <p><b> Step 2: </b></p>
                <p>Bring this print out, or email it to a trained and licensed tattoo artist from the Skin 
                Motion <Link to="/artist-directory">Tattoo Artist Directory</Link>. Your tattoo artist can make 
                the Soundwave bigger or smaller for you using this stencil.</p> */}

                <p>
                  <b>
                    Make sure that you get the tattoo on a flat area that is not
                    curved and does not go around a body part. The tattoo MUST
                    be straight and flat and can not curve! Do not get more than
                    one Soundwave in the same area. The Skin Motion app can only
                    identify one Soundwave at a time. If two are in the camera's
                    view at the same time, the app will not play back properly.
                  </b>
                </p>

                <img src="https://cdn.skinmotion.com/images/help/correct-placement.png" />
                <img src="https://cdn.skinmotion.com/images/help/incorrect-placement.png" />
              </li>
              {/* <li>
                <p><b> Step 3: </b></p>
                <p className='mb-0'>After your Soundwave Tattoo activation has been purchased, Upload a photo of your tattoo, 
                create the playback overlay, and configure the playback.</p>
              </li> */}
              <li>
                <p>
                  Once your tattoo activation has been purchased, you will be
                  prompted to upload a photo of your tattoo. After that is
                  completed and you have chosen the color of your tracer bar,
                  your tattoo will be finalized by a member of our activation
                  team. You will receive a completion email once your tattoo has
                  been activated.
                </p>
              </li>
            </ul>
          </div>

          <div className="sm-activation-container">
            {/*<p>By getting your tattoo from a Skin Motion
            licensed Tattoo Artist near you from our <Link href="/artist-directory">Tattoo
            Artist Directory</Link> you also get a 15-day money back
            guarantee on your Soundwave Tattoo activation.</p>

             <p>Activate your Soundwave Tattoo for playback by the
            Skin Motion app for the first year for $39.99 and $14.99 per
            year after.</p> */}

            <div className="sm-activation-button">
              {/*<h3>Ready to purchase?</h3>*/}
              <div>
                <button
                  className="btn btn-tan btn-large mb-0"
                  onClick={this.props.nextStep}
                  disabled={
                    !marker.get("pdfURL") || marker.get("processing_error")
                  }
                >
                  Continue to Activation
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default DownloadSoundwave;
