import React, { Component } from "react";
import Immutable from "immutable";
import { connect } from "react-redux";
import ViewApplication from "../admin/ViewApplication";
import { resetQuiz } from "../../actions/artistApplications";
import { fetchUser, setUser } from "../../actions/users";

const kARTIST_APPLIED = 0;
const kARTIST_TEST_SUBMITTED = 1;
const kARTIST_IN_MODERATION = 2;
const kARTIST_APPROVED = 3;
const kARTIST_VERIFIED = 4;
const kARTIST_RETEST_REQUESTED = 5;
const kARTIST_RETEST_GRANTED = 6;

const kTEST_FAILED = 0;
const kTEST_PASSED = 1;
const kTEST_RESET = 2;

class ArtistApplicationReviewRow extends Component {
  constructor(props) {
    super(props);
    this.applicationStates = {};
    this.testResults = {};
    this.applicationStates[kARTIST_APPLIED] = "Application Submitted";
    this.applicationStates[kARTIST_TEST_SUBMITTED] = "Quiz Taken";
    this.applicationStates[kARTIST_IN_MODERATION] = "In Moderation Queue";
    this.applicationStates[kARTIST_RETEST_REQUESTED] = "Retest requested";
    this.applicationStates[kARTIST_RETEST_GRANTED] = "Retest Granted";

    this.testResults[kTEST_FAILED] = "Quiz Failed";
    this.testResults[kTEST_PASSED] = "Quiz Passed";
    this.testResults[kTEST_RESET] = "Quiz Reset";
  }

  manageUser = async (e) => {
    const { application } = this.props;
    this.props.fetchUser(application.user_id).then(() => {
      this.props.push(`/admin/users/${application.user_id}`);
    });
    // setUser(await fetchUser(application.user_id));
    // this.props.push(`/admin/users/${application.user_id}`);
  };

  resetQuiz = () => {
    return this.props.resetQuiz(this.props.application.id);
  };

  shouldShowQuizReset(application) {
    if (application) {
      let retestRequested =
        application.application_state == kARTIST_RETEST_REQUESTED;
      let quizReset = application.test_result == kTEST_RESET;
      return retestRequested && !quizReset;
    }
    return false;
  }

  render() {
    const { users, application } = this.props;
    let userEmail = "loading...";
    let applicationState = 0;
    let testResult = 0;
    if (application) {
      // userEmail = users.get(application.user_id, Immutable.Map()).get("email");
      userEmail =
        JSON.parse(application.application_data).email || application.email;
      applicationState = application.application_state;
      testResult = application.test_result;
    }
    return (
      <tr className="sm-admin-app-review-row">
        <td>
          <div onClick={this.manageUser} className="div-link" data-view="users">
            {userEmail}
          </div>
        </td>
        <td>
          {" "}
          <ViewApplication application={application} />{" "}
        </td>
        <td>{this.applicationStates[applicationState]}</td>
        <td>
          <div
            className={
              application.test_result == kTEST_PASSED
                ? "sm-success-message"
                : "sm-error-message"
            }
          >
            {this.testResults[testResult]}
          </div>
        </td>
        <td>
          {this.shouldShowQuizReset(application) && (
            <div onClick={this.resetQuiz} className="div-link">
              Reset Quiz
            </div>
          )}
        </td>
        <td>Give special verification status</td>
      </tr>
    );
  }
}

const mapStateToProps = (store) => ({
  users: store.get("users"),
});

const mapDispatchToProps = (dispatch) => ({
  resetQuiz: (applicationId) => dispatch(resetQuiz(applicationId)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ArtistApplicationReviewRow);
