import React from 'react';
import { formatDate } from '../../../models/payments';
import { Link } from 'react-router-dom';


class TattooBillingCancel extends React.Component {
  state = {
    imgSrc: null
  }

  componentDidMount() {
    this.loadImage();
  }

  loadImage() {
    fetch(this.props.marker.get('tattooImageURL'))
    .then(res => {
      return res.blob()
    })
    .then(blob => {
      const reader = new FileReader()

      reader.addEventListener('load', () => {
        this.setState({ imgSrc: reader.result })
      })

      reader.readAsDataURL(blob)
    })
  }

  cancel() {
    console.log("Canceling subscription"); 
  }

  render() {
    const { imgSrc } = this.state
    const { marker } = this.props
    let loading = false;
    if(!imgSrc) { loading = true }
    
    return (
      <div className="sm-tattoo-billing-cancel">
        <h3>Cancel Subscription</h3>
        <p className="sm-tattoo-billing-cancel-goback">
          <Link to={`/my-tattoos/billing/${marker.get('id')}`}>Go Back to Manage Subscription</Link>
        </p>
        <div className="sm-tattoo-billing-cancel-markerbox">
          <h4> Tattoo </h4>
          <p>"{marker.get('name')}"</p>
          { loading ? (
            <img src={marker.get('imageURL')} className='tattoo-img' />
          ) : (
            <img src={imgSrc} className='tattoo-img' />
          )}
        </div>
        <div className="sm-special-offer">
          <h3> Special Offer! </h3>
          <p> For a limited time, you can get 50% off your Soundwave Tattoo subscription
          when you pay for 10 years for $49.99</p>
          <Link className="btn btn-blue btn-large" to={`/my-tattoos/billing/${marker.get('id')}/discount`}>Buy Now</Link>
        </div>

        <div className="sm-tattoo-billing-cancel-alert">
          <h4>Are you sure you want to cancel your subscription?</h4>
          <p>
            <span className="red">By cancelling you will no longer be able to play
            your Soundwave Tattoo until it is reactivated. Re-activating your tattoo
            after cancellation requires subscribing for $14.99 per year.</span>
          </p>
        </div>
        <div className="sm-actually-cancel">
          <p>If you are sure you would like to cancel your subscription, click the 
          link below. <b>Your tattoo will stop playing with the skinmotion app when your
          subscription expires on { formatDate(marker.get('subscription_expires')) } </b></p>
          <p> Yes I am sure, <span onClick={this.cancel}>cancel my subscription now.</span></p>
        </div>
      </div>
    )
  }
}

export default TattooBillingCancel