import API from '../utils/API'

export const RECEIVE_APPLICATION = 'ARTISTS/RECEIVE_APPLICATION'
export const RECEIVE_APPLICATIONS = 'ARTISTS/RECEIVE_APPLICATIONS'

export const receiveApplication = application => ({
  type: RECEIVE_APPLICATION,
  application
})

export const receiveApplications = applications => ({
  type: RECEIVE_APPLICATIONS,
  applications
})

export const saveApplication = (userId, appData) =>
  dispatch => {
    // API POST request to save application data
    const applicationPayload = {
      user_id: userId,
      application_data: JSON.stringify(appData)
    }
    return API.post('/artist_applications', applicationPayload)
    .then(data => {
      dispatch(receiveApplication(data));
      return Promise.resolve();
    })
  }

export const submitQuiz = (applicationId, answers) => {
  // API PATCH request to update application data
  const quizAnswerPayload = {
    test_answers: JSON.stringify(answers)
  }
  return updateApplication(applicationId, quizAnswerPayload);
}

export const requestRetest = (applicationId) => {
  const requestPayload = {
    request_retest: true
  }
  return updateApplication(applicationId, requestPayload);
}

export const resetQuiz = (applicationId) => {
  const payload = {
    reset_quiz: true
  }
  return updateApplication(applicationId, payload, true); 
}

export const updateApplication = (applicationId, params, fetchAll=false) => 
  dispatch => {
    return API.patch(`/artist_applications/${applicationId}`, params)
    .then(data => {
      if(fetchAll) {
        dispatch(fetchApplications());
      } else {
        dispatch(receiveApplication(data));
      }
      return Promise.resolve();
    })
  }

export const fetchApplication = applicationId => 
  dispatch => {
    return API.get(`/artist_applications/${applicationId}`)
    .then(data => {
      dispatch(receiveApplication(data))
      return Promise.resolve();
    })
  }

export const fetchApplications = () =>
  dispatch => {
    return API.get('/artist_applications')
    .then(data => {
      dispatch(receiveApplications(data))
    })
  }
