import React, { Component } from "react";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form/immutable";
import UserSearchForm from "./UserSearchForm";
import UserDetails from "./UserDetails";
import PaymentDetails from "./PaymentDetails";
import TattooDetails from "./TattooDetails";
import PasswordReset from "../application/PasswordReset";
import { fetchMarker } from "../../actions/markers";

class UserManager extends Component {
  state = {
    selectedPurchase: null,
    //showPasswordReset: false
  };

  /*
  showPasswordReset = () => {
    this.setState({showPasswordReset: true});
  }

  revokeInfluencer = () => {
    this.props.updateUser(this.props.selectedUser.get('id'), {is_influencer: false});
  }
  */

  componentDidMount() {
    const { selectedUser } = this.props;
    const { subComponent, objId } = this.props.match.params;
    if (subComponent == "payment" && selectedUser) {
      this.setSelectedPurchase(objId);
    }
  }

  componentWillReceiveProps(nextProps) {
    const { selectedUser } = nextProps;
    const { subComponent, objId } = this.props.match.params;

    if (subComponent == "payment" && selectedUser) {
      this.setSelectedPurchase(objId, nextProps);
    }
  }

  setSelectedPurchase = (purchaseId, nextProps = null) => {
    const { selectedUser } = nextProps ? nextProps : this.props;
    const purchases = selectedUser.get("purchases");
    if (purchases) {
      const purchase = purchases.find(
        (purchase) => purchase.get("id") == purchaseId
      );
      this.setState({ selectedPurchase: purchase });
    }
  };

  getTattooStatus = (marker) => {
    if (marker.get("is_active")) {
      return `Activated on ${marker.get("activation_date")}`;
    } else if (marker.get("is_deleted")) {
      return `Deleted`;
    } else if (marker.get("current_step") > 8) {
      return `In activation queue`;
    } else if (marker.get("was_rejected")) {
      return `Tattoo Image Rejected`;
    } else if (!marker.get("is_paid")) {
      return `Refunded`;
    } else {
      return `Payment Complete - Step ${marker.get("current_step")}`;
    }
  };

  getComponentToRender(componentName) {
    if (componentName == "payment") {
      return PaymentDetails;
    }
    if (componentName == "tattoo") {
      return TattooDetails;
    }
    return UserDetails;
  }

  render() {
    const { selectedUser } = this.props;
    const { subComponent, objId, adminFunc } = this.props.match.params;
    const UsersSubComponent = this.getComponentToRender(subComponent);

    return (
      <div className="sm-admin-user-management">
        {!selectedUser ? (
          <UserSearchForm
            fetchUser={this.props.fetchUser}
            fetchUsers={this.props.fetchUsers}
            push={this.props.push}
          />
        ) : (
          <UsersSubComponent
            push={this.props.push}
            objId={objId}
            adminFunc={adminFunc}
            fetchUser={this.props.fetchUser}
            updateUser={this.props.updateUser}
            fetchMarker={this.props.fetchMarker}
            selectedUser={selectedUser}
            selectedPurchase={this.state.selectedPurchase}
            setSelectedPurchase={this.setSelectedPurchase}
            getTattooStatus={this.getTattooStatus}
          />
        )}

        {/* (
          <div className="sm-admin-edit-user">
            <div onClick={this.showPasswordReset} className="btn btn-blue">Reset Password</div>
            {selectedUser.get('is_influencer') && (
              <div onClick={this.revokeInfluencer} className="btn btn-blue">Revoke Influencer</div>
            )}

            {this.state.showPasswordReset && (<PasswordReset adminUser={selectedUser} />)}
          </div>
        ) */}
      </div>
    );
  }
}

const mapStateToProps = (store) => ({
  users: store.get("users"),
});

const mapDispatchToProps = (dispatch) => ({
  fetchMarker: (markerId) => dispatch(fetchMarker(markerId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(UserManager);
