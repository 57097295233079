import React from 'react';

class PatentPending extends React.Component {

  render() {
    return (
      <div className="sm-patent-pending">
      <div className='title-main' id='title-patent-pending'>
        <h1>Patent Pending</h1>
      </div>
  		<div className="sm-patent-pending-main">
  			<p>Skin Motion&trade; is a proprietary augmented reality cloud platform and is patent pending.</p>
  			<p>For more information contact: legal@skinmotion.com</p>
  		</div>
  	</div>
    )
  }
}

export default PatentPending