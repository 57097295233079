import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Field, reduxForm } from 'redux-form/immutable';
import { connect } from 'react-redux';
import API from '../../utils/API';
const required = value => (value ? undefined : 'Required')

class ArtistReview extends Component {

  state = {
    success: null,
    disableButton: null
  }

  renderTextAreaField(field) {
    return (
      <div>
        <label className="sm-field-label">
          {field.labelName}
        </label>
        <textarea
          type="text"
          rows="15"
          cols="50"
          {...field.input}
        />
        <div className='sm-error-message'>
          {field.meta.touched ? field.meta.error : ''}
        </div>
      </div>
    )
  }

  onSubmit = (values) => {
    const { resetForm } = this.props;
    this.setState({disableButton: true});
    if(this.props.currentUser) {
      values = values.set('name', `${this.props.currentUser.get('first_name')} ${this.props.currentUser.get('last_name')}`);
      values = values.set('from_email', this.props.currentUser.get('email'));
    }
    return API.post('/email_support', values.toJS())
    .then((response) => {
      if(!response.error) {
        this.setState({success: true, disableButton: null});
      } else {
        this.setState({success: false, disableButton: null});
      }
    });
  }

  render() {
    const { handleSubmit } = this.props;

    return (
      <div className="sm-artist-review">
        <div className='title-main' id='title-artist-review'>
          <h1>Artist Review</h1>
        </div>
        <div className='sm-artist-review-main'>
          <form className='sm-form' onSubmit={handleSubmit(this.onSubmit)}>
            <label htmlFor='rating'>Rate "Artist Name"</label>
            <Field
              name="rating"
              component="select">
              <option value="5">5</option>
              <option value="4">4</option>
              <option value="3">3</option>
              <option value="2">2</option>
              <option value="1">1</option>
            </Field>
            <Field
              labelName='Tell Us About "Artist Name"'
              name="review"
              component={this.renderTextAreaField}
              validate={required} />
            <button className="btn btn-blue" type="submit" disabled={this.state.disableButton}>SUBMIT</button>
          </form>
        </div>
      </div>
    )
  }
}

const ReduxFormArtistReview = reduxForm({ form: 'ArtistReviewForm' })(ArtistReview);

const mapStateToProps = (store) => ({
  currentUser: store.get('currentUser')
})

export default connect(mapStateToProps, null)(ReduxFormArtistReview)
