import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

import { fetchMarkers } from "../../../actions/markers";

import TattooListComponent from "./TattooListComponent";

import { isUsersTattoo } from "../../../models/marker";

class MyTattoos extends React.Component {
  state = {
    loading: null,
  };

  componentDidMount() {
    this.setState({ loading: true });
    this.props.fetchMarkers().then(() => this.setState({ loading: false }));
  }

  render() {
    const { markers } = this.props;
    if (this.state.loading) {
      return (
        <div className="sm-waveform-loading">
          <p>Loading Tattoos...</p>
          <div className="sm-loader"></div>
        </div>
      );
    }
    return (
      <div className="sm-my-tattoos">
        {markers.size === 0 && (
          <p>
            You haven't purchased any tattoo activations. Create a Soundwave to
            activate <Link to="/new-soundwave">here</Link>!
          </p>
        )}

        {markers.valueSeq().map((marker) => (
          <TattooListComponent
            key={marker.get("id")}
            marker={marker}
            push={this.props.push}
          />
        ))}
      </div>
    );
  }
}

const mapStateToProps = (store) => ({
  markers: store
    .get("markers")
    .filter((marker) => isUsersTattoo(marker, store.get("currentUser"))),
});

const mapDispatchToProps = (dispatch) => ({
  fetchMarkers: () => dispatch(fetchMarkers()),
});

export default connect(mapStateToProps, mapDispatchToProps)(MyTattoos);
