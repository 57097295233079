import React from "react";
// import { Link } from 'react-router-dom';
import { connect } from "react-redux";
import ArtistRow from "../artists/ArtistRow";
import API from "../../utils/API";
// import { getDistance } from "../../utils/geolocation";

class ArtistsTable extends React.Component {
  state = {
    artists: [],
    error: null,
    defaultLocation: { lat: 34.0195, lng: -118.4912, zoom: 2 },
  };

  componentDidMount() {
    this.initializeData();
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.search !== prevProps.search ||
      this.props.searchRadius !== prevProps.searchRadius ||
      this.props.searchObject !== prevProps.searchObject
    ) {
      this.initializeData();
    }
  }
  componentWillReceiveProps(nextProps) {
    const { artists } = nextProps;
    if (artists) {
      this.calculateDistances(artists);
    }
  }

  initializeData() {
    const { artists } = this.props;
    if (artists) this.calculateDistances(artists);
  }

  async calculateDistances(artists) {
    try {
      const userLocation = await this.getUserLocation();
      let artistList = [];
      let searchObject = this.props.searchObject;
      if (artists) {
        // console.log({ searchObject });

        artists.forEach((artist, artistId) => {
          let newArtist = artist.toJS();
          const distance = this.getDistance(
            searchObject?.location.lat || userLocation.lat,
            searchObject?.location.lng || userLocation.lng,
            newArtist.location.lat,
            newArtist.location.lng
          );

          if (userLocation.lat === this.state.defaultLocation.lat) {
            if (newArtist.location.lat)
              artistList.push({ distance, ...newArtist });
          } else if (distance < parseInt(this.props.searchRadius)) {
            artistList.push({ distance, ...newArtist });
          }
        });
        artistList.sort(
          (a, b) => parseFloat(a.distance) - parseFloat(b.distance)
        );

        this.setState({ artists: artistList });
      }
    } catch (error) {
      console.log({ error });
    }
  }
  deg2rad(deg) {
    return deg * (Math.PI / 180);
  }
  getDistance(lat1, lon1, lat2, lon2) {
    const R = 3959; // Earth's mean radius in miles
    const dLat = this.deg2rad(lat2 - lat1);
    const dLon = this.deg2rad(lon2 - lon1);
    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(this.deg2rad(lat1)) *
        Math.cos(this.deg2rad(lat2)) *
        Math.sin(dLon / 2) *
        Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const d = R * c; // distance in miles
    return d.toFixed(1); // Round the distance to one decimal place to match the output of the second code
  }

  getUserLocation() {
    return new Promise((resolve, reject) => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const { latitude, longitude } = position.coords;
            resolve({ lat: latitude, lng: longitude });
          },
          () => {
            resolve(this.state.defaultLocation);
            // reject("Unable to retrieve user location");
          }
        );
      } else {
        reject("Geolocation not supported");
      }
    });
  }

  render() {
    const { artists, error } = this.state;
    const { searchRadius, search } = this.props;
    if (error) {
      return <div>Error: {error.message}</div>;
    }

    if (artists?.length > 0) {
      // this.calculateDistances();
      return (
        <div>
          <p>Artists within {searchRadius} miles</p>
          <div className="sm-artist-results">
            {artists.map((artist) => (
              <ArtistRow
                key={artist.id}
                artist={artist}
                distance={artist.distance}
              />
            ))}
          </div>
        </div>
      );
    } else {
      return (
        <div className="empty-list">
          <h3> No tattoo artists within {searchRadius} miles</h3>
          <h4> Contact your local tattoo artist and ask them to join.</h4>
        </div>
      );
    }
  }
}

const mapStateToProps = (store) => ({
  userLocation: store.get("userLocation"),
});

export default connect(mapStateToProps)(ArtistsTable);
