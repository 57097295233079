import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'

import InfluencerPanel from './InfluencerPanel'
import InfluencerSignup from './InfluencerSignup'

import { fetchTopReferrers } from '../../actions/topReferrers'
import { updateUser } from '../../actions/currentUser'

class InfluencerTools extends Component {

  signupInfluencer = (e) => {
    this.props.updateUser(
      this.props.currentUser.get('id'),
      { is_influencer: true }
    )
  }

  componentDidMount () {
    this.props.fetchTopReferrers();
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div className="sm-influencer-tools common-padding">
        {this.props.currentUser.get('is_influencer') ? (
          <InfluencerPanel currentUser={this.props.currentUser} topReferrers={this.props.topReferrers} />
        ) : (
          <div>
            <InfluencerSignup currentUser={this.props.currentUser} signupInfluencer={this.signupInfluencer} />
          </div>
        )}
      </div>
    )
  }
}

const mapStateToProps = store => ({
  currentUser: store.get('currentUser'),
  topReferrers: store.get('topReferrers'),
})

const mapDispatchToProps = dispatch => ({
  updateUser: (userId, userParams) => dispatch(updateUser(userId, userParams)),
  fetchTopReferrers: () => dispatch(fetchTopReferrers()),
})

export default connect(mapStateToProps, mapDispatchToProps)(InfluencerTools)
