import React from "react";
import { connect } from "react-redux";
import SetSoundwaveName from "./SetSoundwaveName";
import DownloadSoundwave from "./DownloadSoundwave";
import StyleSoundwave from "./StyleSoundwave";
import PurchaseActivation from "./PurchaseActivation";
import PurchaseConfirmation from "./PurchaseConfirmation";
import TattooImageUpload from "./TattooImageUpload";
import ArtistSecretKeyEntry from "./ArtistSecretKeyEntry";
import PlayBarColor from "./PlayBarColor";
import AwaitingActivationMessage from "./AwaitingActivationMessage";
// import API from 'utils/API'
import {
  fetchMarker,
  updateMarker,
  uploadPhoto,
  setArtistOnMarker,
} from "../../../actions/markers";
import { fetchActiveArtists } from "../../../actions/artists";

class Soundwave extends React.Component {
  state = {
    audioSrc: null,
    imgSrc: null,
  };

  componentDidMount = () => {
    window.scrollTo(0, 0);
    this.props.fetchActiveArtists();
    this.props.fetchMarker(this.props.match.params.markerId).then(() => {
      const { step } = this.props.match.params;
      const marker = this.getMarker();
      if (!step || step > marker.get("current_step")) {
        //console.log(`current_step at mount: ${marker.get('current_step')}`);
        let uri = this.getURIFromStep(parseInt(marker.get("current_step")));
        this.props.push(
          `/${uri}/${marker.get("id")}/${marker.get("current_step")}`
        );
      }
    });
  };

  componentWillReceiveProps = (nextProps) => {
    const marker = this.getMarker(nextProps);

    if (marker && !this.state.audioSrc) {
      this.loadAudio(marker);
    }
    if (marker && !this.state.imgSrc) {
      this.loadImage(marker);
    }

    if (marker && localStorage.getItem("isUpdated")) {
      this.loadAudio(marker);
      this.loadImage(marker);
    }
  };

  loadAudio = (marker) => {
    if (!marker.get("audioURL")) {
      return null;
    }
    fetch(marker.get("audioURL"))
      .then((res) => {
        return res.blob();
      })
      .then((blob) => {
        let reader = new FileReader();

        reader.addEventListener("load", () => {
          this.setState({ audioSrc: reader.result });
        });

        reader.readAsDataURL(blob);
      });
  };

  loadImage = (marker) => {
    if (!marker.get("tattooImageURL")) {
      return null;
    }
    fetch(marker.get("tattooImageURL"))
      .then((res) => {
        return res.blob();
      })
      .then((blob) => {
        let reader = new FileReader();
        reader.addEventListener("load", () => {
          this.setState({ imgSrc: reader.result });
        });
        reader.readAsDataURL(blob);
      });
  };

  saveImageInState = (dataURL) => {
    this.setState({ imgSrc: dataURL });
  };

  getMarker = (props = null) => {
    const { markers } = props || this.props;
    const { markerId } = this.props.match.params;

    const marker = markers.get(parseInt(markerId));
    return marker;
  };

  soundwaveComponentToRender = () => {
    const { step } = this.props.match.params;
    if (step == 1) {
      return SetSoundwaveName;
    }
    if (step == 2) {
      return StyleSoundwave;
    }
    if (step == 3) {
      return DownloadSoundwave;
    }
    if (step == 4) {
      return PurchaseActivation;
    }
    if (step == 5) {
      return PurchaseConfirmation;
    }
    if (step == 6) {
      return TattooImageUpload;
    }
    if (step == 7) {
      return ArtistSecretKeyEntry;
    }
    if (step == 8) {
      return PlayBarColor;
    }
    if (step == 9) {
      return AwaitingActivationMessage;
    }
    return null;
  };

  getURIFromStep = (step) => {
    let uri = step >= 5 ? "tattoos" : "soundwaves";
    return uri;
  };

  nextStep = () => {
    const { step } = this.props.match.params;
    const marker = this.getMarker();
    this.props
      .updateMarker(marker.get("id"), { marker: { increment_step: step } })
      .then((newMarker) => {
        let uri = this.getURIFromStep(parseInt(newMarker.next_step));
        this.props.push(`/${uri}/${marker.get("id")}/${newMarker.next_step}`);
      });
  };

  previousStep = () => {
    const { step } = this.props.match.params;
    const marker = this.getMarker();
    let uri = this.getURIFromStep(parseInt(step) - 1);
    this.props.push(`/${uri}/${marker.get("id")}/${parseInt(step) - 1}`);
  };

  render() {
    const marker = this.getMarker();

    if (!marker || !this.state.audioSrc) {
      return marker && marker.get("processing_error") ? (
        <div className="sm-waveform-loading">
          <p>
            There was an error processing your audio file. Please try again with
            another file format, or contact support.
          </p>
        </div>
      ) : (
        <div className="sm-waveform-loading">
          <p>Loading Audio...</p>
          <div className="sm-loader"></div>
        </div>
      );
    }

    const SoundwaveCreationStep = this.soundwaveComponentToRender();

    if (SoundwaveCreationStep) {
      return (
        <SoundwaveCreationStep
          marker={marker}
          audioSrc={this.state.audioSrc}
          imgSrc={this.state.imgSrc}
          saveImageInState={this.saveImageInState}
          updateMarker={this.props.updateMarker}
          uploadPhoto={this.props.uploadPhoto}
          setArtistOnMarker={this.props.setArtistOnMarker}
          artists={this.props.artists}
          nextStep={this.nextStep}
          push={this.props.push}
          previousStep={this.previousStep}
          stripeKey={this.props.stripeKey}
        />
      );
    } else {
      this.props.push("/my-soundwaves");
      return null;
    }
  }
}

const mapStateToProps = (store) => ({
  markers: store.get("markers"),
  artists: store.get("artists"),
});

const mapDispatchToProps = (dispatch) => ({
  fetchMarker: (markerId) => dispatch(fetchMarker(markerId)),
  updateMarker: (markerId, markerParams) =>
    dispatch(updateMarker(markerId, markerParams)),
  uploadPhoto: (markerId, params) => dispatch(uploadPhoto(markerId, params)),
  fetchActiveArtists: () => dispatch(fetchActiveArtists()),
  setArtistOnMarker: (markerId, artistId, secretKey) =>
    dispatch(setArtistOnMarker(markerId, artistId, secretKey)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Soundwave);
