import React from "react";
import { connect } from "react-redux";
import Peaks from "peaks.js";
import _ from "underscore";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import {
  defaultInMarker,
  defaultOutMarker,
} from "peaks.js/src/main/waveform/waveform.mixins";
// import API from 'utils/API'
import {
  generateSceneFunc,
  generateSegmentSceneFunc,
} from "../../../utils/waveform_helpers";
import { deleteMarker } from "../../../actions/markers";
import { isMobileWidth } from "../../../utils/general";

const DESKTOP_MAX_WIDTH = 800;
const MOBILE_MAX_WIDTH = 360;

/* In Desktop pixels */
const INITIAL_HEIGHT = 300;
const INITIAL_WIDTH = 500;

const convertDesktopDimensionToMobileDimension = (dim) =>
  Math.floor((MOBILE_MAX_WIDTH / DESKTOP_MAX_WIDTH) * dim);

const convertMobileDimensionToDesktopDimension = (dim) =>
  Math.floor((DESKTOP_MAX_WIDTH / MOBILE_MAX_WIDTH) * dim);

/*
  Takes in the desktop dimension and returns that or converts
  it to the mobile dimension if the device is on mobilez`
*/
const appropriateDimensionFromDesktopDim = (dim) =>
  isMobileWidth() ? convertDesktopDimensionToMobileDimension(dim) : dim;

class Waveform extends React.Component {
  state = {
    audioSrc: null,
    audioContext: null,
    fetchingAudio: null,
    peaksInstance: null,
    waveformSaved: null,
    loadedPeaks: false,
    initialPeaksLoad: true,
    trimming: false,
    saving: null,
    updateAudio: null,
    height: INITIAL_HEIGHT,
    width: INITIAL_WIDTH,
    renderingHeight: appropriateDimensionFromDesktopDim(INITIAL_HEIGHT),
    renderingWidth: appropriateDimensionFromDesktopDim(INITIAL_WIDTH),
    length: 0,
    name: "",
    editName: false,
    unsupportedBrowser: null,
  };

  /*
   * Initialize the state with the
   */
  componentDidMount = () => {
    const newState = {};
    if (this.props.name) {
      newState.name = this.props.name;
    }
    if (this.props.audioSrc) {
      newState.audioSrc = this.props.audioSrc;
    }
    if (this.props.height) {
      newState.height = this.props.height;
      newState.renderingHeight = appropriateDimensionFromDesktopDim(
        newState.height
      );
    }
    if (this.props.width) {
      newState.width = this.props.width;
      newState.renderingWidth = appropriateDimensionFromDesktopDim(
        newState.width
      );
    }
    if (this.props.waveformSaved) {
      newState.waveformSaved = this.props.waveformSaved;
    }
    this.setState(newState);
  };

  // When we get an update to props.marker, we need to check which
  // of the asset keys has changed so we can re-fetch them so everything
  // updates and renders correctly.
  componentWillReceiveProps = (nextProps) => {
    const currentMarker = this.props.marker;
    const nextMarker = nextProps.marker;

    // Do we need to update audio?
    if (currentMarker.get("audioURL") != nextMarker.get("audioURL")) {
      this.fetchAndLoadAudio(nextMarker);
      localStorage.setItem("isUpdated", true);
    }
  };

  componentDidUpdate = () => {
    if (
      !this.state.loadedPeaks &&
      this.state.audioSrc &&
      !this.state.peaksInstance &&
      !this.state.unsupportedBrowser
    ) {
      this.loadPeaks();
    }
  };

  componentWillUnmount = () => {
    if (this.state.peaksInstance) {
      this.state.peaksInstance.destroy();
    }
    if (this.state.audioContext) {
      this.state.audioContext.close();
    }
  };

  /*
   * Load the Peaks Instance
   */
  loadPeaks = () => {
    const browserAudioContext = window.AudioContext || false;
    //|| window.webkitAudioContext
    //|| false;

    if (!browserAudioContext) {
      this.setState({ unsupportedBrowser: true });
      return;
    }

    const myAudioContext = new browserAudioContext();

    let p = Peaks.init({
      container: document.getElementById("peaks-container"),
      mediaElement: document.querySelector("audio"),
      audioContext: myAudioContext,
      waveformBuilderOptions: {
        scale: 1,
      },
      height: this.state.renderingHeight,
      axisGridlineColor: "rgba(56, 197, 243, 1.0)",
      axisLabelColor: "rgba(56, 197, 243, 1.0)",
      zoomWaveformColor: "rgba(24,24,24, 0.5)",
    });

    this.setState({
      peaksInstance: p,
      audioContext: myAudioContext,
    });

    p.on("peaks.ready", () => {
      // Zoom the waveform out to the entire segment.

      p.emit(
        "zoom.update",
        p.waveform.waveformOverview.data.adapter.scale,
        p.zoom._zoomLevels[p.zoom._zoomLevelIndex]
      );

      p.waveform.waveformZoomView.waveformShape.stroke("rgba(24,24,24,0.5)");
      p.waveform.waveformZoomView.waveformShape.strokeWidth(10);
      p.waveform.waveformZoomView.waveformShape.sceneFunc(
        generateSceneFunc(p.waveform.waveformZoomView, 1)
      );
      //  p.waveform.waveformZoomView._playheadLayer._playheadLayer.hide()

      if (
        p.player.getDuration() === Infinity ||
        isNaN(p.player.getDuration())
      ) {
        const _player = p.player._mediaElement;
        _player.addEventListener(
          "durationchange",
          (e) => {
            if (_player.duration != Infinity) {
              _player.pause();
              _player.volume = 1;
              _player.currentTime = 0;
              this._addPeaksSegment(p.segments, _player);
              this.saveWaveform();
            }
          },
          false
        );

        _player.currentTime = 24 * 60 * 60; //fake big time
        _player.volume = 0;
        _player.play();
      } else {
        this._addPeaksSegment(p.segments, p.player._mediaElement);
        this.saveWaveform();
      }

      this.setState({ loadedPeaks: true, initialPeaksLoad: false });
    });
  };

  _addPeaksSegment = (segments, player) => {
    segments.add({
      startTime: player.duration * 0.0,
      endTime: player.duration * 1,
      editable: true,
      color: "rgba(56, 197, 243, 1.0)",
    });
    this.setWidth(this.state.renderingWidth);
    this.setHeight(this.state.renderingHeight);
    this.setState({ length: player.duration });
  };

  /*
   *
   */
  updateAudioSource = (blobOrFile) => {
    if (this.state.peaksInstance) {
      this.state.peaksInstance.destroy();
    }
    if (this.state.audioContext) {
      this.state.audioContext.close();
    }

    let reader = new FileReader();
    reader.addEventListener("load", () => {
      this.setState({ audioSrc: reader.result });
    });

    this.setState(
      {
        peaksInstance: null,
        loadedPeaks: false,
        audioSrc: null,
      },
      () => {
        reader.readAsDataURL(blobOrFile);
      }
    );
  };

  fetchAndLoadAudio = (nextMarker) => {
    if (!nextMarker.get("audioURL")) {
      // TODO -- Throw an error here when Sentry has sourcemaps and it will actually
      // help debug server stuff.
      return null;
    }
    this.setState({ fetchingAudio: true });
    fetch(nextMarker.get("audioURL"))
      .then((res) => {
        return res.blob();
      })
      .then((blob) => {
        this.updateAudioSource(blob);
      });
  };

  saveWaveform = () => {
    if (this.state.initialPeaksLoad && this.props.waveformInitiallySaved) {
      return null;
    }
    this.setState({ waveformSaved: true });
    let dataURL = this.createWaveformImage();
    let markerParams = {
      height: this.state.height,
      width: this.state.width,
      waveform_image: dataURL,
    };

    if (this.state.name != "") {
      markerParams["name"] = this.state.name;
    }
    this.props
      .updateMarker(this.props.marker.get("id"), { marker: markerParams })
      .then(() => {
        if (this.state.trimming) {
          this.setState({ fetchingAudio: false, trimming: false });
        }
      });
  };

  /*
   * Send an API request to trim the audio file as per the user defined segment
   */
  trimAudio = () => {
    const segment = this.state.peaksInstance.segments.getSegments()[0];
    this.setState({ trimming: true });
    this.props.updateMarker(this.props.marker.get("id"), {
      marker: {
        height: this.state.height,
        width: this.state.width,
        renderingHeight: appropriateDimensionFromDesktopDim(this.state.height),
        renderingWidth: appropriateDimensionFromDesktopDim(this.state.width),
      },
    });
    const markerParams = {
      trim_boundaries: {
        start_time: segment.startTime,
        end_time: segment.endTime,
        height: this.state.height,
        width: this.state.width,
        renderingHeight: appropriateDimensionFromDesktopDim(this.state.height),
        renderingWidth: appropriateDimensionFromDesktopDim(this.state.width),
      },
    };
    this.props
      .updateMarker(this.props.marker.get("id"), {
        marker: markerParams,
      })
      .then((res) => {
        this.updateHeight(res.height);
        this.updateWidth(res.width);
        this.setState({
          changed: true,
          renderingHeight: res.height,
          renderingWidth: res.width,
        });
      });
  };

  deleteMarker = () => {
    this.props
      .deleteMarker(this.props.marker.get("id"))
      .then(() => this.props.push("/my-soundwaves"));
  };

  /* React Slider Controlled Component Methods */
  updateHeight = (v) => {
    this.setState({ height: v, changed: true });
    let markerParams = {
      height: v,
      renderingHeight: appropriateDimensionFromDesktopDim(v),
    };
    this.setHeight(v);
    // this.props.updateMarker(this.props.marker.get("id"), {
    //   marker: markerParams,
    // });
  };

  updateWidth = (v) => {
    this.setState({ width: v, changed: true });
    let markerParams = {
      width: v,
      renderingWidth: appropriateDimensionFromDesktopDim(v),
    };
    this.setWidth(v);
    // this.props.updateMarker(this.props.marker.get("id"), {
    //   marker: markerParams,
    // });
  };

  /*
   * setHeight + setWidth => update the height or width of the peaks instance
   * and do all the low level things within Peaks that allow the waveform to render properly.
   */
  setHeight = (height = null) => {
    const tmp = defaultInMarker;
    if (!height) {
      height = this.state.renderingHeight;
    }
    const p = this.state.peaksInstance;
    const segment = p.segments.getSegments()[0];

    p.options.height = height;
    p.options.segmentInMarker = defaultInMarker(p.options);
    p.options.segmentOutMarker = defaultOutMarker(p.options);
    p.waveform.waveformZoomView._playheadLayer._playheadLine.points([
      0.5,
      0,
      0.5,
      height,
    ]);
    p.waveform.waveformZoomView._playheadLayer._playheadLayer.draw();
    p.segments.removeAll();
    p.segments.add({
      startTime: segment.startTime,
      endTime: segment.endTime,
      editable: true,

      color: "rgba(0, 0, 0, 1.0)",
    });
    for (var key in p.waveform.waveformZoomView._segmentsLayer._segmentGroups) {
      const sg = p.waveform.waveformZoomView._segmentsLayer._segmentGroups[key];
      sg.waveformShape.sceneFunc(
        generateSegmentSceneFunc(p.waveform.waveformZoomView, sg.segment, 1)
      );
    }
    p.waveform.waveformZoomView.height = height;
    p.waveform.waveformZoomView.stage.height(height);
    p.waveform.waveformZoomView.waveformLayer.draw();
    p.waveform.waveformZoomView._segmentsLayer._layer.draw();
  };

  setWidth = (width = null) => {
    if (!width) {
      width = this.state.renderingWidth;
    }
    const p = this.state.peaksInstance;

    p.waveform.waveformZoomView.width = width;
    p.waveform.waveformZoomView.stage.width(width);
    p.waveform.waveformOverview.data =
      p.waveform.originalWaveformData.resample(width);
    p.emit(
      "zoom.update",
      p.waveform.waveformOverview.data.adapter.scale,
      p.waveform.waveformZoomView._scale
    );
  };

  createWaveformImage = () => {
    const p = this.state.peaksInstance;
    const segment = p.segments.getSegments()[0];
    const newWidth = 1200;
    const newHeight = (newWidth / this.state.width) * this.state.height;

    p.waveform.waveformZoomView.waveformShape.fill("rgba(0, 0, 0, 1.0)");
    p.waveform.waveformZoomView._playheadLayer._playheadLayer.remove();
    p.waveform.waveformZoomView.waveformLayer.draw();
    this.setWidth(newWidth);
    this.setHeight(newHeight);
    p.segments.removeAll();
    const dataURL = p.waveform.waveformZoomView.stage.toDataURL();

    p.segments.add({
      startTime: segment.startTime,
      endTime: segment.endTime,
      editable: true,

      color: "rgba(0, 0, 0, 1.0)",
    });
    p.waveform.waveformZoomView.waveformShape.fill(p.options.zoomWaveformColor);
    p.waveform.waveformZoomView.stage.add(
      p.waveform.waveformZoomView._playheadLayer._playheadLayer
    );
    p.waveform.waveformZoomView.waveformLayer.draw();
    this.setWidth(this.state.renderingWidth);
    this.setHeight(this.state.renderingHeight);
    return dataURL;
  };

  handleNameChange = (e) => {
    this.setState({ name: e.target.value });
    this.saveName =
      this.saveName ||
      _.throttle((newName) => {
        this.props.updateMarker(this.props.marker.get("id"), {
          marker: { name: newName },
        });
      }, 1000);

    this.saveName(e.target.value);
  };

  editName = (e) => {
    this.setState({ editName: !this.state.editName });
  };

  handleContinueChange = (e) => {
    let markerParams = {
      height: this.state.height,
      width: this.state.width,
      renderingHeight: appropriateDimensionFromDesktopDim(this.state.height),
      renderingWidth: appropriateDimensionFromDesktopDim(this.state.width),
    };
    this.props.updateMarker(this.props.marker.get("id"), {
      marker: markerParams,
    });

    this.props.nextStep();
  };

  render() {
    const { audioSrc, loadedPeaks, name } = this.state;
    const { marker } = this.props;

    let peaksVisibilityState =
      !loadedPeaks || this.state.fetchingAudio ? "hidden" : "visible";

    if (this.state.unsupportedBrowser) {
      return (
        <div className="sm-waveform-tool sm-waveform-tool">
          <div className="sm-soundwave-disabled">
            <p>
              Your browser is not supported at this time. Please try again with
              the latest version of
              <a href="https://www.google.com/chrome/browser/" target="_blank">
                Chrome
              </a>{" "}
              or
              <a href="https://www.mozilla.org/firefox">Firefox</a> on a
              computer.
            </p>
          </div>
        </div>
      );
    }

    return (
      <div
        className={`sm-waveform-tool common-padding ${
          loadedPeaks ? "loaded-peaks" : ""
        }`}
      >
        {marker.get("processing_error") && (
          <div className="notification-banner error">
            We ran into a problem while trimming your audio clip, please try
            again or contact support.
          </div>
        )}
        <div className="sm-form text-left pb-2">
          <div className="center-container tooltip">
            {!this.state.editName ? (
              <div className="sm-waveform-editor-marker-name">
                <div>
                  <b>Name: </b> {name}
                </div>
                <span className="btn btn-tan" onClick={this.editName}>
                  Rename
                </span>
              </div>
            ) : (
              <div>
                <label> Edit Name: </label>
                <input
                  className="edit-name"
                  type="text"
                  value={this.state.name}
                  onChange={this.handleNameChange}
                />
                <div className="btn btn-tan" onClick={this.editName}>
                  Save
                </div>
              </div>
            )}
          </div>
        </div>

        {this.state.audioSrc && (
          <audio
            controls
            id="soundwave-audio"
            src={this.state.audioSrc}
          ></audio>
        )}
        {this.state.audioSrc && (
          <div
            style={{ visibility: peaksVisibilityState }}
            id="peaks-container"
          ></div>
        )}
        {peaksVisibilityState == "hidden" && (
          <div className="sm-waveform-updating">
            <p> Updating Waveform... </p>
            <div className="sm-loader"></div>
          </div>
        )}

        <div className="description-row custom-color">
          <div className="width-and-height-sliders">
            <div className="icon-trash" onClick={this.deleteMarker}>
              <div className="trash-lid"></div>
              <div className="trash-container"></div>
              <div className="trash-line-1"></div>
              <div className="trash-line-2"></div>
              <div className="trash-line-3"></div>
            </div>

            {this.state.peaksInstance && (
              <div className="height-slider">
                Height
                <Slider
                  value={this.state.height}
                  onChange={this.updateHeight}
                  // onAfterChange={this.setHeight}
                  min={100}
                  max={800}
                />
              </div>
            )}

            {this.state.peaksInstance && (
              <div className="width-slider">
                Width
                <Slider
                  value={this.state.width}
                  onChange={this.updateWidth}
                  // onAfterChange={this.setWidth}
                  min={100}
                  max={800}
                />
              </div>
            )}

            {this.state.audioSrc && (
              <button
                id="trim"
                className="btn btn-tan"
                onClick={this.trimAudio}
                disabled={this.state.trimming}
              >
                {this.state.trimming ? "Trimming..." : "Trim"}
              </button>
            )}
          </div>
        </div>

        <div className="row trim-screen">
          <button
            className="btn btn-tan btn-large"
            onClick={this.handleContinueChange}
            disabled={this.state.trimming || marker.get("processing_error")}
          >
            Continue
          </button>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  deleteMarker: (markerId) => dispatch(deleteMarker(markerId)),
});

export default connect(null, mapDispatchToProps)(Waveform);
