import React from 'react'
import { connect } from 'react-redux'

import TransferRow from './TransferRow'
import { fetchVouchers } from '../../actions/vouchers'
import { fetchVoucherTransfers, createVoucherTransfer, cancelVoucherTransfer } from '../../actions/voucherTransfers'

class ManageVouchers extends React.Component {

  state = {
    email: '',
    invalidRecipeint: null,
    alreadyClaimed: null,
    transferring: null,
    success: null
  }

  componentDidMount() {
    this.props.fetchVouchers(this.props.currentUser.get('id'))
    this.props.fetchVoucherTransfers()
  }

  handleEmailChange = (e) => {
    this.setState({ email: e.target.value })
  }

  transferVoucher = () => {
    this.setState({ transferring: true, invalidRecipent: false, success: false })
    this.props.createVoucherTransfer(this.state.email, this.props.vouchers.first().get('id'))
    .then(() => this.setState({ invalidRecipent: false, transferring: false, success: true, email: '' }))
    .catch(() => this.setState({ invalidRecipent: true }))
  }

  handleClaimError = () => {
    this.setState({ alreadyClaimed: true })
  }

  render() {
    return (
      <div className="sm-manage-vouchers common-padding">
        <h2> You have {this.props.vouchers.size} tattoo activation vouchers</h2>
        {this.props.vouchers.size > 0 && (
          <div className="sm-transfer-vouchers">
            <p> To give one of your vouchers to another Skin Motion user, enter their
            email address below.</p>

            <h2>Transfer a voucher</h2>
            <div className='sm-form text-left pb-2'>
              {this.state.invalidRecipent && (
                <div className="sm-error-message">
                  The email you provided is not a verified Skin Motion account. Voucher recipients
                  must verify their email address before receiving vouchers.
                </div>
              )}
              {this.state.success && (
                <div className="sm-success-message">
                  Success! Your voucher has been sent. It will not be transfered until it is accepted by the recipient.
                </div>
              )}
              <div className='transfer-form'>
                <label> Recipient's Email: </label>
                <input 
                  type='text' 
                  value={this.state.email}
                  onChange={this.handleEmailChange} />
                <button 
                  className="btn btn-tan btn-large" 
                  onClick={this.transferVoucher}
                  disabled={this.state.email == '' || this.state.transferring}>
                    {this.state.transferring ? 'Transferring...' : 'Transfer Voucher'}
                </button>
              </div>
            </div>
          </div>
        )}
        {this.props.voucherTransfers.size > 0 && (
          <div className="sm-past-transfers">
            <h2>Vouchers you have transferred to other users</h2>
            {this.state.alreadyClaimed && (
              <div className="sm-error-message">
                Sorry! The recipient has already claimed this voucher so you cannot cancel it.
              </div>
            )}
            <table className="sm-transfers-table">
              <tbody>
                {this.props.voucherTransfers.map(transfer => (
                  <TransferRow 
                    key={transfer.get('id')} 
                    transfer={transfer} 
                    cancelVoucherTransfer={this.props.cancelVoucherTransfer}
                    handleClaimError={this.handleClaimError} />
                )).valueSeq()}
              </tbody>
            </table>
          </div>
        )}
      </div>
    )
  }
}


const mapStateToProps = (store) => ({
  vouchers: store.get('vouchers'),
  currentUser: store.get('currentUser'),
  voucherTransfers: store.get('voucherTransfers')
})

const mapDispatchToProps = dispatch => ({
  fetchVouchers: userId => dispatch(fetchVouchers(userId)),
  fetchVoucherTransfers: () => dispatch(fetchVoucherTransfers()),
  createVoucherTransfer: (toUserEmail, voucherId) => dispatch(createVoucherTransfer(toUserEmail, voucherId)),
  cancelVoucherTransfer: voucherTransferId => dispatch(cancelVoucherTransfer(voucherTransferId))
})

export default connect(mapStateToProps, mapDispatchToProps)(ManageVouchers)