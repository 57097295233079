export const downloadBlob = (blob, name) => {
    var link = document.createElement("a");
    link.download = name;
    link.href= window.URL.createObjectURL(blob);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
}

export const isMobileWidth = () => {
  const width = Math.max(document.documentElement.clientWidth, window.innerWidth || 0)
  if (width <= 480) { return true }
  else { return false }
}
