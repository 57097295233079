import React, { Component } from 'react';
import YouTube from 'react-youtube'

class Press extends Component {

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
  	const opts = {
      height: '100%',
      width: '100%',
      playerVars: {
        autoplay: 0, 
        modestbranding: 1,
        listType: "playlist",
        list: "UCktuYFUTE2vAcJDqPLY5iVA",
        playsinline: 1,
        rel: 0
      }
    }
    return (
      <div className="sm-press-page">
        <div className='title-main' id='title-press'>
          <h1> Press </h1>
        </div>

	        <h2>Skin-motion in press</h2>
	        {/* <p>Skin Motion was founded after the first video of Nate's Soundwave Tattoo went viral in April 2017. 
	        Soundwave Tattoos have been featured on the following tv, news publications, websites, and social media 
	        pages. Have you seen Soundwave Tattoos somewhere interesting? Let us know on 
	        <a href="https://facebook.com/soundwavetattoos" target="_blank">Facebook
	        </a>, <a href="https://instagram.com/soundwavetattoos">Instagram</a>, and <a
	        href="https://twitter.com/soundwavetattoo">Twitter</a>.</p>
				
					<div className="sm-press-page-video">
		        <YouTube
			        videoId="6yqqmD--_mo"
			        opts={opts}
			        onReady={this._onReady}
			      />
		    	</div> */}
				<div className="sm-press-main">
	        <div className="sm-press-left">
		        <h3>Soundwave Tattoos on TV</h3>
		        <ul>
		        	<li><a href="http://www.discovery.ca/Shows/Daily-Planet/video?vid=1231569" target="_blank"><strong>Discovery Channel Canada</strong> Daily Planet</a></li>
		            <li><a href="https://www.cbsnews.com/videos/with-soundwave-tattoos-you-can-record-audio-onto-your-skin/" target="_blank"><strong>CBS News:</strong> With soundwave tattoos, you can record audio onto your skin</a></li>
		        	<li><a href="https://www.nbcnews.com/video/new-soundwave-tattoos-can-talk-and-play-music-954031171697" target="_blank"><strong>NBC News:</strong> New 'Soundwave' Tattoos Can Talk and Play Music</a></li>
		        	<li><a href="https://www.reuters.com/video/2017/05/15/artist-creates-tattoos-you-can-hear?videoId=371681390" target="_blank"><strong>Reuters:</strong> Artist creates tattoos you can hear</a></li>
		        	<li><a href="https://twitter.com/MorningExp/status/862472574873341952" target="_blank"><strong>HLN:</strong> Tech makes this tattoo talk (or sing or roar).</a></li>
		        	<li><a href="http://www.thelisttv.com/the-list/the-3-coolest-tech-tattoos-of-2017" target="_blank"><strong>The List:</strong> 3 Coolect Tech Tattoos of 2017</a></li>
		        	<li><a href="http://minnesota.cbslocal.com/2017/05/16/sound-wave-tattoos/" target="_blank"><strong>CBS Minnesota:</strong> California Artist Offers ‘Sound Wave Tattoos’</a></li>
		        	<li><a href="http://www.nbclosangeles.com/news/tech/What-Are-Soundwave-Tattoos-424078664.html" target="_blank"><strong>NBC Los Angeles:</strong> What are Soundwave Tattoos?</a></li>

		        </ul>
		         <h3>Soundwave Tattoos on Facebook</h3>
		        <ul>
		        	<li><a href="https://www.facebook.com/EveryMomInTheKnowbyAOL/videos/860773460745103/?hc_ref=PAGES_TIMELINE" target="_blank"><strong>Every Mom:</strong> Never forget the voice of a loved one with these permanent tattoos that play sound</a></li>
		            <li><a href="https://www.facebook.com/MicMedia/videos/1502358376453614/" target="_blank"><strong>MIC:</strong> These tattoos you can hear are taking ink to a whole new level.</a></li>
		        	<li><a href="https://www.facebook.com/LADbible/videos/3201517693228732/" target="_blank"><strong>LadBible:</strong> Tattoos you can actually hear! Ingenious! 😲</a></li>
		        	<li><a href="https://www.facebook.com/mashable/videos/10155211714449705/" target="_blank"><strong>Mashable:</strong> These soundwave tattoos let you immortalize your loved one's voice.</a></li>
		        	<li><a href="https://www.facebook.com/PlayGroundMag/videos/1541287055911165/" target="_blank"><strong>Playground:</strong> ¿Cómo suena tu tatuaje?</a></li>
		        	<li><a href="https://www.facebook.com/CulturaColectiv/videos/1592155714128670/" target="_blank"><strong>Cultura Collectiva:</strong> ¿Te imaginas poder llevar tu canción favorita y poder reproducirla a través de un tatuaje?</a></li>
		        	<li><a href="https://www.facebook.com/BusinessInsiderDesign/videos/346556382414168/" target="_blank"><strong>Design Insider:</strong> These tattoos hide audio messages in your skin.</a></li>
		        	<li><a href="https://www.facebook.com/viralthread/videos/772997589539593/" target="_blank"><strong>Viral Thread:</strong> These tattoos play sound you can actually listen to</a></li>
		        	<li><a href="https://www.facebook.com/boredpanda/videos/10155337645629252/" target="_blank"><strong>Bored Panda:</strong> Now you can hear your tattoos By Soundwave Tattoos</a></li>

		        </ul>
	       </div>
	        <div className="sm-press-right">
		        <h3>Soundwave Tattoos online</h3>
		        <ul>
		        	<li><a href="http://mashable.com/2017/05/08/soundwave-tattoos-are-here/#y0vAb9DsC5qK" target="_blank"><strong>Mashable:</strong> Immortalize your loved one's voice with this soundwave tattoo</a></li>
		        	<li><a href="http://time.com/4781519/tattoo-plays-audio/" target="_blank"><strong>Time:</strong> You Can Now Get a Tattoo That Actually Plays Back Your Pre-Recorded Audio</a></li>
		        	<li><a href="http://www.playboy.com/articles/soundwave-tattoos?%3Futm_source=Facebook&utm_medium=Social&utm_campaign=Native&utm_content=sexculture" target="_blank"><strong>Playboy:</strong> Soundwave Tattoos' Turn Your Skin Into a Soundtrack</a></li>
		           	<li><a href="http://www.inkedmag.com/g/tattoos-you-can-hear-see-and-hear-the-video-proof/?utm_content=inf&ipp=1" target="_blank"><strong>InkedMag:</strong> Tattoos you can hear: see and hear the proof!</a></li>
		        	<li><a href="http://www.altpress.com/news/entry/soundwave_tattoos" target="_blank"><strong>Alternative Press:</strong> These futuristic soundwave tattoos let you hear your awesome ink</a></li>
		        	<li><a href="http://www.dailymail.co.uk/sciencetech/article-4487502/Sound-wave-tattoo-lets-listen-body-art.html" target="_blank"><strong>Daily Mail:</strong> Would you get a loved one's VOICE inked on you? Bizarre 'sound wave tattoo' lets you listen to your body art</a></li>
		        	<li><a href="https://www.thesun.co.uk/tech/3516427/weird-soundwave-tattoos-can-play-a-dogs-bark-a-childs-laugh-or-even-your-favourite-song-from-your-smartphone/" target="_blank"><strong>The Sun:</strong> TAT'S AMAZING!Weird soundwave TATTOOS ‘can play a dog’s bark, a child’s laugh or even your favourite song from your smartphone’</a></li>
		           	<li><a href="http://www.news.com.au/technology/science/human-body/can-soundwave-tattoos-actually-play-a-childs-laugh-from-your-phone/news-story/7e3dbc6822533affc7dd8eba747ee4e8" target="_blank"><strong>News.com.au:</strong> Can soundwave tattoos actually play a child’s laugh from your phone?</a></li>
		           	<li><a href="http://www.elle.com.au/beauty/soundwave-tattoos-13211" target="_blank"><strong>Elle Australia:</strong> Can soundwave tattoos actually play a child’s laugh from your phone?</a></li>
		           	<li><a href="http://radio1057.iheart.com/onair/mike-jones-58134/now-you-can-hear-a-tattoo-15813262/?cmd=managed_social" target="_blank"><strong>Radio 1057:</strong> Now You Can Hear A Tattoo</a></li>
		           	<li><a href="https://www.rt.com/viral/388459-tattoo-soundwave-skin-audio/" target="_blank"><strong>RT:</strong> Artist develops ‘soundwave’ tattoos to immortalize voices of nearest & dearest</a></li>
		           	<li><a href="http://www.teenvogue.com/story/soundwave-tattoos-use-app-to-translate-image-to-sound" target="_blank"><strong>Teen Vogue:</strong> Soundwave Tattoos Use App to Translate Image to Sound</a></li>
		           	<li><a href="https://www.thisiswhyimbroke.com/sound-wave-tattoos/" target="_blank"><strong>This is why I'm Broke:</strong> Soundwave Tattoos </a></li>

		        </ul>
	       </div>
	    </div>
      </div>
    )
  }
}

export default Press
