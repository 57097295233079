import React, { Component } from "react";
import serialize from "form-serialize";
import { Elements } from "react-stripe-elements";
import { connect } from "react-redux";
import { fetchCurrentUser } from "../../actions/currentUser";
import CheckoutForm from "../checkout/CheckoutForm";

class StripeWrapper extends Component {
  render() {
    const { currentUser } = this.props;
    return (
      <div className={this.props.containerClass}>
        <Elements>
          <CheckoutForm
            children={this.props.children}
            nameOnly={this.props.nameOnly}
            submitButtonText={this.props.submitButtonText}
            successMessage={this.props.successMessage}
            amount={this.props.amount}
            submitPayment={this.submitPayment}
            currentUser={currentUser}
            paymentURL={this.props.paymentURL}
            handleResponse={this.props.handleResponse}
            fetchCurrentUser={this.props.fetchCurrentUser}
            voucherId={this.props.voucherId}
          />
        </Elements>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  fetchCurrentUser: (userId) => dispatch(fetchCurrentUser(userId)),
});

export default connect(null, mapDispatchToProps)(StripeWrapper);
