import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
// import Waveform from './Waveform'
// import ComingSoon from 'components/application/ComingSoon';
// import { hasActivatorPrivs } from 'models/user';

import { createMarker, updateMarker } from "../../../actions/markers";

class NewSoundwave extends React.Component {
  state = {
    audioSrc: null,
    uploadingAudio: null,
    error: null,
    markerId: null,
    loading: null,
  };

  appendAudio = (e) => {
    let file = e.target.files[0];

    // Check that file exists
    if (!file) {
      return;
    }

    if (file.size > 1e7) {
      this.setState({
        error:
          "This file is too large. Please upload a file less than 10MB and less than 30 seconds in length.",
      });
      // don't process audio
      return;
    }

    if (!(file.type.includes("audio") || file.type.includes("ogg"))) {
      this.setState({
        error:
          "This file doesn't seem to be an audio file. Please select again.",
      });
      return;
    }

    let reader = new FileReader();
    reader.addEventListener("load", () => {
      const audioSrc = reader.result;
      const audioObj = new Audio(audioSrc);
      audioObj.onloadedmetadata = () => {
        if (audioObj.duration > 30) {
          this.setState({
            error:
              "This file is too large. Please upload a file less than 10MB and less than 30 seconds in length.",
          });
          return;
        }

        this.setState({ uploadingAudio: true });
        // console.log("kndjksfnjkdsnfjknsjkn", this.state, audioObj);
        this.props.createMarker().then((markerId) => {
          // console.log(audioObj.duration, "helloworld");
          this.props
            .updateMarker(markerId, {
              marker: {
                audio_file: reader.result,
              },
            })
            .then(() => {
              this.setState({ uploadingAudio: false });
              this.props.push(`/soundwaves/${markerId}`);
            })
            .catch(() => {
              // console.log("njdbfhjbdsjfhbdsfhb");
              this.setState({ uploadingAudio: false });
            });
        });
      };
    });

    if (file) {
      reader.readAsDataURL(file);
    }
  };

  render() {
    const browserAudioContext = window.AudioContext || false;

    if (!browserAudioContext) {
      return (
        <div className="sm-waveform-tool common-padding">
          <div className="sm-soundwave-disabled">
            <p>
              Your browser is not supported at this time. Please try again{" "}
              <strong>on a computer</strong> with the latest version of{" "}
              <a href="https://www.google.com/chrome/browser/" target="_blank">
                Chrome
              </a>{" "}
              or <a href="https://www.mozilla.org/firefox">Firefox</a>.
            </p>
          </div>
        </div>
      );
    }

    const { currentUser } = this.props;
    return (
      <div className="sm-waveform-tool common-padding mt-0">
        <div className="upload-container d-flex flex-column justify-content-around text-center align-items-center ">
          <h2 className="text-case">
            {" "}
            Upload your audio file to create your Soundwave stencil
          </h2>
          <h2 className="custom-mb text-case">
            After you create your stencil, you can activate your Soundwave to
            play back using your account with the{" "}
            <Link to="/download">Skin Motion app</Link>.
          </h2>
          <h2 className="custom-mb text-case">
            PLEASE NOTE: We are currently experiencing issues with the Safari
            browser in support of uploading and editing audio files. Please use
            Chrome until further notice while we work to solve this issue.
          </h2>
          <ol className="tips text-left">
            <li>
              Your audio clip must be an approved audio or video file type: wav,
              mp3, ogg, h264, or mpeg4.
            </li>
            <li>
              Audio file must be smaller than 10mb and shorter than 30 seconds.
            </li>
            <li>
              After uploading your audio you will see a Soundwave image that you
              can style, download, and print.
            </li>
            <li>
              Use your Soundwave Stencil to get your Soundwave Tattoo&trade;
              from a tattoo artist in the{" "}
              <a href="/artist-directory">Tattoo Artist Directory</a>.
            </li>
            <li>
              Activation requires a payment of $39.99 to enable playback of your
              tattoo within the Skin Motion app for the first year and $14.99 a
              year after that.
            </li>
          </ol>
          <form>
            <label className="file-upload btn btn-tan">
              <input type="file" onChange={this.appendAudio} />

              {this.state.uploadingAudio ? "Uploading..." : "Upload Audio File"}
            </label>
            {this.state.error && (
              <div className="alert">{this.state.error}</div>
            )}
          </form>
          <p className="upload-tos">
            By uploading audio to Skin Motion<sup>TM</sup> you agree to only use
            audio that you created and own the copyright to as you agreed in the
            Skin Motion{" "}
            <Link to="terms-and-conditions">Terms & Conditions</Link>.
          </p>
          {/*<p className="upload-disclaimer">
            <strong>PLEASE DO NOT GET A TATTOO OF A COPYRIGHTED AUDIO CLIP</strong>. 
            <br />Any violation is subject to immidiate removal of the clip and the Skin Motion account deactivated.</p>*/}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (store) => ({
  currentUser: store.get("currentUser"),
});

const mapDispatchToProps = (dispatch) => ({
  createMarker: () => dispatch(createMarker()),
  updateMarker: (markerId, markerParams) =>
    dispatch(updateMarker(markerId, markerParams)),
});

export default connect(mapStateToProps, mapDispatchToProps)(NewSoundwave);
