import React, { Component } from 'react';
import { connect } from 'react-redux';
import { isArtist } from '../../models/user';
import DeactivateAccountButton from './DeactivateAccountButton';

class DeactivateAccount extends Component {

  render() {
    const { currentUser } = this.props;

    return ( 
      <div className="sm-deactivate-account sm-content">
        <h2>Are you sure you want to deactivate?</h2>

        <p>You can deactivate your account below.</p>

        <h4>We love to help</h4>
        <p>Before you deactivate, if you have any problems
        or concerns that we can help with please let us know and we 
        will do everything we can to resolve the issue.</p>

				{isArtist(currentUser) && (
        <div>
          <h4>Tattoo Artists</h4>
          <p>Artists that want to maintain their Skin Motion
          user account, and only be removed from the Artist Directory should 
          change their <a href="/artist-profile">Profile Settings</a> to make their Profile private.
          Artist accounts that are deactivated will also be removed from the 
          Artist Directory. Deactivating your account will also terminate your
          Skin Motion Tattoo Artist License agreement.</p>
        </div>
       	)}

        <h4>Purchase Warning</h4>
        <p class="warning-text">If you deactivate your account with the form
        below, you will loose access to all Skin Motion features, products, and 
        services and will cancel any subscriptions that may exist.</p>
        <p>By canceling, any Soundwaves or Tattoos that were in your account 
        will no longer exist. If you want to re-activate a Soundwave Tattoo 
        after this account has been deactivated, you will need to create a new 
        account, complete the activation process and pay the activation fee again.
        </p>

        <DeactivateAccountButton push={this.props.push} />
      </div>
    )
  }
}

const mapStateToProps = store => ({
  currentUser: store.get('currentUser'),
})

export default connect(mapStateToProps, null)(DeactivateAccount)
