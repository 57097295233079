import React from 'react'

import { SketchPicker } from 'react-color'

class SMColorPicker extends React.Component {
  state = {
    showPicker: false
  }

  togglePicker = () => {
    this.setState({ showPicker: !this.state.showPicker })
  }

  handleChange = (color) => {
    this.props.handleChange({
      name: this.props.name,
      value: color.rgb,
    })
  }

  closePicker = () => {
    this.setState({ showPicker: false })
  }

  render () {
    const { value } = this.props
    const colorRGBA = `rgba(${value.r}, ${value.g}, ${value.b}, ${value.a})`
    return (
      <div className='sm-color-picker'>
        <div className='color-picker-swatch' onClick={this.togglePicker}>
          <div className='color' style={{ background: colorRGBA }} />
        </div>

        {
          this.state.showPicker && (
            <div className='popover'>
              <div className='cover' onClick={this.closePicker} />
              <SketchPicker color={value} onChange={this.handleChange} />
            </div>
          )
        }
      </div>
    )
  }
}

export default SMColorPicker
