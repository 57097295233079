import API from "../utils/API";

export const RECEIVE_USER = "USERS/RECEIVE_USER";
export const RECEIVE_USERS = "USERS/RECEIVE_USERS";

export const setUser = (user) => ({
  type: RECEIVE_USER,
  user,
});

export const setUsers = (users) => ({
  type: RECEIVE_USERS,
  users,
});

export const fetchUser = (userId) => (dispatch) => {
  return API.get(`/users/${userId}`).then((data) => {
    dispatch(setUser(data));
    return Promise.resolve();
  });
};

export const fetchUsers = (userIds) => (dispatch) => {

  return API.get("/users").then((data) => {
    dispatch(setUsers(data));
    return Promise.resolve();
  });
};

export const updateUser = (userId, userParams) => (dispatch) => {
  return API.patch(`/users/${userId}`, userParams)
    .then((user) => {
      dispatch(setUser(user));
      return Promise.resolve();
    })
    .catch((err) => {
      return Promise.reject();
    });
};

export const assignVoucher = (userId) => (dispatch) => {
  return API.post("/tattoo_vouchers", { user_id: userId }).then(() => {
    dispatch(fetchUser(userId));
    return Promise.resolve();
  });
};
