import Immutable from 'immutable'
import {
  RECEIVE_INSTAGRAM_POSTS,
} from '../actions/instagram'

const instagram = (state = Immutable.Map(), action) => {
  switch (action.type) {
    case RECEIVE_INSTAGRAM_POSTS:
      return state.set(action.username, Immutable.fromJS(action.posts))
    default:
      return state
  }
}

export default instagram
