import Immutable from 'immutable';
import {
  RECEIVE_USER_LOCATION,
  SET_SEARCH_LOCATION,
  CLEAR_SEARCH_LOCATION
} from '../actions/userLocation';

const userLocation = (state = Immutable.Map(), action) => {
  switch(action.type) {
    case RECEIVE_USER_LOCATION:
      return state.set('user', Immutable.fromJS(action.location))
    case SET_SEARCH_LOCATION:
      return state.set('search', Immutable.fromJS(action.searchObject))
    case CLEAR_SEARCH_LOCATION:
      return state.delete('search')
    default:
      return state
  }
}

export default userLocation