import React, { Component } from "react";
// import Immutable from 'immutable';
import { Field, reduxForm } from "redux-form/immutable";
import { reset } from "redux-form";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import API from "../../utils/API";
// import FAQSliders from 'components/application/FAQSliders';

const required = (value) => (value ? undefined : "Required");

class Support extends Component {
  state = {
    success: null,
    disableButton: null,
  };

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  renderNameField(field) {
    return (
      <div>
        <input type="text" {...field.input} />
        <div className="sm-error-message">
          {field.meta.touched ? field.meta.error : ""}
        </div>
      </div>
    );
  }

  renderEmailField(field) {
    return (
      <div>
        <input type="email" {...field.input} />
        <div className="sm-error-message">
          {field.meta.touched ? field.meta.error : ""}
        </div>
      </div>
    );
  }

  renderTextAreaField(field) {
    return (
      <div>
        <textarea type="text" rows="15" cols="50" {...field.input} />
        <div className="sm-error-message">
          {field.meta.touched ? field.meta.error : ""}
        </div>
      </div>
    );
  }

  onSubmit = (values) => {
    const { resetForm } = this.props;
    this.setState({ disableButton: true });
    if (this.props.currentUser) {
      values = values.set(
        "name",
        `${this.props.currentUser.get(
          "first_name"
        )} ${this.props.currentUser.get("last_name")}`
      );
      values = values.set("from_email", this.props.currentUser.get("email"));
    }
    return API.post("/email_support", values.toJS()).then((response) => {
      if (!response.error) {
        this.setState({ success: true, disableButton: null });
      } else {
        this.setState({ success: false, disableButton: null });
      }
    });
  };

  render() {
    const { handleSubmit } = this.props;
    return (
      <div className="sm-support">
        <div className="title-main title-short" id="title-support">
          <h1>Support</h1>
        </div>
        <div className="sm-support-main sm-content">
          <div className="support-intro">
            <div className="text-center">
              <h3>Have a Question?</h3>
              <p>
                Answers to the questions you have without the wait for a
                reponse. We answered the most common questions to save you time.
              </p>
              <p className="center">
                <Link to="/faq" className="btn btn-tan">
                  Frequently asked questions
                </Link>
              </p>
              {/* <p>&nbsp;</p> */}
            </div>

            <h4>
              Upload a sound that you want as a Soundwave Tattoo&trade; &nbsp;
              {!this.props.mobileMode ? (
                <a href="/new-soundwave">Here</a>
              ) : (
                <span> with a computer at SkinMotion.com</span>
              )}
              .
            </h4>
            <p>
              We generate the shape of Soundwave from that file which you can
              trim the beginning and end off and change the height and width.
            </p>
            <p>
              You take the Soundwave Tattoo&trade; stencil to a tattoo artist
              from our &nbsp;
              {!this.props.mobileMode ? (
                <a href="/artist-directory">Tattoo Artist Directory</a>
              ) : (
                <a href="/artist-directory?inapp=true">
                  Tattoo Artist Directory
                </a>
              )}
              . Tattoo artists must register to learn the requirements and
              limitations of our patent-pending technology as they apply to
              tattoo placement, size, and customization of the design of a
              Soundwave Tattoo&trade; in order to provide a 15 day money-back
              guarantee on the Soundwave Tattoo activation.
            </p>
            <p>
              After the Artist does the tattoo, you can activate it to be played
              back by the Skin Motion app for $39.99 for the first year of
              service and $14.99 a year after that.
            </p>
            <p>
              After we process your tattoo and the tattoo activation is
              complete, you can log in to your account using the Skin
              Motion&trade; app to point the camera in your mobile device at the
              tattoo to play it back.
            </p>
            <p>
              Download the Skin Motion app &nbsp;
              {!this.props.mobileMode && (
                <a href="/download" className="no-decoration">
                  available on the Apple App Store and Google Play
                </a>
              )}
              {this.props.mobileMode && (
                <span>available on the Apple App Store and Google Play</span>
              )}
              .
            </p>

            <h3 className="mb-0">
              Send an email to support @<span>Skinmotion.com</span> for
              assistance
            </h3>
          </div>

          {/* Remove old slider. Should we include the new FAQ here?
        <h3>Answers to frequently asked questions</h3>
        
        <div className="sm-faq">
          <FAQSliders mobileMode={this.props.mobileMode} />
        </div>
        
        // THIS IS REMOVED WHILE EMAIL IS BROKEN
        <div className="sm-support-email">
          <h3>Start a support request</h3>
          <p>Typical response times are between 24-48 hours. If you have a question about your account, 
          please provide as much information as possible so that we can better assist you. </p>
          {this.state.success === true && (
            <div className="sm-success-message"> Your message was sent. Please allow 24-28 hours for a reply. Thank you.</div>
          )}
          {this.state.success === false && (
            <div className="sm-error-message"> Oops! Something went wrong! One of our engineers has been notified of the problem! </div>
          )}
          <form className='sm-form' onSubmit={handleSubmit(this.onSubmit)}>
            <label htmlFor='department'>Reason:</label>
            <Field name="department" component="select">
              <option value="general-questions">General questions</option>
              <option value="my-account">Questions about my account</option>
              <option value="purchases-and-payments">Purchases and payments</option>
              <option value="soundwave-tattoo-questions">Soundwave Tattoo playback issues</option>
              <option value="tattoo-artists">Tattoo Artists & Tattoo Artist Directory</option>
              <option value="mobile-app">Questions about the Skin Motion app</option>
              <option value="website-questions">Questions about the Skin Motion Website</option>
              <option value="bug-report">Report a Bug or technical issue</option>
            </Field>
            {!this.props.currentUser && (
              <div>
                <label htmlFor='name'>Name:</label>
                <Field
                  name="name"
                  component={this.renderNameField}
                  validate={required} />
                <label htmlFor='from_email'>Email:</label>
                <Field
                  name="from_email"
                  component={this.renderEmailField}
                  validate={required} />
              </div>
            )}
            <label htmlFor='message'>Message:</label>
            <Field
              name="message"
              component={this.renderTextAreaField}
              validate={required} />
            <button className="btn btn-submit" type="submit" disabled={this.state.disableButton}>Send</button>
          </form>
        </div>*/}
        </div>
      </div>
    );
  }
}

const clearForm = (result, dispatch) => dispatch(reset("SupportForm"));

const ReduxFormSupport = reduxForm({
  form: "SupportForm",
  onSubmitSuccess: clearForm,
})(Support);

const mapStateToProps = (store) => ({
  currentUser: store.get("currentUser"),
  mobileMode: store.get("mobileMode"),
});

export default connect(mapStateToProps, null)(ReduxFormSupport);
