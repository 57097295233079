import React from 'react';

class TutorialVideo extends React.Component {

  componentDidMount() {
    window.scrollTo(0,0);
  }

  render() {
    return (
      <div className="sm-artist-app-video">
        <div className="title-main title-long" id='title-artist-application-video'>
          <h1>Tattoo artist application</h1>
          <h2>Step 2 of 3 - Video Tutorial</h2>
        </div>
        <div className="sm-artist-app-video-main">
          <div className="sm-artist-app-video-main-intro">
            <p> Please watch this video to learn about the requirements and limitations of the Skin Motion&trade;
             app and Soundwave Tattoos&trade;.</p>
            <p> After the video, you will need to pass a test about what you have learned in order to 
            complete your registration. The test has questions that you will ONLY be able to answer by watching this video.</p>
          </div>
          <div className="sm-video">
            <video controls poster="https://cdn.skinmotion.com/images/artist-application/training-video-thumb.jpg">
              <source src='https://cdn.skinmotion.com/videos/training-video.mp4' type='video/mp4'></source>
            </video>
          </div>
          <div className="nav-buttons">
            <button className="btn btn-tan btn-large" onClick={this.props.showQuiz}> Continue to Test </button>
          </div>
          <div className='video-script'>
            <h3>Transcription from video</h3>
            <p>
              Hello, thank you for filling out the Skin Motion tattoo artist network application.
            </p>
            <p>In order to register, we need to go over a few things with you about how the Skin Motion app works, placement and customization of the tattoo so you can make tattoos that your clients will love and that are compatible with the Skin  Motion app!</p>
            <p>The first thing we are going to talk about is how the Skin Motion app works. Skin Motion uses computer vision and image recognition to find shapes that the app recognizes so that it can play back the Soundwave Tattoo.</p>
            <p>When the Skin Motion app user uploads their audio, the app generates a Soundwave that they bring to you to get tattooed.</p>
            <p>The thing that makes the Skin Motion app unique is our patent pending augmented reality cloud platform.</p>
            <p>What this means is that when the user points the camera on their device at their Soundwave Tattoo, it checks the server to figure out what sound it is going to play back.</p>
            <p>In order for this to work we must make sure that the shape of the Soundwave Tattoo is readable by the device.</p>
            <p>This means that the placement of the Soundwave Tattoo is very important!</p>
            <p>When you do a Soundwave Tattoo, always consider what the camera sees; the entire tattoo must be visible for the camera in one shot. In other words, the user should never have to rotate the body part while using the app.</p>
            <p>This is important because if the tattoo goes around a body part then the camera never sees the whole tattoo at once and the app WILL NOT recognize the image.</p>
            <p>To avoid this issue, always do the Soundwave tattoo in a straight area so that when the camera sees that tattoo it is on a flat surface.</p>
            <p>If this is done correctly, when the user moves their camera around, the playback on the device will match the playback on the tattoo.</p>
            <p>Once again, the Soundwave tattoo will only be compatible with the Skin Motion app if the tattoo is straight. It will not work if it wraps around a body part.</p>
            <p>While deciding placement, it is also important to consider a location on the body that the user will feel comfortable holding their device over to play it back.</p>
            <p>The user has the option of using the forward facing camera as well. Just make sure you consider the position the person will be holding their device while playing back their tattoo. And again, make sure that the body part is as flat as possible while the user is holding the device over it.</p>
            <p>Lastly, we are going to talk about customization. This is the exciting part where you can get creative! There are a lot of things you can do with Soundwave Tattoos to make them unique for everyone who gets one.</p>
            <p>What makes a Soundwave Tattoo work, really, is the shape of the outline. It does not need to be filled in, it can be empty, it can have a design around it, or it can be simply the Soundwave. But the thing that is necessary for every single Soundwave Tattoo is the unique shape of the Soundwave that is generated by the Skin Motion app. As long as you include this shape you can customize it in any way that you and the client choose.</p>
            <p>You can also use colors, but remember the way that this works is with image recognition and image recognition requires as much contrast as possible between the ink and the skin.</p>
            <p>This is the number one reason why the Soundwave Tattoo might not work with the app  So, Always remember to include as much contrast as possible especially on darker skin tones.</p>
            <p>Now for the recap. The three things you need to remember to make Soundwave Tattoos that your clients will love and will be compatible with the Skin Motion app are:</p>
            <ul>
              <li>How the Skin Motion app works using computer vision and image recognition</li>
              <li>The placement of the Soundwave Tattoo, making sure it is straight and on the flattest surface possible. The tattoo can not wrap around any body part! Always be aware of what the camera sees when deciding placement.</li>
              <li>And Customization, which is either an outline or a filled in Soundwave with any kind of custom design around it as long as it is on a flat and level surface. Any colors can be used as long as it has as much contrast as possible.</li>
            </ul>
            <p>This concludes the instructional part of this video.</p>
            <p>The next page will have a follow up quiz to this video’s content. This is so we can be sure that you retain this important information. Once that is done you will be able to create your profile in the Skin Motion artist directory.</p>
            <p>Thank you and we look forward to having you as part of the Skin Motion artist network.</p>
          </div>
        </div>
      </div>
    )
  }
}

export default TutorialVideo
