import React from 'react';

class CampaignStepThree extends React.Component {

  render() {
    return (
      <div className='sm-swcampaign-main'>
        <div className="sm-swcampaign-shader">
          <div className="sm-swcampaign-main-smlogo">
            <h1><span>Skin Motion</span></h1>
          </div>
          <div className='sm-swcampaign-main-clientlogo'>
            <h1><span>{this.props.campaign.get('company_name')}</span></h1>
          </div>
          <div className='sm-swcampaign-main-activate-step3'>
            <h2>Your Soundwave has been activated!</h2>
            <h3>To play back your Soundwave:</h3>
            <ol>
              <li>1. Open the Skin Motion app
                  <div className="sm-swcampaign-download">
                    <div className="download-apple">
                      <a href="https://itunes.apple.com/us/app/skin-motion/id1255007214?mt=8"><span>Download on the App Store</span></a>
                    </div>
                    <div className="download-google">
                      <a href="https://play.google.com/store/apps/details?id=com.skinmotion.skinmotion"><span>Download on Google Play</span></a>
                    </div>
                  </div>
              </li>
              <li>2. Login to your account
                <div className="sm-swcampaign-user-email">
                  {this.props.campaignEmail}
                </div>
              </li>
              <li>3. Hold your device over the Soundwave to hear it!</li>
            </ol>
          </div>
        </div>
      </div>
    )
  }
}

export default CampaignStepThree