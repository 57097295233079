import Immutable from "immutable";
import { RECEIVE_VOUCHERS, RECEIVE_VOUCHER } from "../actions/vouchers";

const vouchers = (state = Immutable.Map(), action) => {
  switch (action.type) {
    case RECEIVE_VOUCHERS:
      let tempState = state;
      // console.log({ vouchers: action.vouchers });
      action?.vouchers?.data.forEach((voucher) => {
        tempState = tempState.set(voucher.id, Immutable.fromJS(voucher));
      });
      return tempState;
    case RECEIVE_VOUCHER:
      if (action.voucher.is_redeemed) {
        return state.delete(action.voucher.id);
      } else {
        return state.set(action.voucher.id, Immutable.fromJS(action.voucher));
      }

    default:
      return state;
  }
};

export default vouchers;
