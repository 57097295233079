import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Advertisement from '../layouts/Advertisement';
import { fetchBlogFeed } from '../../actions/blogFeed';
class News extends Component {

  state = {
    page: 0,
    loading: null
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  renderNews = () => {
    return (
      <div>
        <div className="sm-news-article">
          <div className="sm-news-thumbnail"><img src={this.props.item.get('image')}></img></div>
          <div className="sm-news-contents">
            <div className="sm-news-con-title">
              <div className="sm-news-date"><span>{this.props.item.get('pubDay')}</span><h5>{this.props.item.get('pubMonth')}</h5></div>
              <h3><a href={this.props.item.get('link')} title={this.props.item.get('title')}>{this.props.item.get('title')}</a></h3>
            </div>
            <p>{this.props.item.get('description')}</p>
            <div className="sm-news-readmore">
              <a href={this.props.item.get('link')} title={this.props.item.get('title')}>Read More</a>
            </div>
          </div>
        </div>
      </div>
    )
  }

  render() {
    return (
      <div className="sm-news">
        <div className="title-main title-short" id="title-news">
            <h1>NEWS</h1>
        </div>
        <div className="sm-news-main">
          <h3>Follow us for updates!</h3>
          <p>Follow us on Twitter <a href="https://twitter.com/skinmotionapp" target="_blank">@skinmotionapp</a> and on Facebook for the latest updates to Skin Motion app.</p>
        </div>
        <Advertisement />
      </div>
    )
  }

}

const mapStateToProps = (store) => ({
  blogFeed: store.get('blogFeed')
})

const mapDispatchToProps = dispatch => ({
  fetchBlogFeed: () => dispatch(fetchBlogFeed())
})

export default connect(mapStateToProps, mapDispatchToProps)(News)
