import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
// import { logoutUser } from 'actions/currentUser';

class SoundwaveTattoos extends Component {
    constructor(props) {
        super(props);

        this.state = {};
    }

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        const { currentUser } = this.props;

        const userTattoos = this.props.currentUser ? (
            <Link className="btn-tats btn-opaque" to="my-tattoos">
                My Tattoos
            </Link>
        ) : (
            <a></a>
        );

        return (
            <div className="sm-soundwave-tattoos">
                <div
                    className="title-main title-soundwave"
                    id="title-soundwave-tattoos"
                >
                    <h1>Step 1 - Upload your Sound</h1>
                    <a
                        className="btn btn-blue btn-hero btn-cushion"
                        href="/new-soundwave"
                        title="Upload your sound"
                    >
                        Upload audio file
                    </a>
                </div>
                <h2>
                    Soundwave Tattoos play back only with the{' '}
                    <Link to="/download">Skin Motion app</Link>
                </h2>
                <div className="page-content d-flex justify-content-around flex-wrap">
                    <div className="sm-soundwave-tattoos-types common-padding">
                        <h3>
                           <span> There are three tattoo styles you can choose from: </span>
                        </h3>
                        <div className="sm-soundwave-tattoos-types-grid">
                            <div className="sm-soundwave-tattoos-type-standard">
                                <div className="gif-wrap"></div>
                                <h3>Soundwave</h3>
                                <p>
                                    The Original Soundwave Tattoo design. 
                                    Upload your audio and customize your
                                    soundwave to use as your tattoo stencil.
                                </p>
                            </div>
                            <div className="sm-soundwave-tattoos-type-script">
                                <div className="gif-wrap"></div>
                                <h3>Script</h3>
                                <p>
                                    Activate a word, signature or other writing
                                    to hear your uploaded audio.
                                </p>
                            </div>
                            <div className="sm-soundwave-tattoos-type-symbols">
                                <div className="gif-wrap"></div>
                                <h3>Symbol</h3>
                                <p>
                                    Want to activate your audio to play over a
                                    symbol or other tattoo image?  We can do
                                    that too.
                                </p>
                            </div>
                        </div>
                        <h3 className='mb-0 '>
                            <span>
                                All three tattoo styles follow the creation and
                                activation process below.  Please also familiarize
                                yourself with the Tattoo Placement section of the
                                FAQ page before choosing the location for your
                                tattoo.
                            </span>
                        </h3>
                    </div>
                    <div className="content-box d-flex justify-content-end flex-column">
                        <div className="step1">&nbsp;</div>
                        <h3>Step 1</h3>
                        <h4>Upload Your Audio</h4>
                        <p>
                            Upload an audio file to generate your Soundwave
                            stencil <a href="/new-soundwave">here</a>.
                        </p>
                    </div>
                    <div className="content-box d-flex justify-content-end flex-column">
                        <div className="step2">&nbsp;</div>
                        <h3>Step 2</h3>
                        <h4>Get it tattooed</h4>
                        <p>
                            We have a collective of authorized tattoo artists
                            who are familiar with the procedures of our audio
                            tattoos. Find one near you in our{' '}
                            <Link to="/artist-directory">
                                Tattoo Artist Directory
                            </Link>
                            .
                        </p>
                    </div>
                    <div className="content-box d-flex justify-content-end flex-column">
                        <div className="step3">&nbsp;</div>
                        <h3>Step 3</h3>
                        <h4>Purchase and Activate</h4>
                        <p>
                            Upload a photo of your tattoo and activate it for{' '}
                            <span>$39.99</span> the first year and $14.99 each
                            year after.
                        </p>
                    </div>
                </div>

                {/* <div className="sm-how-it-works-video">
                    <iframe src="https://www.youtube.com/embed/wVsXARjQc_w"></iframe>
                </div> */}

                <div className="download-app common-padding">
                    <h3>Want to try it yourself?</h3>
                    <p>
                        <Link
                            to="/download"
                            title="Download the Skin Motion app"
                        >
                            Download the app 
                        </Link>
                        &nbsp; <span> and use it to play the sample tattoos below.</span>
                    </p>
                </div>
                

                {/* <div className="testimonials">
                    <h2>Or use the Skin Motion app with the tattoo below:</h2>
                    <div className="sm-demo">
                        <div className="sm-demo-main">
                            <div className="demo-odd">
                                <div className="demo-img">
                                    <img src="https://cdn.skinmotion.com/images/layout/demo/juliana-tattoo.jpg"></img>
                                </div>
                                <div className="demo-info special-item">
                                    <div className="info-title">
                                        <div className="info-title-img">
                                            <img src="https://cdn.skinmotion.com/images/layout/demo/juliana-photo.jpg"></img>
                                        </div>
                                        <div className="info-title-content">
                                            <h3>Juliana</h3>
                                            <h4>
                                                <strong>Tattoo: </strong>
                                                Juliana's dog Baci howling
                                            </h4>
                                            <a href="https://youtu.be/wVsXARjQc_w">
                                                Watch Juliana's Soundwave Tattoo
                                                Video
                                            </a>
                                        </div>
                                    </div>
                                    <div className="info-quote">
                                        <p>
                                            "My choice was my dog Baci. He has a
                                            famous howl that he does. So I
                                            decided to get a illustration of
                                            Baci and have him howling for my
                                            Soundwave Tattoo."
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
                <div className="faq">
                    <div className='d-flex know-more-img-wrap common-padding justify-content-center'>
                        <img src='https://storage.googleapis.com/skinmotion-assets/images/general/NeilSWTattooCropped_first.jpg'></img>
                        <img src='https://storage.googleapis.com/skinmotion-assets/images/general/DaniMilkshakeCropped_second.jpg'></img>
                        
                    <p style={{ fontSize: '25px' }}>
                           <Link to="/demo">
                            Scan some additional tattoos here
                            </Link>
                    </p>
                    </div>
                    <h3>Want to know more?</h3>
                    <p>
                        {' '}
                        <Link to="/faq" className="faq-text">
                            Read the Frequently Asked Questions
                        </Link>
                    </p>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (store) => ({
    currentUser: store.get('currentUser'),
});

export default connect(mapStateToProps)(SoundwaveTattoos);
