import React, { Component } from 'react';
import { connect } from 'react-redux';
import { updateUser } from '../../../actions/currentUser';

class FreeArtistPayment extends Component {
  setPaid = (e) => {
    this.props.updateUser(this.props.currentUser.get('id'), {
      paid_artist_account: true
    })
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div className="sm-artist-payment common-padding">
      	<h2>Membership Information</h2>

      	

        <p><strong>Tattoo Artist accounts are free for all artists with no yearly subscription fee.</strong></p>
        <p>Click the button below to create your Profile in the Tattoo Artist Directory</p>

	      {this.props.currentUser && (
	         <div onClick={this.setPaid} className="btn btn-tan btn-large">Create Artist Profile</div>
	      )}
      </div>
    )
  }
}

const mapStateToProps = (store) => ({
  currentUser: store.get('currentUser')
})

const mapDispatchToProps = dispatch => ({
  updateUser: (userId, userParams) => dispatch(updateUser(userId, userParams))
})

export default connect(mapStateToProps, mapDispatchToProps)(FreeArtistPayment)
