import React from 'react';
import { connect } from 'react-redux';
import { closeMobileMode } from '../../actions/mobileMode';

class MobileHeader extends React.Component {

  closeMobile = () => {
    this.props.closeMobileMode()
  }

  render() {
    return (
      <div className="sm-mobile-app-header">
        <a 
          className="sm-mobile-close" 
          href="uniwebview://close" 
          title="Close this window" >
            <span>Close</span>
        </a>
      	<h1 id="sm-mobile-app-header-logo"><span>Skin Motion</span></h1>
      	{/* <a className="sm-mobile-back" href="javascript:history.go(-1)" title="Go back one page">Back</a> */}
      </div>
    )
  }
}

const mapDispatchToProps = dispatch => ({
  closeMobileMode: () => dispatch(closeMobileMode())
})

export default connect(null, mapDispatchToProps)(MobileHeader)