import React from "react";
import Immutable from "immutable";
import UserPurchaseRow from "../admin/UserPurchaseRow";

class UserPurchaseHistory extends React.Component {
  render() {
    const { selectedUser, fetchMarker } = this.props;
    let purchases = selectedUser.get("purchases").toJS();
    console.log("purchases", purchases);

    purchases.sort((a, b) => {
      let dateA = new Date(a.purchase_date);
      let dateB = new Date(b.purchase_date);
      return dateB.getTime() - dateA.getTime();
    });
    const sortedPurchases = Immutable.fromJS(purchases);

    return (
      <div className="sm-user-purchases-table">
        <table>
          <tbody>
            <tr>
              <td>
                {" "}
                <b> Purchase Date </b>{" "}
              </td>
              <td>
                {" "}
                <b> Total </b>{" "}
              </td>
              <td>
                {" "}
                <b> Status </b>{" "}
              </td>
              <td>
                {" "}
                <b> Action </b>{" "}
              </td>
            </tr>
            {sortedPurchases.map((purchase, purchaseId) => (
              <UserPurchaseRow
                key={purchaseId}
                push={this.props.push}
                purchase={purchase}
                selectedUser={selectedUser}
                setSelectedPurchase={this.props.setSelectedPurchase}
                fetchMarker={fetchMarker}
              />
            ))}
          </tbody>
        </table>
      </div>
    );
  }
}

export default UserPurchaseHistory;
