import React from 'react'
import { connect } from 'react-redux';
import Immutable from 'immutable';
import { Field, reduxForm, change } from 'redux-form/immutable';
import { required } from '../../utils/form_validators';
import { fetchCampaigns, completeCampaignSignup } from '../../actions/campaigns';

const FORM_NAME = "campaignStepTwo"

const renderField = props => {
  const additionalClasses = props.meta.touched && props.meta.error ? props.meta.error : ''
  return (
    <input
      {...props.input}
      placeholder={props.placeholder}
      type={props.type}
      className={`${props.className} ${additionalClasses}`} />
  )
}

class CampaignStepTwo extends React.Component {

  state = {
    submitting: false,
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  onSubmit = (params) =>  {
    this.setState({submitting: true})
    let completeParams = params.set('email', this.props.campaignEmail)
    completeParams = completeParams.set('completion_token', this.props.newUserToken)
    completeParams = completeParams.set('complete_signup', true)
    this.props.completeCampaignSignup(this.props.campaign.get('id'), completeParams)
    .then(() => this.props.setStep(2))
  }

  render() {
    const { handleSubmit, error, submitFailed, onSubmit } = this.props

    return (
      <div className="sm-swcampaign-main">
        <div className="sm-swcampaign-shader">
          <div className="sm-swcampaign-main-smlogo">
            <h1><span>Skin Motion&trade;</span></h1>
          </div>
          <div className='sm-swcampaign-main-clientlogo'>
            <h1><span>{this.props.campaign.get('company_name')}</span></h1>
          </div>
          <div className="sm-swcampaign-main-activate">
            <h2>Soundwave Activation</h2>

            <form onSubmit={handleSubmit(this.onSubmit)}>

              {this.props.newUserToken ? (
                <div>
                  <div className="sm-campaign-main-activate-legend">
                    <p>Finish creating your Skin Motion account</p>
                  </div>
                  <div className="sm-swcampaign-name">
                    <div className="sm-swcampaign-name-fname">
                      <Field
                        type="text"
                        name="first_name"
                        component={renderField}
                        validate={required}
                        placeholder="First Name" />
                    </div>
                    <div className="sm-swcampaign-name-lname">
                      <Field 
                        type="text"
                        name="last_name"
                        component={renderField}
                        validate={required}
                        placeholder="Last Name" />
                    </div>
                  </div>
                  <div className="sm-swcampaign-password">
                    <Field 
                      type="password"
                      name="password"
                      component={renderField}
                      validate={required}
                      placeholder="Password" />
                  </div>
                  <div className="sm-swcampaign-password">
                    <Field 
                      type="password"
                      name="confirm_password"
                      component={renderField}
                      validate={required}
                      placeholder="Confirm Password" />
                  </div>
                  <div className="sm-swcampaign-main-activate-grid">
                    <div className="sm-swcampaign-main-activate-grid-right">
                      <p>Do you agree to Skin Motion's <a onClick={this.props.showTerms}>Skin Motion Terms 
                      of Use</a> and <a onClick={this.props.showPrivacy}>Privacy Policy</a>?</p>
                    </div>
                    <div className="sm-swcampaign-main-activate-grid-left">
                      <div className="consent-checkbox">
                        <Field
                          type="checkbox"
                          component={renderField}
                          name="terms"
                          validate={required}
                          className="required"/>
                      </div>
                      <p>Yes</p>
                    </div>
                  </div>
                </div>
              ) : (
                <div>
                  {(!this.props.currentUser || (this.props.currentUser && this.props.currentUser.get('email') != this.props.campaignEmail)) && (
                    <div>
                      <div className="sm-campaign-main-activate-legend">
                        <p>Login to your Skin Motion account to continue Soundwave activation.</p>
                      </div>
                      <div className="sm-swcampaign-password">
                        <Field
                          type="password"
                          name="password"
                          component={renderField}
                          validate={required}
                          placeholder="Your Password" />
                      </div>
                    </div>
                  )}
                </div>
              )}

              <div className="sm-swcampaign-main-activate-grid">
                <div className="sm-swcampaign-main-activate-grid-right">
                  <p>Do you consent to Skin Motion sharing your email address and name, and zip code with {this.props.campaign.get('company_name')} for marketing purposes?</p>
                </div>
                <div className="sm-swcampaign-main-activate-grid-left">
                  <div className="consent-checkbox">
                    <Field
                      type="checkbox"
                      component={renderField}
                      name="client_consent"
                      validate={required}
                      className="required"/>
                  </div>
                  <p>Yes</p>
                </div>
              </div>
              <div className="sm-campaign-main-activate-disclaimer">
                <p>All fields are required to continue activation.</p>
              </div>

              <button 
                type="submit"
                className="sm-swcampaign-main-activate-submit"
                disabled={this.state.submitting} >
                {this.state.submitting ? "Completing activation..." : "Complete Activation"}
              </button>
            </form>
          </div>
        </div>
      </div>
    )
  }
}

const ReduxFormCampaignFormTwo = reduxForm({
  form: FORM_NAME
})(CampaignStepTwo)

const mapStateToProps = (store) => ({
  currentUser: store.get('currentUser')
})

const mapDispatchToProps = dispatch => ({
  completeCampaignSignup: (campaignId, signupData) => dispatch(completeCampaignSignup(campaignId, signupData))
})

export default connect(mapStateToProps, mapDispatchToProps)(ReduxFormCampaignFormTwo)