import React from 'react';

class CampaignFooter extends React.Component {

  render() {
    return (
      <div className="sm-swcampaign-footer">
        <p className="sm-swcampaign-footer-copyright">
          &copy; 2021 Skin Motion&trade;
        </p> 
        <p>
          <a onClick={this.props.showTerms}>Terms of Use</a> | <a onClick={this.props.showPrivacy}>Privacy Policy</a> | 
          Patent-pending
        </p>
      </div>
    )
  }
}

export default CampaignFooter