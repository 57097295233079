import React, { Component } from "react";
import { connect } from "react-redux";
// import { Link } from 'react-router-dom';
// import FAQSliders from 'components/application/FAQSliders';

class FAQs extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div className="sm-faq">
        <div className="title-main title-short" id="title-faq">
          <h1>FAQ’s</h1>
        </div>
        <div className="sm-faq-main">
          {/*  {!this.props.mobileMode ? (
                  <a href="/new-soundwave">here</a>
                ) : (
                  <span> with a computer at SkinMotion.com</span>
                )} */}

          <div className="sm-faq-main-topics">
            <h4>Topics</h4>
            <ul>
              <li>
                <a href="#faq-soundwavetattoos" className="topic-sub-title">
                  Soundwave Tattoos
                </a>
              </li>
              <li>
                <ul>
                  <li>
                    <a href="#faq-howitworks">How it works</a>
                  </li>
                  <li>
                    <a href="#faq-uploading-audio">Uploading audio</a>
                  </li>
                  <li>
                    <a href="#faq-sw-customizing">Customizing a tattoo</a>
                  </li>
                  <li>
                    <a href="#faq-sw-music">Using audio from music</a>
                  </li>
                  <li>
                    <a href="#faq-tattoo-placement">Tattoo placement</a>
                  </li>
                  <li>
                    <a href="#faq-body-skin">Body hair and skin tone</a>
                  </li>
                  <li>
                    <a href="#faq-tattoo-care">Tattoo care and longevity</a>
                  </li>
                  <li>
                    <a href="#faq-sw-multiples">Multiple activations</a>
                  </li>
                  <li>
                    <a href="#faq-cost">Cost & subscription</a>
                  </li>
                  <li>
                    <a href="#faq-preexisting">
                      Pre-existing tattooed soundwave
                    </a>
                  </li>
                  <li>
                    <a href="#faq-sw-troubleshoot">Troubleshooting playback</a>
                  </li>
                </ul>
              </li>
              <li>
                <a href="#faq-sw-activation" className="topic-sub-title">
                  Soundwave Tattoo Activation
                </a>
              </li>
              <li>
                <ul>
                  <li>
                    <a href="#faq-activation-process">The activation process</a>
                  </li>
                  <li>
                    <a href="#faq-artist-key">Artists secret key</a>
                  </li>
                  <li>
                    <a href="#faq-vouchers">Vouchers</a>
                  </li>
                  <li>
                    <a href="#faq-activation-time">How long does it take</a>
                  </li>
                  <li>
                    <a href="#faq-reactivation">Re-activation</a>
                  </li>
                </ul>
              </li>
              <li>
                <a href="#faq-tattooartists" className="topic-sub-title">
                  Tattoo Artists
                </a>
              </li>
              <li>
                <ul>
                  <li>
                    <a href="#faq-artist-directory">Artist directory</a>
                  </li>
                  <li>
                    <a href="#faq-artist-app">Artist application</a>
                  </li>
                  <li>
                    <a href="#faq-artist-license">Artist license</a>
                  </li>
                  <li>
                    <a href="#faq-artist-marketing">Artist marketing</a>
                  </li>
                  <li>
                    <a href="#faq-artist-guide">Artist training guide</a>
                  </li>
                  <li>
                    <a href="#faq-artist-report">Report an artist</a>
                  </li>
                </ul>
              </li>
              <li>
                <a href="#faq-mobile-app" className="topic-sub-title">
                  Mobile App
                </a>
              </li>
              <li>
                <ul>
                  <li>
                    <a href="#faq-devices">Supported devices</a>
                  </li>
                </ul>
              </li>
              <li>
                <a href="#faq-company" className="topic-sub-title">
                  Skin Motion
                </a>
              </li>
              <li>
                <ul>
                  {/* <li>
                    <a href="#faq-about">About us</a>
                  </li>
                  <li>
                    <a href="#faq-press">Press</a>
                  </li>
                  <li>
                    <a href="#faq-licensing">Licensing</a>
                  </li> */}
                  <li>
                    <a href="#faq-social-media">Social media</a>
                  </li>
                  <li>
                    <a href="#faq-privacy">Privacy policy</a>
                  </li>
                  <li>
                    <a href="#faq-terms">Terms of Use</a>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
          <div className="sm-faq-main-content">
            <h2>Answers to frequently asked questions</h2>
            <p>
              Welcome to the FAQ. All the information about Skin Motion app and
              Soundwave Tattoos here to provide easy access to find answers
              quickly.
            </p>

            {/* <div id="faq-soundwavetattoos" />
            <h3>Soundwave Tattoos</h3>
            <p>
              Soundwave Tattoos&trade; that can play back with a mobile app were
              first invented by tattoo artist and entrepreneur{" "}
              <a href="https://natesiggard.com" className="mb-0">
                Nate Siggard
              </a>
              . Nate tattooed one on himself and the video went viral in April
              2017 and reached more than 350 million people around the world.
            </p> */}

            <div id="faq-howitworks">
              <a href="#" className="btn btn-tan">
                Back to top
              </a>
            </div>
            <h4>How it works</h4>
            <p>
              The simple answer: Skin Motion takes your audio, creates a
              Soundwave stencil that youcan take to get tattooed. Once it's
              tattooed you upload a picture to our website and we activate your
              tattoo to play back with the Skin Motion app.
            </p>

            <p>
              The technical answer: a combination of audio processing, image
              recognition, computer vision, and cloud computing on top of a
              custom built proprietary platform to create a mixed reality experience.
            </p>

            <div id="faq-uploading-audio">
              <a href="#" className="btn btn-tan">
                Back to top
              </a>
            </div>
            <h4>Uploading Audio</h4>

            <p>
              Upload your audio file on our website using a computer. Uploading
              from mobile is not currently supported, and will be coming soon.
            </p>

            <div id="faq-sw-customizing">
              <a href="#" className="btn btn-tan">
                Back to top
              </a>
            </div>
            <h4>Customizing a tattoo</h4>

            <p>
              As long as the shape of the Soundwave stencil that is created by
              the Skin Motion website exists within the design you can add
              elements around or inside it. The Soundwave shape must be easily
              recognizable in order to playback properly.
            </p>
            <p>
              Examples include: filling the Soundwave shape in with a color or
              design, leaving the inside of the Soundwave empty so that it is
              only an outline, putting design elements around the outside of
              Soundwave outline.
            </p>

            <div id="faq-sw-music">
              <a href="#" className="btn btn-tan">
                Back to top
              </a>
            </div>
            <h4>Using audio from music</h4>

            <p>
              Assuming you own the copyright (see question above), Most modern
              music has been compressed and maximized in a process called
              Mastering that attempts increase the volume of quiet parts of a
              song and decrease the louder parts. While this makes music sound
              louder coming from a cell phone or laptop speaker, t means the
              Soundwave does not have the "peaks and valley" that older music,
              or audio recorded of a person talking may have. The "peaks and
              valleys" are what make the Soundwave Tattoo so interesting to play
              back, and it is for this reason we do not recommend getting a
              Soundwave Tattoo of commercially produced music.
            </p>

            <div id="faq-tattoo-placement">
              <a href="#" className="btn btn-tan">
                Back to top
              </a>
            </div>
            <h4>Tattoo-placement</h4>

            <p>
              Soundwave Tattoos need to be on a flat surface, and can not curve
              around body parts. Because the human body is 3D and not flat, this
              makes the placement of a Soundwave Tattoo{" "}
              <strong>very important</strong>! We recommend the inner-forearm as
              the best place, but in general legs and arms with the Soundwave
              placed so that the long edge of the stencil is placed along the
              body part so that it does not curve. Curved Soundwave Tattoos will
              not work.
            </p>

            <p>
              The tattoo should be the appropriate size so that the camera on
              your phone can see it while you are in a comfortable position. If
              you hold your phone where you want to hold it comfortably while
              playing the tattoo back with the camera on your phone open you can
              see where the tattoo should go and how big it should be.
            </p>

            <p>
              Some people have chosen to get them where they can't hold their
              phone or got them so large that they can not hold their phone
              comfortably to play the tattoo back with the Skin Motion app, and
              have had to ask others to hold their phone for them. It's your
              tattoo and you're free to do what you wish, but we recommend the
              Soundwave in the tattoo is no larger than 6 inches or 150mm.
            </p>

            <p>
              <img src="https://cdn.skinmotion.com/images/help/incorrect-placement.png" />
              <img src="https://cdn.skinmotion.com/images/help/correct-placement.png" />
            </p>

            <div id="faq-body-skin">
              <a href="#" className="btn btn-tan">
                Back to top
              </a>
            </div>
            <h4>Body hair and Skin tone</h4>

            <p>
              There is a certain tolerance to hair that the app will allow for
              thin or light hair. For thicker hair or very hairy areas the app
              will not work properly. The app needs to be able to see the tattoo
              more than it sees the hair. For this reason, we only recommend
              getting a Soundwave Tattoo™ in an area where there is minimal,
              thin or light colored hair or removing any hair hat grows over it.
            </p>

            <div id="faq-tattoo-care">
              <a href="#" className="btn btn-tan">
                Back to top
              </a>
            </div>
            <h4>Tattoo care and longevity</h4>

            <p>
              Our patented platform is engineered using modular components such 
              that everything is interchangeable. The frequency that new api's, frameworks, codecs
              and devices are coming out means that by preserving our users'
              data, we will be able to deliver it through the most advanced
              distribution channels and interfaces as augmented reality
              technology advances.
            </p>

            <div id="faq-sw-multiples">
              <a href="#" className="btn btn-tan">
                Back to top
              </a>
            </div>
            <h4>Multiple activations</h4>

            <p>
              Want more than one Soundwave Tattoo? That's great! Just make sure
              they are far enough apart so that they don't both show when using
              the camera in the Skin Motion app for playback. As long as they
              are not near each other, each one should play back.
            </p>

            <div id="faq-cost">
              <a href="#" className="btn btn-tan">
                Back to top
              </a>
            </div>
            <h4>Cost & subscription</h4>

            <p>
              The cost of the tattoo procedure is determined by and paid to a
              tattoo artist from our Tattoo Artist Directory. The cost to
              "Activate" a Soundwave tattoo so that it can be played back using
              the Skin Motion app is a one-time payment of $39.99 and $14.99 each
              year after to keep it activated. Your yearly subscription starts
              when you pay to activate your tattoo, and the card you use is
              stored securely by our payment processor Stripe to use to pay the
              subscription cost each year. We offer a 100% money-back guarantee
              on the Soundwave Tattoo "Activation" within 15 days after
              activation is complete when you go to a licensed Skin Motion
              tattoo artist.{" "}
            </p>

            <div id="faq-preexisting">
              <a href="#" className="btn btn-tan">
                Back to top
              </a>
            </div>
            <h4>Pre-existing tattooed soundwave</h4>

            <p>
              Potentially, as long as you upload the original audio file used to
              generate the Soundwave for your tattoo. Pre-existing tattoos that
              are activated with the Skin Motion app are not guaranteed to work,
              and do not qualify for our 15 day guarantee policy.
            </p>

            <div id="faq-sw-troubleshoot">
              <a href="#" className="btn btn-tan">
                Back to top
              </a>
            </div>
            <h4>Troubleshooting playback</h4>

            <p>
              If these steps did not resolve the issue we would be happy to look
              into the problem and do whatever we can to remedy the issue. We
              have a very good record of resolving issues for customers who have
              followed the instructions we provide and go to a Skin Motion
              licensed Tattoo Artist. Your satisfaction is our highest priority.
              If you have any questions, please login to your account and
              contact our <a href="/support">Support team here</a>. If you have
              not heard back from our support team within 48 hours please check
              your spam folder.
            </p>

            <div id="faq-sw-activation">
              <a href="#" className="btn btn-tan">
                Back to top
              </a>
            </div>
            <h3>Soundwave Tattoo Activation</h3>

            <p>Before playing a Soundwave Tattoo is needs to be activated.</p>

            <div id="faq-activation-process">
              <a href="#" className="btn btn-tan">
                Back to top
              </a>
            </div>
            <h4>The activation process</h4>

            <p>
              Soundwave tattoo activation is done on the Skin Motion website.
              You must use a computer for step 1 through 4. As you go through
              this process, each step is explained to you. Here is the process
              from start to finish.
            </p>

            <ol>
              <li>Upload your Sound</li>
              <li>Name your Soundwave</li>
              <li>Trim the audio and change it's height and width</li>
              <li>Download the Soundwave Stencil PDF file</li>
              <li>Get the tattoo from a licensed Skin Motion artist</li>
              <li>Pay to activate the Soundwave Tattoo</li>
              <li>Upload an photo of your tattoo.</li>
              <li>Select your tattoo artist and enter their secret key</li>
              <li>Choose your playbar color</li>
              <li>Send to activation queue</li>
              <li>Soundwave Tattoo activated and ready for playback</li>
            </ol>

            <div id="faq-artist-key">
              <a href="#" className="btn btn-tan">
                Back to top
              </a>
            </div>
            <h4>Artist secret key</h4>

            <p>
              When activating a Soundwave Tattoo you can enter the secret-key
              provided by a licensed tattoo artist from the{" "}
              <a href="/artist-directory">
                Skin Motion Tattoo Artist Directory
              </a>{" "}
              to receive a 15 day money-back satisfaction guarantee on the cost
              of your activation purchase.
            </p>

            <p>
              Artist can set their own secret key on their artist Profile page.
            </p>

            <div id="faq-vouchers">
              <a href="#" className="btn btn-tan">
                Back to top
              </a>
            </div>
            <h4>Vouchers</h4>

            <p>
              Vouchers are an alternative way to activate a Soundwave Tattoo.
              Vouchers were pre-sold prior to our launch, and are given to
              winners of contests and promotions.
            </p>

            <p>
              A Voucher can be transfered to another Skin Motion user on the
              Vouchers page within the My Account section of the website by
              entering the users email address. The recipient will receive an
              email from Skin Motion with a link to claim the voucher and
              complete the transfer.
            </p>

            <p>
              If you have a voucher it can be used for your Soundwave Tattoo
              activation. When you get to the purchase page in the Soundwave
              Tattoo activation process you will see a section that says "You
              have a Voucher" and a special button that will let you checkout
              with your voucher.
            </p>

            <p>
              Paying with a voucher is only good for the cost of the Soundwave
              Tattoo activation, and will require you to sign up for yearly
              service after the first year for $14.99.
            </p>

            <div id="faq-activation-time">
              <a href="#" className="btn btn-tan">
                Back to top
              </a>
            </div>
            <h4>How long does activation take?</h4>

            <p>
              Soundwave Tattoo Activation can take up to 24-48 hours but the
              average wait is 12 hours.{" "}
            </p>

            <div id="faq-reactivation">
              <a href="#" className="btn btn-tan">
                Back to top
              </a>
            </div>
            <h4>Re-activation</h4>

            <p>
              In the event that a Soundwave Tattoo has an issue that can not be
              resolved using our troubleshooting guide, it is possible to
              re-activate the tattoo. As tattoos age and the skin grows back
              over them they can change a lot. The first month after getting a
              tattoo is when you will see the most change. Over time the aging
              will cause the skin to lose "resolution" as far as how much little
              detail it will hold. Exposure to the sun increases this decline in
              quality as well.
            </p>

            <p>
              Because Soundwave Tattoos are a relatively new technology and we
              have not proven exactly what will happen over the lifetime of the
              tattoo. However, we have a done a lot of research that shows for
              most people a Soundwave Tattoo will be able to be re-activated and
              play as long as the tattoo remains detailed enough for the image
              recognition algorithms to work properly and should last well into
              the 60's or 70's of a healthy person.
            </p>

            <div id="faq-tattoo-artists">
              <a href="#" className="btn btn-tan">
                Back to top
              </a>
            </div>
            <h3>Tattoo Artists</h3>

            <p>
              Find an artist to do your Soundwave Tattoo that knows how to do it
              correctly and is licensed by Skin Motion by searching our Artist
              directory or ask your preferred tattoo artist to apply. Artists
              can apply for free and registration takes less than ten minutes
              and incudes a short training video.
            </p>

            <div id="faq-artist-directory">
              <a href="#" className="btn btn-tan">
                Back to top
              </a>
            </div>
            <h4>Artist directory</h4>

            <p>
              The Artist Directory is a searchable map of the Earth that shows
              where you can a licensed Skin Motion Tattoo Artist. You can search
              by entering your zip code.
            </p>

            <div id="faq-artist-app">
              <a href="#" className="btn btn-tan">
                Back to top
              </a>
            </div>
            <h4>Artist application</h4>

            <p>
              Tattoo Artists are our partners on every Soundwave Tattoo. As a
              Tattooer owned company, Skin Motion has designed our service
              around the needs of our artists. Tattoo artists can apply to
              register to become a licensed Skin Motion artist. Registration is
              free for artists. Every artist must watch a short training video
              and pass a quiz about the correct way to create a Soundwave Tattoo
              so that it will playback with the Skin Motion app.
            </p>

            <div id="faq-artist-license">
              <a href="#" className="btn btn-tan">
                Back to top
              </a>
            </div>
            <h4>Artist license</h4>

            <p>
              The current Skin Motion Tattoo Artist License can be found{" "}
              <a href="/artist-license">here</a>.
            </p>

            <div id="faq-artist-marketing">
              <a href="#" className="btn btn-tan">
                Back to top
              </a>
            </div>
            <h4>Artist marketing</h4>

            <p>
              Skin Motion licensed Tattoo Artists are required to follow our{" "}
              <a href="/marketing-guide">Marketing Guide</a>.
            </p>

            <div id="faq-artist-guide">
              <a href="#" className="btn btn-tan">
                Back to top
              </a>
            </div>
            <h4>Artist training guide</h4>

            <p>
              Artists should follow all of the instructions in the{" "}
              <a href="/artist-guide">Tattoo Artist Training Guide</a>.
            </p>

            <div id="faq-artist-report">
              <a href="#" className="btn btn-tan">
                Back to top
              </a>
            </div>
            <h4>Report an artist</h4>

            <p>
              Any Tattoo Artist who is practicing unprofessional behavior, is
              not offering Soundwave tattoos, or is otherwise violating Skin
              Motion Tatto Artist license needs to be reported to Skin Motion
              support team immediately. We take all reports of inapropriate
              behavior very seriously. Any tattoo artist found to be violating
              our policies will immidiately be removed from the tattoo artist
              directory and have their Skin Motion account and any Soundwave
              Tattoos on their account deactivated.
            </p>

            {/* <p>
            <a href="/support" className="btn btn-blue">Report an artist</a>
            </p> */}

            <div id="faq-mobile-app d-flex" className="faq-mobile-app">
              <a href="/support" className="btn btn-outline">
                Report an artist
              </a>

              <a href="#" className="btn btn-tan">
                Back to top
              </a>
            </div>
            <h3>Mobile App</h3>

            <p>
              Skin Motion platform consist of the Skin Motion website and the
              Skin Motion mobile phone app. The Skin Motion mobile phone app is
              available for iOS App Store and Google Play and is free to
              download and try yourself with the examples{" "}
              <a href="/demo">here</a>.
            </p>

            <div id="faq-devices">
              <a href="#" className="btn btn-tan">
                Back to top
              </a>
            </div>
            <h4>Supported Devices</h4>

            <h5>Operating Systems</h5>
            <ul>
              <li>iOS 13.0 or above</li>
              <li>Android 8.0 or above</li>
            </ul>

            <h5>Supported Devices</h5>
            <ul>
              <li>iPhone 8 or newer</li>
              <li>Samsung Galaxy 9 or newer</li>
            </ul>

            {/* <h3>Skin Motion</h3>

            <p>The company behind the Soundwave Tattoo.</p>

            <h4>About us</h4>

            <p>
              Skin Motion in an LLC founded in Encino, California in April 2017.
              You can learn more <a href="/about">about us here</a>.
            </p>

            <div id="faq-press">
              <a href="#" className="btn btn-tan">
                Back to top
              </a>
            </div>
            <h4>Press</h4>

            <p>
              Skin Motion began with overwhelming unsolicted support from news
              organizations and publications around the world. We are happy to
              provide a digital press-kit for registered members of the press
              who{" "}
              <a href="https://news.skinmotion.com/press-kit" target="blank">
                apply here
              </a>
              .
            </p>

            <div id="faq-licensing">
              <a href="#" className="btn btn-tan">
                Back to top
              </a>
            </div>
            <h4>Licensing</h4>

            <p>
              The Skin Motion app and platform were designed for tattoos
              specifically, but our app will work for use on other surfaces. We
              are working on addressing e interest in using Soundwaves on other
              surfaces by building a set of tools which will enable and empower
              creatives to use playback Soundwaves of all types in the near
              future.
            </p>

            <p>
              We can not guarantee that every use case will work however, we
              encourage you to create your Soundwave art piece and follow our
              activation process. As long as the shape of the Soundwave is
              similar to the shape generated by the Skin Motion Soundwave
              creation tools we should be able to activate it. You must follow
              these guidelines if you want it work:
            </p>

            <ul className="dark-txt">
              <li>
                The Soundwave must be on a flat surface. Curved surfaces will
                not work.
              </li>
              <li>
                The Soundwave must be straight. It can not curve and should not
                be distorted.
              </li>
              <li>
                The Soundwave shape generated by the Skin Motion Soundwave
                creation tools must be present in the design. It can be filled
                in, outlined, or be an empty space within a larger design.
              </li>
            </ul>

            <p>
              If you purchase an activation for an art piece, and you follow
              these guidelines and we are still unable to activate your
              Soundwave please contact support and we will do our best to help
              you.
            </p>

            <p>
              If you are interested in licensing our Soundwave app and platform
              for more than one art piece please complete our{" "}
              <a href="https://news.skinmotion.com/licensing">
                licensing request form
              </a>{" "}
              for more information.
            </p>*/}

            <div id="faq-social-media">
              <a href="#" className="btn btn-tan">
                Back to top
              </a>
            </div> 
            <h4>Social Media</h4>

            <p>
              Follow us on Instagram{" "}
              <a href="https://instagram.com/skinmotionapp" target="_blank">
                @skinmotionapp
              </a>
              <br />
              Twitter{" "}
              <a href="http://twitter.com/skinmotionapp" target="_blank">
                @skinmotionapp
              </a>
              <br />
              Facebook{" "}
              <a href="http://facebook.com/skinmotionapp" target="_blank">
                @skinmotionapp
              </a>
              .
            </p>
            <div id="faq-privacy">
              <a href="#" className="btn btn-tan">
                Back to top
              </a>
            </div>
            <h4>Privacy policy</h4>

            <p>
              Skin Motion Privacy Policy can be found{" "}
              <a href="/privacy-policy">here</a>.
            </p>
            <div id="faq-terms">
              <a href="#" className="btn btn-tan">
                Back to top
              </a>
            </div>
            <h4>Terms of Use</h4>

            <p>
              Skin Motion Terms of Use can be found <a href="/terms">here</a>.
            </p>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (store) => ({
  mobileMode: store.get("mobileMode"),
});

export default connect(mapStateToProps, null)(FAQs);
