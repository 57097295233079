import React, { Component } from "react";
import { Link } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";
import serialize from "form-serialize";
import PasswordResetToken from "../../models/password_reset_token";

class ForgotPassword extends Component {
  state = {
    error: null,
    success: null,
    captchaSolved: false,
  };

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  onChange = (value) => {
    if (value) this.setState({ captchaSolved: true });
  };

  doForgotPassword = (e) => {
    e.preventDefault();
    if (!this.state.captchaSolved)
      return this.setState({
        error: "Please solve captcha",
      });
    let data = serialize(e.target, { hash: true });
    if (data.email) {
      // TODO -- Refactor into the Redux way with reducer & actions
      let passwordResetToken = new PasswordResetToken({
        email: data.email,
      })
        .save()
        .then((error) => {
          if (error) {
            this.setState({ error: error });
          } else {
            this.setState({ success: data.email });
          }
        });
    }
  };

  render() {
    return (
      <div>
        <div className="title-main" id="title-forgot-password">
          <h1>Forgot Password?</h1>
        </div>
        <div className="sm-form-container forgot-password-wrap">
          {this.state.success ? (
            <div className="sm-pwreset-success-message">
              <h3>Email verification</h3>
              <p> An email has been sent to {this.state.success}.</p>
              <p>It contains a link you must click to reset your password. </p>
              <p className="sm-pwreset-note">
                {" "}
                Note: You can only request a new password once within 24 hours.
              </p>
              <p> If you don't get an email check your spam folder.</p>
              <div>
                <Link to="/login">Log In</Link>
              </div>
            </div>
          ) : (
            <form className="sm-form" onSubmit={this.doForgotPassword}>
              <h4>Forgot Your Password?</h4>
              <p>
                Please enter your email address and we will send you an email
                with instructions to reset your password.
              </p>
              {this.state.error && (
                <div className="sm-error-message">{this.state.error}</div>
              )}
              <div className="field-wrap">
                <label htmlFor="name">Email : </label>
                <input
                  type="email"
                  name="email"
                  className="mb-0"
                  placeholder="Email... *"
                  required
                />
              </div>
              <ReCAPTCHA
                sitekey={this.props.siteKey}
                onChange={this.onChange}
                type="image"
              />
              {/* <input className='field-wrap' type="email" placeholder="Email..." name="email" /> */}
              <button className="btn btn-tan btn-large" type="submit">
                Get Reset Link
              </button>
              <Link className="link-form" to="/login">
                Return to Login
              </Link>
            </form>
          )}
        </div>
      </div>
    );
  }
}

export default ForgotPassword;
