import React from 'react';
import API from '../../utils/API'; 

class VerificationReminder extends React.Component {

  state = {
    clicked: false
  }

  resend = () => {
    this.setState({clicked: true}); 
    return API.post('/resend_verification', {user_id: this.props.currentUser.get('id')})
  }

  renderLink() {
    if(this.state.clicked) {
      return (
        <div> Sent! Please check your inbox. </div>
      )
    } else {
      return (<div><div className="verification-link" onClick={this.resend}>Click here to resend</div></div>)
    }
  }

  render() {
    return (
      <div className="sm-verification-reminder">
        Please verify your email – {this.renderLink()}
      </div>
    )
  }
}

export default VerificationReminder