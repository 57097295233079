import API from '../utils/API'

export const RECEIVE_TRANSFERS = 'VOUCHER_TRANSFERS/RECEIVE_TRANSFERS'
export const RECEIVE_TRANSFER = 'VOUCHER_TRANSFERS/RECEIVE_TRANSFER'

export const receiveTransfers = transfers => ({
  type: RECEIVE_TRANSFERS,
  transfers
})

export const receiveTransfer = transfer => ({
  type: RECEIVE_TRANSFER,
  transfer
})

export const fetchVoucherTransfers = () => 
  dispatch => {
    return API.get('/voucher_transfers')
    .then((transfers) => {
      dispatch(receiveTransfers(transfers))
      return Promise.resolve()
    })
  }

export const createVoucherTransfer = (toUserEmail, voucherId) => 
  dispatch => {
    return API.post('/voucher_transfers', { to_user_email: toUserEmail, tattoo_voucher_id: voucherId })
    .then(transfer => {
      dispatch(receiveTransfer(transfer))
      return Promise.resolve()
    })
    .catch(() => {
      return Promise.reject()
    })
  }

export const cancelVoucherTransfer = (voucherTransferId) => 
  dispatch => {
    return API.patch(`/voucher_transfers/${voucherTransferId}`, { is_canceled: true })
    .then(transfer => {
      dispatch(receiveTransfer(transfer))
      return Promise.resolve()
    })
    .catch(() => {
      return Promise.reject()
    })
  }