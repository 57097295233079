import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { logoutUser } from "../../actions/currentUser";
import { hasAdminPrivs, isArtist, hasActivatorPrivs } from "../../models/user";

class Header extends Component {
  constructor(props) {
    super(props);

    this.state = {
      dropDown: false,
      navPage: false,
      pageType: "",
    };
  }

  componentDidMount() {
    const { currentUser, location } = this.props;
    if (currentUser) {
      if (hasAdminPrivs(currentUser)) {
        this.setState({ pageType: "Admin" });
      } else if (isArtist(currentUser)) {
        this.setState({ pageType: "Artist" });
      } else {
        this.setState({ pageType: "User" });
      }
    } else {
      this.setState({ pageType: "LoggedOut" });
    }
  }

  handleClick = () => {
    this.setState((prevState) => ({
      dropDown: !prevState.dropDown,
    }));
    window.scrollTo(0, 0);
  };

  logoClick = () => {
    this.setState({ dropDown: false });
  };

  render() {
    const { currentUser } = this.props;
    return (
      <div className="head">
        <div className="header-placeholder"></div>
        <div
          className="header"
          id={this.state.dropDown ? "drop-true" : "drop-false"}
        >
          <div className="logo">
            <Link to="/" onClick={this.logoClick}>
              <span>Skin Motion</span>
            </Link>
          </div>
          <div className="links">
            <div className="d-flex justify-content-end align-items-center">
              <Link
                className={`tattoos ${
                  location.pathname === "/soundwave-tattoos" ? "active" : ""
                }`}
                to="/soundwave-tattoos"
              >
                {" "}
                Soundwave Tattoos{" "}
              </Link>
              <Link
                className={`directory ${
                  location.pathname === "/artist-directory" ? "active" : ""
                }`}
                to="/artist-directory"
              >
                {" "}
                Tattoo Artist Directory{" "}
              </Link>
              {currentUser ? (
                <div className="nav-buttons">
                  <Link
                    className="account btn-tan"
                    to="/my-account"
                    onClick={this.logoClick}
                  >
                    {" "}
                    My Account{" "}
                  </Link>
                </div>
              ) : (
                <div className="nav-buttons">
                  <Link
                    className="signUp"
                    to="/signup"
                    onClick={this.logoClick}
                  >
                    {" "}
                    Sign Up{" "}
                  </Link>
                  <Link className="logIn" to="/login" onClick={this.logoClick}>
                    {" "}
                    Log In{" "}
                  </Link>
                </div>
              )}
            </div>
          </div>
          <div className="burger">
            <button onClick={this.handleClick} className="hamburger"></button>
          </div>
          {this.state.dropDown ? (
            <div
              id={this.state.pageType}
              className="dropDown d-flex justify-content-end"
            >
              <div className="dropdown-background"></div>
              <div className="dropDownContents">
                <Link
                  onClick={this.handleClick}
                  className="tattoosDrop"
                  to="/soundwave-tattoos"
                >
                  {" "}
                  Soundwave Tattoos{" "}
                </Link>
                <Link
                  onClick={this.handleClick}
                  className="directoryDrop"
                  to="/artist-directory"
                >
                  {" "}
                  Tattoo Artist Directory{" "}
                </Link>

                {currentUser ? (
                  <div>
                    <hr className="nav-drop-divider" />

                    {hasAdminPrivs(currentUser) && (
                      <Link
                        onClick={this.handleClick}
                        className="navDrop"
                        to="/admin"
                      >
                        Admin Dashboard
                      </Link>
                    )}
                    {hasAdminPrivs(currentUser) && (
                      <hr className="nav-drop-divider" />
                    )}

                    {hasActivatorPrivs(currentUser) && (
                      <Link
                        onClick={this.handleClick}
                        className="navDrop"
                        to="/admin/manage-tattoos"
                      >
                        Activator Queue
                      </Link>
                    )}
                    {hasAdminPrivs(currentUser) && (
                      <hr className="nav-drop-divider" />
                    )}

                    <Link
                      onClick={this.handleClick}
                      className="navDrop"
                      to="/new-soundwave"
                    >
                      {" "}
                      New Soundwave{" "}
                    </Link>
                    <Link
                      onClick={this.handleClick}
                      className="navDrop"
                      to="/my-soundwaves"
                    >
                      {" "}
                      My Soundwaves{" "}
                    </Link>
                    <Link
                      onClick={this.handleClick}
                      className="navDrop"
                      to="/my-tattoos"
                    >
                      {" "}
                      My Tattoos{" "}
                    </Link>

                    <hr className="nav-drop-divider" />

                    {isArtist(currentUser) && (
                      <Link
                        onClick={this.handleClick}
                        className="navDrop"
                        to="/artist-dashboard"
                      >
                        {" "}
                        Artist Dashboard{" "}
                      </Link>
                    )}

                    {isArtist(currentUser) && (
                      <Link
                        onClick={this.handleClick}
                        className="navDrop"
                        to="/artist-guide"
                      >
                        {" "}
                        Artist Guide{" "}
                      </Link>
                    )}

                    {isArtist(currentUser) && (
                      <Link
                        onClick={this.handleClick}
                        className="navDrop"
                        to="/artist-profile"
                      >
                        {" "}
                        My Artist Profile{" "}
                      </Link>
                    )}

                    {isArtist(currentUser) && (
                      <hr className="nav-drop-divider" />
                    )}

                    <Link
                      onClick={this.handleClick}
                      className="accountDrop navDrop"
                      to="/my-account"
                    >
                      {" "}
                      Account Dashboard
                    </Link>
                    <Link
                      onClick={this.handleClick}
                      className="navDrop"
                      to="/account-settings"
                    >
                      {" "}
                      Account Settings{" "}
                    </Link>
                    <Link
                      onClick={this.handleClick}
                      className="navDrop"
                      to="/change-password"
                    >
                      {" "}
                      Change Password{" "}
                    </Link>
                    <Link
                      onClick={this.handleClick}
                      className="navDrop"
                      to="/payments"
                    >
                      {" "}
                      Payments{" "}
                    </Link>

                    {currentUser.get("unused_voucher_count") > 0 && (
                      <Link to="/vouchers">
                        <div data-path="/vouchers" className="navDrop">
                          Vouchers
                        </div>
                      </Link>
                    )}

                    <hr className="nav-drop-divider" />

                    <Link
                      onClick={this.props.logoutUser}
                      className="logoutDrop"
                      to="/"
                    >
                      {" "}
                      Logout{" "}
                    </Link>
                  </div>
                ) : (
                  <div>
                    <Link
                      onClick={this.handleClick}
                      className="signUpDrop"
                      to="/signup"
                    >
                      {" "}
                      Sign Up{" "}
                    </Link>
                    <Link
                      onClick={this.handleClick}
                      className="logInDrop"
                      to="/login"
                    >
                      {" "}
                      Log In{" "}
                    </Link>
                  </div>
                )}
              </div>
            </div>
          ) : (
            <div />
          )}
        </div>

        {/* <div className="sm-sale-banner">
          <span className="sm-sale-banner-title"></span> Skin Motion is in the final stages of beta testing prior to relaunching for customers on August 21st.  Until then, do not get any new soundwave Tattoos.
        </div> */}
        {/* <div className="sm-sale-banner">
          <span className="sm-sale-banner-title"></span> Some users have reported issues creating Soundwave Tattoos. We are looking into the issue. Thank you for your patience.
        </div> */}
      </div>
    );
  }
}

const mapStateToProps = (store) => ({
  currentUser: store.get("currentUser"),
});

const mapDispatchToProps = (dispatch) => ({
  logoutUser: () => dispatch(logoutUser()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);
