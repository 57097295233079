import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { fetchApplication, requestRetest } from '../../../actions/artistApplications';
import ArtistApplication from '../../artists/application_process/ArtistApplication';
import ArtistQuiz from '../../artists/application_process/ArtistQuiz';
import TutorialVideo from '../../artists/application_process/TutorialVideo';

const kTEST_FAILED = 0;
const kTEST_PASSED = 1;
const kTEST_RESET = 2;

const kARTIST_RETEST_REQUESTED = 5;

class ArtistApplicationManager extends React.Component {

  state = {
    showQuiz: false
  }

  componentDidMount() {
    window.scrollTo(0,0);
    if(this.props.currentUser.get('application_id')) {
      this.props.fetchApplication(this.props.currentUser.get('application_id'));      
    }
  }

  applicationNotSubmitted() {
    return (!this.props.application || !this.props.application.get('application_data'))
  }

  noTestResults() {
    return (this.props.application.get('test_result') === null || this.testReset())
  }

  testPassed() {
    return (this.props.application.get('test_result') === kTEST_PASSED)
  }

  testFailed() {
    return (this.props.application.get('test_result') === kTEST_FAILED)
  }

  testReset() {
    return (this.props.application.get('test_result') === kTEST_RESET)
  }

  showQuiz = () => {
    this.setState({showQuiz: true});
  }

  requestRetest = () => {
    this.props.requestRetest(this.props.currentUser.get('application_id'));
  }

  render() {
    if(!this.props.currentUser.get('is_verified')) {
      return (
        <div className="sm-artist-app-status">
          <div className="title-main title-long" id='title-artist-application-fail'>
            <h1>Artist Application</h1>
          </div>
          <div className="sm-artist-app-status-main sm-content">
            <h3> You must verify your email address before you can apply. </h3>
            <p>To complete the Artist Application you must first verify your email address.</p>
            <p> If you didn not receive an email, please check your spam folder.</p>

            <p>Once you have verified your email, return to the Artist Application and continue the application process.</p>

          
            <p className="sm-artist-app-status-spacer">&nbsp;</p>
          </div>
        </div>
      )
    } else if(this.applicationNotSubmitted()) {
      return (<ArtistApplication />)
    } else if(this.noTestResults()) {
      return this.state.showQuiz ? (<ArtistQuiz />) : (<TutorialVideo showQuiz={this.showQuiz}/>)
    } else if(this.testFailed()) {
      return (
        <div className="sm-artist-app-status">
          <div className="title-main" id='title-artist-application-fail'>
            <h1>Artist Application</h1>
          </div>
          <div className="sm-artist-app-status-main sm-content">
            <h3>You did not pass</h3>
            <p>We're sorry, you did not pass the test required to register for the
              Skin Motion&#8482; Tattoo Artist licnese.</p>
            <p>If you would like to try again, you must request a re-test from our
              Support team. This is required in order to verify your application
              information and ensure only Professional Tattoo Artists who
              understand the requirements and limitations of the Skin Motion app and 
              Soundwave Tattoos&#8482; are in the
              Tattoo Artist Directory.</p>
            {this.props.application.get('application_state') == kARTIST_RETEST_REQUESTED ? (
              <h4>Your request has been received and will be reviewed. </h4>
            ) : (
              <button className="btn btn-tan btn-large" onClick={this.requestRetest}>Request Re-Test</button>
            )}
          </div>
        </div>
      )
    } else if(this.testPassed()) {
      return (
        <div className="sm-artist-app-status">
          <div className="title-main title-long" id='title-artist-application-pass'>
            <h1>Artist Application</h1>
          </div>
          <div className="sm-artist-app-status-main sm-content">
            <h3>Registration successful</h3>
            <p>You have completed the tutorial and passed the test. We
              recommend that you read the <Link to="/artist-guide">Artist Guide</Link> that 
              has all the details you need about creating Soundwave Tattoos.</p>
              <p>To finish your registration click the link below to create your Artist Profile.</p>
            <Link className="btn btn-tan btn-large" to="/artist-profile">Create your profile</Link>
          </div>

        </div>
      )
    }
  }
}

const mapStateToProps = (store) => ({
  currentUser: store.get('currentUser'),
  application: store.get('artistApplications').get(store.get('currentUser').get('application_id'))  // Ew. What's the right way?? TODO
})

const mapDispatchToProps = dispatch => ({
  fetchApplication: applicationId => dispatch(fetchApplication(applicationId)),
  requestRetest: (applicationId) => dispatch(requestRetest(applicationId))
})

export default connect(mapStateToProps, mapDispatchToProps)(ArtistApplicationManager)
