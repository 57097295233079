import React from 'react';

class UnsolicitedIdeaPolicy extends React.Component {

  render() {
    return (
      <div className="sm-unsolicited-idea-policy">
	      <div className="title-main title-long" id='title-terms-and-conditions'>
	        <h1>Legal</h1>
	      </div>
	      <div className="sm-unsolicited-idea-policy-main">
      	  <h2>Skin Motion Unsolicited Idea Submission and Product Feedback Policy</h2>
      	  <p>Skin Motion (or any of its employees or representatives) does not accept or consider 
          unsolicited ideas, including, but in no way limited to, ideas for new advertising campaigns, 
          new promotions, new or improved products or technologies, product enhancements, processes, 
          materials, marketing plans or new product names. Please do not submit any unsolicited ideas, 
          original creative artwork, suggestions or any other works (collectively “Submissions”) 
          in any form to Skin Motion (or any of its employees or representatives). The sole purpose 
          of this Skin Motion Unsolicited Idea Submission and Product Feedback Policy (“Policy”) is 
          to avoid potential misunderstandings or disputes when Skin Motion’s products or marketing 
          strategies might seem similar to ideas submitted to Skin Motion. If, despite our request 
          that you not send us your Submission, you still submit them, then regardless of what your 
          letter or other communication says when submitting such Submissions, the following terms 
          shall apply to your Submissions.</p>
      	  <h3>Terms of Idea Submission</h3>
      	  <p>You agree that:</p>
      	  <ol>
      	  	<li>Your Submissions and their contents will automatically become the property of 
            Skin Motion, without any compensation or attribution to you;</li>
      	  	<li>Without in any way intending to revise the previous language, Skin Motion may use 
            or redistribute the Submissions and their contents for any purpose and in any way;</li>
      	  	<li>There is no obligation for Skin Motion to review the Submission; and </li>
      	  	<li>There is no obligation to keep any Submission confidential.</li>
      	  </ol>
      	  <h3>Product Feedback</h3>
      	  <p>Skin Motion does, however, welcome your feedback regarding many areas of Skin Motion’s 
          existing business. If you want to send us your feedback, and we hope you do, we simply 
          request that you send it to us at support@skinmotion.com. Please provide only specific 
          feedback on Skin Motion’s existing products or marketing strategies; do not include any 
          ideas that this Policy will not permit us to accept or consider. Product feedback is 
          just one more way that Skin Motion can learn how to best satisfy your needs.</p>

      	  <h3>Feedback and Information</h3>

      	  <p>Any feedback you provide to Skin Motion shall be deemed to be non-confidential. 
          Skin Motion shall be free to use such information on an unrestricted basis without 
          notice, approval, compensation, or attribution to you.</p>
        </div>
      </div>
    )
  }
}

export default UnsolicitedIdeaPolicy