import API from "../utils/API";
import { ipGeoWrapper } from "../utils/geolocation";

export const RECEIVE_USER_LOCATION = "USER_LOCATION/RECEIVE_USER_LOCATION";
export const SET_SEARCH_LOCATION = "USER_LOCATION/SET_SEARCH_LOCATION";
export const CLEAR_SEARCH_LOCATION = "USER_LOCATION/CLEAR_SEARCH_LOCATION";

export const receiveUserLocation = (location) => ({
  type: RECEIVE_USER_LOCATION,
  location,
});

export const setSearchLocation = (searchObject) => ({
  type: SET_SEARCH_LOCATION,
  searchObject,
});

export const unsetSearchLocation = () => ({
  type: CLEAR_SEARCH_LOCATION,
});

// Tries to get geolocation data for the currentUser based on
// IP address. In case of failure, we fall back to the first
// stored geolocation we have for the user (calculated at signup)
export const getGeolocation = (currentUser) => (dispatch) => {
  // console.log("currentUser : ", currentUser.toJS());
  return ipGeoWrapper()
    .then((location) => {
      dispatch(receiveUserLocation(location));
      return Promise.resolve(location);
    })
    .catch(() => {
      if (currentUser) {
        let location = currentUser.get("geolocation").get(0);
        if (location) {
          dispatch(receiveUserLocation(location.toJS()));
          return Promise.resolve(location);
        } else {
          return Promise.reject();
        }
      } else {
        return Promise.reject();
      }
    });
};

export const dispatchSearchLocation = (searchObject) => (dispatch) => {
  return dispatch(setSearchLocation(searchObject));
};

export const clearSearchLocation = () => (dispatch) => {
  return dispatch(unsetSearchLocation());
};
