import React from 'react';
import { Link } from 'react-router-dom';

class AwaitingActivationMessage extends React.Component {

  componentDidMount() {
    window.scrollTo(0, 0); 
  }

  render() {
    return (
      <div className="sm-activation-end common-padding">
        <div className="text-center">
           <h2>Your Soundwave Tattoo&trade; is awaiting activation by a Skin Motion team member</h2>
        </div>
        <p>You will receive an email when it is ready to be played back using the Skin Motion app. For more information please visit our <a href="/faq">Frequently Asked Questions</a>.</p>
        <hr />
        {/* <p>Would you like to earn a free Soundwave Tattoo activation to give to a friend or
        loved one?</p>
        <p>Refer 25 people to create their own Soundwaves and earn a free Soundwave Tattoo activation
        with our referral program <Link to='/referrals'>here</Link></p>
        <hr />
        <p>Do you want a free Soundwave Tattoo activation? We are giving one away each week to our
        favorite posts on Instagram with the hashtag #mysoundwavestory. Share your tattoo and the
        story of it's meaning to you for your chance to win!</p> */}
      </div>
    )
  }
}

export default AwaitingActivationMessage