import React from 'react'

class ArtistDashboard extends React.Component {

	componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div className="sm-artist-dashboard common-padding">
        <h3 className="sm-artist-dashboard-first">Soundwave Tattoo&trade; Training Guide</h3>
        
        <p>Everything you need to know about Skin Motion app and Soundwave 
        Tattoos in one central training guide. Don't see your question answered? Please 
        let us know by emailing support@skinmotion.com.</p>

        <p className='mb-0'>
          <a className="btn btn-tan" href="/artist-guide">Artist Guide</a>
        </p>

        <h3>Marketing Guide</h3>

        <p>Best practices and requirements for marketing Soundwave Tattoos by Skin Motion.
        Our goal is to send you educated, passionate and committed life-long clientelle. 
        When advertising please follow the instructions in this guide. You will also find
        our logos, photo resources, and links you can use and post.</p>

        <p className='mb-0'>
          <a className="btn btn-tan" href="/marketing-guide">Marketing Guide</a>
        </p>

        <h3>Email Newsletter</h3>

        <p>Do you want to be featured in our regular email newsletter? Fill out this form and you may get featured to thousands of people around the world.</p>

        <p className='mb-0'>
          <a className="btn btn-tan" href="https://goo.gl/forms/jqCM2EHjKik3lowZ2" target="_blank">Featured Artist form</a>
        </p>

        <h3>Tag us on Instagram</h3>

        <p>Tag your Soundwave Tattoos with #soundwavetattoo on Instagram and there's a
        great chance that we'll repost it on our Instagram and Facebook.</p>

      </div>
    )
  }
}

export default ArtistDashboard