import React from "react";
import CardSection from "./CardSection";

class AddressSection extends React.Component {
  render() {
    const { currentUser } = this.props;
    return (
      <div>
        <h2>Payment Info</h2>
        <div className="payment-info-form">
          <div className="field-wrap">
            <label htmlFor="name">Full Name : </label>
            <input
              type="text"
              name="name"
              className="mb-0"
              placeholder="Name as it appears on card *"
              required
            />
          </div>
          <div className="field-wrap">
            <label htmlFor="address_line1">Address Line 1 : </label>
            <input
              type="text"
              name="address_line1"
              className="mb-0"
              placeholder="Address * "
              required
            />
          </div>
          <div className="field-wrap">
            <label htmlFor="address_line2">Address Line 2 : </label>
            <input
              type="text"
              name="address_line2"
              className="mb-0"
              placeholder=" "
            />
          </div>
          <div className="field-wrap">
            <label htmlFor="address_city">City : </label>
            <input
              type="text"
              name="address_city"
              className="mb-0"
              placeholder="City * "
              required
            />
          </div>
          <div className="field-wrap">
            <label htmlFor="address_state">State : </label>
            <input
              type="text"
              name="address_state"
              className="mb-0"
              placeholder="State * "
              required
            />
          </div>
          {/* <div className="field-wrap">
                <label htmlFor='card_details'>Card Details : </label>
                <input type="text" name="card_details" className='mb-0'  placeholder="Card number *  "  />
              </div> */}

          <CardSection />

          <input type="hidden" name="email" value={currentUser.get("email")} />
        </div>
      </div>
    );
  }
}

export default AddressSection;
