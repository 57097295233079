import React from 'react';

class MySoundwaveStory extends React.Component {

  render() {
    return (
      <div className="sm-mysoundwavestory">
      <div className='title-main' id='title-mysoundwavestory'>
        <h1>#mySoundwaveStory</h1>
      </div>
  		<div className="sm-mysoundwavestory-main">
  			<p>Coming Soon</p>
        
  		</div>
  	</div>
    )
  }
}

export default MySoundwaveStory