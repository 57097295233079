import React, { Component } from 'react';
import { connect } from 'react-redux';

class Title extends Component {

  state = {
    pageTitle: null,
    pageIdSlug: null
  }

  componentDidMount() {
    const location = this.props.location.pathname;
    let pageIdSlug = location.split('/')[1]

    const pages = {
      "account-home":"Account Home",
      "admin":"Admin Panel",
      "my-account": "My Account",
      "my-tattoos": "My Tattoos",
      "new-soundwave": "New Soundwave",
      "my-soundwaves": "My Soundwaves",
      "soundwaves": "Edit Soundwave",
      "tattoos": "My Tattoos",
      "account-settings": "Account Settings",
      "my-profile": "My Profile",
      "artist-profile": "Artist Profile",
      "artist-dashboard": "Artist Dashboard",
      "artist-guide": "Artist Training Guide",
      "referrals": "Referrals",
      "afflilate-admin": "Affliate Admin",
      "manage-users": "Manage Users",
      "manage-coupon": "Manage Coupon",
      "manage-faq": "Manage faq",
      "manage-news": "Manage News",
      "manage-videos": "Manage Videos",
      "reports": "Reports",
      "artist-applications": "Artist Applications",
      "support-tickets": "Support Tickets",
      "vouchers": "Vouchers",
      "payments": "Payments",
      "change-password": "Change Password",
      "deactivate-account": "Deactivate Account"
    }

    if(pageIdSlug in pages) {
      this.setState({pageIdSlug: pageIdSlug, pageTitle: pages[pageIdSlug]})
    }

    window.addEventListener('scroll', this.handleScroll);
  }

  render() {
    return (<div className='title-container'>
      {this.props.location == "/" ? null :
        (<div className="title-main title-account title-long" id={'title-' + this.state.pageIdSlug}>
          <h1>{this.state.pageTitle}</h1>
        </div>)
      }
    </div>)
  }

}

export default Title
