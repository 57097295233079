import React from 'react'; 
import { CardElement } from 'react-stripe-elements';

class CardSection extends React.Component {

  render() {
    return (
      <div className="field-wrap">
        <label>
          Card Details
        </label>
          <CardElement className="card-element-form" />
      </div>

    )
  }
}

export default CardSection