import Immutable from 'immutable';
import { RECEIVE_MOBILE_MODE, UNSET_MOBILE_MODE } from '../actions/mobileMode';

const mobileMode = (state = null, action) => {
  switch(action.type) {
    case RECEIVE_MOBILE_MODE:
      return Immutable.fromJS(true)
    case UNSET_MOBILE_MODE:
      return Immutable.fromJS(false)
    default:
      return state
  }
}

export default mobileMode